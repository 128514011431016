export const apiBaseUrl = {
    devServer: process.env.REACT_APP_API_URL
}

export const iosAppId = {
    LUCI_CAM: '6446018575',
    REAL_ESTATE: '6450928480',
}


export const errorConstant = {
    name: "name",
    full_name: "name",
    organisation_name: "company name",
    phone_number: "phone number",
    email: "email",
    password: "password",
    password_confirmation: "confirmation password",
    event_name: "event name",
    start_date: "start date",
    end_date: "end date",
}

// export const ROLE = {
//     ADMIN: 'admin',
//     MEMBER: 'MEMBER',
//     ASSOCIATE: 'associate'
// }

// export const PERMISSIONS = {
//     'user.create': [ROLE.ADMIN, ROLE.MANAGER],
//     'user.delete': [ROLE.ADMIN],
//     'user.edit': [ROLE.ADMIN, ROLE.MANAGER],
//     'user.read': [ROLE.ASSOCIATE, ROLE.ADMIN, ROLE.MANAGER]
// }

// export const ROLE_IDS = {
//     ORG_ADMIN: 1,
//     ORG_MEMBER: 2,
//     TEAM_LEAD: 3,
//     TEAM_MEMBER: 4,
//     PROJECT_MANAGER: 5,
//     COLLABORATOR: 6,
//     FILMER: 7,
//     REVIEWER: 8,
//     APPROVER: 9
// }

// export const ROLE_PERMISSIONS = {

//     'user.admin': [ROLE_IDS.ORG_ADMIN],
//     'user.teamlead': [ROLE_IDS.TEAM_LEAD],
//     'user.projectmanager': [ROLE_IDS.PROJECT_MANAGER],
//     'user.collaborator': [ROLE_IDS.COLLABORATOR],

//     'user.addOrgMember': [ROLE_IDS.ORG_ADMIN],
//     'user.removeOrgMember': [ROLE_IDS.ORG_ADMIN],
//     'user.editOrgMemberRole': [ROLE_IDS.ORG_ADMIN],
//     'user.viewOrgMembers': [ROLE_IDS.ORG_ADMIN, ROLE_IDS.ORG_MEMBER],

//     'user.addTeamMember': [ROLE_IDS.ORG_ADMIN, ROLE_IDS.TEAM_LEAD],
//     'user.removeTeamMember': [ROLE_IDS.ORG_ADMIN, ROLE_IDS.TEAM_LEAD],
//     'user.editTeamMemberRole': [ROLE_IDS.ORG_ADMIN, ROLE_IDS.TEAM_LEAD],


// }

export const PERMISSION = {
    ORGANISATION: {
        MEMBER: {
            VIEW: "o_organisation_member_view",
            ADD: "o_organisation_member_add",
            UPDATE: "o_organisation_member_update_roles",
            DELETE: "o_organisation_member_delete"
        },
        TEAM: {
            CREATE: "o_team_create",
            UPDATE_SCOPE: "o_team_update_scope",
            RENAME: "o_team_rename",
            DELETE: "o_team_delete",
        },
        PROJECT: {
            CREATE: "o_project_create",
            DELETE: "o_project_delete",
            DUPLICATE: "o_project_duplicate",
            IMPORT_FILES: "o_project_import_files",
        }
    },
    TEAM: {
        MEMBER: {
            VIEW: "t_team_members_view",
            ADD: "t_team_members_add",
            UPDATE: "t_team_members_update_roles",
            REMOVE: "t_team_members_remove",
        },
        PROJECT: {
            CREATE: "t_project_create",
            DELETE: "t_project_delete",
            DUPLICATE: "t_project_duplicate",
            IMPORT_FILES: "t_project_import_files",
        }
    },
    PROJECT: {
        INFO: {
            VIEW: "e_view_info",
            EDIT: "e_edit",
        },
        MEMBER: {
            VIEW: "e_project_member_view",
            ADD: "e_project_member_add",
            UPDATE: "e_project_member_update",
            REMOVE: "e_project_member_remove",
        },
        GUEST_INVITE: "e_guest_user_invite",
        EXTEND_EDITING_DATE: "e_extend_editing_date",
        MEDIA: {
            VIEW: {
                VIEW: "e_media_files_view",
                ALL: "e_media_files_view_all",
                SELF: "e_media_files_view_self",
                IMPORTED: "e_media_files_view_imported",
            },
            UPLOAD: "e_media_files_upload",
            IMPORT: "t_project_import_files",
            PLAYBACK: {
                ALL: "e_media_files_playback_all",
                SELF: "e_media_files_playback_self",
                IMPORTED: "e_media_files_playback_imported",
            },
            DOWNLOAD: {
                ALL: "e_media_files_download_all",
                SELF: "e_media_files_download_self",
                IMPORTED: "e_media_files_download_imported",
            },
            FAVORITE: {
                ALL: "e_media_files_favorite_all",
                SELF: "e_media_files_favorite_self",
                IMPORTED: "e_media_files_favorite_imported",
            },
            DELETE: {
                ALL: "e_media_files_delete_all",
                SELF: "e_media_files_delete_self",
                IMPORTED: "e_media_files_delete_imported",
            }
        },
        REVISION: {
            PAGE_VIEW: "e_revision_view_revision_page",
            ACCEPT_VIDEO: "e_revision_accept_video",
            REQUEST: {
                CREATE: "e_revision_create_request",
                SEND: "e_revision_send_request",
            },
            NOTES: {
                VIEW: "e_revision_notes_view",
                CREATE: "e_revision_notes_create",
                EDIT: "e_revision_notes_edit",
                DELETE: "e_revision_notes_delete",
            },
            FINAL_VIDEO: {
                VIEW: "e_final_video_view",
                DOWNLOAD: "e_final_video_download",
            }
        }
    }

}



export const PRODUCT_ID = {
    lucihub: 1
}

export const STATUS_TYPE = [
    {id: 1 , status: 'Pre-Production'},
    {id: 2 , status: 'Production'},
    {id: 3 , status: 'Post Production'},
    {id: 4 , status: 'Review'},
    {id: 5 , status: 'Complete'},
]