import React, { useEffect, useState } from 'react'
import { getInitials } from '../../../utils/commonServices'
import { Avatar, Box, Collapse, IconButton, ListItemText, Menu, MenuList, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeamState } from '../../../redux/teamSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateTeamMemberRole } from '../actions';
import usePermissions from '../../../hooks/usePermissions';
import { PERMISSION } from '../../../constants/constants';
import { colors } from '../../../constants/staticData';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';

const MembersList = ({member, index, testmembers, setTestmembers, roleOptions}) => {


    // const colors = ['red', 'pink', 'black'];

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRole, setSelectedRole] = useState(member?.role?.name);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [hasPermission] = usePermissions()
    const [theme ] = useCustomTheme()

    // const roleOptions = ['Manager', 'Team Manager', 'Associate', 'Administrator'];
    // const roleOptions = dropdownReducer.organisation_roles
    const [numberOfAdmins, setNumberOfAdmins] = useState(testmembers.filter(member => member?.role?.id === 3).length)

    // console.log({numberOfAdmins})
    // useEffect(()=>{
    //   dispatch(updateTeamState({selected_member: member}))
    // },[member])

    // useEffect(()=>{
    //   setSelectedRole(member.role.name)
    // },[member.role.name, member])

    
    useEffect(()=>{
        setNumberOfAdmins(teamsReducer.selected_team_members.users?.filter((item) => item.role.id == 3)?.length)  
    },[testmembers, teamsReducer.selected_team_members.users])
   

  
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
  
    const handleRoleSelect = (role) => {  
      if (numberOfAdmins === 1 && member?.role?.id == 3){
        dispatch(updateTeamState({assign_role_popup : true, change_admin_flag: true, invite_team_members_popup : false}))
      }else{
        const payload = {
          team_id:teamsReducer?.selected_team?.id,
          user_id: member?.id,
          role_id: role?.id
      }
      updateTeamMemberRole(payload, dispatch, navigate,  {commonReducer} ,setSelectedRole, role)
      // setSelectedRole(role.name);
     }
    setAnchorEl(null);
    };

    const handleDeleteMember =()=>{   
      if(member.role?.id == 3){
        if (numberOfAdmins == 1) {
          dispatch(updateTeamState({assign_role_popup : true}))
        } else {
          dispatch(updateTeamState({selected_member: member}))
          dispatch(updateTeamState({remove_member_confirmation_popup : true}))
        }
      }else{
        dispatch(updateTeamState({selected_member: member}))
        dispatch(updateTeamState({remove_member_confirmation_popup : true}))
      }
    dispatch(updateTeamState({invite_team_members_popup : false}))  
    }
  
   

  return (
    <div style={{width:'85%'}} >
        {testmembers?.length > 0 &&
            <>
            <Box mb={{xs:1, xl:3}} className='flex a-center' gap={2} >
    
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: colors[index % colors.length],
                                    color: 'white',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    fontWeight:600
                                    }}
                                >
                                    {getInitials(member.name)}
                                </Avatar>
                <Box flex={8} className='flex col' sx={{fontSize:{xs:'9px', md:'10px',lg:'13px', xl:'18px'}, fontWeight:600, color:'#000000', fontFamily:'Rubik'}}>
                   {member.name}
                    <br />
                    {/* <span style={{fontSize:'10px', fontWeight:500}}>{member.email}</span> */}
                </Box>
             <Box flex={6} className='flex a-center' style={{justifyContent:'end'}}>
                    <Typography sx={{ fontSize:  {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500, color:!member?.role?.change_allowed? 'gray':'#000', fontFamily:'Rubik' }}>
                        {/* { selectedRole || member.role.name} */}
                        {member?.role?.name}
                    </Typography>
                {teamsReducer.invite_team_members_popup == true && 
                    <IconButton edge="end" disabled = {teamsReducer.is_loading || !member?.role?.change_allowed}  onClick={handleClick}>
                        <ExpandMoreIcon  style={{ fontSize: '14px' }} />
                    </IconButton>
                }
                </Box>
            </Box>
     
            </>}
    

         <Menu
            id="role-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
               
                vertical: 0,
                horizontal: 150
            }}
          >
            {hasPermission(PERMISSION.TEAM.MEMBER.UPDATE) && roleOptions.map((role, index) => (
              <MenuItem key={index} onClick={() => handleRoleSelect(role)} sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70`, color:'black'}, backgroundColor: selectedRole == role.name && `${theme.palette.primary.main}99`}}>
              {/* {testmembers.filter(item => item.id == member.id)[0]?.role} */}
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Set as {role.name}</Typography></ListItemText>
                {selectedRole == role.name && <CheckRoundedIcon style={{fontSize:'10px', fontWeight:600}}/>}
              </MenuItem>
            ))}
            {hasPermission(PERMISSION.TEAM.MEMBER.REMOVE) && <MenuItem onClick={() => handleDeleteMember()} sx={{'&:hover':{backgroundColor: `${theme.palette.primary.main}70`, color:'black'}}}>
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#CE0101' } }}>Remove from Team</Typography></ListItemText>
            </MenuItem>}
     
        </Menu> 
    </div>
  )
}

export default MembersList