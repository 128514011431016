import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    permissions_list: [], 
    permissions_master: {
       o_organisation_member_view: false,  
       o_organisation_member_add: false,   
       o_organisation_member_update_roles: false,   
       o_organisation_member_delete: false,   
       o_team_create: false,   
       o_team_update_scope: false, 
       o_team_rename: false, 
       o_team_delete: false, 
       o_project_create: false, 
       o_project_delete: false, 
       o_project_duplicate: false, 
       o_project_import_files: false, 
       t_team_members_view: false, 
       t_team_members_add: false, 
       t_team_members_update_roles: false, 
       t_team_members_remove: false, 
       t_project_create: false, 
       t_project_delete: false, 
       t_project_duplicate: false, 
       t_project_import_files: false, 
       e_view_info: false, 
       e_edit: false, 
       e_project_member_view: false, 
       e_project_member_add: false, 
       e_project_member_update: false, 
       e_project_member_remove: false, 
       e_guest_user_invite: false, 
       e_extend_editing_date: false, 
       e_media_files_view: false, 
       e_media_files_view_all: false, 
       e_media_files_view_self: false, 
       e_media_files_view_imported: false, 
       e_media_files_upload: false, 
       e_media_files_playback_all: false, 
       e_media_files_playback_self: false, 
       e_media_files_playback_imported: false, 
       e_media_files_download_all: false, 
       e_media_files_download_self: false, 
       e_media_files_download_imported: false, 
       e_media_files_favorite_all: false, 
       e_media_files_favorite_self: false, 
       e_media_files_favorite_imported: false, 
       e_media_files_delete_all: false, 
       e_media_files_delete_self: false, 
       e_media_files_delete_imported: false, 
       t_project_import_files: false,
       e_revision_view_revision_page: false, 
       e_revision_accept_video: false, 
       e_revision_create_request: false, 
       e_revision_send_request: false, 
       e_revision_notes_view: false, 
       e_revision_notes_create: false, 
       e_revision_notes_edit: false, 
       e_revision_notes_delete: false, 
       e_final_video_view: false, 
       e_final_video_download: false, 
    },
    org_role_id: null,
    team_role_id: null,
    project_role_id: null,
    user_permissions: []
}


export const permissionSlice = createSlice({
    name: 'permission',
    initialState: initialValue,
    reducers: {
        updatePermissionState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetPermissionState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updatePermissionState, resetPermissionState } = permissionSlice.actions
export default permissionSlice.reducer