import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customTheme as theme } from "../../../theme/customTheme";
import luciLogo from '../../../assets/luci_dark_logo.svg'
import "@fontsource/rubik";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    // maxHeight: "60px",
    // minHeight: "40px",
    // height: "50px",
    display: "flex",
    backgroundColor: 'black',
    alignItems: "center",
    justifyContent: "center",
    // boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 1150,
    border:'1px solid #707070'
  },
}));

const Footer = () => {
  const classes = useStyles();
  const date = new Date()

  return (
    <>
      <Box className={classes.footer} sx={{height:{xs:'30px',md:'40px',lg:'40px',xl:'65px'}}}>
        <Box sx={{height:{xs:'18px',lg:'18px',xl:'26.5px'}}}>
            <img src={luciLogo} alt="Luci Logo" height='100%'/>
        </Box>
        <Typography
          variant="body1"
          sx={{ml: 0.5, fontFamily: 'Rubik', fontSize:{xs:'10px',md:'10px',lg:'10px',xl:'14px'}}}
          style={{
            color: 'white',
            fontWeight: 600,
          }}
        >
         {` © ${date?.getFullYear()} All Rights Reserved.`}
        </Typography>
      </Box>
    </>
  );
};

export default Footer;