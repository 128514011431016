import { Box, IconButton, Input, TextField, Typography, getContrastRatio } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { updateReviewState } from '../../../redux/reviewSlice'
import CustomInput from '../../eventcreation/components/CustomInput'
import RevisionLogNotes from './RevisionLogNotes'
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import CustomTooltip from '../../../components/CustomTooltip'
import usePermissions from '../../../hooks/usePermissions'
import { PERMISSION } from '../../../constants/constants'
import { generateDarkColor, generateLighterContrastColor } from '../../../utils/commonServices'
// import { customTheme as theme } from '../../../theme/customTheme'
import useCustomTheme from '../../../theme/useCustomTheme'
import useGuestReviewActions from '../useGuestReviewActions'
import { LoadingButton } from '@mui/lab'
import { setGuestReviewState } from '../../../redux/guestSlice'
import DeleteGuestLogConfirmationPopup from './DeleteGuestLogConfirmationPopup'


const GuestRequestLog = (props) => {
  const {disabled} =props
  const dispatch = useDispatch()

  const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
  const guestReducer = useSelector(state=> state.guestReducer)
  const {createGuestUserLog, updateGuestLog} = useGuestReviewActions()
  const [text, setText] = useState("");
  const [hasPermission] = usePermissions()
  const [theme ] = useCustomTheme()


  useEffect(()=> {
   dispatch(updateReviewState({timestamp:'00:00'}))
    // dispatch(setGuestReviewState({timestamp:'00:00'}))
  }, [])

  const saveToLogClickHandler = ()=>{
    if(text != "") {
      if(guestReducer?.edit_flag){

        const payload = {
          guest_reviewer_id: guestReducer?.guest_reviewer_id,
          timestamp: reviewEventReducer?.timestamp,
          message: text  
        }

        updateGuestLog({id: guestReducer?.log_id, param:payload}, clearRevisionInput)

      }else{
        const payload = {
          guest_reviewer_id: guestReducer?.guest_reviewer_id,
          revision_id: guestReducer?.event_details?.revision_id,
          timestamp: reviewEventReducer?.timestamp,
          message: text
          
        }
        // console.log({payload})
        createGuestUserLog(payload, clearRevisionInput)
      }
    }
  }


const clearRevisionInput = ()=> {
  setText("");
}



  return (
    <Box sx={{maxHeight:'700px',height:'100%',padding:'13px', display:'flex', flexDirection:'column' , }}>
        <Box className='flex a-center' textAlign='left' gap={1} sx={{marginBottom: {xs:'10px',md:'15px',lg:'20px',xl:'30px'}}}>
            <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'black', fontSize:{xs:'10px',md:'13px',lg:'14px',xl:'20px'}}}>Revisions Log</Typography>
        {
          !disabled && <CustomTooltip title= "How to submit revisions?" placement = "right" >
             <HelpOutlinedIcon onClick={()=> dispatch(updateReviewState({open_change_request_tutorial_popup : true}))} sx={{fontSize:{xs:'12px',lg:'16px', xl:'20px'}, cursor:'pointer'}}/>
          </CustomTooltip>
        }    
       
        </Box>
        <Box component='div' textAlign='left' sx={{overflowY: 'scroll', height: '60%'}} mb={1}>
          { 
            guestReducer?.logs?.length > 0 && guestReducer?.event_details?.logs.map((log)=> {
              return <Box mt={1}>
                {log?.deleted === false && <RevisionLogNotes color={generateDarkColor(log, 'username')} userName={log.username} message = {log.message} timestamp={log.timestamp} log={log} setText={setText}/>}
                </Box>
            })
          }
         
        </Box>
       {//hasPermission(PERMISSION.PROJECT.REVISION.NOTES.CREATE) && 
       <Box mt={1}>
            <Box flexGrow='1' textAlign='left'  >
              <CustomInput type='timestamp' disabled = {disabled} onChange={(e)=> setText(e.target.value)} value= {text} size='small' max={500} style={{backgroundColor:'white'}}  sx={{width:'100%', input:{}, }} placeholder='Type request to editor here…'/>   
            </Box>
        
            <LoadingButton 
                btnText='Save to Log'
                disabled = {disabled || reviewEventReducer.is_loading || text === '' }
                onClick={saveToLogClickHandler}
                color='button'
                loading={guestReducer?.create_log_loading}
                loadingPosition="start"
                variant='contained'  style={{borderRadius: '5px', color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main),opacity: (disabled || reviewEventReducer.is_loading ) && 0.6 , textTransform:'none', minWidth: '92px' }} sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:'100%', fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600}}>Save to Log</LoadingButton>
        </Box>}
       {guestReducer?.delete_log_confirmation_popup && <DeleteGuestLogConfirmationPopup />}
    </Box>
  )
}

export default GuestRequestLog