import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDropDownArray } from "../../../redux/dropdownSlice";
import { updateState } from "../../../redux/commonSlice";

export default (listType)=> {
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const dispatch = useDispatch();
  //  const {listType, counts = false, combinedArray} = props;

    const statusCheckHandler = (currentIndex) => {
        var tempArr = []
        if (currentIndex == 0) {                                //when user checks the view all, it checks whole list
            tempArr = dropdownReducer[listType]?.map((status, index) => {
                if(index == 0) {
                    return { ...status, checked: true }
                } else {
                    return { ...status, checked: false }
                }
            })
        } else {
            tempArr = dropdownReducer[listType]?.map((status, index) => {
                if (index == 0) {                               // when user unchecks any list item, it unchecks the view all
                    return { ...status, checked: (tempArr.filter((type) => type.checked == true)?.length + 1 == tempArr?.length) };
                } else if (index == currentIndex) {
                    if (status.checked == false || dropdownReducer[listType]?.filter((type) => type.checked == true)?.length > 1) {
                        // when user tries to uncheck the only checked list item, it does not allow
                        return { ...status, checked: !status.checked }
                    } else {
                        return status
                    }
                } else {
                    return status
                }
            })
            // when use selects all the list item, it also selects the view all
           // tempArr[0].checked = (tempArr.filter((type) => type.checked == true)?.length + 1 == tempArr?.length);
        }
        dispatch(setDropDownArray({ [listType]: tempArr }))
        onFilterChange(tempArr)
    }
    const onFilterChange = (types) => {
        // const eventsToFilter = listType == "status_type"? commonReducer.type_filtered_events : commonReducer.status_filtered_events
        const filteredEventsKey = listType == "status_type"? "status_filtered_events" : "type_filtered_events"
        let tempArr = []
        types?.map((type) => {
            if((type.id == 0) && type.checked == true) {
                tempArr = commonReducer.events
            } else if (type.checked == true) {
                if(listType == "status_type"){
                    tempArr = [...tempArr, ...commonReducer.events.filter((event) => event.status_type_order == type.order)]
                } else {
                    tempArr = [...tempArr, ...commonReducer.events.filter((event) => event.event_type == type.value)]
                }
                
            }
        })
        dispatch(updateState({ [filteredEventsKey]: tempArr }))

        let combinedFilter = [];
        if(listType == "status_type") {
            tempArr?.map((statusEvent)=> {
                combinedFilter = [...combinedFilter, ...commonReducer.type_filtered_events?.filter((typeEvent)=> statusEvent.id == typeEvent.id)];
            })
        } else {
            tempArr?.map((typeEvent)=> {
                combinedFilter = [...combinedFilter, ...commonReducer.status_filtered_events?.filter((statusEvent)=> typeEvent.id == statusEvent.id)];
            })
        }
        dispatch(updateState({ combined_filter: combinedFilter }))
    }

    const eventCountByStatus = (order) => {
        let eventArr = commonReducer.events.filter((event) => event.status_type_order == order)
        return (order == 0 ? commonReducer.events.length : eventArr.length)
    }

    useEffect(()=> {
        dropdownReducer[listType]?.map((status, index)=> {
           if (status.checked == true ){
            statusCheckHandler(index)
           }
        })

    },[commonReducer.events])

    return {eventCountByStatus, statusCheckHandler}
}