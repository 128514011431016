import React, { useState } from "react";
import { createTheme, responsiveFontSizes,  } from "@mui/material";
import "@fontsource/rubik";
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { alpha, getContrastRatio } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { generateLighterContrastColor } from "../utils/commonServices";


export default () => {
    
    // const primaryColor = localStorage.getItem('primary')
    // const secondaryColor = localStorage.getItem('secondary')
    const themeReducer = useSelector((state) => state.root.themeReducer);
    const primaryColor = themeReducer?.primary
    const secondaryColor = themeReducer?.secondary
  const customTheme = responsiveFontSizes(
        createTheme({
          // props: {
          //   // Name of the component ⚛️
          //   MuiButtonBase: {
          //     // The default props to change
          //     disableRipple: true, // No more ripple, on the whole application 💣!
          //   },
          // },
          breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 960,
              lg: 1280,
              xl: 1920,
            },
          },
          palette: {
            primary: {
              main: (primaryColor == null || primaryColor == undefined || primaryColor  == '') ? '#00d744' : primaryColor,
              // main: primaryColor,
            //   main: "#00D744",
              // light: "#52636C",
              // dark: "#00111A",
              // contrastText: "#000",
            },
            secondary: {
              main: (secondaryColor  == null || secondaryColor  == undefined || secondaryColor  == '') ? "#00ceff" : secondaryColor,
              // main:secondaryColor,
            //   main: "#00CEFF",
              // light: "#93e3ff",
              // dark: "#00b3de",
              // contrastText: "#fff",
            },
            button:{
              main: '#08231B',
              // contrastText: getContrastRatio('#08231B', ((primaryColor == null || primaryColor == undefined || primaryColor  == '')  ? '#00d744' : primaryColor )) > 4.5 ? primaryColor : generateLighterContrastColor(primaryColor),
            },
            // containerBackground: {
            //     main: "#F5F1E9",
            //     light: "rgb(247, 243, 237)",
            //     dark: "rgb(171, 168, 163)",
            //     contrastText: "rgba(0, 0, 0, 0.87)"
            // },
            white: {
              main: "#ffffff",
              // light: "rgb(255, 255, 255)",
              // dark: "#F5F1E9",
              // contrastText: "rgba(0, 0, 0, 0.87)",
            },
            inputs: {
              main: "#004B64",
              // light: "#39728D",
              // dark: "#00223F",
              // contrastText: "#FFFFFF87",
            },
            disabled: {
              main: "#52636C",
              light: "#e4e4e4",
              dark: "#cdcdcd",
              contrastText: "#FFFFFF",
            },
            // action: {
            //     main: "",
            //     light: "",
            //     dark: "",
            //     contrastText: ''
            // },
            // error: {
            //     main: '',
            //     dark: '',
            //     light: ''
            // }
          },
          typography: {
            htmlFontSize: 16,
            //fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
            fontFamily: 'Rubik',
            fontSize: 14,
            lineHeight: 1,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
      
            h1: {
              fontSize: 62,
            },
            h2: {
              fontSize: 48,
            },
            h3: {
              fontSize: 40,
            },
            h4: {
              fontSize: 32,
            },
            h5: {
              fontSize: 26,
            },
            h6: {
              fontSize: 20,
            },
      
            subtitle1: {
              fontSize: 22,
            },
            subtitle2: {
              fontSize: 18,
            },
            body1: {
              fontSize: 16,
            },
            body2: {
              fontSize: 14,
            },
            button: {
             // textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 20,
            },
          },
      
          components: {
            MuiTextField: {
              styleOverrides: {
                root: {
                  '--TextField-brandBorderColor': '#000000',
                  '--TextField-brandBorderHoverColor': '#000000',
                  '--TextField-brandBorderFocusedColor': '#000000',
                  // '& label.Mui-focused': {
                  //   color: 'var(--TextField-brandBorderFocusedColor)',
                  // },
                },
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                notchedOutline: {
                  borderColor: '#000000',
                },
                root: {
                  [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: '#000000',
                  },
                  [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: '#000000',
                  },
                },
              },
            },
            // MuiFilledInput: {
            //   styleOverrides: {
            //     root: {
            //       '&::before, &::after': {
            //         borderBottom: '2px solid var(--TextField-brandBorderColor)',
            //       },
            //       '&:hover:not(.Mui-disabled, .Mui-error):before': {
            //         borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            //       },
            //       '&.Mui-focused:after': {
            //         borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            //       },
            //     },
            //   },
            // },
            // MuiInput: {
            //   styleOverrides: {
            //     root: {
            //       '&::before': {
            //         borderBottom: '2px solid var(--TextField-brandBorderColor)',
            //       },
            //       '&:hover:not(.Mui-disabled, .Mui-error):before': {
            //         borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            //       },
            //       '&.Mui-focused:after': {
            //         borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            //       },
            //     },
            //   },
            // },
          },
        
          overrides: {
            
            MuiCssBaseline: {
              "@global": {
                body: {
                  backgroundColor: "#F4F4F4",
                },
              },
            },
      
            // MuiPickersToolbar: {
            //     root: {
            //       backgroundColor: "#002832",
            //     },
            //   },
            //   MuiPickersCalendarHeader: {
            //     root: {
            //       // backgroundColor: lightBlue.A200,
            //       // color: "white",
            //     },
            //   },
            //   MuiPickersDay: {
            //     root: {
            //       color: "#FFFFFF",
            //       "&$disabled": {
            //         color: "rgba(0, 0, 0, 0.87)",
            //       },
            //       "&$selected": {
            //         backgroundColor: "#002832" ,
            //       },
            //     },
            //     today: {
            //       color: "'#52636C'",
            //     },
            //   },
            //   MuiPickersModalDialog: {
            //     dialogAction: {
            //       color: "'#52636C'"
            //     },
            //   },
          },
        })
      );


  return [customTheme];
};