import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../events/components/CustomAlert";
import "@fontsource/rubik";
import useAuthRequest from "../../../../hooks/useAuthRequest";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import { makeStyles } from "@mui/styles";
import { gaEvents } from "../../../../ga/actions";

const useStyles = makeStyles((theme) => ({
    centerBox:{
     display:'flex',
     justifyContent:'center',
     alignItems:'center',
    },
    text:{
     textAlign:'center',
     textTransform:'uppercase'
    },
    movieIcon : {
     maxWidth: 170
    }
 }))

const EmailVerificationPopup = (props) => {

   //const [open, setOpen] = useState(true);
   const navigate = useNavigate();
   const {openHandler, closeHandler, token, forgotPopupHandler} = props;
   const [emailVerification, emailVerificationStatus] = useAuthRequest("verify-mail")
   const [emailVerificationMessage, setEmailVerificationMessage] = useState("")
   const classes = useStyles();

   useEffect(()=> {
       emailVerification({token});
   }, [])
 
   useEffect(()=> {
     if(emailVerificationStatus == "loading") {
       setEmailVerificationMessage("Verifying...")
       }
     if(emailVerificationStatus == "success") {
       gaEvents.emailVerified();
       setEmailVerificationMessage("Congratulations! Your email has been verified successfully. Please proceed to Sign In")
     }
     if(emailVerificationStatus == "already_verified") {
       setEmailVerificationMessage("This email has been verified earlier. Please proceed to Sign In")
     }
     if(emailVerificationStatus == "error") {
       setEmailVerificationMessage("The verification link seems invalid. Please proceed to Forgot Password and get new link")
     }
   }, [emailVerificationStatus])
 

   const handleClose = () => {
       setTimeout(()=> {
           if(emailVerificationStatus == "error") {
               // navigate("/signin?forgot-password=true" );
               // window.location.reload();
             //  forgotPopupHandler(true)
           }
           // } else {
           //     navigate("/signin");
           // }
        
       }, 200);
       closeHandler(false);
     };

       //code to detect enter key for editing Organization Name
 useEffect(() => {
   const keyDownHandler = (event) => {
       if (event.key === 'Enter') {
           event.preventDefault();
           handleClose();


       }
   };
   document.addEventListener('keydown', keyDownHandler);

   return () => {
       document.removeEventListener('keydown', keyDownHandler);
   };
}, []);
   

  
  return (
    
        <CustomAlert 
          open={openHandler} 
          handleClose={handleClose} 
          width={{xs:'400px',sm:'400px',md:'420px',lg:'440px', xl:'600px'}}
          height={{xs:'340px',sm:'280px',md:'300px',lg:'300px', xl:'380px'}}
        >
           <Box p={5} className="flex col" sx={{justifyContent:'space-evenly', height:'100%'}}>

           <Box className= 'flex row j-center' width='100%' >
            {
                emailVerificationStatus == "error" ? <CancelRoundedIcon style={{fontSize:'30px', color:'#AA4A44'}}/> :
                    emailVerificationStatus == "loading" ? <HourglassBottomRoundedIcon style={{fontSize:'30px', color:'#B2BEB5'}}/> :
                        <CheckCircleRoundedIcon style={{fontSize:'30px', color:'#1C4328'}}/>
            }
             
            
           </Box>
          
           <Box flexDirection='column'>
           <Typography variant="h4" sx={{ textAlign: 'center', fontFamily:'Rubik', fontSize:{xs:'23px',sm:'23px',md:'23px',lg:'23px',xl:'28px'}, mb:2, color:'black'}}>{ emailVerificationStatus=="loading" ? "VERIFYING..." : 
                  emailVerificationStatus== "success" || emailVerificationStatus== "already_verified" ?  "EMAIL VERIFIED!" : "VERIFICATION FAILED!"}
           </Typography>
           <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'13px',xl:'18px'}, color:'black'}}> {emailVerificationMessage}</Typography>
              <Box className='flex row j-end' width='100%' mt={2}>
              <CustomButton
                 // disabled={signinStatus == "loading"}
                  btnText={emailVerificationStatus != "error" ? "SIGN IN" : "RESEND LINK"}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleClose}
                  style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#21D644', textTransform:'none', }} 
                  sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'100px', lg:'120px',xl:'140px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,}}
                />
                </Box>
           </Box>
           </Box>
        </CustomAlert>
      
  )
}

export default EmailVerificationPopup