
import React, { useState } from 'react'
import { Cropper } from 'react-cropper';
import { Box, Grid, Typography } from "@mui/material";
import "cropperjs/dist/cropper.css";
import { makeStyles } from '@mui/styles';
import CustomAlert from '../../events/components/CustomAlert';
import CustomButton from '../../../components/CustomButton';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';

export default function CustomCropperModal(props) {
    const classes = useStyles();
    const [theme ] = useCustomTheme()
    const { toggleHandler, openHandler, image, uploadAgain, onSubmitImage, setImage, onChange } = props;

    const [cropper, setCropper] = useState("");

    const handleClose = () => {
        toggleHandler(false);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            onSubmitImage(cropper?.getCroppedCanvas()?.toDataURL());
            // setImage(cropper.getCroppedCanvas().toDataURL())
            handleClose();

        }
    };

    return <CustomAlert   height={{xs:'900px'}}
    width={{md:'65%',lg:'65%', xl:'1400px'}} handleClose={handleClose} open={openHandler}>
        <Box className='flex col' container sx={{width:'100%', height:'100%',justifyContent:'space-between', alignItems:'center', }}  px={5} py={3} gap={1}>
            <Box pt={4} sx={{width:'90%', display:'grid', placeContent:'center'}}>
                <Cropper
                    style={{ height: 320, width: "90%" }}
                    zoomTo={0.8}
                    zoomable
                    initialAspectRatio={7/3}
                    // aspectRatio={7/3}
                    preview=".img-preview"
                    src={image}
                    viewMode={0}
                    minCropBoxHeight={50}
                    minCropBoxWidth={50}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    guides={true}
                />
                <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'10px',md:'10px',lg:'11.67px',xl:'17px'}, fontFamily:'Rubik'}} textAlign={'end'}>Use scroll to zoom-in and zoom-out.</Typography>
            </Box>

            <Box className='flex row' sx={{width:'100%', justifyContent:'flex-end', alignItems:'center'}} gap={2}>
            <Box >
                <input
                    accept="image/*"
                    className={classes.input}
                    id="upload_again"
                    type="file"
                    onChange={(e)=>onChange(e)}
                />

                <label htmlFor="upload_again">
                        {/* <Typography textAlign={'center'} alignContent={'end'} style={{
                               borderRadius: '5px',
                            //    border: "none",      
                            color:theme.palette.primary.main,    
                               textTransform:'none',
                               cursor:'pointer',
                            //    backgroundColor:'red'
                               }}
                           sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"36px"}, width:{xs:'150px',sm:'150px',md:'150px',lg:'150px',xl:'255px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'12px'}, marginTop:{xl:1}}}>Upload Again</Typography> */}
                    <CustomButton
                        btnText='Upload Again'
                        variant='outlined'
                        color='secondary'    
                        component='span'
                        // color='primary'      
                           style={{
                               borderRadius: '5px',
                            //    border: "none",          
                               textTransform:'none',
                               // backgroundColor:theme.palette.secondary.main
                               }}
                           sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"36px"}, width:{xs:'150px',sm:'150px',md:'150px',lg:'163px',xl:'255px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'12px'}, marginTop:{xl:1}}}
                    />
                </label>
            </Box>
            <Box >

                <CustomButton
                    btnText='Continue'
                    variant='contained'
                    // color='primary'
                    onClick={getCropData}
                    color='secondary'
                           
                            style={{
                                borderRadius: '5px',
                                border: "none",          
                                textTransform:'none',
                                // backgroundColor:theme.palette.secondary.main
                                }}
                            sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"36px"}, width:{xs:'150px',sm:'150px',md:'150px',lg:'163px',xl:'255px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'12px'}, marginTop:{xl:1}}}
                />
            </Box>
            </Box>
        </Box>

    </CustomAlert>
}

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
}));