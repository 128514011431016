import React, { useEffect } from 'react'
import CustomAlert from './CustomAlert'
import CheckIcon from '../../../components/icons/CheckIcon'
import { Box, Typography, getContrastRatio } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import duplicatingFilesImage from '../../../assets/importPopup.svg'
import { useState } from 'react'
import { updateState } from '../../../redux/commonSlice'
import useEventList from '../../dashboard/hooks/useEventList'
import useUploadedList from '../../../hooks/useUploadedList'
import { setAllMediaFilesArray } from '../../../redux/mediaFilesSlice'
import { generateLighterContrastColor } from '../../../utils/commonServices'
import useCustomTheme from '../../../theme/useCustomTheme'
import DuplicateFilesImage from './assets/DuplicateFilesImage'

const DuplicatingFilesPopup = (props) => {

    const {importFilesStatus, displayCloseButton } = props

    const commonReducer = useSelector((state) => state.root.commonReducer);
    const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);
   // const [uploadedVideos, refetchList] = useUploadedList(true);
    const dispatch = useDispatch()
    const [theme] = useCustomTheme()

    const handleClose = () => {
        dispatch(setAllMediaFilesArray({import_files_confirmation_popup : false}))
       };

    const filesDuplicatedClickHandler = ()=>{
        handleClose()
    }


  return (
    <CustomAlert
    open={mediaFilesReducer?.import_files_confirmation_popup}
    handleClose={handleClose}
    rightComponent={
        <Box  sx={{ paddingX:{xs:'0.5rem',md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%', display:'grid', placeItems:'center', }}>
           <Box sx={{height:'50%'}}  className= 'flex col j-center a-center' mt={{xs:5,sm:0}}> 
           <DuplicateFilesImage sx={{maxWidth: '200px', width:'100%', height: '100%'}} />
                {/* <img src={duplicatingFilesImage} style={{maxWidth: '200px', width:'100px'}} height='100%'/> */}
           </Box>
         </Box>
        }
    >
        <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}  px={5} pt={4} pb={5.8}>
            <Box flexGrow={1} pt={7} >
                <Typography  sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}}}>{displayCloseButton? 'Your files have been duplicated.': 'We are duplicating your files now.'}</Typography>
                
                <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>{displayCloseButton ? 'Files have been added to your project.': 'Please do not close the browser window.'}</Typography>
            </Box>

            {/* box to be displayed in files duplicating state */}
            {/* <Box flexGrow={1} pt={7} >
                <Typography  sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}}}>We are duplicating your files now.</Typography>
                
                <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>Please do not close the browser window.</Typography>
            </Box> */}
            {displayCloseButton && (
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}} pr={1.5}>
                <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick={filesDuplicatedClickHandler} disabled = {!displayCloseButton}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:  'pointer', }}/>
            </Box>
            )}
        </Box>
    </CustomAlert>
  )
}

export default DuplicatingFilesPopup