import { Backdrop, Box, Slide, Typography, getContrastRatio } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import CustomInput from './CustomInput';
import { updateCreationState } from '../../../redux/eventCreationSlice';
import { bytesToMegabytes, generateLighterContrastColor, getFileType, setInput } from '../../../utils/commonServices';
import UpIcon from '../icons/UpIcon';
import DownIcon from '../icons/DownIcon';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


const ProjectMusicPage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex, selectedProjectType, setSelectedProjectType, totalScreens, closeHandler} = props
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const dispatch = useDispatch()
    const [theme ] = useCustomTheme()
    const [fileFormatError, setFileFormatError] = useState(false)
    const [notesErrorMessage, setNotesErrorMessage] = useState('')

    useEffect(() => {
      const keyDownHandler = (event) => {
        // console.log("key Parent", event.key)
        if (event.key === "Enter") {
          event.preventDefault();
  
          nextPageHandler();
        }
      };
        document.addEventListener("keydown", keyDownHandler)
        
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [pageData]);

    const uploadSongHandler = (event, newValue) => {
      if(!event.target.files[0]?.type?.includes('application') || (getFileType(event.target.files[0].name) === 'unknown')){
        setFileFormatError(true)
        setNotesErrorMessage('Please upload file in valid format. (pdf, doc, docx, rtf)')
      }else if(bytesToMegabytes(event.target.files[0].size) >= 100){
        setFileFormatError(true)
        setNotesErrorMessage('File size should be less than 100 Mb')
      }else{
        setFileFormatError(false)
        setPageData((last) => {
          return { ...last, song: event.target.files[0]?.type?.includes('application') ? event.target.files[0] : null };
        });
      }
  }

    return (
        <>
        <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(23px) !important', display:'flex', flexDirection:'column', background: '#08231B99 0% 0% no-repeat padding-box', }}
        open={createEventReducer.open_backdrop_project_creation}
        >
            <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
              <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3, fontFamily:'Rubik'}} onClick={closeHandler}>Close X</Typography>
              <Box flexGrow={1} className='flex col ' sx={{ paddingLeft:'14vw', marginTop:{xs:'20%',sm:'14%',md:'8%', lg:'7%'}}}>
                  <Box mb={6}>
                    <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                    <Typography sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>Type of music for your Lucihub Video:</Typography>
                    <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>Describe the type of music you want. Or upload your music using the 'Music Files' button within the project</Typography>
                  </Box>
                  <Box className='flex' sx={{width:'80%'}}>
                  <CustomInput
                    type='sendInput'
                    required
                    medium
                    fullWidth
                    max={500}
                    minRows={2}
                    multiline={true}
                maxRows={8}
               
                    placeholder="Enter track name, mood, genre..."
                    onChange={(event) =>
                      {
                      // setNametooltip(false)
                      setInput(
                          event.target.value,
                          "song_type",
                        pageData,
                        setPageData
                      )}
                    }
                    value={pageData?.song_type}
                    onButtonClick={pageIndex !== 8 ? nextPageHandler : null}
                   />
                  </Box>

                        {/* <Box sx={{marginBottom:{xs:'10px',sm:'15px',md:'15px',lg:'20px',xl:'57px'}}}>
                              
                                <input
                                    accept=".mp3,audio/*"
                                    id="contained-button-file"
                                    onClick={event => event.target.value = ''}
                                    style={{display:'none'}}
                                    type="file"
                                    onChange={(event, newValue) => uploadSongHandler(event, newValue)}
                                />
                            <Box sx={{display:'flex', gap:1, alignItems:'center'}}>
                            <label htmlFor="contained-button-file">
                                <Typography textAlign={'left'} variant='body1' sx={{fontSize:{xs:'13px',lg:'15px',xl:'20px'}, fontWeight:600, fontFamily:'Rubik', cursor:'pointer', textDecoration:'underline', color:theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)}} >Upload Music</Typography>
                            </label>
                            <Typography textAlign={'left'} variant='body1' sx={{fontSize:fileFormatError ? '10px' :'11px', fontWeight:600, fontFamily:'Rubik', ml:1, color: fileFormatError && 'red'}} >{fileFormatError ? notesErrorMessage : (!pageData?.song ? "(Maximum 100 MB, MP3, WAV, or RTF only)" : pageData?.note?.name )}</Typography>

                           {pageData?.song?.name && !fileFormatError && <CloseRoundedIcon 
                                onClick={()=>setPageData((last) => {
                                          return { ...last, note: null }
                                        })} 
                                style={{color:'red', fontSize:'12px', cursor:'pointer'}} 
                            />}

                            </Box>
                        </Box> */}

              </Box>
              <Box className='flex' sx={{justifyContent:'flex-end',gap:0.5, mb:3, mr:3}}>
                {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer', opacity: pageIndex === 1 && 0.5}} onClick={pageIndex !== 1 ? prevPageHandler : null}>/\</Box> */}
                {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer',  opacity: pageIndex === 8 && 0.5}} onClick={pageIndex !== 8 ? nextPageHandler : null}>\/</Box> */}
                <UpIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', opacity: pageIndex === 1 && 0.5, fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
                <DownIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 8 ? nextPageHandler : null}/>
              </Box>
            </Box>
        </Backdrop>
        </Slide>
        </>
      )
}

export default ProjectMusicPage