
import { Swiper, SwiperSlide } from 'swiper/react';
import error from '../../../assets/filmingTutorialCard.png'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination ,  Navigation} from 'swiper/modules';
import { Box } from '@mui/material';
import { useState } from 'react';
import useCustomTheme from '../../../theme/useCustomTheme';

export default function CustomCarousel({slides}) {
  const pagination = {
    clickable: true,
  };
const [theme] = useCustomTheme()
  const [primaryColor, setPrimaryColor] = useState(`${theme.palette.primary.main}99`);

  return (
    <Box sx={{height:{xs:'200px',lg:'270px', xl:'300px'}, 
             // width:{xs:'350px',sm:'400px',md:'450px',lg:'510px', xl:'650px'}
             width: {xs: "350px", sm:"400px", md: "35vw", xl:"32vw"}
    }}>
      <Swiper
         slidesPerView={1}
         loop={true}
        pagination={pagination}
        // navigation={true}
        modules={[Pagination]}
        className="mySwiper"
        style={{'--primary-color': primaryColor}}
      >
        <Box sx={{height:'20%'}}>
            {slides.length > 0 && slides.map(slide =>(
                <SwiperSlide key={slide.img}>
                    <img src={slide.img} alt={slide.alt} style={{objectFit: 'contain'}} />
                </SwiperSlide>
            ))}
        </Box>
      </Swiper>
    </Box>
  );
}