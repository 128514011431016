import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    pageData: {
        name : "",
        public: true,
        members: [], 
    },
    events: [],
    status_filtered_events: [],
    type_filtered_events: [],
    combined_filter: [],
    open_create_team_form: false,
    delete_team_popup: false,
    rename_team_popup: false,
    invite_team_members_popup:false,
    remove_member_confirmation_popup:false,
    assign_role_popup:false,
    member_removed_popup:false,
    organization_members: [],
    selected_member: null,
    view_members_popup:false,
    all_teams:[], 
    selected_team:  null, 
    selected_team_members: [],
    change_admin_flag: false,
    loading_state: 'noloading',
    prev_team_lead: null,
    prev_org_admin: null,
    is_loading: false,
    events_loading: false,
}


export const teamSlice = createSlice({
    name: 'teams',
    initialState: initialValue,
    reducers: {
        updateTeamState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetTeamState: (state) => {
            assignValueInState(initialValue, state)
        },
        resetTeamPageData: (state) => {
            assignValueInState(initialValue.pageData, state.pageData)
        }
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateTeamState, resetTeamState , resetTeamPageData} = teamSlice.actions
export default teamSlice.reducer