import React from 'react'
import CustomAlert from '../../events/components/CustomAlert'
import { Box, Button, Typography, getContrastRatio } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { updateReviewState } from '../../../redux/reviewSlice'
import CheckIcon from "../../../components/icons/CheckIcon";
import { LoadingButton } from "@mui/lab";
import "@fontsource/rubik";
import { useMutation } from 'react-query'
import { acceptFinalVideo } from '../../../services/api'
import useAcceptFinalVideo from '../hooks/useAcceptFinalVideo'
import useCustomTheme from '../../../theme/useCustomTheme'
import { generateLighterContrastColor } from '../../../utils/commonServices'



const AcceptVideoPopup = (props) => {
    const {open, acceptRequest, status} = props;
    const dispatch = useDispatch()
    const reviewEventReducer = useSelector((state)=>state.reviewEventReducer)
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [theme ] = useCustomTheme()

    const handleClose = () => {
        dispatch(updateReviewState({open_video_accept_popup : false}))    
       };



    const acceptVideoHandler =()=>{
        // send api req to accept video
        acceptRequest({final_video_id: commonReducer.event_details?.final_video_id})    
        // dispatch(updateReviewState({open_video_accept_popup : false})) 
    }

    return (
    
        <CustomAlert 
          open={open} 
          handleClose={handleClose} 
          width={{xs:'400px',sm:'400px',md:'420px',lg:'440px', xl:'600px'}}
          height={{xs:'245px',sm:'235px',md:'255px',lg:'275px', xl:'350px'}}
        >
           <Box p={5} className="flex col" sx={{justifyContent:'space-evenly', height:'100%'}}>
          
            <Box   mb={{xs:1 ,md:2}}>
              <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'13px',xl:'18px'}, color:'black'}}>Once you accept the video, this project will be marked as closed. </Typography>
            </Box>
    
            <Box sx={{display:'flex',justifyContent:'end' }} mt={{xs:1 ,md:3}} >
    
              {/* <Button variant='outlined' onClick={acceptVideoHandler} style={{borderRadius: '5px', backgroundColor:'#08231B', color:theme.palette.primary.main, textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'90px',sm:'100px',md:'100px',lg:'120px',xl:'150px'}, paddingY:0.4,}}>Accept Video</Button> */}

              {/* { createEventStatus == 'loading' || createEventStatus == 'success' ? <LoadingButton
                   // size="small"
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.primary.main,
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={createEventStatus == 'loading'}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> : */}
               <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {acceptVideoHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:  'pointer',}}/>
               {/* } */}

              {/* <Button
                    // disabled={!checked || addConsentLoading}
                  
                    style={{borderRadius: '5px', backgroundColor:'#08231B', color:theme.palette.primary.main, textTransform:'none', }} 
                    sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},  opacity: !checked && 0.7, minWidth: '110px !important'}}
                    // loading={addConsentLoading}
                    // loadingPosition="start"
                    variant="contained"
                    >
                   Continue
               </Button>  */}
            </Box>
           </Box>
        </CustomAlert>
      
  )
}

export default AcceptVideoPopup