import { Box, Button, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import { updateCreationState } from "../../../redux/eventCreationSlice";
import { generateLighterContrastColor, timeStampToDateString, timeStampToDayString, timeStampToNumberedDateString } from "../../../utils/commonServices";
import { LoadingButton } from "@mui/lab";
import { updateTeamState } from "../../../redux/teamSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { removeOrganisationMember } from "../../dashboard/actions";
import { removeTeamMember } from "../actions";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";
import RemoveUserImage from "../assets/RemoveUserImage";


const RemoveOrganisationMemberConfirmationPopup = (props) => {
    const {open, setOpen, submitRevision, success, newEditDatePageData, submitRequestClickHandler, requestStatus} = props
   
    const dispatch = useDispatch()
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const location = useLocation()
    const navigate = useNavigate()
    const [memberRemoved, setMemberRemoved] = useState(false)
    const [theme ] = useCustomTheme()
   
// console.log('Organisation member confirmation popoup')
    const handleClose = () => {
        dispatch(updateTeamState({remove_member_confirmation_popup : false, loading_state: 'noloading'}))

    };

       const removeMemberClickHandler = ()=>{
          // const updatedMembers = teamsReducer.organization_members?.filter(member => member.id !== teamsReducer.selected_member?.id)
          // dispatch(updateTeamState({organization_members: updatedMembers}))
          ///////////////////api to remove org member //////////////////////
          const payload = {
            user_id: teamsReducer?.selected_member?.id
          }
        removeOrganisationMember(payload, dispatch, navigate, {commonReducer:commonReducer})
        setMemberRemoved(true)
       }

   

    return (
        <CustomAlert 
          open={teamsReducer.remove_member_confirmation_popup} 
          handleClose={handleClose} 
          rightComponent={
          <Box sx={{display:'flex', flexDirection:'column', paddingX:{md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
            <Box  sx={{height: '100%'}} className= 'flex col j-center a-center'> 
              <RemoveUserImage sx={{width: '200px', height: '60%'}} />
                {/* <img src={RemoveUserIcon} style={{maxWidth: '200px'}} height='60%'/> */}
            </Box>
          </Box>}
          >       
            {(teamsReducer.loading_state !== 'success' || teamsReducer.loading_state === 'loading')  && <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={3} pb={2}>
              <Box flexGrow={1} pt={7}>
                  <Typography sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}, color:'#000'}}>Are you sure you want to remove this member from the Organization?</Typography>

                  <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word', color:'#000'}}>
                    <span style={{fontWeight:600}}>Note: </span>This action cannot be undone. 
                  </Typography>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               { teamsReducer.loading_state === 'loading' ? <LoadingButton
                   // size="small"
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.primary.main,
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={teamsReducer.loading_state === 'loading'}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> :
               <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {removeMemberClickHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
               }
                  {/* <CheckIcon onClick= {finishedHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:  'pointer', }}/> */}
                </Box>
              <Box>    
                <Button 
                variant="text"
                onClick={handleClose}
                 sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'120px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                Or Go Back
               </Button>
              </Box>
            </Box>
            </Box>}

            {teamsReducer.loading_state === 'success' && memberRemoved == true &&
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={3} pb={2}>
              <Box flexGrow={1} pt={10}>
                  <Typography sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}, color:'#000'}}>The user has been removed from your organization.</Typography>

                  <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word', color:'#000'}}>
                    <span style={{fontWeight:600}}>{`${teamsReducer.selected_member?.name}`}</span> has been removed. 
                  </Typography>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {handleClose}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
               
                </Box>
              
            </Box>
          </Box>
          }
       
        </CustomAlert>
      )
}

export default RemoveOrganisationMemberConfirmationPopup