import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { showSnackBar } from "../../utils/commonServices";
import {  watchVideoApi } from "../../services/api";
import { setGuestReviewState } from "../../redux/guestSlice";
import { setTheme } from "../../redux/customThemeSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { gaEvents } from "../../ga/actions";



export default ()=> {
 
  const dispatch = useDispatch();
  const commonReducer = useSelector(state=> state.root.commonReducer)
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get('c')
  const guestReducer = useSelector(state=> state.guestReducer)
  const { id } = useParams();
  console.log(id)


      const getVideoLinkDetails = (payload, callback ) => {
        watchVideoApi({slug: id}) 
        .then((response) => {
          if(response?.data?.http_code === 200){
            console.log(response.data)
            callback && callback()
           dispatch(setGuestReviewState({watch_video_link: response?.data?.data?.video_url, video_download_url: response?.data?.data?.video_download_url}))
            dispatch(setTheme({primary:  response?.data?.data?.theme?.primary_colour, secondary: response?.data?.data?.theme?.secondary_colour, brand_logo: response?.data?.data?.theme?.url, brand_logo_download_url: response?.data?.data?.theme?.download_url}))
          }
        })
        .catch((error) => {
          gaEvents.videoShareFailed()
         if(error) {
          const errorObject = error?.response?.data;
          showSnackBar(dispatch, errorObject);
          dispatch(setGuestReviewState({is_loading: false}))
        }
        });
      }


  return { getVideoLinkDetails};
}