import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { resetState, updateState } from "../redux/commonSlice";
import { eventsList, uploadVideo } from "../services/api";

export default () => {
  const [uploadStatus, setuploadStatus] = useState("");
  const [eventsListStatus, setEventsListStatus] = useState("");
  const dispatch = useDispatch();

  //POST API Request to create new event
  const {
    mutateAsync: uploadMutate,
    isLoading: uploadLoading,
    isSuccess: uploadSuccess,
  } = useMutation(uploadVideo, {
    onSuccess: (data, variables, context) =>
      uploadSuccessHandler(data, variables, context),
    onError: (data, variables, context) =>
      uploadErrorHandler(data, variables, context),
  });

  const uploadSuccessHandler = (data, variables, context) => {
    if (data?.data?.data) {
      //  setSnackbarProps({type: "success", flag: true, message: "Congratulations! Event created successfully"})
      setuploadStatus("success");
    }
  };

  const uploadErrorHandler = (data, variables, context) => {
    // setSubmitFlag(false);
    if (data) {
      if (data?.response?.status == 401) {
        // dispatch(resetState())
        // window.localStorage.removeItem("token");
      }
      //  setSnackbarProps({ type: "error", flag: true, message : data?.response?.data?.error})
      setuploadStatus("error");
      //  console.log("error", data)
    }
  };

  useEffect(() => {
    if (uploadLoading == true) {
      setuploadStatus("loading");
    }

    // return ()=>{ setStatus("")}
  }, [uploadLoading]);
  // const selectedeventList = (id) => {
  //     if (eventList.length > 0 && eventList.find((item)=> item.id == id) ) {
  //        // setApproverError("");
  //         return eventList.find((item)=> item.id == id)
  //     } else {
  //         return {}
  //     }
  // };

  return [uploadMutate, uploadLoading, uploadSuccess];
};
