import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../events/components/CustomAlert";
import "@fontsource/rubik";

const ResetSuccessPopup = ({openHandler, closeHandler, eventData}) => {

   // const [closeHandler, setCloseHandler] = useState(false)
    const isDisabled = false
    const dispatch = useDispatch()
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const navigate = useNavigate();
    
    const handleClose = () => {
      navigate('/signin', {replace: true})
         closeHandler(false)   
       };

      

  
  return (
    
        <CustomAlert 
          open={openHandler} 
          handleClose={handleClose} 
          width={{xs:'400px',sm:'400px',md:'420px',lg:'440px', xl:'600px'}}
          height={{xs:'290px',sm:'220px',md:'230px',lg:'250px', xl:'310px'}}
        >
           <Box p={5} className="flex col" sx={{justifyContent:'space-evenly', height:'100%'}}>
          
           <Box flexDirection='column'>
           <Typography variant="h4" sx={{ textAlign: 'center', fontFamily:'Rubik', fontSize:{xs:'23px',sm:'23px',md:'23px',lg:'23px',xl:'28px'}, mb:2, color:'black'}}>Password Changed!</Typography>
           <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'13px',xl:'18px'}, color:'black'}}>Your account password has been reset successfully.</Typography>
              <Box className='flex row j-end' width='100%' mt={2}>
              <CustomButton
                 // disabled={signinStatus == "loading"}
                  btnText="Continue"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={()=> navigate('/signin', {replace: true})}
                  style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#21D644', textTransform:'none', }} 
                  sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,}}
                />
                </Box>
           </Box>
           </Box>
        </CustomAlert>
      
  )
}

export default ResetSuccessPopup