import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDropDownArray } from '../../../redux/dropdownSlice';
import { genreList, eventTypeList } from '../../../services/api';


export default ()=> {
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dispatch = useDispatch();
    //Get approver dropdown array from Server
    // const [genre, setgenre] = useState(dropdownReducer.genre);
    const [eventType, setEventType] = useState([...dropdownReducer.event_type, ...dropdownReducer.event_type_old ])
    

 
     const { data: eventTypeData, isLoading: eventTypeLoading, refetch: refetchEventType } = useQuery( ["eventType"],
                                            () => eventTypeList(),
                                            { enabled: (dropdownReducer.event_type.length == 0 || dropdownReducer.event_type_filter.length == 0 ), retry: false }
                                         )                                            

    useEffect(() => {
        if (eventTypeData) {
            var temArr = (eventTypeData?.data?.event_types?.filter(filteredType=> filteredType.visibile == true))?.map((type)=> { 
                return {...type, checked: false} 
            })
            // console.log("event type", dropdownReducer.event_type)
            // console.log("event type list", dropdownReducer.event_type.length)
           dispatch(setDropDownArray({event_type: eventTypeData?.data?.event_types?.filter(filteredType=> filteredType.visibile == true), 
            event_type_filter: temArr, 
            // event_type_all: eventTypeData?.data?.event_types,
            event_type_old: eventTypeData?.data?.event_types?.filter(filteredType=> filteredType.visibile == false) }))
           
          // setgenre(genreData?.data?.genre)
            setEventType(eventTypeData?.data?.event_types)
            // console.log('inside useeffect', eventTypeData?.data?.event_types )
        }

    }, [eventTypeData])


    const selectedEventType = (id) => {
        // console.log({id}, 'selectedeventype')
        // console.log(eventType, 'eventTYpe')
    //    console.log(eventTypeData)
        // console.log(dropdownReducer?.event_type_all, 'all events')
        if(id == 15 || id == 3) {
            return eventType?.find((item)=> item.id == 15)
        }
        if(id == 7 || id == 19) {
            return eventType?.find((item)=> item.id == 19)
        }
        if (eventType?.length > 0 && eventType?.find((item)=> item.id == id) ) {
            // console.log("khvjv", eventType?.find((item)=> item.id == id) )
            return eventType?.find((item)=> item.id == id)
        } else {
            return {}
        }

        // if(id == 15 || id == 3) {
        //     return dropdownReducer?.event_type_all?.find((item)=> item.id == 15)
        // }
        // if(id == 7 || id == 19) {
        //     return dropdownReducer?.event_type_all?.find((item)=> item.id == 19)
        // }
        // if (dropdownReducer?.event_type_all?.length > 0 && dropdownReducer?.event_type_all?.find((item)=> item.id == id) ) {
        //     console.log("khvjv", dropdownReducer?.event_type_all?.find((item)=> item.id == id) )
        //     return dropdownReducer?.event_type_all?.find((item)=> item.id == id)
        // } else {
        //     return {}
        // }
    };
  
    return [dropdownReducer.event_type, selectedEventType]  
}