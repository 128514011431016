import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setDropDownArray } from "../../../redux/dropdownSlice";
import { videoLengthList } from "../../../services/api";

export default (enable = true) => {
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const dispatch = useDispatch();


  //Get approver dropdown array from Server
  //const phoneCountRangesLabel = 'value';
  // const [quality, setQuality] = useState(dropdownReducer.capture_quality_list);
  const [videoLength, setVideoLength] = useState(dropdownReducer.video_length)
   


  const {
    data: videoLengthData,
    isLoading: videoLengthLoading,
    refetch: refetchVideoLength,
  } = useQuery(["videoLength"], () => videoLengthList(), {
    enabled: dropdownReducer.video_length?.length == 0,
    retry: false,
  });



  useEffect(() => {
    if (videoLengthData) {
        dispatch(setDropDownArray({video_length: videoLengthData?.data?.video_lengths}))
    }
  }, [videoLengthData]);



  const selectedVideoLength = (id) => {
    if (dropdownReducer?.video_length?.length > 0 && dropdownReducer?.video_length?.find((item) => item.id == id)) {
      // setApproverError("");
      return dropdownReducer?.video_length?.find((item) => item.id == id);
    } else {
      return {};
    }
  };

 
  return [dropdownReducer?.video_length, selectedVideoLength]
};