
import { Backdrop, Box, Checkbox, FormControlLabel, FormGroup, Slide, Typography, getContrastRatio } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from './CustomInput';
import CustomAutocomplete from './CustomAutoComplete';
import { updateCreationState } from '../../../redux/eventCreationSlice';
import CheckIcon from '../../../components/icons/CheckIcon';
import DateTimePicker from '../../../components/date-time/DateTimePicker';
import { afterValidate, generateLighterContrastColor, timeStampToDateString } from '../../../utils/commonServices';
import { getErrorMsg } from '../../../utils/validator';
import UpIcon from '../icons/UpIcon';
import DownIcon from '../icons/DownIcon';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';

const EditingStartDatePage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex, selectedProjectType, setSelectedProjectType, totalScreens, closeHandler} = props
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const [editStartDateTime, setEditStartDateTime] = useState(createEventReducer.edit !== true ? new Date():new Date(pageData?.editor_start_date * 1000) )
    const [editorStartDateValidationFlag, setEditorStartDateValidationFlag] = useState(false)
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const dispatch = useDispatch()
    const [theme ] = useCustomTheme()

    const handleChange = (event)=>{
      dispatch(updateCreationState({checked: event.target.checked}))
    }

    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(nextPageHandler);
    }

    const validateInputs = (flag) => {
      setEditorStartDateValidationFlag(flag)
    }

    useEffect(() => {
      const keyDownHandler = (event) => {
        // console.log("key Parent", event.key)
        if (event.key === "Enter" && createEventReducer.checked ) {
          event.preventDefault();
          finishedHandler()
          // finishedHandler();
        }
      };
        document.addEventListener("keydown", keyDownHandler)
        
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [pageData, createEventReducer.checked ]);


  return (
    <>
    <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createEventReducer.open_backdrop_project_creation}
    >
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
          <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3, fontFamily:'Rubik'}} onClick={closeHandler}>Close X</Typography>
          <Box flexGrow={1} className='flex col ' sx={{ paddingLeft:'14vw', marginTop:{xs:'20%',sm:'14%',md:'8%', lg:'7%'}}}>
              <Box mb={6}>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik',letterSpacing:'0.5px'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik',letterSpacing:'0.5px'}}>When does filming end and editing starts?</Typography>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik',letterSpacing:'0.5px'}}>Select the editing start date of your Lucihub video.</Typography>
              </Box>
              <Box className='flex' sx={{width:'70%'}} gap={1}>
                
                <Box flex={1}>
                <DateTimePicker 
                    placeholder='When do you want editing to begin?'
                    onFocus={() => {
                      setEditorStartDateValidationFlag(false);
                    }}
                    onBlur={() => setEditorStartDateValidationFlag(true)}
                    required
                    errorFlag={
                      editorStartDateValidationFlag &&
                      getErrorMsg(
                      "editor_start_date_time",
                      pageData?.editor_start_date,
                      // pageData?.start_date,
                      Math.floor(Date.now()/1000)
                      ) != ""
                    }
                    errorMsg={getErrorMsg(
                      "editor_start_date_time",
                      pageData?.editor_start_date,
                      // pageData?.start_date,
                      Math.floor(Date.now()/1000)
                    )}
                    // disabled={pageData?.start_date == ""}
                    disablePast
                    defaultValue={
                      pageData?.editor_start_date != ""
                      ? new Date(pageData?.editor_start_date * 1000)
                      : null
                     }
                    onDateChange={(date) => {
                            setPageData((last) => {
                            return {
                              ...last,
                              editor_start_date: date
                             };
                           });
                          }}
                    dateTime={editStartDateTime}
                    setDateTime={setEditStartDateTime}
                    medium={true}
               />
                </Box>
                      <Box sx={{ pt:'2px'}}>
                        <CheckIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick={pageIndex !== 8 ? (createEventReducer?.checked ? finishedHandler : null) : null}  sx={{fontSize:{xs:'50px',xl:'60px'},  opacity: !createEventReducer?.checked ? .5 : 1, cursor: createEventReducer?.checked && 'pointer'}}/>
                      </Box>
              </Box>
              <Box mt={1} textAlign='justify' sx={{display:'flex', alignItems:'center', justifyContent:'start', paddingRight:'15px'}}>
                <FormGroup sx={{fontSize:'14px', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <FormControlLabel sx={{marginRight:0}} control={<Checkbox checked={createEventReducer?.checked} onChange={handleChange} sx={{ '& .MuiSvgIcon-root': { fontSize:{xs:'25px',lg:'30px',xl:'34px'}, margin:0, padding:0,} , borderRadius:'5px', color:'white !important'}} />}  />             
                </FormGroup>
                <Typography  sx={{fontFamily: 'Rubik', fontSize:{xs:'9px',sm:'11px',lg:'13px',xl:'16px'}, color:'white'}}>I acknowledge no new videos can be submitted after the date you specified: 
                  <span style={{fontFamily: 'Rubik', fontWeight:600, paddingLeft:'5px'}}>
                    {pageData?.editor_start_date ? timeStampToDateString(pageData?.editor_start_date): 'MM/DD/YYYY'}
                  </span>
                </Typography>
             </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:5, mr:5}}>
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer', opacity: pageIndex === 1 && 0.5}} onClick={pageIndex !== 1 ? prevPageHandler : null}>/\</Box> */}
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer',  opacity: pageIndex === 8 && 0.5}} onClick={pageIndex !== 8 ? (createEventReducer?.checked ? finishedHandler : null) : null}>\/</Box> */}
            <UpIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', opacity: pageIndex === 1 && 0.5, fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <DownIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor: createEventReducer?.checked && 'pointer', opacity: !createEventReducer?.checked ? .5 : 1,  fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 8 ? (createEventReducer?.checked ? finishedHandler : null) : null}/>
          </Box>
        </Box>
    </Backdrop>
    </Slide>
    </>
  )
}

export default EditingStartDatePage

