import { Avatar, Box, Button, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
// import { customTheme as theme } from "../../../theme/customTheme";
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import RemoveUserIcon from '../../../assets/RemoveUserIcon.svg'
import { updateCreationState } from "../../../redux/eventCreationSlice";
import { generateLighterContrastColor, getInitials, timeStampToDateString, timeStampToDayString, timeStampToNumberedDateString } from "../../../utils/commonServices";
import { LoadingButton } from "@mui/lab";
import { updateTeamState } from "../../../redux/teamSlice";
import CustomAutocomplete from "../../eventcreation/components/CustomAutoComplete";
import CustomButton from "../../../components/CustomButton";
import { exampleTeamMembers } from "../../../constants/staticData";
import { useLocation, useNavigate } from "react-router-dom";
import { changeOrganisationAdmin, removeOrganisationAdmin } from "../../dashboard/actions";
import { removeTeamAdmin } from "../actions";
import useCustomTheme from "../../../theme/useCustomTheme";
import RemoveUserImage from "../assets/RemoveUserImage";



const AssignAdminRolePopup = (props) => {
    const { requestStatus} = props
    const colors = ['red', 'pink', 'black'];
    const dispatch = useDispatch()
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer)
    const [selectedMember, setSelectedMember] = useState(null)
    const [newAdminAssigned, setNewAdminAssigned] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [theme ] = useCustomTheme()
    const [prevAdmin, setPreviousAdmin] = useState(teamsReducer.organization_members?.filter(member => member.role.id == 1)[0]);
    const [options, setOptions] = useState([])
  
   
    useEffect(() => {
        // setPreviousAdmin(teamsReducer.organization_members?.filter(member => member.role.name === 'Admin')[0])
        setOptions(teamsReducer.organization_members?.filter(member => member?.role?.id != 1))
    }, [teamsReducer.organization_members])
    
   
    const handleClose = () => {
        dispatch(updateTeamState({assign_role_popup : false,  change_admin_flag: false, loading_state:'noloading', prev_admin: null}))
        setSelectedMember(null)
    };

    const handleAutocompleteChange = (event, value) => {
        if(value){
        
            setSelectedMember(value)    
        }
    };

    const assignAdminHandler =()=>{
        //api to set another mem as admin
     
        let data
        if(teamsReducer?.change_admin_flag === true){
         data = {
            old_admin_user_id: prevAdmin?.id,
            new_admin_user_id: selectedMember?.id,
            new_admin_role_id: selectedMember?.role?.id
          }
        }else{
          data = {
            old_admin_user_id: prevAdmin?.id,
            new_admin_user_id: selectedMember?.id
          }
        }
        // setPayload(data)
        dispatch(updateTeamState({prev_admin: prevAdmin}))
        submitRequestHandler(data)
        // setSelectedMember(null)   
    }

    // useEffect(()=>{
    //   if(teamsReducer?.change_admin_flag === true){
    //     setPayload({
    //       old_admin_user_id: prevAdmin?.id,
    //       new_admin_user_id: selectedMember?.id,
    //       new_admin_role_id: selectedMember?.role?.id
    //     })
    //   }else{
    //     setPayload({
    //       old_admin_user_id: prevAdmin?.id,
    //       new_admin_user_id: selectedMember?.id
    //     })
    //   }
    // },[teamsReducer?.change_admin_flag, selectedMember, prevAdmin])

    
    const submitRequestHandler =(data)=>{
        /////////////////////api to remove organisation admin////////////////////
        if(teamsReducer?.change_admin_flag === true){
          
          changeOrganisationAdmin(data, dispatch, navigate, {permissionsReducer:permissionsReducer, commonReducer:commonReducer})
        }else{
          removeOrganisationAdmin(data , dispatch, navigate, {commonReducer:commonReducer})
         
        }
      setNewAdminAssigned(true)
    }
   

    return (
        <CustomAlert 
          open={teamsReducer.assign_role_popup} 
          handleClose={handleClose} 
          height={{xs:'400px', xl:'600px'}}
          rightComponent={
          <Box sx={{display:'flex', flexDirection:'column', paddingX:{md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
            <Box  sx={{height: '100%'}} className= 'flex col j-center a-center'> 
            <RemoveUserImage sx={{width: '100%', maxWidth: '200px', height: '60%'}} />
                {/* <img src={RemoveUserIcon} style={{maxWidth: '200px'}} height='60%'/> */}
            </Box>
          </Box>}
          >       
          { (teamsReducer.loading_state !== 'success' || teamsReducer.loading_state === 'loading') && <Box gap={1.5} sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={3} pb={2}>
              <Box   pt={4}>
                  <Typography sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}, color:'#000'}}>{`You are trying to ${teamsReducer?.change_admin_flag === true ? 'update role of ' : ' remove ' }  an Administrator.`}</Typography>

                  <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word', color:'#000'}}>
                    You need atleast one Administrator. Select a new one   
                  </Typography>
                
              </Box>
              <Box>
                    <CustomAutocomplete
                        options={options}
                        placeholder='Select...'
                        onChange={handleAutocompleteChange}
                        // sx={{width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},}}
                        getOptionLabel={(option) =>
                        Object.keys(option).length > 0 ? option.name : ""
                        }
                      
                        renderOption={(props, option, { selected , index}) => (
                            <li {...props} >       
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: colors[index % colors.length],
                                    color: 'white',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    fontWeight:600,
                                    mr:2
                                    }}
                                >
                                    {getInitials(option.name)}
                                </Avatar>
                                <Box sx={{'& span': {color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',},}}>{option.name}</Box>
                            </li>
                            )}
                    />
                  </Box>
                  <Box>
                    {/* <CustomButton btnText='Assign new Administrator' disabled= {!selectedMember}  type='button' onClick={assignAdminHandler}  style={{color:'#08231B', backgroundColor:theme.palette.secondary.main, borderRadius:5, border:'none', fontSize:'12px', padding:'6px', width:'14.1rem', fontWeight:600, textTransform:'none', height:'20px', opacity: !selectedMember && 0.5}}/> */}
                  </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               { teamsReducer.loading_state === 'loading'  ? <LoadingButton
                   // size="small"
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.primary.main,
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={teamsReducer.loading_state === 'loading'}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> :
               <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {assignAdminHandler} disabled= {!selectedMember}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:!selectedMember?'not-allowed': 'pointer', opacity: !selectedMember && 0.5 }}/>
               }
                  {/* <CheckIcon onClick= {finishedHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:  'pointer', }}/> */}
                </Box>
              <Box>    
                <Button 
                variant="text"
                onClick={handleClose}
                 sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'120px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                Or Go Back
               </Button>
              </Box>
            </Box>
          </Box>}

          {teamsReducer.loading_state === 'success' && newAdminAssigned == true &&
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={3} pb={2}>
              <Box flexGrow={1} pt={10}>
                  <Typography sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}, color:'#000'}}>{`${teamsReducer?.change_admin_flag === true ? 'The user role has been changed.' : 'The user has been removed from your Organisation.'}`}</Typography>

                  <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word', color:'#000'}}>
                    <span style={{fontWeight:600,}}>{`${teamsReducer?.prev_admin?.name}`}</span>{`${teamsReducer?.change_admin_flag === true ? ' is the Organisation Member now. ': ' has been removed.  '}`} 
                    <span style={{fontWeight:600}}> {`${selectedMember?.name}`}</span> is the new Admin
                  </Typography>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {handleClose}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
               
                </Box>
              
            </Box>
          </Box>
          }
       
        </CustomAlert>
      )
}

export default AssignAdminRolePopup