import React from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { customTheme as theme } from "../theme/customTheme";

const CustomSwitch = styled(({ ...props }) => <Switch {...props} />)(({ theme }) => ({
    width: 40,
    height: 19,
    padding: 0,
    // margin: theme.spacing(1),
    '& .MuiSwitch-switchBase': {
      padding: 1,
      '&.Mui-checked': {
        transform: 'translateX(21px)',
        color: theme.palette.common.white,
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor:theme.palette.primary.main,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    '& .MuiSwitch-thumb': {
      width: 17.5,
      height: 17.5,
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      border: `1px solid black`,
      // backgroundColor: theme.palette.grey[300],
      backgroundColor:'black',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },

      // Show "ON" text when switch is checked
  '& .Mui-checked + .MuiSwitch-track::before': {
    content: '"ON"',
    position: 'absolute',
    left: 6,
    color: 'black', // Set the text color to black
    fontSize:8,
    top: '50%',        
    transform: 'translateY(-50%)', 
    fontWeight:600
  },

  // Show "OFF" text when switch is unchecked
  '& .MuiSwitch-track::before': {
    content: '"OFF"',
    position: 'absolute',
    right: 3,
    color: 'white', 
    fontSize:8,
    top: '50%',       
    transform: 'translateY(-50%)',
  },
}));

export default CustomSwitch;