import {  useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import useSnackMessage from "../../../hooks/useSnackMessage"; 
import { acceptFinalVideo } from "../../../services/api";
import { updateReviewState } from "../../../redux/reviewSlice";
import { useMutation } from "react-query";
import { updateState } from "../../../redux/commonSlice";
import { setSnackbar } from "../../../redux/messageSlice";


export default () => {

  const dispatch = useDispatch();
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [finalVideoStatus, setFinalVideoStatus] = useState();
  const [showSnackbar] = useSnackMessage();
  // const [finalVideoUrl, setFinalVideoUrl] = useState('')
  
 //PUT API Request for accepting final video
const { mutate: acceptFinalVideoMutate, isLoading: finalVideoLoading } =
useMutation(acceptFinalVideo, {
  onSuccess: (response, variables, context) =>
    acceptVideoSuccessHandler(response, variables, context),
  onError: (error, variables, context) =>
    acceptVideoErrorHandler(error, variables, context),
});

const  acceptVideoSuccessHandler = (response, variables, context) => {
  const responseObject = response?.data;
  const responseData = responseObject?.data

  if (responseObject?.http_code == 200) {
    //   showSnackbar(responseObject);
   // console.log(responseData?.completed_video_url)
  // dispatch(updateState({selected_event: {...commonReducer.selected_event, status_type: "Complete", status_type_order: 5}}))
    dispatch(updateReviewState({ open_video_accept_popup : false}));    
   // dispatch(updateReviewState({request_changes: true}))
    // refetch events list to update status type
     setFinalVideoStatus('success')
    //  showSnackbar(responseObject)
     dispatch(setSnackbar({flag:true, message: 'Congrats on completing New Luci Video! Reminder: you can download your video anytime directly from the project card.', type:'success'}))
    }
  };

const acceptVideoErrorHandler = (error, variables, context) => {
// setSubmitFlag(false);
// setCreateEventStatus("error");
const errorObject = error?.response?.data;
dispatch(updateReviewState({open_video_accept_popup:false}))
// dispatch(setSnackbar({flag:true, message: 'Oh no! Something went wrong while accepting the video.', type:'error'}))
showSnackbar(errorObject)
 setFinalVideoStatus('error')

};

useEffect(()=> {
  if(finalVideoLoading == true) {
    setFinalVideoStatus("loading")
  }
}, [finalVideoLoading])


  return [acceptFinalVideoMutate, finalVideoStatus ];
};