import { makeStyles, SvgIcon } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    cls1: {
        fill: 'none',
      },

      cls2: {
        clipPath: 'url(#clip-path)',
      },

      cls3: {
        fill: '#878887',
      }
}))

const FileIcon =(props) => {

    const classes = useStyles()

    return (
        <SvgIcon {...props} width="74.01" height="64.483" viewBox="0 0 74.01 64.483" >    
             
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_100" data-name="Rectangle 100" class= {classes.cls1} width="74.01" height="64.483"/>
    </clipPath>
  </defs>
  <g id="Group_150" data-name="Group 150" transform="translate(0 150)">
    <g id="Group_149" data-name="Group 149" class={classes.cls2} transform="translate(0 -150)">
      <path id="Path_59" data-name="Path 59" class={classes.cls3} d="M136.256,52.236c-1.826,1.751-3.547,3.414-5.283,5.062-1.127,1.07-2.127.815-2.611-.662-.843-2.57-1.658-5.15-2.523-7.712-.1-.287-.439-.7-.673-.7-2.956-.048-5.912-.031-8.924-.031-.041.444-.075.808-.116,1.245h-2.541l-.107-1.2H93.134V25.489l-1.3-.149v-2.5l1.276-.16V21.53q0-9.784,0-19.568c0-1.608.336-1.949,1.919-1.949q13.05,0,26.1-.013a2.473,2.473,0,0,1,1.924.775q4.684,4.742,9.43,9.423a2.982,2.982,0,0,1,.942,2.34c-.031,7,0,13.99-.038,20.985a1.1,1.1,0,0,0,.967,1.286c4.171,1.206,8.327,2.46,12.485,3.709,1.858.558,2.057,1.5.739,2.883-1.606,1.685-3.131,3.447-4.756,5.248,1.2,1.177,2.709,2.651,4.207,4.138.982.975.977,1.558,0,2.542Q145.19,55.171,143.344,57A1.416,1.416,0,0,1,141,56.985c-1.562-1.552-3.112-3.116-4.74-4.749M95.795,45.429h28.922c-1.339-4.108-2.67-8.106-3.923-12.129a2.4,2.4,0,0,1,.164-1.734c.39-.67,1.145-.454,1.8-.251,1.292.4,2.589.787,3.886,1.172,1.344.4,2.69.787,4.043,1.182V13.2h-1.1c-2.522,0-5.043.007-7.565,0-1.387-.005-1.821-.44-1.824-1.8-.005-2.143,0-4.285,0-6.428V2.725H95.795Zm32.94-34.924-5.826-5.77v5.77Z" transform="translate(-74.413 0)"/>
      <path id="Path_60" data-name="Path 60" class={classes.cls3} d="M2.8,122.923l-.09,1.175H.175v-3.823H4V122.8l-1.2.124" transform="translate(-0.142 -97.463)"/>
      <path id="Path_61" data-name="Path 61" class={classes.cls3} d="M2.646,319.742l.074,1.179,1.232.135v2.509H.092v-3.823Z" transform="translate(-0.075 -259.098)"/>
      <path id="Path_62" data-name="Path 62" class={classes.cls3} d="M199.719,320.9l1.158-.084.149-1.232h2.514v3.846h-3.821Z" transform="translate(-161.839 -258.967)"/>
      <rect id="Rectangle_92" data-name="Rectangle 92" class={classes.cls3} width="4.72" height="2.539" transform="translate(8.804 61.941)"/>
      <rect id="Rectangle_93" data-name="Rectangle 93" class={classes.cls3} width="2.534" height="4.709" transform="translate(0 41.307)"/>
      <rect id="Rectangle_94" data-name="Rectangle 94" class={classes.cls3} width="2.526" height="4.714" transform="translate(0.031 50.956)"/>
      <rect id="Rectangle_95" data-name="Rectangle 95" class={classes.cls3} width="4.717" height="2.515" transform="translate(18.497 61.953)"/>
      <rect id="Rectangle_96" data-name="Rectangle 96" class={classes.cls3} width="2.517" height="4.707" transform="translate(0.042 31.653)"/>
      <rect id="Rectangle_97" data-name="Rectangle 97" class={classes.cls3} width="4.693" height="2.555" transform="translate(28.158 61.928)"/>
      <rect id="Rectangle_98" data-name="Rectangle 98" class={classes.cls3} width="4.374" height="2.525" transform="translate(8.445 22.807)"/>
      <rect id="Rectangle_99" data-name="Rectangle 99" class={classes.cls3} width="2.531" height="3.589" transform="translate(39.175 53.227)"/>
      <path id="Path_63" data-name="Path 63" class={classes.cls3} d="M149.347,86.962c3.748,0,7.5-.007,11.243.011a1.922,1.922,0,0,1,1.058.264.921.921,0,0,1-.327,1.633,3.521,3.521,0,0,1-1.026.09q-10.959.007-21.919,0c-.157,0-.315,0-.472,0-.674-.014-1.22-.243-1.21-1.01s.534-.992,1.222-.992q5.716.006,11.432,0" transform="translate(-110.767 -70.467)"/>
      <path id="Path_64" data-name="Path 64" class={classes.cls3} d="M149.336,137.658q5.526,0,11.053,0c.282,0,.648-.079.828.064a1.69,1.69,0,0,1,.7.974c.019.279-.378.68-.687.859a2.182,2.182,0,0,1-1.017.1q-10.911.006-21.822,0c-1.282,0-1.828-.308-1.743-1.044.125-1.088.983-.951,1.734-.951q5.479,0,10.958,0" transform="translate(-110.72 -111.533)"/>
      <path id="Path_65" data-name="Path 65" class={classes.cls3} d="M149.515,112.268q5.433,0,10.865,0c.314,0,.7-.092.926.055.348.225.816.625.809.941s-.485.692-.826.946c-.164.123-.488.035-.74.035q-11.054,0-22.109,0c-.283,0-.644.084-.83-.056-.314-.237-.711-.621-.711-.943s.408-.7.73-.91c.227-.148.611-.067.925-.067q5.48,0,10.96,0" transform="translate(-110.935 -90.953)"/>
    </g>
  </g>     

  
         </SvgIcon>
    )
}

export default FileIcon