import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { consentDetail, eventsList } from "../../../services/api"; 
import useSnackMessage from "../../../hooks/useSnackMessage"; 
import { updateState } from "../../../redux/commonSlice";

export default (enableEventsRequest=true) => {
  // const commonReducer = useSelector((state) => state.root.commonReducer);

  //Get approver dropdown array from Server
  //const eventListLabel = 'value';
  const dispatch = useDispatch();
  const [showSnackbar] = useSnackMessage();
  const [enableEventsList, setEnableEventsList] = useState(enableEventsRequest);
 
  const commonReducer = useSelector(state=> state.root.commonReducer)
  // const [approverError, setApproverError] = useState("Please select approver");
  const {
    data: eventsListData,
    isLoading: eventsListLoading,
    error: eventListError,
    refetch: refetchEventsList,
  } = useQuery(
    [
      `eventsList${commonReducer.consent_id}`,
      commonReducer.consent_id,
    ],
    () => eventsList(),
    { enabled: enableEventsList, retry: false }
  );



  useEffect(() => {
    if (eventsListData) {
        setEnableEventsList(false);

        const events = eventsListData?.data?.data?.event_details;
        // const events = eventsListData?.data?.data?.event_details?.filter((e)=> e.cancel != true);

        // if(checkConsentFlag(events) && commonReducer.consent_detail == ''){
        //   setPayload({category:'Event file upload'}) 
        //   setEnableConsentDetail(true)   
        // }

      dispatch(
        updateState({
         events: events,
         status_filtered_events: events,
         type_filtered_events: events,
         combined_filter: events
        })
      );


    }
  }, [eventsListData]);

  

  useEffect(() => {
    if(eventListError) {
      const errorObject = eventListError?.response?.data;
      showSnackbar(errorObject);
      // dispatch(setSnackbar({ ...setSnackMessageType(responseObject) }));
      // if (eventListError?.response?.data?.http_code == 401) {
      //   window.localStorage.removeItem("token");
      //   dispatch(resetState());
      // }
    }
   
  }, [eventListError]);

  // const selectedeventList = (id) => {
  //     if (eventList.length > 0 && eventList.find((item)=> item.id == id) ) {
  //        // setApproverError("");
  //         return eventList.find((item)=> item.id == id)
  //     } else {
  //         return {}
  //     }
  // };

  return [refetchEventsList];
};