import { SvgIcon } from "@mui/material";
import React from "react";
import { customTheme as theme } from "../../../theme/customTheme";
import { getContrastRatio } from '@mui/material/styles';

const primaryColor = localStorage.getItem('primary')

const PublicLockIcon = (props)=> {
    return <SvgIcon {...props}  width="100" height="100" viewBox="0 0 100 100" >
                  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_178" data-name="Rectangle 178" width="61.827" height="76.236" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_255" data-name="Group 255" transform="translate(10 -7)" opacity="0.4">
    <g id="Group_255-2" data-name="Group 255" transform="translate(-10 7)" clip-path="url(#clip-path)">
      <path id="Path_142" data-name="Path 142" d="M38.389,25.04H15.046V24.2c0-4.2-.006-8.407,0-12.61.007-3.656,2.577-6,6.4-5.717a140.762,140.762,0,0,0,19.035-.008c3.806-.229,6.3,2.1,6.306,5.791q0,.6,0,1.2a1.606,1.606,0,0,0,1.606,1.606h2.63a1.607,1.607,0,0,0,1.607-1.608q0-.636,0-1.273A11.342,11.342,0,0,0,40.342.018C33.982.339,27.616.242,21.252.01A11.283,11.283,0,0,0,9.205,11.526c-.01,4.175,0,8.349,0,12.524v.966c-.367.063-.655.116-.944.161a9.472,9.472,0,0,0-8.218,9.35q-.076,16.14,0,32.281A9.478,9.478,0,0,0,9.461,76.2q21.469.072,42.938,0a9.454,9.454,0,0,0,9.383-9.329c.069-10.819.052-21.638,0-32.457a9.32,9.32,0,0,0-6.2-8.7,22.054,22.054,0,0,0-2.52-.671H38.389ZM55.965,35.2q0,15.479,0,30.958c0,2.7-1.5,4.211-4.19,4.213q-10.448.007-20.9,0-10.36,0-20.719,0c-2.826,0-4.293-1.466-4.293-4.287q0-15.435,0-30.869c0-2.829,1.461-4.3,4.278-4.3q20.763,0,41.527,0c2.826,0,4.293,1.469,4.294,4.288" transform="translate(0 0)" fill="#00d544"/>
    </g>
  </g>
    </SvgIcon>
}

export default PublicLockIcon

