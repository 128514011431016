import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  type: "",
  flag: false,
  message: "",
};

export const messageSlice = createSlice({
  name: "message",
  initialState: initialValue,
  reducers: {
    setSnackbar: (state, action) => {
      assignValueInState(action.payload, state);
    },
    resetSnackbar: (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { setSnackbar, resetSnackbar } = messageSlice.actions;
export default messageSlice.reducer;