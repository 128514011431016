import axios from 'axios'

export const Request = (url, method, params, formDataFlag, progressHandler, headers, controller) => {
  let formData = new FormData();
 
  for (const key in params) {
   
    // if(key.includes('[]')) {
    //   for(const obj of params[key]) {
    //     formData.append(key, obj )
    //   }
    // } else {
      formData.append(key, params[key])
    //}
   
  }
  
  return axios({
    url: url,
    method: method, 
    onUploadProgress: (progressEvent) => {
      //console.log("progressEvent.loaded " + progressEvent.loaded)
      //console.log("progressEvent.total " + progressEvent.total)
      let percent = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
     progressHandler && progressHandler(percent);
    },
    headers: buildHeaders(headers),
    params: !formDataFlag ? params : null,
    data: formDataFlag ? formData : null,
    signal: controller && controller.signal,
  })
}

function buildHeaders(extraHeaders) {
  const token = window.localStorage.token
  let headers = {
    ...extraHeaders
  }
  if (!!token){
    // headers = { ...headers, Authorization: `Bearer ${token}` }
    headers = { ...headers, "Authorization": `Bearer ${token}`, "product": process.env.REACT_APP_PRODUCT_ID}
  } 
  

  return { ...headers }
}