
import { makeStyles } from "@mui/styles";
import {  Box, Checkbox, CircularProgress, IconButton, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import "@fontsource/rubik";
import CustomTooltip from "../../../components/CustomTooltip";
import {  downloadS3File, removeExtension, timeStampToNumberedDateString } from '../../../utils/commonServices'
import usePermissions from "../../../hooks/usePermissions";
import CustomVideoLinearProgress from "../../../components/CustomVideoLinearProgress";
import { deleteEventSong } from "../actions";


const useStyles = makeStyles((theme) => ({
  container: {
      width:'100%',
      borderCollapse: 'collapse',
     // paddingRight:'4%'
     scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
               display : 'none'
             }
  },
  tableHead:{
      marginBottom:'10px',
      height:'28.5px',
      fontFamily: "Rubik",
     // position: 'sticky',
      //top: '0px',
      //zIndex: 15,
     // background: 'white',
  },
  tableRowHead:{
      textAlign:'left',
      verticalAlign:'top',
      color:'#08231B',
      fontSize:'15px',
      fontFamily: "Rubik",
      fontWeight:500,
      letterSpacing: '0.75px',
      zIndex: 15
  },
  tableRowData:{
      borderBottom:'2px solid #cBcBcB',
      // height:'60px',
      backgroundColor:'transparent',
      fontFamily: "Rubik",
      textAlign:'left',
      width:'100%',
      fontSize:'15px',
      // font: 'normal normal 600 15px/18px ',
      fontWeight:600,
      color:'#08231B',
      letterSpacing: '0.75px',
      zIndex: 15,
      '&:last-child':{
        borderBottom:'none'
      }
  },
//   'tableRowData&:last-child' :{
//     borderBottom:'none',
 
// },
  tableCell:{
      textAlign:'right'
  },
  icons:{
      height:'18px',
      width:'18px'
  }
}));




const UploadedSongsTable = (props) => {

  const {progressFlag=true, files, isUploading, progress, stopUpload, uploadStatus, uploadedSongs=[],setUploadedSongs,  refetch} = props;
  const classes = useStyles();
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const projectReducer = useSelector((state) => state.projectReducer);
  const dispatch = useDispatch();
  const [reversedArray, setReversedArray] = useState(uploadedSongs)
  const [fileId, setFileId] = useState(null)
  const [clickType, setClickType] = useState("")
  const [hasPermission] = usePermissions()




  const handleDelete =  (id) => {
    console.log('here')
       setClickType("dlt")
       setFileId(id)
    //    delete song api
       deleteEventSong({file_id: id, event_id:  commonReducer?.production_event_id}, dispatch)
    };




  const videoDownloadHandler =  (file)=>{
      setFileId(file.id)
      if(file?.song_file_url != null && file?.song_file_url != "") {
        downloadS3File(file.song_file_url)
      }
  }

  

  useEffect(() => {
    let tempArr = [...uploadedSongs].reverse().map(element => {
      return element;
    });
    setReversedArray(tempArr)
  }, [uploadedSongs,  projectReducer?.uploaded_songs])

  

  




  

  const iconButtonActions = (
      <Box >
          <IconButton >
           <DeleteIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>
          </IconButton>
    
          <IconButton disabled >
                 <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},'& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           } }}/>
         </IconButton>
 </Box>
    )

return (
  <Box pr={0.5}>

    <Box className={classes.container} 
      component = 'div'
      sx={{
          // overflowY: 'scroll',
          // height: {xs:'35vh',sm:'35vh',md: '37vh',lg:'39vh', xl: '48vh'}, 
          // minHeight:'300px',
          height:{xs:'150px', sm:'160px',md:'140px',lg:'150px', xl:'200px'},
          width:'100%'
        
      }}
    >
      <Box sx={{ borderBottom:'2px solid black', paddingBottom:'5px'}}>
          <Typography sx={{fontSize: {xs:'10px',md:'12px',lg:'13px',xl:'20px'}, fontFamily: 'Rubik' , color:'#08231B'}}>{'File(s) :'}</Typography>
      </Box>
      <Box sx={{ 
        // height:{xs:'140px', lg:'220px'},
         overflowY:'scroll !important',  height:'100%'}}>

              <Box sx={{width:'100%', textAlign:'center', display:'grid', placeContent:'center', }} >
                   {(reversedArray?.length == 0 && files?.length == 0 ) && <Typography px={15} sx={{color:'#878887', fontSize:{xs:'10px',sm:'15px',md:'15px',lg:'20px',xl:'30px'}, fontWeight: 300, fontFamily: "Rubik", marginTop:'3%', marginBottom:'2%'}} variant="h3">{commonReducer.event_details.status_type_order > 2 ? "No song file to show" : "Upload songs to your Project."}</Typography>}
                </Box>
             

          {files?.length > 0 && files?.map((file, index) => (
                  <Box className={classes.tableRowData} key={file.size} sx={{display:'flex', alignItems:'center', height:{xs:'40px',md:'45px',xl:'63px'}}}>

                   <CustomTooltip title = {file?.name} >
                       <Box flex={3} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, minWidth:'150px'}} ><Typography sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:400, fontFamily: 'Rubik'}}>{removeExtension(file?.name)}</Typography></Box>
                   </CustomTooltip>


                    <Box flex={2}  sx={{display:'flex', justifyContent:'end', backgroundColor:'transparent', minWidth:'100px', }} ><Box textAlign='end' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik',width:'100%',display:'flex', justifyContent:'end', }}>{progressFlag ? <CustomVideoLinearProgress
                       progress={progress[index]}
                       status={uploadStatus[index]}
                       stopUpload={() => stopUpload(index)}
                       fontSize = {12}
                       bar = {5}
          /> : iconButtonActions}</Box>
                    </Box>
                  </Box>
              ))}


              {reversedArray?.length > 0 && reversedArray?.map((file, index) => (
                <Box className={classes.tableRowData} key={file?.size + index} sx={{display:'flex',  alignItems:'center', height:{xs:'40px',md:'45px',xl:'63px'}, overflowY:'scroll !important'}}>
                <Box>
                    <CustomTooltip title = {file?.name} >
                       <Box flex={3} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, wordWrap:'break-word', minWidth:'150px'}}><Typography  sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight: 900 , wordWrap:'break-word', fontFamily: 'Rubik', }}>{removeExtension(file?.name)}</Typography>
                  
                       </Box>
                    </CustomTooltip> 
                    </Box>

                    <Box flex={2}  sx={{ display:'flex', justifyContent:'end'}} className={classes.tableCell}><Box  sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, display:'flex', alignItems:'center',justifyContent:'end' }} gap={0.5}>
                  {  ((commonReducer?.event_details?.status_type_order < 3 || ( commonReducer.event_details?.revision?.submitted == false && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id)) ) &&
                       <> { projectReducer?.delete_song_loading == true && fileId == file?.id ?(
                              <CircularProgress    
                                sx={{
                                  height:'4px', width:'4px',
                                  margin:{xs:0.75,md:0.87,lg:1.1, xl:1.4},
                                  color:'#666'
                                  }}
                                size={14}
                                loading={  projectReducer?.delete_song_loading == true}
                                disabled 
                              >    
                              </CircularProgress>
                             ):(
                           
                         <IconButton  
                            disabled = { projectReducer?.delete_song_loading == true }
                            onClick={()=>handleDelete(file.id)} sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}>
                            <DeleteIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>
                         </IconButton> 
                             )
                       }
                           
                           </>
                               }
                          <CustomTooltip title= {"Download"} >
                             <IconButton 
                              disabled={ projectReducer?.delete_song_loading == true}  
                              onClick={()=>videoDownloadHandler(file)} 
                              sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, 
                                  '& .MuiSvgIcon-root':{fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}} 
                            >
                              <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},  '& .MuiSvgIcon-root':{fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}}/>
                            </IconButton>
                          </CustomTooltip>
                    </Box></Box>
               </Box>
               ))}

      </Box>
  </Box >
  </Box>
)
}

export default UploadedSongsTable