import { Avatar, Box, IconButton, Typography, getContrastRatio } from "@mui/material"
import { useState } from "react"
import CustomButton from "../../../components/CustomButton"
import AddEventPopup from "./AddEventPopup"
import CustomCard from "./CustomCard"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { useDispatch, useSelector } from "react-redux"
import "@fontsource/rubik";
import CustomTooltip from "../../../components/CustomTooltip"
import { generateLighterContrastColor, getInitials } from "../../../utils/commonServices"
import SettingsIcon from '@mui/icons-material/Settings';
import { updateProjectState } from "../../../redux/projectSlice"
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import usePermissions from "../../../hooks/usePermissions"
import { PERMISSION } from "../../../constants/constants"
// import { customTheme as theme } from "../../../theme/customTheme"
import useCustomTheme from "../../../theme/useCustomTheme"


const ProjectMembersCard = (props) => {

    const [open, setOpen] = useState(false)
    const { shareClickHandler,  buttonState = 'Copy event code', isDisabled = false }= props
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const projectReducer = useSelector((state) => state.projectReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer);
    const dispatch = useDispatch()
    const [hasPermission] = usePermissions();
    const [theme ] = useCustomTheme()
   
    const colorsCollaborator = ['#08231B', '#49A086', '#00CDFE'];
    const colorsFilmer = ['#6C00FF', theme.palette.primary.main, '#AFABB5']

 
    
    const addMembersClickHandler =()=>{
        dispatch(updateProjectState({invite_project_members_popup : true}))
    }

    const viewMembersClickHandler =()=>{
        dispatch(updateProjectState({view_project_members_popup : true}))
    }
  

  return (
    <CustomCard   style={{ height:'100%', opacity: isDisabled  && 0.5 }} >

        <Box sx={{paddingLeft:{xs:'10px' , sm:'10px',md:'10px',lg:'23px',xl:'30px'}, display:'flex', flexDirection:'column', justifyContent:'center', height:'100%', width:'100%',  paddingTop: {xs:'5px',sm:'5px',md:'9px', lg:'12px', xl:'21px'}, paddingBottom: {xs:'5px',sm:'5px',md:'13px', lg:'12px', xl:'21px'}, paddingRight:{xs:'10px' , sm:'10px',md:'10px',lg:'13px',xl:'20px'},}} gap={0.5}>

         <Box className='flex col' flex={1} sx={{gap:2}}>
            <Box  sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, fontWeight:600,letterSpacing:0, fontFamily:'Rubik'}}>Project Members</Typography>
               { hasPermission(PERMISSION.PROJECT.MEMBER.VIEW)   && <IconButton disabled={isDisabled} onClick={hasPermission(PERMISSION.PROJECT.MEMBER.ADD)  ? addMembersClickHandler :  viewMembersClickHandler  } >
                  <SettingsIcon sx={{fontSize:{xs:'13px !important',md:'14px !important',lg:'16px !important',xl:'20px !important'}, color:'#08231B'}}/>
                </IconButton>}
            </Box>
            <Box sx={{display:'flex', alignItems:'flex-start', justifyContent:'space-between', width:{xs:'100%', md:'100%',lg:'95%'}}} gap={0.5}>
                <Box mr={0.5}>
                    <Typography mb={0.5} sx={{fontSize:{xs:'10px',md:'10px',lg:'12px',xl:'18px'}, color:'#000000', fontFamily:'Rubik'}}>Owner</Typography>
                
                    <Avatar sx={{borderRadius:'5px', backgroundColor:theme.palette.primary.main,  color:getContrastRatio( theme.palette.primary.main , '#ffffff') > 2.5 ? '#ffffff' : '#000000',width:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, height:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, paddingRight:0, marginRight:0,  fontSize:{xs:'7px',md:'8px',lg:'8px',xl:'12px'}, textTransform:'uppercase', fontFamily:'Rubik', fontWeight:600}}>{getInitials(commonReducer?.event_details?.owner_name)}</Avatar>
                </Box>

                <Box mr={0.6}>
                    <Typography mb={0.5} sx={{fontSize:{xs:'10px',md:'10px',lg:'12px',xl:'18px'}, color:'#000000', fontFamily:'Rubik'}}>Collaborator(s)</Typography>
                        {commonReducer.selected_project_members?.filter(item => item.role.name == 'Collaborator')?.length == 0 ?
                           (hasPermission(PERMISSION.PROJECT.MEMBER.ADD)  && <Box sx={{width:{xs:'70%', lg: '100%'}}}>         
                            <CustomButton 
                                disabled={isDisabled} 
                                btnText={hasPermission(PERMISSION.PROJECT.MEMBER.ADD)  ? 'Invite' : 'View'}
                                variant='contained' 
                                onClick={ hasPermission(PERMISSION.PROJECT.MEMBER.ADD)  ?  addMembersClickHandler :viewMembersClickHandler }  
                                // onClick={()=> dispatch(updateProjectState({invite_project_members_popup : true, invite_contributor_flag: true}))} 
                                startIcon={<AddRoundedIcon fontSize="small" />}
                                // style={{borderRadius: '5px', backgroundColor:'#08231B', color:`${theme.palette.primary.main}`, textTransform:'none', }} 
                                style={{borderRadius: '5px', textTransform:'none', }} 
                                color='button'
                                sx={{height:{xs:'16px', lg:'16px', xl:'35px'}, width:'73%', fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600, color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)}}
                                />
                                </Box>
                        ):(
                        <Box className='flex' gap={0.5}>
                            {commonReducer.selected_project_members?.filter(item => item.role.name == 'Collaborator')?.slice(0, 2).map((item, index) => (
                                <Avatar sx={{borderRadius:'5px', backgroundColor:colorsCollaborator[index], color:'white',width:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, height:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, paddingRight:0, marginRight:0,  fontSize:{xs:'7px',md:'8px',lg:'8px',xl:'12px'}, textTransform:'uppercase', fontFamily:'Rubik'}}>{getInitials(item.name)}</Avatar>
      
                            ))}
                            {commonReducer.selected_project_members?.filter(item => item.role.name == 'Collaborator')?.length > 2 && (
                                <Avatar sx={{borderRadius:'5px', backgroundColor:'transparent', color:'black',width:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, height:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, paddingRight:0, marginRight:0,  fontSize:{xs:'7px',md:'8px',lg:'8px',xl:'12px'}, textTransform:'uppercase', fontFamily:'Rubik', border:'1px solid black', boxSizing:'border-box'}}> +{commonReducer.selected_project_members?.filter(item => item.role.name == 'Collaborator')?.length - 2}</Avatar>
                            )}
                        </Box>
                        )}
                </Box>

                {/* <Box>
                    <Typography mb={0.5} sx={{fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, color:'#000000', fontFamily:'Rubik'}}>Filmers(s)</Typography>
                    {projectReducer.all_project_members?.filter(item => item.role.name == 'Filmer')?.length == 0 ? (
                        <Box sx={{width:'100%'}} >         
                            <CustomButton 
                                btnText='Invite'
                                variant='contained' 
                                disabled={isDisabled} 
                                onClick={()=> dispatch(updateProjectState({invite_project_members_popup : true, invite_filmer_flag: true}))} 
                                startIcon={<AddRoundedIcon fontSize="small"/>}
                                style={{borderRadius: '5px', backgroundColor:'#08231B', color:theme.palette.primary.main, textTransform:'none', }} 
                                sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'50%',lg:'70%'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,}}
                                />
                                </Box>
                    ):(
                        <Box className='flex' gap={0.5}>       
                            {projectReducer.all_project_members?.filter(item => item.role.name == 'Filmer')?.slice(0, 2).map((item, index) => (
                                <Avatar sx={{borderRadius:'5px', backgroundColor:colorsFilmer[index], color:'white',width:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, height:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, paddingRight:0, marginRight:0,  fontSize:{xs:'7px',md:'8px',lg:'8px',xl:'12px'}, textTransform:'uppercase', fontFamily:'Rubik'}}>{getInitials(item.name)}</Avatar>
      
                            ))}
                            {projectReducer.all_project_members?.filter(item => item.role.name == 'Filmer')?.length > 2 && (
                                <Avatar sx={{borderRadius:'5px', backgroundColor:'transparent', color:'black',width:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, height:{xs:'18px',sm:'18px' ,md:'20px' , lg:'20px' ,xl:'35px'}, paddingRight:0, marginRight:0,  fontSize:{xs:'7px',md:'8px',lg:'8px',xl:'12px'}, textTransform:'uppercase', fontFamily:'Rubik', border:'1px solid black', boxSizing:'border-box'}}> +{projectReducer.all_project_members?.filter(item => item.role.name == 'Filmer')?.length - 2}</Avatar>
                            )}
                        </Box>
                    )}
                </Box> */}
            </Box>
           
         </Box>
         {/* <Box flex={1} sx={{display:'flex', flexDirection:'column',  }} gap={{xs:'4px',md:'6px',lg:'6px',xl:'12px'}}>
            <Typography sx={{fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, color:'#000000', fontFamily:'Rubik'}}>Project Collaborator</Typography>
            <Box sx={{display:'flex'}} gap={{xs:'5px',sm:'7px',md:'9px',lg:'10px',xl:'15px'}}>
                
              <CustomTooltip title='Only project owner is authorized' sx={{display:commonReducer.user_name == commonReducer.event_details?.owner_name && 'none' }}>
              <span>
              <CustomButton 
               disabled={isDisabled  || (commonReducer.user_name != commonReducer.event_details?.owner_name)}
               btnText={buttonState}
                startIcon={<AddOutlinedIcon sx={{fontSize:''}}/>}  
                variant='contained' onClick={copyInvite} style={{borderRadius: '5px', backgroundColor:'#08231B', color:theme.palette.primary.main, textTransform:'none', }} sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'130px', lg:'130px',xl:'170px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600}}/>
               <CustomButton 
               disabled={isDisabled  || (commonReducer.user_name != commonReducer.event_details?.owner_name)}
               btnText={buttonState}
               
                variant='contained' onClick={copyInvite} style={{borderRadius: '5px', backgroundColor:'#08231B', color:theme.palette.primary.main, textTransform:'none', }} sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'130px', lg:'130px',xl:'170px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600}}/>
                </span>
                </CustomTooltip>
            </Box>
         </Box> */}
      
        {open && <AddEventPopup open={open} setOpen={setOpen}/>}

        
        </Box>
    </CustomCard>
  )
}

export default ProjectMembersCard