import ReactGA from "react-ga4";

const lucihubSignup = ()=> ReactGA.event({action:'signup', category:'click', label:'Signed up'})
const signupFailed = ()=>{
    ReactGA.event({action:'signup-failed', category:'event', label:'Signup Failed'})
}
const emailVerified = () => ReactGA.event({action:'email-verified', category:'event', label:'Email Verified'})

const lucihubLogin =()=> ReactGA.event( 'login')

const loginFailed =()=>{
    ReactGA.event({action:'login-failed', category:'event', label:'Login Failed'})
}


const clickedOnJoinButton = ()=>{
    ReactGA.event({action:'join', category:'click', label:'Join button clicked'})
}

const eventCreationFormOpened = ()=>{
    ReactGA.event({action:'event-create-form-opened', category:'click', label:'Event Creation Form Opened'})
}

const eventCreated = ()=>{
    ReactGA.event({action:'event-create', category:'event', label:'New Event Created'})
}

const eventCreationFailed = ()=>{
    ReactGA.event({action:'event-create-api-failed', category:'event', label:'Event Creation Failed'})
}

const sharedVideoDownloadButtonClicked = ()=>{
    ReactGA.event({action:'shared-video-downloaded', category:'event', label:'Shared Video Downloaded'})
}

const watchVideoLinkVisited = ()=>{
    ReactGA.event({action:'watch-shared-video', category:'event', label:'Watch Shared Video'})
}

const videoShareFailed = ()=>{
    ReactGA.event({action:'video-share-api-failed', category:'event', label:'Failed to Share Video'})
}






 const gaEvents ={
    loginEvent: lucihubLogin,
    loginFailed:loginFailed,
    joinClicked: clickedOnJoinButton,
    signupEvent: lucihubSignup,
    signupFailed: signupFailed,
    emailVerified: emailVerified,
    eventCreated: eventCreated,
    eventFormOpened: eventCreationFormOpened,
    failedEventCreaton:eventCreationFailed,
    watchVideoLinkVisited: watchVideoLinkVisited,
    sharedVideoDownloadButtonClicked: sharedVideoDownloadButtonClicked,
    videoShareFailed: videoShareFailed

}

export { gaEvents}