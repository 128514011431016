import { Box, IconButton, Input, TextField, Typography, getContrastRatio } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { updateReviewState } from '../../../redux/reviewSlice'
import CustomInput from '../../eventcreation/components/CustomInput'
import useRevisionRequests from '../hooks/useRevisionRequests'
import RevisionLogNote from './RevisionLogNote'
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import CustomTooltip from '../../../components/CustomTooltip'
import usePermissions from '../../../hooks/usePermissions'
import { PERMISSION } from '../../../constants/constants'
import { createRevisionLog, getRevisionLogs } from '../actions'
import { useNavigate } from 'react-router-dom'
import { generateDarkColor, generateLighterContrastColor } from '../../../utils/commonServices'
// import { customTheme as theme } from '../../../theme/customTheme'
import useCustomTheme from '../../../theme/useCustomTheme'
import useRevisionActions from '../useRevisionActions'
import DeleteLogConfirmationPopup from './DeleteLogConfirmationPopup'


const RequestLog = (props) => {
  const {disabled} =props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
  const [text, setText] = useState("");
  const [hasPermission] = usePermissions()
  const [theme ] = useCustomTheme()
  const {updateLog} = useRevisionActions()

  useEffect(()=> {
    if(commonReducer.event_details.revision.id != null && commonReducer.event_details.revision.submitted != true) {
      hasPermission(PERMISSION.PROJECT.REVISION.NOTES.VIEW) && getRevisionLogs(dispatch, navigate, {commonReducer});
    }
  }, [commonReducer.event_details])

  useEffect(()=> {
   dispatch(updateReviewState({timestamp:'00:00'}))

  }, [])

  const saveToLogClickHandler = ()=>{
    if(text != "") {
      reviewEventReducer?.edit_flag === true ? updateLog( {id: reviewEventReducer?.log_id, param: { message: text, timestamp: reviewEventReducer.timestamp}}, clearRevisionInput) :
      createRevisionLog(dispatch, navigate, {commonReducer, reviewEventReducer}, {id: commonReducer.user_id, param: {revision_id: commonReducer.event_details.revision.id, message: text, timestamp: reviewEventReducer.timestamp}}, clearRevisionInput)
    }
   // dispatch(updateReviewState({request_changes: false}))
  }
const clearRevisionInput = ()=> {
  setText("");
}
  return (
    <Box sx={{maxHeight:'600px',height:'100%',padding:'13px', display:'flex', flexDirection:'column' , }}>
        <Box className='flex a-center' textAlign='left' gap={1} sx={{marginBottom: {xs:'10px',md:'15px',lg:'20px',xl:'30px'}}}>
            <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'black', fontSize:{xs:'10px',md:'13px',lg:'14px',xl:'20px'}}}>Revisions Log</Typography>
        {
          !disabled && <CustomTooltip title= "How to submit revisions?" placement = "right" >
             <HelpOutlinedIcon onClick={()=> dispatch(updateReviewState({open_change_request_tutorial_popup : true}))} sx={{fontSize:{xs:'12px',lg:'16px', xl:'20px'}, cursor:'pointer'}}/>
          </CustomTooltip>
        }    
       
        </Box>
        <Box component='div' textAlign='left' sx={{overflowY: 'scroll', height: '60%'}} mb={1}>
          { hasPermission(PERMISSION.PROJECT.REVISION.NOTES.VIEW) &&
            reviewEventReducer.revision_logs.map((log)=> {
              return <Box mt={1.5}>
             {log?.deleted === false && <RevisionLogNote disabled={disabled} color={generateDarkColor(log, 'username')} userName={log.username} message = {log.message} timestamp={log.timestamp} setText={setText} id={log?.id} log={log}/>}
              </Box>
            })
          }
         
        </Box>
       {//hasPermission(PERMISSION.PROJECT.REVISION.NOTES.CREATE) && 
       <Box>
            <Box flexGrow='1' textAlign='left'  >
              <CustomInput type='timestamp' disabled = {disabled ?? !hasPermission(PERMISSION.PROJECT.REVISION.NOTES.CREATE)} onChange={(e)=> setText(e.target.value)} value= {text} size='small' max={500} style={{backgroundColor:'white'}}  sx={{width:'100%', input:{}, }} placeholder='Type request to editor here…'/>   
            </Box>
        
            <CustomButton 
                btnText='Save to Log'
                disabled = {disabled || reviewEventReducer.is_loading || !hasPermission(PERMISSION.PROJECT.REVISION.NOTES.CREATE)}
                onClick={saveToLogClickHandler}
                color='button'
                variant='contained'  style={{borderRadius: '5px', color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main),opacity: (disabled || reviewEventReducer.is_loading || !hasPermission(PERMISSION.PROJECT.REVISION.NOTES.CREATE)) && 0.6 , textTransform:'none', minWidth: '92px' }} sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:'100%', fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600}}/>
        </Box>}
        {reviewEventReducer?.delete_log_confirmation_popup && <DeleteLogConfirmationPopup/>}
    </Box>
  )
}

export default RequestLog