import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../../redux/commonSlice";
import { eventVideoUploadConsent } from "../../../services/api";
import { useMutation } from "react-query";
import useEventList from "../../dashboard/hooks/useEventList";
import { LoadingButton } from "@mui/lab";
import CustomAlert from "../../events/components/CustomAlert";
import CustomInput from "../../eventcreation/components/CustomInput";
import { setAllMediaFilesArray } from "../../../redux/mediaFilesSlice";
import { downloadS3File, openInNewTab } from "../../../utils/commonServices";

const DownloadConfirmationPopup = (props) => {
    const {resetList,  name, deleteProject,url } = props
    const [closeHandler, setCloseHandler] = useState(false)
    const isDisabled = false
    const dispatch = useDispatch()
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const mediaFilesReducer = useSelector(state=> state.mediaFilesReducer)
   // const [refetchEvents] =  useEventList(commonReducer.is_logged);
    const [text, setText] = useState("")

    const handleClose = () => {
        dispatch(setAllMediaFilesArray({download_confirmation_popup : false})) 
       };

       


    const confirmHandler= ()=>{
        // dispatch(setAllMediaFilesArray({enable_media_download:true}))
        downloadS3File(url)
      // openInNewTab(url)
         setTimeout(()=> {
             handleClose()
            }, 1000)
    }

    const cancelHandler =()=>{
      handleClose();
    }
  

  return (
    
        <CustomAlert 
          open={mediaFilesReducer.download_confirmation_popup} 
          handleClose={handleClose} 
          width={{xs:'400px',sm:'400px',md:'420px',lg:'440px', xl:'600px'}}
          height={{xs:'250px',sm:'250px',md:'270px',lg:'280px', xl:'360px'}}
        >
           <Box p={5} className="flex col j-center" sx={{ height:'100%'}}>
          
            <Box   mb={{xs:1 ,md:2}} mt= {1} textAlign={'center'}>
              {/* <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, color:'black'}}>{commonReducer.consent_detail}</Typography> */}
              <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'14px',sm:'15px',md:'16px',lg:'18px',xl:'24px'}, color:'black', fontWeight:600}}>Click below to start downlaod</Typography>
            </Box>
    
         
    
            <Box sx={{display:'flex',justifyContent:'center' }} gap={2} mt={{xs:1 ,md:3}} >
    
          
              <LoadingButton
                   size="small"
                    // disabled={status == 'loading'}
                    onClick={confirmHandler}
                    color="secondary"
                    style={{borderRadius: '5px', 
                    // backgroundColor:theme.palette.secondary.main,
                     textTransform:'none', }} 
                    sx={{ fontSize:{xs:'12px',sm:'12px',md:'14px',lg:'16px',xl:'20px', },fontWeight:700, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, minWidth: '110px !important', color:'black'}}
                    // loading={status == 'loading'}
                    // loadingPosition="start"
                //  loading = {true}
                    variant="contained"
                    >
                   Download
               </LoadingButton> 
            </Box>
           </Box>
        </CustomAlert>
      
  )
}

export default DownloadConfirmationPopup