import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { redirectToApp } from "../utils/commonServices";
import { iosAppId } from "../constants/constants";


const RedirectToAppStore = ()=> {
    const location = useLocation();
   // console.log("path", location.pathname)
    const query = new URLSearchParams(location.search);
   // const code = query.get('c')
   let queryString = ''
   let appId = ''

   const setQueryString = (searchParam)=>{
        let queryArr = []
        query.forEach((value, key) => {
            queryArr.push(`${key}=${value}`);
        })
    queryString = queryArr.join("&");
   }

   const setAppId = (loc) => {
    switch(loc.pathname) {
        case '/realestate-verify' : 
        case '/realestate-resetpassword' :
                    appId = iosAppId.REAL_ESTATE
                    break;
        case '/invite-collaborator' : 
                    appId = iosAppId.LUCI_CAM
                    break;
        // case '/guestuploads' : 
        //             appId = iosAppId.LUCI_CAM
        //             break;
    }
   }
    useEffect(()=> {
        setQueryString(query);
        setAppId(location);
        redirectToApp(appId, queryString)
    }, []);

    return <></>
}

export default RedirectToAppStore;