import { makeStyles } from "@mui/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Dialog, DialogContent, IconButton, Box, Typography, DialogActions } from "@mui/material";
import { useState } from "react";
// import { customTheme as theme } from '../../../theme/customTheme'
import '../../../App.css'
import useCustomTheme from "../../../theme/useCustomTheme";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function CustomAlert(props) {
  //   const [open, setOpen] = useState(true);
  const classes = useStyles();
  const [theme ] = useCustomTheme()
  const { maxWidth, open, handleClose, rightComponent , width, height, hideCloseHandler} = props;

  const themeReducer = useSelector((state) => state.root.themeReducer);
  // const brandLogo = window.localStorage.getItem('brand_logo_url')
 //  const primaryColor = localStorage.getItem('primary')
  const primaryColor = themeReducer?.primary

  const inlineStyles = {
    background: ` 
  linear-gradient(0deg, white, transparent, transparent 80%),
  linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  linear-gradient(0deg, white, #ffffff40, #ffffff20 97%),
  linear-gradient(to right, ${theme.palette.secondary.main}70,  ${theme.palette.primary.main}60, #70707080)
    `
  };



  return (
    <>
    <Dialog
      open={open}
      //onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth ? maxWidth : "lg"}
      slotProps={{
        backdrop: {
            sx: {
              backdropFilter:primaryColor ? 'blur(12px) !important':  'blur(12px) !important',
              // backdropFilter:  'blur(12px) !important',
              // background: '#08231B88 0% 0% no-repeat padding-box',
              background: primaryColor ? `${theme.palette.primary.main}95 0% 0% no-repeat padding-box , #08231B50 0% 0% no-repeat padding-box` : '#08231B88 0% 0% no-repeat padding-box'
            }
        }
        
    }}
      PaperProps={{ 
        sx: { borderRadius: '5px', 
        height:height ? height : {sm:'330px' ,md:'430px' ,lg:'350px' ,xl:'555px'},
        width: width ?width :{lg:'820px',xl:'1400px'}, 
        backgroundColor:'transparent',
        boxShadow:'none'
        }}}
      fullWidth='lg'
      {...props}
    >

      <DialogContent
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" , margin:0, padding:0, backgroundColor:'transparent'}}
        id="alert-dialog-description"
      >
        <Box className='flex col' width='100%' height='90%' style={{borderRadius:'5px'}}>
            <Box className='flex row j-end' sx={{backgroundColor: 'transparent'}} >    
           {!hideCloseHandler && <Typography onClick={handleClose} 
                    sx={{fontFamily: 'Rubik', textAlign: 'right', 
                         fontSize:{xs:'10px',md:'12px', xl:'14px'}, 
                         color: '#FFFFFF',
                         width: '60px', fontWeight:700, marginTop:{xs:'10px',sm:'15px',md:'20px',lg:'20px',xl:'44px'}, 
                         marginBottom:{xs:'10px',md:'14px',lg:'12px',xl:'24px'}, 
                         cursor:'pointer',
                         backgroundColor: 'transparent'
                        }}>Close X</Typography>}
            </Box>

            <Box className='flex' width='100%' height='90%' style={{backgroundColor:'white', borderRadius:'5px', overflowX:'auto'}}>
                <Box className="linear-gradient-dialog"  style={primaryColor ?  inlineStyles: {}}  sx={{ height:'100%', borderRadius:'5px 0 0 5px'}} flex={3}>
                    {props.children}
                </Box>
                {rightComponent && <Box pl={1} style={{backgroundColor:'white', height:'100%',  borderRadius:'5px'}} flex={1.2}>
                 {rightComponent}
                </Box>}
            </Box>
        </Box>
        
      </DialogContent>
    </Dialog>
  
    </>
  );
}
