import CustomInput from "../../eventcreation/components/CustomInput";
import { makeStyles } from "@mui/styles";
// import { customTheme as theme } from "../../../theme/customTheme";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { checkEmail, userAppleSignin } from "../../../services/api";
import { afterValidate, generateLighterContrastColor, setInput } from "../../../utils/commonServices";
import useValidator from "../../../hooks/useValidator";
// import AppleSigninSuccessPopup from "./AppleSigninSuccessPopup";
import useAuthRequest from "../../../hooks/useAuthRequest";
// import CustomSnackbar from "../../components/CustomSnackbar";
import CustomTooltip from "../../../components/CustomTooltip";
import PasswordInstructions from "../../../components/PasswordInstructions";
import movieIcon from '../../../assets/movieIcon.svg';
import { Box, CircularProgress, Grid, Paper, Typography, getContrastRatio } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import CustomButton from "../../../components/CustomButton";
import { LoadingButton } from "@mui/lab";
import { gaEvents } from "../../../ga/actions";
import useAppleSignup from "./hooks/useAppleSignup";
import { useDispatch, useSelector } from "react-redux";
import { updateAppleSignState } from "../../../redux/appleSignSlice";
import CheckIcon from "../../../components/icons/CheckIcon";
import SignInWithApple from "./components/SignInWithApple";
import { PRODUCT_ID } from "../../../constants/constants";
import useCustomTheme from "../../../theme/useCustomTheme";



const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const AppleSignin = (props) => {

  const {importFilesStatus, } = props
    const [signApple, signAppleStatus] = useAppleSignup();
    const classes= useStyles();
    const navigate = useNavigate();
    const [theme ] = useCustomTheme()
    const appleSignReducer = useSelector((state) => state.appleSignReducer);
    const [pageData, setPageData] = useState({
        name: "" ,
        company_name: "" ,
       // phone_number: "",
        email:  "" ,
      // apple_token: appleSignReducer.apple_response.email,
        
      })
    // const [pageData, setPageData] = useState({
    //   name: "" ,
    //   company_name: "" ,
    //   phone_number: "",
    //   email:  "" ,
    //  // apple_token: appleSignReducer.apple_response.email,
      
    // })
    const [errorObjectArray, checkEmptyFields, checkValue] = useValidator(pageData);
    const dispatch = useDispatch()
   
    useEffect(()=> {
      if(appleSignReducer.apple_response) {
        setPageData({
          name: appleSignReducer.apple_response.name === null ? "" : appleSignReducer.apple_response.name,
          company_name: appleSignReducer.apple_response.company_name ,
         // phone_number: appleSignReducer.apple_response.phone_number ? appleSignReducer.apple_response.phone_number : "",
          //email: (appleSignReducer.apple_response.email != null ? (appleSignReducer.apple_response.email?.includes('@privaterelay.appleid.com') ? "" : appleSignReducer.apple_response.email)  : "" ),
          email: appleSignReducer.apple_response.email === null ? "" : appleSignReducer.apple_response.email,
          // apple_token: appleSignReducer.apple_response.email,
        })
      }
    },[appleSignReducer.apple_response])

    useEffect(() => {
      // if (signupStatus == "success") {
      //   setOpenSuccessPopup(true);
      // }
       if (signAppleStatus == "check_mail") {
        // setOpenSuccessPopup(true);
        // navigate('/resendlink', {state: pageData})
        navigate('/resendlink', {state: {pageData: pageData, title: 'Your account has been created', icon: movieIcon, btnText: 'Resend Email', message:'Please check your email and click on the verify now button to continue.', signIn: false}, replace:true})
       }
    }, [signAppleStatus]);
  
    //code to detect enter key for editing Organization Name
    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          submitClickHandler(event);
        }
      };
      document.addEventListener("keydown", keyDownHandler);
  
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [pageData]);


    const submitClickHandler = async (event) => {
      // setSnackbarProps({type: "success", flag: true, message: "Test snackbar"})
      // console.log("button", event.type) 
    await checkEmptyFields(event);
     await checkValue(event);
     setTimeout(()=> {
      afterValidate(afterValidatCallback);
     }, 1000)
    };
  
    const afterValidatCallback = () => {
     const payload = { 
        product_id: PRODUCT_ID.lucihub,
        full_name: pageData.name,
        company_name: pageData.company_name ,
        phone_number: pageData.phone_number,
        email: pageData.email,
        apple_token: appleSignReducer.apple_token
      }
      gaEvents.joinClicked();
      signApple(payload);
    };


  return ( 
    <UnauthLayout both>
      <Box className={classes.center} square component="Box">
        <Box
          container
          justifyContent="flex-start"
          spacing={3}
        >
          {
            appleSignReducer.apple_response == null ? 
              <SignInWithApple/> 
              : <>
                  <Box item xs={11} sm={8} md={7}>
                    <Box sx={{marginY:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
                      <Typography
                        variant="h4"
                        color="primary"
                        style={{
                          fontWeight: 600,
                          color:'#08231B'
                        }}
                        sx={{fontSize:{xs:'30px',sm:'40px',md:'47px',lg:'54px',xl:'80px'}, fontFamily:'Rubik'}}
                      >
                        Enter Your Details..
                      </Typography>
                    </Box>
                  </Box>

                  <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column'}}>
                    <CustomInput
                      id={errorObjectArray[0]?.id}
                      onFocus={checkEmptyFields}
                      onBlur={checkValue}
                      disabled = {appleSignReducer.apple_response.name ? true : false }
                      required
                      errorFlag={errorObjectArray[0]?.errorFlag}
                      errorMsg={errorObjectArray[0]?.error}
                      // errorFlag= {true}
                      // errorMsg= "jsnkdjbfskfj"
                      // label="Name*"
                      type="text"
                      max={40}
                      placeholder="Name"
                      onChange={(event) => {
                        setInput(
                          pageData?.name != ""
                            ? event.target.value
                            : event.target.value.trim(),
                          "name",
                          pageData,
                          setPageData
                        );
                        checkEmptyFields(event);
                      }}
                      value={pageData?.name}
                      // required
                      // error={submitFlag && getErrorMsz('name', pageData.name) != ""}
                      // errorMsz={getErrorMsz('name', pageData.name)}
                    />
                    <CustomInput
                      id={errorObjectArray[1]?.id}
                      onFocus={checkEmptyFields}
                      onBlur={checkValue}
                     required
                     errorFlag={errorObjectArray[1]?.errorFlag}
                     errorMsg={errorObjectArray[1]?.error}
                      type="text"
                      max={40}
                      placeholder="Company Name"
                      onChange={(event) => {
                        setInput(
                          pageData?.company_name != ""
                            ? event.target.value
                            : event.target.value.trim(),
                          "company_name",
                          pageData,
                          setPageData
                        );
                        checkEmptyFields(event);
                      }}
                      value={pageData?.company_name}
                      // required
                      // error={submitFlag && getErrorMsz('name', pageData.name) != ""}
                      // errorMsz={getErrorMsz('name', pageData.name)}
                    />
                    {/* <CustomInput
                      id={errorObjectArray[2]?.id}
                      onFocus={checkEmptyFields}
                      onBlur={checkValue}
                      // required
                      // errorFlag={errorObjectArray[2]?.errorFlag}
                      // errorMsg={errorObjectArray[2]?.error}
                      // label="Phone Number"
                      type="text"
                      max={10}
                      placeholder="Phone Number"
                      onChange={(event) => {
                        !isNaN(event.target.value) &&
                          setInput(
                            event.target.value.trim(),
                            "phone_number",
                            pageData,
                            setPageData
                          );
                        checkEmptyFields(event);
                      }}
                      value={pageData?.phone_number}
                    /> */}
                    {
                      !appleSignReducer.apple_response.email?.includes('@privaterelay.appleid.com') &&
                      <CustomInput
                      id={errorObjectArray[2]?.id}
                      onFocus={checkEmptyFields}
                      onBlur={checkValue}
                      disabled = { appleSignReducer.apple_response.email !== null}
                      required
                      errorFlag={errorObjectArray[2]?.errorFlag}
                      errorMsg={errorObjectArray[2]?.error}
                      // label="Email Address*"
                      type="text"
                      max={40}
                      placeholder="Email Address*"
                      onChange={(event) => {
                        setInput(
                          pageData?.email != ""
                            ? event.target.value
                            : event.target.value.trim(),
                          "email",
                          pageData,
                          setPageData
                        );
                        checkEmptyFields(event);
                      }}
                      value={pageData?.email}
                    />
                    }
                   
                  
                  </Box>

                  <Box item xs={11} sm={8} md={7}>
                    <Box
                      container
                      justifyContent="space-between"
                      sx={{
                        flexDirection: { xs: "column-reverse", sm: "row" },
                        alignItems: "center",
                      }}
                    >

                      <Box item xs={8} sm={4} sx={{display:'flex', justifyContent:'flex-end', marginTop:{xs:'20px',sm:'28px',md:'28px',lg:'33px',xl:'50px'}, gap:2}}>
                        {/* <CustomButton
                          btnText="Join Now"
                          disabled={AppleSigninStatus === "loading"}
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={submitClickHandler}
                          style={{borderRadius: '5px', backgroundColor:'#08231B', color:theme.palette.primary.main, textTransform:'none', }} 
                          sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,}}
                        /> */}
                        { signAppleStatus == 'loading' || signAppleStatus == 'success' ? <LoadingButton
                          // size="small"
                            sx={{
                                '& .MuiLoadingButton-loadingIndicator': {
                                    color: theme.palette.primary.main,
                                },
                                height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                              minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                                backgroundColor: '#08231B'
                            }}
                            loading={signAppleStatus == 'loading'}
                            variant="outlined"
                            disabled
                            >
                          
                      </LoadingButton> :
                      <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {submitClickHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
                      }
                      </Box>

                      <Box
                        sx={{ marginTop: { xs:'20px',sm:'30px',md:'40px',lg:'45px',xl:'70px' } }}
                        item
                        xs={12}
                        sm={8}
                        textAlign='center'
                      ><Typography  sx={{ mb:2, fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13px',xl:'20px'}, fontFamily:'Rubik', color:'#000000'}}>
                        {`Use another Apple Id? `}
                        </Typography>
                        <SignInWithApple />
                      </Box>
                    </Box>
                  </Box>
              </>
          }
          
        </Box>
      </Box>
    </UnauthLayout>
  )
}

export default AppleSignin


