import React from "react";
import { Box, SvgIcon } from "@mui/material";
import useCustomTheme from "../../../../theme/useCustomTheme";

const EditDateImage = (props)=> {
    const [theme] = useCustomTheme()
    return <SvgIcon 
            {...props} 
            xmlns="http://www.w3.org/2000/svg" width="181.672" height="183.962" viewBox="0 0 181.672 183.962">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_173" data-name="Rectangle 173" width="181.672" height="183.962" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_239" data-name="Group 239">
    <path id="Path_184" data-name="Path 184" d="M83.112,21.984c-4.318,0-8.519.059-12.717-.016-4.865-.086-8.141-3.082-8.111-7.275.029-4.289,3.182-7.142,8.13-7.223,4.2-.069,8.4-.014,11.555-.014C84.3,4.76,85.689,2.2,87.837.892c3.66-2.237,8-.05,10.006,4.129.489,1.018,1.812,2.306,2.771,2.322,10,.169,20.011.1,30.087.1.37-.987.669-1.837,1.007-2.671A6.91,6.91,0,0,1,138.421.142c3.19-.041,5.832,1.468,6.73,4.519.74,2.513,2.07,2.91,4.276,2.823,3.157-.125,6.323-.068,9.484-.021,12.8.19,22.251,9.3,22.623,22.057.212,7.239.149,14.49.01,21.733-.122,6.368-4.025,10.242-10.376,10.625-1.314.079-2.634.031-3.952.031H14.834V66.3q0,46.631,0,93.261c0,7.058,2.453,9.527,9.432,9.618a85.962,85.962,0,0,1,9.073.216,7.24,7.24,0,0,1-.1,14.344,70.413,70.413,0,0,1-14.571-.309A21.637,21.637,0,0,1,.1,161.62Q-.089,95.625.107,29.632C.151,16.9,9.852,7.67,22.6,7.462c2.371-.039,4.749-.116,7.113.016,3.357.188,6.168.324,7.564-3.995.944-2.917,4.2-3.791,7.365-3.174,3.368.657,5.567,2.838,5.748,6.158a139.35,139.35,0,0,1-.02,16.583c-.227,3.479-2.61,5.7-6.2,6.135-3.55.434-6.535-.972-7.555-4.334-.842-2.772-2.322-2.983-4.608-2.892-3.157.126-6.329-.086-9.483.075-4.691.24-7.565,3.173-7.65,7.831-.1,5.628-.025,11.259-.025,17.1H166.73c0-6.182.177-12.207-.063-18.216-.155-3.861-3.292-6.517-7.5-6.707-3.681-.167-7.382-.152-11.061.03a3.356,3.356,0,0,0-2.531,1.651c-1.274,3.759-3.749,5.717-7.663,5.5C133.7,29,131.728,26.2,130.879,22.19H98.4c-.983,3.953-2.972,7.043-7.585,7.064s-6.649-3.039-7.706-7.27" transform="translate(0 0)" fill="#08231b"/>
    <path id="Path_185" data-name="Path 185" d="M68.462,184.056c-2.955-9.269,1.117-17.313,6.423-25.44,9.5-14.546,18.291-29.547,27.534-44.259a21.061,21.061,0,0,1,22.061-9.806c9.076,1.859,16.068,8.6,16.919,17.972a24.031,24.031,0,0,1-2.671,13.545c-10.786,18.39-22.191,36.419-33.5,54.5a11.009,11.009,0,0,1-4.389,3.753c-6.861,3.335-13.82,6.473-20.787,9.583-6.443,2.877-11.34-.2-11.566-7.277-.126-3.947-.022-7.9-.022-12.569m14.446,1.912c7.563-1.895,10.573-4.019,14.29-10.017,5.923-9.558,11.842-19.119,17.891-28.885L103.894,140.2c-.907,1.356-1.653,2.4-2.33,3.5-5.063,8.152-9.686,16.622-15.306,24.369-4.163,5.736-3.826,11.61-3.35,17.906m28.715-58.552,11.244,6.989c1.99-4.151,6.256-7.359,3.32-12.051a8.308,8.308,0,0,0-6.012-3.375c-5.075-.01-6.322,4.646-8.552,8.437" transform="translate(-13.952 -21.535)" fill="#08231b"/>
    <path id="Path_186" data-name="Path 186" d="M209.73,141.013c0,12.639.022,25.278-.006,37.917-.033,14.81-9.372,24.109-24.228,24.132-13.694.021-27.388-.017-41.083.016-3.676.009-6.5-1.407-7.773-4.894-1.066-2.928-.635-6.039,2.04-7.822a12.74,12.74,0,0,1,6.579-1.939c13.166-.175,26.334-.075,39.5-.093,7.853-.01,10.175-2.313,10.178-10.051q.008-37.126,0-74.253c0-.922-.017-1.843,0-2.764.121-5.006,3.164-8.57,7.355-8.628,4.256-.059,7.37,3.389,7.405,8.489.071,10.137.023,20.274.024,30.412q0,4.739,0,9.479" transform="translate(-28.146 -19.157)" fill="#08231b"/>
    <path id="Path_187" data-name="Path 187" d="M86.862,195.37c-.478-6.3-.813-12.169,3.35-17.906,5.621-7.746,10.243-16.216,15.306-24.369.678-1.091,1.424-2.139,2.331-3.5l11.194,6.868c-6.049,9.766-11.969,19.327-17.891,28.885-3.718,6-6.727,8.122-14.29,10.017" transform="translate(-17.906 -30.937)" fill={theme.palette.primary.main}/>
    <path id="Path_188" data-name="Path 188" d="M123.135,131.286c2.23-3.791,3.477-8.447,8.552-8.437a8.308,8.308,0,0,1,6.012,3.375c2.936,4.692-1.33,7.9-3.32,12.051l-11.244-6.989" transform="translate(-25.464 -25.405)" fill={theme.palette.secondary.main}/>
  </g>
  </SvgIcon>
    
  
}
export default EditDateImage