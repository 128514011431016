import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  genre: [],
  phone_count_range: [],
  tones: [],
  capture_quality_list: [],
  event_type_old: [],
  event_type:[],
  event_type_filter: [],
  video_length:[],
  status_type: [],
  selected_status: [],
  user_role :[
    { id:1,name:'Manager'}, 
    { id:2, name:'Team Manager'},
    { id:3, name:'Administrator'},
    { id:4, name:'Associate',}
  ],
  project_user_role :[ 
    { id:1, name:'Contributor'},
    { id:2, name:'Filmer'},
  ],
  organisation_roles: [],
  team_roles: [],
  project_roles: [],
  asset_options: []
};

export const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: initialValue,
  reducers: {
    setDropDownArray: (state, action) => {
      assignValueInState(action.payload, state);
    },
    emptyDropdownArray : (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { setDropDownArray, emptyDropdownArray } = dropdownSlice.actions;
export default dropdownSlice.reducer;