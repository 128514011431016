import { Box, Button, IconButton, Input, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { useDispatch, useSelector } from "react-redux";
import { updateTeamState } from "../../../redux/teamSlice";
import SearchIcon from "@mui/icons-material/Search";
import { updateProjectState } from "../../../redux/projectSlice";
import ProjectMembersList from "./ProjectMembersList";



const ViewProjectMembersPopup = () => {

   
    const dispatch = useDispatch()
    const [searchTerm, setSearchTerm] = useState('');
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const projectReducer = useSelector((state) => state.projectReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const options = dropdownReducer.project_roles
    // const [testmembers, setTestmembers] = useState(teamsReducer.organization_members)
    // const [testmembers, setTestmembers] = useState(members)
    const [members, setMembers] = useState(commonReducer.selected_project_members)
    const [searchedArray, setSearchedArray] = useState(members)

  


    useEffect(()=>{
      setSearchedArray(commonReducer.selected_project_members)
    },[commonReducer.selected_project_members])
  


    const handleClose = () => {
      dispatch(updateProjectState({view_project_members_popup : false}))      
    };

  

  const handleSearch = (e) => {
    const searchedInput = e.target.value.toLowerCase();
    if (!searchedInput.startsWith(' ')) {
      setSearchTerm(searchedInput);
    }
    // const searchedInput = e.target.value.toLowerCase().trim();
    // setSearchTerm(searchedInput);

    const updatedFiles = [...members];

    const results = updatedFiles?.filter(item =>
      item.name?.toLowerCase().includes(searchedInput) ||
      item.email?.toLowerCase().includes(searchedInput)||
      item.role?.name?.toLowerCase().includes(searchedInput)
    );
    setSearchedArray(results);
  };

    return (
        <CustomAlert 
        open={projectReducer.view_project_members_popup} 
          handleClose={handleClose} 
          height={{xs:'420px', sm:'370px',md: '500px', xl:'620px'}}
          width={{md:'65%',lg:'65%', xl:'1400px'}}
          rightComponent={
          <Box component='div' className= 'flex col a-start' sx={{paddingX:{xs:'10px',md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%',  overscrollBehaviorBlock:'contain', overflowY:'auto', width:'100%', justifyContent:{md:'center'}, paddingTop:{xs:2,sm:3, md:1, lg:0}}}>
            {/* <Box  sx={{height: '100%',  overscrollBehaviorBlock:'contain', overflowY:'auto', width:'100%', justifyContent:{sm:'center'}}} className='flex col a-start'>  */}
              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, fontFamily:'Rubik', color:'#000'}} >Project Managers</Typography>
              <Box component='ul' pl={1} mt={0} style={{listStyleType: 'disc'}} mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Manage project members</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Edit project details</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Manage all media files of the project</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Create and send edit requests</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Approve, and download final video</Typography>
              </Box>

              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Collaborators</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0} mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of other team members</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Manage their uploaded media files</Typography>
              </Box>
              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Filmers</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}  mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Upload, view and delete their files in the project</Typography>
              </Box>
              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Reviewers</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}  mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View edited video</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Start a revision request and view edit notes</Typography>
              </Box>
              <Typography sx={{fontSize:{xs:'8px',md:'9px',lg:'10px', xl:'14px'}, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Approvers</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}  mb={{xs:1, md:1.5, lg:2.5}}>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View edited video and edit notes</Typography>
                <Typography component='li' sx={{fontSize:{xs:'7px',md:'8px',lg:'9px', xl:'12px'}, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View and download final accepted video</Typography>
              </Box>
            {/* </Box> */}
          </Box>}
          >       
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={4} pt={3} pb={2}>
              <Box flexGrow={1} pt={4} >    
                  <Box className='flex col' gap={1.5}>
                    <Box className='flex j-between a-center' style={{width:{xs:'90%', md:'80%'}}} pb={2}>
                      <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'#000', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'27px'}}}>Members ({members?.length})</Typography>
                      <Box id="search" >
                        <Input
                        value={searchTerm}
                        onChange={handleSearch}
                        inputProps={{ maxLength: 50 }}
                        sx={{
                          //  minWidth: '160px',
                            borderBottom: 1,
                            borderColor: "#333333",
                            input: {
                                fontSize: { xs: '12px', md: '12px', lg: '13px', xl: '16px' },
                                fontWeight: 400,
                                color: "#000000",
                            },
                            width:'60%', 
                            // mr:5
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton sx={{paddingBottom:0.5, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'15px',xl:'20px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                    </Box>
                    </Box>
                    <Box sx={{height:'28vh', overflowY:'auto'}} >

                      {searchedArray.length > 0 ? searchedArray.map((member, index) => {
                        return  <ProjectMembersList  member={member} index={index} searchedArray={searchedArray} roleOptions={options}/>
                      }): 
                       <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'>
                          <Typography sx={{fontSize: 20, color: '#878887', fontWeight: 300, textAlign: 'center', fontFamily:'Rubik'}} >No results found.
                          </Typography>
                        </Box>
                      }
                    </Box>
                  </Box>
  
              </Box>
          </Box>
       
        </CustomAlert>
      )
}

export default ViewProjectMembersPopup