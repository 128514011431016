import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LoadingButton } from "@mui/lab";
import CustomAlert from "../../events/components/CustomAlert";
import CustomInput from "../../eventcreation/components/CustomInput";
import { updateCreationState } from "../../../redux/eventCreationSlice";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";

const  DeclineEditProjectPopup = (props) => {
    const dispatch = useDispatch()
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const [theme ] = useCustomTheme()

    const handleClose = () => {
        dispatch(updateCreationState({edit_decline_popup : false})) 
       };

  return <CustomAlert
          open={createEventReducer?.edit_decline_popup}
          handleClose={handleClose}
          width={{ xs: '400px', sm: '400px', md: '420px', lg: '440px', xl: '600px' }}
          height={{ xs: '220px', sm: '220px', md: '230px', lg: '230px', xl: '295px' }}
      >
          <Box p={5} className="flex col j-center" sx={{ height: '100%' }}>
              <Box mb={{ xs: 1, md: 2 }} mt={1}>
                {/* {
                    commonReducer.is_collaborator ? <Typography variant="h4" sx={{ fontFamily: 'Rubik', fontSize: { xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '18px' }, color: 'black' }}>Sorry! Only project owner is authorized to delete the project.</Typography> :
                    <Typography variant="h4" sx={{ fontFamily: 'Rubik', fontSize: { xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '18px' }, color: 'black' }}>{`Sorry, "`}<Typography component='span' sx={{ fontFamily: 'Rubik', fontWeight: 600, fontSize: { xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '18px' }, color: 'black' }} >{commonReducer.event_delete_name}</Typography> {`" cannot be cancelled at this stage.`}</Typography>
                } */}
                <Typography variant="h4" sx={{ fontFamily: 'Rubik', fontSize: { xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '18px' }, color: 'black' }}>{`Sorry, "`}{`"Project cannot be edited at this stage.`}</Typography>
                 
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end' }} gap={2} mt={{ xs: 1, md: 2 }} >
                  <Button variant='outlined' onClick={handleClose} style={{ borderRadius: '5px', backgroundColor: '#08231B', color: theme.palette.primary.main, textTransform: 'none', }} sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, width: { xs: '76px', sm: '76px', md: '76px', lg: '86px', xl: '129px' }, paddingY: 0.4, }}>Close</Button>
              </Box>
          </Box>
      </CustomAlert>
}

export default DeclineEditProjectPopup