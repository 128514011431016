import { Box } from "@mui/material";
import React from "react";
import CustomTooltip from "../../../components/CustomTooltip";
import { getStatusNameFromStatusTypeOrder } from "../../../utils/commonServices";

const EventStageBar = (props)=> {
    const {statusCode} = props;
    //const stages = ["pre", "production", "post", "review", "complete"];
    const statusCodes = [1, 2, 3, 4, 5]
   // const currentStage = stages.indexOf(props.status)
    return   <CustomTooltip title={getStatusNameFromStatusTypeOrder(statusCode)}  
    PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [-35, -4],
            },
          },
        ],
      }} 
      ><Box width='100%'  sx={{ height:{xs:'7px',lg:'7px',xl:'13px'}}} className='flex row j-between'>
        {
            statusCodes.map((status, index)=> {
                return <Box sx={{
                    height: {xs:'7px',lg:'7px',xl:'13px'}, 
                                    width: '19%', 
                                    backgroundColor: statusCode > index ? '#08231B' : '#EBEBEB',
                                    borderBottomLeftRadius: index == 0 && '10px',
                                    borderBottomRightRadius: index == statusCodes?.length - 1 && '10px' 
                                    
                                }}>

                </Box>

            })
        }
    </Box></CustomTooltip>
}
export default EventStageBar