import { ThemeContext } from "@emotion/react";
import backGround from "../../../assets/unAuth_BG.svg";
import {Backdrop, Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "./Footer";
import RightComponent from './RightComponent'
import Header from "./Header";
import { useState } from "react";
// import CustomSnackbarDrawer from "../../../components/CustomSnackbarDrawer";
import { useSelector } from "react-redux";
import filmingCard from '../../../assets/filmingTutorialCard.png'
import CustomSnackbar from "../../../components/CustomSnackbar";
import "@fontsource/rubik";
import SignupWithApplePopup from "../appleSignin/components/SignupWithApplePopup";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";
import CreateProjectSpinner from "../../eventcreation/components/CreateProjectSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",

    height: "100%",
    minWidth: "100vw",
    // backgroundImage: `linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670, ${theme.palette.secondary.main}20 , ${theme.palette.secondary.main}50 80%,  ${theme.palette.primary.main}60 , ${theme.palette.primary.main}60 )`, 
    // backgroundImage: `linear-gradient(to right bottom, #ffffff, #ffffff, #efefef, #efefef, ${theme.palette.primary.main}80, ${theme.palette.primary.main} )`
    backgroundImage: 'linear-gradient(140deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
    // aspectRatio: '1/6'
  },
  content: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    scrollbarColor: "#FFFFFF00",
    height: "85vh",
    marginTop: "10vh",
  },
}));

const UnauthLayout = (props) => {
  const { signup, both, signin, rightComponent } = props;
  const classes = useStyles();
  const [theme ] = useCustomTheme()
  const snackbarProps = useSelector((state) => state.messageReducer);
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const appleSignReducer = useSelector((state) => state.appleSignReducer);
  const themeReducer = useSelector((state) => state.root.themeReducer);
  // const [snackbarProps, setsnackbarProps] = useState();

  return (
    <>
      <Box className={classes.root} sx={{width:'100%',}}>
        <Header signup={signup} both={both} signin = {signin} />
        <Box
        //   maxWidth="md"
          sx={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },

            scrollbarColor: "#FFFFFF00",
            // height: {xs: 'calc(100vh - 140px)', lg: 'calc(100vh - 180px)'},
            height: "calc(100% - 130px)",
            // height:'100%',
            marginTop: { xs: "80px", lg: "80px" },
            px: { xs: '1vw', sm: "3vw", md: "6vw", lg:'6vw',xl:'6vw' },
            display:'flex',
            width:'100%',
            maxWidth:'1920px',
            margin:'auto'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6 } lg={6} xl={6}  sx={{display:'flex', alignItems:'center', justifyContent:{xs:'center',md:'flex-start'}, }}>
              {/* <Box flex={1} className='flex a-center' pl={3}> */}
                {props.children}
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  className='flex j-center a-center'>
              {/* <Box flex={1} className='flex j-center a-center' > */}
            {  rightComponent ? rightComponent: <RightComponent/>}
              {/* </Box> */}
            </Grid>
          </Grid>
        </Box>
        {Object.keys(snackbarProps).length > 0 && (
          <CustomSnackbar
         
          />
        )}
       {/* {appleSignReducer?.signup_with_apple_popup == true && <SignupWithApplePopup/>} */}
        <Footer />
      </Box>

    </>
  );
};

export default UnauthLayout;