import React, {useMemo,useEffect, useState}  from 'react';
import CustomCard from './CustomCard';
import { Typography } from '@mui/material';
import "@fontsource/rubik";


  
 
const UpgradePlanCard = (props) => { 
  return (
    <CustomCard className= 'flex col j-center a-center' sx={{ width: '100%', height: {md: '50vh',lg: '50vh' , xl: '50vh'}, }} >
        <Typography sx={{ color: '#08231B', fontSize: {xs: '12px', sm: '14px', md: '17px', lg: '20px', xl: '25px'}, fontWeight: 300, fontFamily: 'Rubik', textAlign: 'center', mx: 2}} >
            Your uploaded videos will be available to download for the next 
            <Typography sx={{ color: '#08231B',fontSize: {xs: '12px', sm: '14px', md: '17px', lg: '20px', xl: '25px'}, fontFamily: 'Rubik', fontWeight: 600, ml: 0.5}}>
                30 days.
            </Typography>
        </Typography>
    </CustomCard>
  );
}

export default UpgradePlanCard;