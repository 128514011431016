import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    guest_user_details_popup: false,
    guest_name:'',
    event_details: {},
    logs:[],
    theme:{},
    guest_reviewer_id: null,
    is_loading: false,
    create_log_loading: false,
    timestamp: '00:00',
    log_id: '',
    edit_flag: false,
    delete_log_loading: false,
    delete_log_confirmation_popup:false,
    log: {},
    show_guest_revision_page: true,
    watch_video_link: '',
    video_download_url:'',
    guest_reviewer_page_loading: true,
    get_event_details_loading: false
}


export const guestSlice = createSlice({
    name: 'guest',
    initialState: initialValue,
    reducers: {
        setGuestReviewState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetGuestReviewState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { setGuestReviewState, resetGuestReviewState } = guestSlice.actions
export default guestSlice.reducer