import { useSelector } from "react-redux";
import { timeStampToNumberedDateString } from "../../../utils/commonServices";


export default (filesArray, selectedProjectTypes) => {
 
    let filteredFiles=[]
// if(projectType === 'View All' || projectType == '' || projectType == null){
//   filteredFiles = [...filesArray]
// }else{
//   const sorted = [...filesArray]?.filter(item => item.event_type == projectType)
//   filteredFiles = sorted;
// }

if (selectedProjectTypes.includes('View All') || selectedProjectTypes.length === 0) {
  filteredFiles = [...filesArray];
} else {
  filteredFiles = [...filesArray].filter(item => selectedProjectTypes.includes(item.event_type));
}
// console.log('filteredFiles', filteredFiles)
    return [filteredFiles];
  };