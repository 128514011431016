import { Box, Button, CircularProgress, IconButton, Input, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import { LoadingButton } from "@mui/lab";
import CustomAutocomplete from "../../eventcreation/components/CustomAutoComplete";
import { setAssetState } from "../../../redux/assetSlice";
import { getNamesFromIds } from "../../../commonactions/actions";
import { getErrorMsg } from "../../../utils/validator";
import BrandboxUploadZone from "./BrandboxUploadZone";
import { getAllAssets } from "../actions";
import { useNavigate } from "react-router-dom";
import useSequentialUpload from "../hooks/useSequentialUpload";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { blue } from "@mui/material/colors";
import { generateLighterContrastColor } from "../../../utils/commonServices";
import useCustomTheme from "../../../theme/useCustomTheme";
// import { customTheme as theme } from "../../../theme/customTheme";



const UploadFilesPopup = (props) => {
    const {   } = props
   
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const assetReducer = useSelector(state=> state.assetReducer)
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const [selectedBrandboxOption, setSelectedBrandboxOption] = useState({option_id: ''})
    const [brandboxOptionValidationFlag, setBrandBoxOptionValidationFlag] = useState(false)
    const [errorText, setErrorText] = useState('')
    let label = getNamesFromIds(selectedBrandboxOption?.option_id, dropdownReducer.asset_options)?.name
    const [uploadedVideos , setUploadedVideos] = useState ([])
    const [acceptType, setAcceptType] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [theme] = useCustomTheme()
    // const [enableFileUpload, setEnableFileUpload] = useState(false)
    // const uploadedVideos = assetReducer?.all_assets?.find( option => option.label == label)?.files
    // const [requestController , setRequestController] = useState('')
    const { files, 
        setFiles,
        progress, 
        uploadStatus,
        setUploadStatus, 
        setProgress,
        isLoading,
        requestController, 
        setRequestController,
        stopUpload, 
        stopList ,
        enableFileUpload,
        setEnableFileUpload} = useSequentialUpload( selectedBrandboxOption?.option_id , false, uploadedVideos, ()=>getAllAssets(dispatch, navigate), ()=>afterUploadCallback()) 
   
    // useEffect (()=> {
    //   if(files?.length > 0 && uploadStatus?.filter((item)=> item.type == 'error')?.length == 0 && !isLoading ) {
    //     setDisableSubmit(false)
    //   } else {
    //     setDisableSubmit(true)
    //   }
    // }, [files, isLoading, uploadStatus])
 
useEffect(()=>{
    label = getNamesFromIds(selectedBrandboxOption?.option_id, dropdownReducer.asset_options)?.name
    const givenAssetOption = assetReducer?.all_assets?.find( asset => asset.label == label)
    if (givenAssetOption) {
      const filesArray = givenAssetOption?.files;
      setUploadedVideos(filesArray)
    } else {
      setUploadedVideos([])
    }
},[selectedBrandboxOption?.option_id, assetReducer?.all_assets])

    const handleClose = () => {
      setEnableFileUpload(false);
      isLoading && stopList();
      getAllAssets(dispatch, navigate)
      dispatch(setAssetState({upload_files_popup : false}))      
    };

  useEffect(()=>{
    // console.log(files)
  },[files])


  const uploadFilesClickHandler =()=>{
   (files?.length > 0 && uploadStatus?.filter((item)=> item.type == 'error')?.length == 0 && !isLoading )  && setEnableFileUpload(true)
  }

  const removeFileHandler =(file, index)=>{
  const updatedFiles = files?.filter(data => data.name !== file.name)
  if(!!enableFileUpload) {
    stopUpload(index)
  } else {
    // display: enableFileUpload && 'none'
    setUploadStatus((last) => {
      var temp = last?.filter((_, i) => i !== index);
      return temp;
    });
    setFiles(updatedFiles)
  }
  
  }

  const selectBrandboxOptionHandler = (event, newValue)=> {
    setFiles([])
    setSelectedBrandboxOption({option_id: newValue.id})
    setBrandBoxOptionValidationFlag(false)
    setErrorText('')
    switch (newValue.id) {
      case 1:
        setAcceptType({"image/*": [], 'application/pdf': ['.pdf']})
        break;
      case 2:
        setAcceptType({})
        break;
      case 3:
          setAcceptType({"image/*": [], 'application/pdf': ['.pdf'], 'application/msword': ['.doc'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], 'text/plain': []})
        break;
      case 4:
          setAcceptType({"image/*": []})
        break;
      case 5:
          setAcceptType({"video/*": []})
        break;
      case 6:
     
      default:
          setAcceptType({})
        break;
    }

  }

  const afterUploadCallback =()=>{
    setDisableSubmit(true)
    handleClose()
   
  }

    return (
        <CustomAlert 
          open={assetReducer.upload_files_popup} 
          handleClose={handleClose} 
          height={{xs:'460px',md:'520px',lg:'530px' ,xl:'740px'}}
          // height={{xs:'550px',md:'610px',lg:'605px' ,xl:'700px'}}
          width={{xs:'600px',md:'700px',lg:'740px',xl:'1130px'}}
          >       
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={4} pt={1} pb={2}>
              <Box flexGrow={1} pt={{xs:3,sm:1, md:1.5, lg:2.5, xl:3}} >    
                 
                  <Box className='flex col' gap={{xs:0.5, lg:1}}  sx={{ height:'100%'}}>
                    <Box className='flex j-between a-center' style={{width:{xs:'90%', md:'80%'}}}>
                      <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'#000', fontSize:{xs:'14px',sm:'14px',md:'16px',lg:'20px',xl:'30px'}}}>Upload Files(s)</Typography>
                      
                    </Box>
                    {/* <Box sx={{height:'30vh', overflowY:'auto'}} gap={1} pr={2}> */}

                        <Box sx={{width:{xs:'220px',sm:'320px'}}}>
                        <CustomAutocomplete
                            id="asset_type"
                            options={dropdownReducer.asset_options}
                            onFocus={() => {
                            setBrandBoxOptionValidationFlag(false)
                            }}
                            onBlur={() => setBrandBoxOptionValidationFlag(true)}
                            required
                            errorFlag= {brandboxOptionValidationFlag && getErrorMsg("brandbox_option", selectedBrandboxOption?.option_id) != ""}
                            // errorMsg= {getErrorMsg("brandbox_option", selectedBrandboxOption?.option_id)}
                            errorMsg={errorText}
                            value={getNamesFromIds(selectedBrandboxOption?.option_id, dropdownReducer.asset_options)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={selectBrandboxOptionHandler}
                            label=""
                            placeholder='Select Folder'
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.name : ""
                            }
                            msgColor='red'
                        />
                        </Box>
                        <Box  sx={{height:'100px', width: '90%' , minWidth: {xs:'240px',sm:'320px'}, marginTop:{xs: '20px', md: '10px'}, marginBottom:{xs:'10px', sm:'15px',md:'5px',lg:'45px', xl:'90px'}}}>
                        <BrandboxUploadZone
                         isDisabled={
                            selectedBrandboxOption.option_id === '' || isLoading
                           }
                         files={files}
                         setFiles={setFiles}
                         setUploadStatus={setUploadStatus}
                         setProgress={setProgress}
                         setRequestController={setRequestController}
                         refetch={()=> getAllAssets(dispatch, navigate)}
                         height={{xs: '100px',sm:'85px',md:'100px',lg:'140px', xl:'180px'}}
                         accept = {acceptType}
                         setEnableFileUpload={setEnableFileUpload}
                        //  refetchEvent = {()=> getEventDetails({id: commonReducer.production_event_id}, dispatch)}
                        //  listOfAllEvents={listOfAllEvents}
                       />
                        </Box>
                        <Box height= '100%'  className= 'flex row j-between a-start' >
                        <Box className='flex' sx={{maxWidth: {xs:'510px',md:'590px',lg:'625px',xl:'950px'}}}  >
                          <Typography mr={1}  sx={{ fontFamily:'Rubik', color:'#000', fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'18px'}}}>Files(s) 
                          </Typography>
                          <Box pl={1} component='div' sx={{ overflowY: 'scroll', maxHeight: {xs: '330px', sm: '120px', md: '210px', lg: '150px', xl: '215px'} ,  maxWidth:'95%', width:'90%'}}>
                          {files?.length > 0 && files?.map((file, index) => (
                            <Box gap={0.5}  className='flex row a-center' pr={1.5} key={file.name} >
                                <Typography  sx={{ fontFamily:'Rubik', color:'#000', whiteSpace: 'nowrap', overflowX: 'clip', fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'18px'}, fontWeight:600}}>{file.name}</Typography>
                                {
                                  uploadStatus[index]?.message == "Uploading..." ? <CircularProgress variant="determinate"  sx={{
                                    height:'4px', width:'4px',
                                    margin:{xs:0.75,md:0.87,lg:1.1, xl:1.4},
                                    color:'blue'
                                    }}
                                  size={14}
                                  value={progress[index]} />
                                  : uploadStatus[index]?.message == "Uploaded!" ? <CheckCircleOutlineIcon sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'18px'}, color: 'green'}} /> 
                                    : <Typography sx={{ fontFamily:'Rubik',  fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'18px'},  color:uploadStatus[index]?.type == 'error' ? 'red': 'green'}}> {uploadStatus[index]?.message}</Typography>
                                }
                               { uploadStatus[index]?.message != "Uploaded!" && <Typography sx={{ fontFamily:'Rubik', color:'#A00', fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'18px'}, fontWeight:600, cursor:'pointer'}} onClick={()=>removeFileHandler(file, index)}>X</Typography>
                               }
                            </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box height= '100%' className='flex col j-end a-end' >
                          <CheckIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick={uploadFilesClickHandler}  sx={{fontSize:{xs:'28px',sm:'30px',md:'42px', lg:'56px',xl:'75px'}, cursor: (files?.length > 0 && uploadStatus?.filter((item)=> item.type == 'error')?.length == 0 && !isLoading )  &&  'pointer', opacity: !(files?.length > 0 && uploadStatus?.filter((item)=> item.type == 'error')?.length == 0 && !isLoading )  && 0.5}}/>
                        </Box>
                    </Box>
                  </Box>
  
              </Box>
          </Box>
       
        </CustomAlert>
      )
}

export default UploadFilesPopup