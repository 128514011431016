import { VerticalAlignCenter } from "@mui/icons-material";
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
//import sampleUpload from '../assets/sampleUpload.svg'
// import { customTheme as theme } from "../theme/customTheme";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from '@mui/icons-material/Close';
import CustomTooltip from "./CustomTooltip";
import "@fontsource/rubik";
import BoldCloseIcon from "./icons/BoldCloseIcon";
import useCustomTheme from "../theme/useCustomTheme";

const useStyles = makeStyles((theme) => ({
  root: {
   // width: 355,
   // height: 40,
  // width:'75%',
    position: "relative",
    borderRadius: "20px",

    //  margin: 30,
  },
  box: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: "20px",
    //  opacity: 0.7,
    //  background: '#0057e3',
  },
  overlay: {
    zIndex: 9,
    // margin: 30,
    background: "#FFFFFF",
    opacity: 0.8,
    borderRadius: "0px 20px 20px 0px",
  },
}));

const CustomVideoLinearProgress = (props) => {
  const { imageURL, status, alt, progress, stopUpload, fontSize, bar } = props;

  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const [theme ] = useCustomTheme()
  // console.log({status})
  const classes = useStyles();
  // const [progress, setProgress] = useState(100);
  const intervalRef = React.useRef();
  return (
    <Box className={classes.root} sx={{width:{md:'75%'}}}>
      {
        // progress > 0 &&
        status?.type == "error" ? (
          <Box
            width="100%"
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "flex-start",
              justifyContent:{xs:'flex-start',md:'end'},
              alignItems: "center",
            }}
          >
            {/* <WarningAmberIcon
              style={{ color: "#ED1B2F", fontSize: "20px"}}
            /> */}
            <Typography
             // variant="h6"
              // color='primary'
              // style={{color: progress >20 ? theme.palette.primary.main : '#444444'}}
              textAlign='end'
              sx={{ color: "#ED1B2F", fontSize: {xs:fontSize*0.5, sm: fontSize*0.6, md: fontSize*0.7, lg: fontSize*0.8 , xl: fontSize}, fontWeight: 600,  fontFamily: 'Rubik', }}
            >
              {status?.message}
            </Typography>
          </Box>
        ) : progress > 0 && progress < 100 ? (
          <Box sx={{ display: "flex", alignItems: "baseline", width:'100%' }}>
            <Box sx={{ width: "100%", mr: 1, mt: 2 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                style={{ height: bar, borderRadius: 5 }}
                sx={{
                  color: "#08231B",
                  backgroundColor: "#FFFFFF",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              />
            </Box>
            <Box textAlign="right" >
              <Typography
                sx={{ color: "#08231B", fontSize: {xs:fontSize*0.5, sm: fontSize*0.6,  md: fontSize*0.7, lg: fontSize*0.8 , xl: fontSize},  fontFamily: 'Rubik', }}
                
              >{`${Math.round(progress)}%`}</Typography>
            </Box>
            <Box>
              <CustomTooltip title="Cancel upload">
                <IconButton onClick={stopUpload}>
                  <BoldCloseIcon
                    style={{ color: "#08231B", fontSize: 12, fontWeight: 600, marginBottom: 0 }}
                  />
                </IconButton>
              </CustomTooltip>
            </Box>
          </Box>
        ) : status?.type == "success" ? (
          <Box
            width="100%"
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "flex-start",
              justifyContent:{xs:'flex-start',md:'end'},
              alignItems: "center",
            }}
          >
            {/* <CheckCircleOutlineRoundedIcon
              style={{ color: "#08231B", fontSize: "20px" }}
            /> */}
            <Typography
             // variant="h6"
              // color='primary'
              // style={{color: progress >20 ? theme.palette.primary.main : '#444444'}}
              // textAlign='end'
              sx={{ color: "#08231B", fontSize: {xs:fontSize*0.5, sm: fontSize*0.6,  md: fontSize*0.7, lg: fontSize*0.8 , xl: fontSize}, fontWeight: 600,  fontFamily: 'Rubik', }}
            >
              {status?.message}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "flex-start",
              justifyContent:{xs:'flex-start',md:'end'},
              alignItems: "flex-end",
              width: '100%'
            }}
          >
            <Typography
             // variant="h6"
              color="primary"
              // textAlign='end'
              // style={{color: progress >20 ? theme.palette.primary.main : '#444444'}}
              sx={{ color: "#08231B", fontSize: {xs:fontSize*0.5, sm: fontSize*0.6,  md: fontSize*0.7, lg: fontSize*0.8 , xl: fontSize}, fontWeight: 600, fontFamily: 'Rubik', }}
            >
              {
                // `${100-progress}%`
                status?.message
              }
            </Typography>
            {/* <CustomTooltip title="Cancel upload">
              <IconButton
                onClick={() => setOpenCancelAlert(true)}
                sx={{ paddingBottom: "0px" }}
              >
                <CloseIcon
                  style={{ color: "#08231B", fontSize: "20px", fontWeight: 900 }}
                />
              </IconButton>
            </CustomTooltip> */}
          </Box>
        )
      }
     
    </Box>
  );
};

export default CustomVideoLinearProgress;
