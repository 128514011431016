import {  useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import useSnackMessage from "../../../hooks/useSnackMessage"; 
import { deleteUser } from "../../../services/api";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { resetState, updateState } from "../../../redux/commonSlice";
import { resetAllMediaFilesArray } from "../../../redux/mediaFilesSlice";
import { resetReviewState } from "../../../redux/reviewSlice";
import { resetSnackbar } from "../../../redux/messageSlice";
import { resetCreationState } from "../../../redux/eventCreationSlice";
import { logout } from "../../../utils/commonServices";



export default () => {

  const dispatch = useDispatch();
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [deleteUserStatus, setDeleteUserStatus] = useState();
  const [showSnackbar] = useSnackMessage();
  const navigate = useNavigate();

  // Delete Api request to delete user

  // const logout = () => {
  //   window.localStorage.removeItem("token");
  //   dispatch(resetAllMediaFilesArray())
  //   dispatch(resetReviewState())
  //   dispatch(resetSnackbar())
  //   dispatch(resetCreationState())
  //   dispatch(resetState());
  //   //navigate('/signin');
  // };

    const { mutate: deleteUserMutate, isLoading: deleteUserLoading } =
    useMutation(deleteUser, {
      onSuccess: (response, variables, context) =>
        deleteUserSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
        deleteUserErrorHandler(error, variables, context),
    });
  
  const  deleteUserSuccessHandler = (response, variables, context) => {
    const responseObject = response?.data;
    const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {
      setDeleteUserStatus("success");
      logout(dispatch)
      showSnackbar(responseObject);
    //   navigate('/signin')
    }
  };
  
  const deleteUserErrorHandler = (error, variables, context) => {
    // setSubmitFlag(false);
    setDeleteUserStatus("error");
    const errorObject = error?.response?.data;
    dispatch(updateState({account_delete_popup:false}))
    showSnackbar(errorObject)
  };
  
 

useEffect(()=> {
  if(deleteUserLoading == true) {
    setDeleteUserStatus("loading")
  }
}, [deleteUserLoading])


  return [deleteUserMutate, deleteUserStatus ];
};