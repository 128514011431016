
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { Box,  Typography } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import "@fontsource/rubik";
import useCustomTheme from "../../../theme/useCustomTheme";


const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const ResendLink = () => {

     const classes = useStyles();
     const [theme ] = useCustomTheme()
   


const title = 'Your registration is under process.'
const message = ' You will receive a verification email once your registration is processed. If you did not receive an acceptance email within 24 hours, please reach out to'



        
  return (
    <UnauthLayout both>
        <Box className={classes.centerBox} flexDirection='column'>

<Box px={4} pb={3}>
     <Box className={classes.centerBox} mb={7} >
     </Box>

     <Box className={classes.centerBox} flexDirection='column' mb={3}>
        <Typography 
                color="primary"
                style={{
                  fontWeight: 600,
                  color:'#08231B',
                  textAlign: 'center',
                  textTransform: 'none'
                }}
                sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'50px',xl:'80px'},mb:1, fontFamily:'Rubik'}}>{title? title :''}</Typography>

                <span style={{display:'inline'}}><Typography 
                color="primary"
                style={{
                  fontWeight: 300,
                  color:'#08231B',
                  textAlign: 'center',
                  textTransform: 'none'
                }}
                sx={{fontSize:{xs:'10px',sm:'13px',md:'15px',lg:'17px',xl:'30px'}, fontFamily:'Rubik'}} >{message? message :''} <Link to='mailto:support@lucihub.com'>support@lucihub.com</Link>.</Typography>
     
                </span>



     </Box>
 </Box>

</Box>
    </UnauthLayout>
  )
}

export default ResendLink
