import { useMutation } from "react-query";
import { createRevision, createRevisionLogs, disableRevisionTutorial, submitRevision } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import useSnackMessage from "../../../hooks/useSnackMessage";
import { useEffect, useState } from "react";
import { updateState } from "../../../redux/commonSlice";
import { useQuery } from "react-query";
import { updateReviewState } from "../../../redux/reviewSlice";

export default (requestType, afterSuccessCallback)=> {
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
  const [createRevisionStatus, setCreateRevisionStatus] = useState("");
  const [disableRevisionTutorialStatus, setDisableRevisionTutorialStatus] = useState("")
  const [submitRevisionStatus, setSubmitRevisionStatus] = useState("");
  const [createLogStatus, setCreateLogStatus] = useState("");
  const [checkNewVideoStatus, setCheckNewVideoStatus] = useState("");
  const [revisionLogsStatus, setRevisionLogsStatus] = useState("");
  const [enableRevisionLogs, setEnableRevisionLogs] = useState(false);
 // const [eventId, setEventId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar] = useSnackMessage();

 

  //POST API Request to create new Revision
  const { mutate: createRevisionMutate, isLoading: createRevisionLoading } =
    useMutation(createRevision, {
      onSuccess: (response, variables, context) =>
        createRevisionSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
        createRevisionErrorHandler(error, variables, context),
    });

  const createRevisionSuccessHandler =  (response, variables, context) => {
    const responseObject = response?.data;
    const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {
      disableRevisionTutorialMutate()
     // await afterSuccessCallback();
    // console.log("revisionId", responseData?.revision_id)
      //console.log("event created", commonReducer.events.find((e)=> e.id == responseData?.id));
     // dispatch(updateState({ event_details: {...commonReducer.event_details, revision: {id: responseData?.revision_id, submitted: false, final_video_id: reviewEventReducer.final_video_id }}}));
      dispatch(updateReviewState({request_changes: true}))
     // showSnackbar(responseObject);
      setCreateRevisionStatus("success");
    }
    // if (data?.data?.data) {
    //   dispatch(updateState({ production_event_id: data?.data?.data?.id }));
    //   // setEventId(data?.data?.data?.id)
    //   setSnackbarProps({
    //     type: "success",
    //     flag: true,
    //     message: "Congratulations! Event created successfully",
    //   });
    //   setcreateRevisionStatus("success");
    // }
  };

  const createRevisionErrorHandler = (error, variables, context) => {
    // setSubmitFlag(false);
    setCreateRevisionStatus("error");
    const errorObject = error?.response?.data;
   // showSnackbar(errorObject)
    // if (data) {
    //   if (data?.response?.status == 401) {
    //     window.localStorage.removeItem("token");
    //     dispatch(resetState());
    //   }
    //   setSnackbarProps({
    //     type: "error",
    //     flag: true,
    //     message: data?.response?.data?.error,
    //   });
      
      //  console.log("error", data)
   // }
  };

   //////////////////////POST API Request to create revision logs/////////////////////////////////////////////////////////////

   const { mutate: createLogMutate, isLoading: createLogLoading } = useMutation(
    createRevisionLogs,
    {
      onSuccess: (response, variables, context) =>
        createLogSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
        createLogErrorHandler(error, variables, context),
    }
  );

  const createLogSuccessHandler = (response, variables, context) => {
    const responseObject = response?.data;
    const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {
     // refetchRevisionLogs()
      setCreateLogStatus("success");
    //  showSnackbar(responseObject);
     // dispatch(updateState({ production_event_id: responseData?.id}));
    }
   
  };

  const createLogErrorHandler = (error, variables, context) => {
    const errorObject = error?.response?.data;
   // showSnackbar(errorObject)
   // dispatch(updateState({ add_event_open: false }));
      setCreateLogStatus("error");
      //  console.log("error", data)
    
  };


  //PUT API Request to submit a revision
  const { mutate: submitRevisionMutate, isLoading: submitRevisionLoading } =
    useMutation(submitRevision, {
      onSuccess: (response, variables, context) =>
        submitRevisionSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
        submitRevisionErrorHandler(error, variables, context),
    });

  const submitRevisionSuccessHandler = (response, variables, context) => {
    // console.log("event id", data?.data)
    // if (data?.data?.data) {
      // dispatch(updateState({ event_details: {...commonReducer.event_details, revision: {...commonReducer.event_details.revision, submitted: true}, revision_left: commonReducer.event_details.revision_left - 1}, under_revision: true }));
       dispatch(updateReviewState({revision_logs: []})) 
       dispatch(updateState({new_video: false}))
       //   // setEventId(data?.data?.data?.id)
    //   setSnackbarProps({
    //     type: "success",
    //     flag: true,
    //     message: "Congratulations! Event edited successfully",
    //   });
       setSubmitRevisionStatus("success");
    // }
  };

  const submitRevisionErrorHandler = (error, variables, context) => {
    // setSubmitFlag(false);
    // if (data) {
    //   if (data?.response?.status == 401) {
    //     window.localStorage.removeItem("token");
    //     dispatch(resetState());
    //   }
    //   setSnackbarProps({
    //     type: "error",
    //     flag: true,
    //     message: data?.response?.data?.error,
    //   });
    //   setsubmitRevisionStatus("error");
    //   //  console.log("error", data)
    // }
  };

  //PUT API to disable auto open revision tutorial poopup
  const { mutate: disableRevisionTutorialMutate, isLoading: disableRevisionTutorialLoading } =
    useMutation(disableRevisionTutorial, {
      onSuccess: (response, variables, context) =>
      disableRevisionTutorialSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
      disableRevisionTutorialErrorHandler(error, variables, context),
    });

  const disableRevisionTutorialSuccessHandler = (response, variables, context) => {
       dispatch(updateState({show_revision_tutorial: false}));
       setDisableRevisionTutorialStatus("success");
  };

  const disableRevisionTutorialErrorHandler = (error, variables, context) => {
    setDisableRevisionTutorialStatus("error")
  };


   //GET API for Revision Logs ***********************************************************

  //  const {
  //   data: revisionLogsData,
  //   isLoading: revisionLogsLoading,
  //   error: revisionLogsError,
  //   refetch: refetchRevisionLogs,
  // } = useQuery(
  //   [
  //     `revisionLog${commonReducer.event_details.revision?.id}`,
  //     commonReducer.event_details.revision?.id,
  //   ],
  //   () => getRevisionLogs({revision_id: commonReducer.event_details.revision?.id }),
  //   { enabled: enableRevisionLogs, retry: false }
  // );

  // useEffect(()=> {
  //   if(revisionLogsData) {
  //       setEnableRevisionLogs(false)
  //       setRevisionLogsStatus("success");
  //      // console.log(revisionLogsData?.data?.data?.revision_logs)
  //       dispatch(updateReviewState({revision_logs: revisionLogsData?.data?.data?.revision_logs }))
  //   }
  // }, [revisionLogsData])

  const revisionLogs = ()=> {
    if(commonReducer.event_details.revision.id) {
        
    }
  }

  useEffect(() => {
    if (createRevisionLoading) {
      setCreateRevisionStatus("loading");
    }
    if (submitRevisionLoading) {
      setSubmitRevisionStatus("loading");
    }
    if (createLogLoading) {
      setCreateLogStatus("loading");
    }
    // if(revisionLogsLoading) {
    //     setRevisionLogsStatus("loading");
    // }
    if(disableRevisionTutorialLoading) {
      setDisableRevisionTutorialStatus("loading")
    }
   

    // return ()=>{ setStatus("")}
  }, [createRevisionLoading, submitRevisionLoading, createLogLoading, disableRevisionTutorialLoading]);

  //Functions to request API as per incoming mutation type
  const revisionRequest = (data) => {
    switch (requestType) {
      case "create-revision":
        return createRevisionMutate(data);
      case "submit-revision" :   return submitRevisionMutate(data);
      case "create-log": return createLogMutate(data);
      case "logs": return (commonReducer.event_details.revision.id != null && setEnableRevisionLogs(true))
      case "disable-revision-tutorial": return disableRevisionTutorialMutate() ;
      // case "reset-password" : return resetPasswordMutate(data) ;
      default:
        return () =>
          console.log(
            "requested operation is not available. Available options: signin | signup | forgot-password | reset-password"
          );
    }
  };

  return [
    revisionRequest,
    requestType == "create-revision" ? createRevisionStatus :
      requestType == 'submit-revision' ? submitRevisionStatus : 
        requestType == 'create-log' ? createLogStatus : 
        //  requestType == 'logs' ? revisionLogsStatus : 
            requestType == 'disable-revision-tutorial' ? disableRevisionTutorialStatus : ""
    
  ];
}