import React, { useEffect, useRef } from "react";
import { useState } from "react";

import './DisplayArea.css';
// import Calendar from "../Tasks/Calendar/Calendar";
import Calendar from "./calendar/Calendar"; 
import Timer from "./timer/Timer";
import CheckIcon from "../icons/CheckIcon";
import { Box, IconButton, getContrastRatio } from "@mui/material";
import { generateLighterContrastColor } from "../../utils/commonServices";
import useCustomTheme from "../../theme/useCustomTheme";


const CustomDateTimePicker = (props) => {

  const { showTimer,numIntervals = 15, value, changeValue, hideLast = false, hideFuture = false, placeholder ,  onFocus, onBlur, required,  disabled, defaultValue, right, extendEditingDate, buttonClickHandler, buttonDisabled, medium} = props;

  const [circularTime, setCircularTime] = useState([])
  const [open, setOpen] = useState(false);
  const [finalValue, setFinalValue] = useState(defaultValue ? defaultValue.toLocaleString('en-US', {weekday: 'short', day: "2-digit", month: "2-digit", year: "numeric", hour: 'numeric', hour12: true, minute:'numeric'}) : "")
  const [finalDate, setFinalDate] = useState(new Date(Date.parse(value)))
  const [finalTime, setFinalTime] = useState( defaultValue
    ? defaultValue.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    : "")
  const displayRef = useRef(null);
  const inputRef = useRef(null);
  const [theme] = useCustomTheme()

  // console.log({defaultValue})
  // console.log({value})
  // console.log({finalValue})
  // console.log({finalTime})

  const inputHandler = (event) => {
    if (open){
      setOpen(false);
    } else {
      let dateString = `${finalDate.toDateString()}, ${finalTime}`
      setFinalValue(new Date(Date.parse(dateString)).toLocaleString('en-US', {weekday: 'short', day: "2-digit", month: "2-digit", year: "numeric", hour: 'numeric', hour12: true, minute:'numeric'}))
      setOpen(true)
    }
  }

  const handleIntervalChange = (value) => {
    const interval = parseInt(value);
    if (!isNaN(interval)) {
      const newTimes = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += numIntervals) {
          let tempHour = hour === 0 ? 12 : hour > 12 ? hour-12 : hour
          const time = `${tempHour.toString().padStart(1, '0')}:${minute.toString().padStart(2, '0')} ${hour < 12 ? 'AM' : 'PM'}`;
          newTimes.push(time);
        }
      }
      setCircularTime(newTimes);
    }
  };

  useEffect(() => {
    handleIntervalChange(numIntervals)
  }, [numIntervals]) 

  const handleClickOutside = (event) => {
    if ((displayRef.current && !displayRef.current.contains(event.target)) && 
      (inputRef.current && inputRef.current.contains(event.target))){
      setOpen(true);
    } else if ((displayRef.current && !displayRef.current.contains(event.target))) {
      extendEditingDate != true && setOpen(false);
    }
  }

  useEffect(()=>{
   
    if(extendEditingDate == true){
      setOpen(true)
    }
  },[])

  useEffect(() => {
   // console.log({finalValue})
    if(finalValue != "") {
      changeValue(new Date(finalValue))
    }
  }, [finalValue])

  
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  return (
    <>
      <div className="input" ref={inputRef}>
        <input
        id={medium? 'date-time-input' : 'date-time-input-drawer'}
          style={{paddingLeft:'10px', fontFamily:'Rubik', backgroundColor:'white', display: extendEditingDate ==true && 'none'}}
          onClick={inputHandler}
          placeholder={placeholder}
          value={finalValue}
          readOnly
          onBlur={onBlur}
          onFocus={onFocus}
          required={required}
          disabled={disabled} 
        />
      </div>
      { open && <div className={medium ? "display" : 'display-drawer'} style={{right: right && right, marginTop: extendEditingDate==true && '22px'}} ref={displayRef}>
        <Calendar finalDate={finalDate} setFinalDate={setFinalDate} hideLast={hideLast} hideFuture={hideFuture} setFinalValue={setFinalValue} finalTime={finalTime}/>
        { showTimer && <Timer extendEditingDate={extendEditingDate} handleClose={setOpen} circularTime={circularTime} value={value} numIntervals={numIntervals} finalTime={finalTime} setFinalTime={setFinalTime} finalDate={finalDate} setFinalValue={setFinalValue}/>}
        {extendEditingDate == true && <Box className='flex a-end'>
          <IconButton disabled={buttonDisabled}>
            <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{cursor: 'pointer', opacity: buttonDisabled && 0.5}} onClick={buttonClickHandler}/>
          </IconButton>
        </Box>}

      </div>}
    </>
  );
};

export default CustomDateTimePicker;