import { updateState } from "../../redux/commonSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { updateReviewState } from "../../redux/reviewSlice";
import { createRevision, createRevisionLogs, deleteRevisionLogApi, disableRevisionTutorial, getRevisionLogsApi, submitRevision } from "../../services/api";
import { showSnackBar } from "../../utils/commonServices";
import { getEventDetails } from "../events/actions";

///////////////////////////////// POST API REQ TO CREATE NEW REVISION REQUEST ///////////////////////////////

export const createNewRevision = (dispatch, navigate, state , payload) => {

    //const data = {event_id: payload.event_id, final_video_id: payload.final_video_id}
  
    createRevision(payload)
    .then((response) => {
        if (response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data
            if (responseObject?.http_code == 200) {
              disableRevisionTutorialRequest(dispatch, navigate, state);
              dispatch(updateReviewState({request_changes: true}))
             // setCreateRevisionStatus("success");
            }
        }
    })
    .catch((error) => { 
     if (error) {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject)
       // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
      }
    });
}


///////////////////////////////// POST API REQ TO CREATE REVISION LOGS ///////////////////////////////

export const createRevisionLog = (dispatch, navigate, state, payload, successCallback  ) => {

    // const data = {event_id: payload.event_id, final_video_id: payload.final_video_id}
  
    createRevisionLogs(payload)
    .then((response) => {
        if (response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data
            if (responseObject?.http_code == 200) {
              successCallback();  
              getRevisionLogs(dispatch, navigate, state, payload)
            }
        }
    })
    .catch((error) => { 
     if (error) {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject)
      }
    });
}


///////////////////////////////// POST API REQ TO SUBMIT REVISION ///////////////////////////////

export const submitRevisionRequest = (dispatch, navigate, state, payload, successCallback ) => {
  
    submitRevision(payload)
    .then((response) => {
        if (response) {
            getEventDetails({id: state.commonReducer.production_event_id}, dispatch)
            successCallback()
            dispatch(updateReviewState({revision_logs: []})) 
            dispatch(updateState({new_video: false}))
           // setSubmitRevisionStatus("success");
        }
    })
    .catch((error) => { 
     if (error) {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject)
      }
    });
}


/////////////////////////////////PUT API to disable auto open revision tutorial poopup///////////////////////////////

export const disableRevisionTutorialRequest = (dispatch, navigate, state ) => {
  
    disableRevisionTutorial()
    .then((response) => {
        if (response) {
            dispatch(updateState({show_revision_tutorial: false}));
        }
    })
    .catch((error) => { 
     if (error) {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject)
      }
    });
}


///////////////////////////////// API TO GET REVISION LOGS ///////////////////////////////

export const getRevisionLogs = (dispatch, navigate, state, payload ) => {
    dispatch(updateReviewState({is_loading: true}))
    const data = {revision_id: state.commonReducer.event_details.revision?.id }
  
    getRevisionLogsApi(data)
    .then((response) => {
        dispatch(updateReviewState({is_loading: false}))
        if (response) {
            dispatch(updateReviewState({revision_logs: response?.data?.data?.revision_logs }))
        }
    })
    .catch((error) => { 
        dispatch(updateReviewState({is_loading: false}))
     if (error) {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject)
      }
    });
}


export const deleteLog = (values ,dispatch, callback) => {
  const payload = values
  deleteRevisionLogApi(payload) 
  .then((response) => {
    if(response?.data?.http_code === 200){
       callback && callback()
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    }
  });
  }