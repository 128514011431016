import { AppBar, Box, Toolbar } from '@mui/material'
import React from 'react'
import CustomUserCard from '../../events/components/CustomUserCard'
import luciLogo from '../../../assets/luciLogo2.png'
import poweredBy from '../../../assets/poweredBy.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import butterfly from './../../../assets/butterfly_navigation.png'
import { goToCopilot } from '../../../utils/commonServices'

const Header = () => {

    const navigate = useNavigate()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const themeReducer = useSelector((state) => state.root.themeReducer);
    // const brandLogo = window.localStorage.getItem('brand_logo_url')
    const brandLogo = themeReducer?.brand_logo

  return (
    <AppBar position='sticky' sx={{backgroundColor:'transparent',}} elevation={0} >
        <Toolbar  sx={{  display:'flex', justifyContent:{xs:'center',sm:'space-between'}, alignItems:'center', flexDirection:{xs:'column', sm:'row'}, mt:{xs:2, sm:0}}}>
        <Box sx={{ width:{xs:'100%',sm:"10%"}, display:'flex',justifyContent:{xs:'center', sm:'start'}, mb:{xs:2,sm:0}}}>
                <Box sx={{ cursor:'pointer'}} onClick={()=>navigate('/dashboard', {replace:true})}>
                    <img src={brandLogo !== null ? brandLogo : luciLogo}  style={{cursor:'pointer', maxWidth:'70px', maxHeight:'35px'}}/>
                    {/* {
                        brandLogo !== null && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
                    } */}
                </Box>
        </Box>
        <Box className='flex j-end a-center' sx={{ width:'100%', gap:1, justifyContent:{xs:'space-between', sm:'end'}}}>
            {commonReducer?.copilot_access && <Box sx={{width:{xs:'120px',sm:'150px',md:'170px',lg:'190px', xl:'230px'}, display:'flex', alignItems:'center'}}><img onClick={goToCopilot} src={butterfly} style={{ borderRadius: '5px', width: '100%', cursor: 'pointer', marginRight:'10px'}} /></Box>}
            <CustomUserCard/>
        </Box>
        </Toolbar>
    </AppBar>
  )
}

export default Header