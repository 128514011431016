import { useEffect, useState } from "react";
import Bottleneck from "bottleneck";
import { useDispatch, useSelector } from "react-redux";
import { base64ToBlob, isAcceptedFormat, showSnackBar } from "../../../utils/commonServices";
// import { uploadVideo } from "../services/api";
// import useUpload from "./useUpload";
import { setSnackbar } from "../../../redux/messageSlice";
import {  uploadEventSongApi } from "../../../services/api";

export default (eventId, isGuestUser, uploadedSongs, refetch) => {

  const [progress, setProgress] = useState([]);
  const [requestController, setRequestController] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([{ type: "", message: "" }]);
  const [imgSrc, setImgSrc] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false)
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [enableFileUpload, setEnableFileUpload] = useState(true)
  const dispatch = useDispatch();
  const maxSizeMb = 500;
  const refresh = async (index) => {
    if (index == files?.length - 1 && !isGuestUser) {
      setTimeout(() => {
         refetch();
         setFiles([])
      }, 3000);
    }
  };

  const stopUpload = (index) => {
    requestController[index].abort();
    setUploadStatus((last) => {
      var temp = last?.map((num, i) => {
        if (i == index) {
          return { type: "error", message: "Upload Cancelled!" };
        } else {
          return num;
        }
      });
      return temp;
    });
  };

  const stopList = (callback) => {
    setUploadStatus((last) => {
      var temp = last?.map((num, i) => {
        if(num.type == "standard" ) {
          requestController[i].abort();
          callback()
          return { type: "error", message: "Upload Cancelled!" };
        } else {
          return num;
        }
        
      });
      return temp;
    });

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  useEffect(() => {
    const limiter = new Bottleneck({
      maxConcurrent: 1,
      minTime: 200,
    });
    // if(files?.length > 0 && imgSrc?.length > 0 && files?.length == imgSrc?.length && progress?.length == files?.length) {
    if (
      files?.length > 0 &&
      progress?.length == files?.length &&
      uploadStatus?.length == files?.length
    ) {
      const uploadRequest = () => {
        var tempControllers = [];
        for (let index = 0; index < files?.length; index++) {
          
          if (files[index].size / (1024 * 1024 ) > maxSizeMb) {
            setIsloading(false);
            setUploadStatus((last) => {
              var temp = last?.map((status, i) => {
                if (i == index) {
                  return {
                    type: "error",
                    message: `Max allowed size: ${maxSizeMb} Mb `,
                  };
                } else {
                  return status;
                }
              });
              return temp;
            });

             //refresh(index)
          } else if (
            uploadedSongs != null &&
            uploadedSongs.filter((item) => (item.name == files[index].name ) )?.length > 0
          ) {
            setIsloading(false);
            setUploadStatus((last) => {
              var temp = last?.map((status, i) => {
                if (i == index) {
                  return { type: "error", message: "File already exists" };
                } else {
                  return status;
                }
              });
              return temp;
            });

            // refresh(index)
          } else {
            //  uploadVideoService({"id": eventId, "videos": files[index], "thumbs": thumb}, index)

            limiter
              .schedule(() =>
                uploadVideoService(
                  { id: eventId, song: files[index] },
                  index,
                  requestController[index]
                )
              )
              .then((result) => {
                setIsloading(false);
                setUploadStatus((last) => {
                  var temp = last?.map((num, i) => {
                    if (i == index) {
                      return {
                        type: "success",
                        message: "Uploaded Successfully!",
                      };
                    } else {
                      return num;
                    }
                  });
                  return temp;
                });

                 refresh(index)

              
              })
              .catch((err) => {
                
                setIsloading(false);
                setUploadStatus((last) => {
                  var temp = last?.map((num, i) => {
                    if (i == index) {
                      if (
                        err?.response?.data?.error == "File already present"
                      ) {
                        return {
                          type: "error",
                          message: err?.response?.data?.error,
                        };
                      } else if (err?.message == "canceled") {
                        return { type: "error", message: "Upload Cancelled!" };
                      } else {
                        //if(err?.response?.data?.http_code === 403) {
                          dispatch(setSnackbar({type:'error', flag:true, message: err?.response?.data?.message}))
                       // }
                        return { type: "error", message: "Upload Failed!" };
                      }
                    } else {
                      return num;
                    }
                  });
                  return temp;
                });
                 refresh(index)
              });
          }
        }
      };
      uploadRequest();
    }

    return () => {
      limiter.stop().then(() => {
        //console.log("Shutdown completed!");
      });
    };
  }, [files]);

  const uploadVideoService = (fData, index, controller) => {
    setIsloading(true);
    setIsDisabled(true)
    console.log({ fData, index });
    return new Promise((resolve, reject) => {
      uploadEventSongApi({
        data: fData,
        progress: (number) => {
          setProgress((last) => {
            var temp = last?.map((num, i) => {
              if (i == index) {
                return number;
              } else {
                return num;
              }
            });
            return temp;
          });

          setUploadStatus((last) => {
            var temp = last?.map((num, i) => {
              if (i == index) {
                if (number > 99) {
                  return { type: "standard", message: "Finalizing..." };
                } else {
                  return { type: "standard", message: "Uploading..." };
                }
              } else {
                return num;
              }
            });
            return temp;
          });
        },
        header: { "content-type": "multipart/form-data" },
        controller: controller,
      })
        .then((r) => {
          resolve(r.data)
          // setTimeout(() => {
          //   setIsDisabled(false)
          // }, 4000);
        })
        .catch((error) =>{
           reject(error)
          //  setTimeout(() => {
          //   setIsDisabled(false)
          // }, 4000);
          //  if(error) {
          //   const errorObject = error?.response?.data;
          //   showSnackBar(dispatch, errorObject);
          // }
          });
    });
  };



  return {
    files,
    setFiles,
    uploadStatus,
    setUploadStatus,
    progress,
    setProgress,
    imgSrc,
    setImgSrc,
    isLoading,
    requestController,
    setRequestController,
    stopUpload,
    stopList,   enableFileUpload, setEnableFileUpload, isDisabled, setIsDisabled
  };
};