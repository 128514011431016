import { useEffect, useState } from "react";
import { consentDetail } from "../../../services/api";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { updateState } from "../../../redux/commonSlice";

export default ()=> {
    const dispatch = useDispatch();
    const [payload, setPayload] = useState()
    const [enableConsentDetail, setEnableConsentDetail] = useState(false)
    ////////////////////////////query for consent detail//////////////////////////
  const {
    data: consentDetailData,
    isLoading: consentDetailLoading,
    error: consentDetailError,
    refetch: refetchConsentDetail,
  } = useQuery(
    [`consentDetail${payload}`, payload],
    () => consentDetail(payload),
    { enabled: enableConsentDetail , retry: false }
  );

//   const checkConsentFlag = (events)=>{
//     const filteredEvents = events?.filter(event => event.consent_accepted == false);
//     return filteredEvents.length > 0;
//   }

const getConsentDetails = (eventId) => {
    setPayload({event_id: eventId});
    setEnableConsentDetail(true)
}

  useEffect(()=> {
    if(consentDetailData) {
      setEnableConsentDetail(false)  
      const consentDetails = consentDetailData?.data?.data
      dispatch(updateState({consent_detail: consentDetails?.description, consent_category: consentDetails?.category, consent_id: consentDetails?.consent_id}))
    }
  }, [consentDetailData])
return [getConsentDetails]

}