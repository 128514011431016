import React from "react";
import { Box, SvgIcon } from "@mui/material";
import useCustomTheme from "../../../../theme/useCustomTheme";

const CelebrationImage = (props)=> {
    const [theme] = useCustomTheme()
    return <SvgIcon 
            {...props} 
            id="Group_156" data-name="Group 156" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.571 205.257">
     <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_103" data-name="Rectangle 103" width="251.571" height="205.257" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_155" data-name="Group 155" clip-path="url(#clip-path)">
    <path id="Path_66" data-name="Path 66" d="M349.757,276.781q-61.5-21.3-123-42.616c-4.809-1.667-5.545-4.171-2.355-8q29.038-34.846,58.094-69.678c3.382-4.055,5.847-3.832,8.434.74q27.564,48.723,55.12,97.451c3.12,5.516,6.29,11,9.355,16.551,1.6,2.892.16,5.859-3.289,6.172-.51-.132-1.457-.31-2.359-.622M286.465,165.165l-52.651,63.168,109.06,37.809.428-.491L286.465,165.165" transform="translate(-104.484 -72.146)" fill="#08231b"/>
    <path id="Path_67" data-name="Path 67" d="M150.276,93.259c2.765,28.383,21.471,48.34,50.731,53.794,1.986.37,4,.642,6.008.84a3.876,3.876,0,0,0,4.56-3.346c.318-2.319-1.164-4.122-3.8-4.421a63.125,63.125,0,0,1-26.783-9.041c-15.867-9.936-22.34-24.934-22.822-43.04-.082-3.072.158-6.155.3-9.231.125-2.636-1.277-4.5-3.579-4.628-2.4-.137-4.083,1.333-4.208,4.045-.23,5.006-.283,10.019-.41,15.03" transform="translate(-70.578 -34.837)" fill="#08231b"/>
    <path id="Path_68" data-name="Path 68" d="M148.734,31.2A15.637,15.637,0,1,1,145.772.068a15.5,15.5,0,0,1,16.956,14.076A15.581,15.581,0,0,1,148.734,31.2m-.719-7.887A7.764,7.764,0,0,0,155,14.9a8.065,8.065,0,0,0-8.614-7.183,7.842,7.842,0,1,0,1.628,15.6" transform="translate(-61.758 0)" fill="#08231b"/>
    <path id="Path_69" data-name="Path 69" d="M177.859,245.579a31.626,31.626,0,0,0-1.771-3.011c-13.028-16.86-30.035-24.021-51.207-20.84a67.691,67.691,0,0,0-8.475,1.952c-2.612.746-3.647,2.711-2.926,5,.667,2.119,2.514,3.088,5.1,2.376,7.2-1.981,14.49-3.03,21.934-1.919A42.677,42.677,0,0,1,169.5,246.714c.47.635.841,1.647,1.447,1.818,1.479.418,3.295.991,4.547.47,1.114-.463,1.654-2.31,2.367-3.423" transform="translate(-53.192 -103.791)" fill="#08231b"/>
    <path id="Path_70" data-name="Path 70" d="M270.583,85.183c1.176,12.324,8.3,21.337,20.173,26.85,2.5,1.162,4.646.452,5.649-1.621,1.054-2.179.13-4.223-2.487-5.5C280.147,98.193,274.837,85.13,280,70.671c.237-.663.529-1.306.759-1.971.836-2.41.073-4.415-2-5.3s-4.132-.05-5.176,2.279a38.486,38.486,0,0,0-3,19.5" transform="translate(-127.002 -29.617)" fill="#08231b"/>
    <path id="Path_71" data-name="Path 71" d="M314.57,81.8l-7.44.676c.229,2.52.453,4.986.69,7.6l7.44-.676-.691-7.6" transform="translate(-144.246 -38.417)" fill={theme.palette.primary.main}/>
    <path id="Path_72" data-name="Path 72" d="M116.994,276.668a3.935,3.935,0,1,0,3.586-4.129,3.918,3.918,0,0,0-3.586,4.129" transform="translate(-54.943 -127.993)" fill={theme.palette.secondary.main}/>
    <path id="Path_73" data-name="Path 73" d="M146.253,23.149A7.864,7.864,0,0,0,161.912,21.7,8.065,8.065,0,0,0,153.3,14.52a7.821,7.821,0,0,0-7.045,8.629" transform="translate(-68.669 -6.806)" fill={theme.palette.primary.main}/>
    <path id="Path_74" data-name="Path 74" d="M17.237,125.834A15.637,15.637,0,1,1,14.275,94.7a15.637,15.637,0,0,1,2.961,31.132m-.719-7.887a7.764,7.764,0,0,0,6.986-8.415,8.065,8.065,0,0,0-8.614-7.183,7.842,7.842,0,1,0,1.628,15.6" transform="translate(0 -44.446)" fill="#08231b"/>
    <path id="Path_75" data-name="Path 75" d="M14.756,117.783a7.864,7.864,0,0,0,15.659-1.446,8.065,8.065,0,0,0-8.614-7.183,7.821,7.821,0,0,0-7.045,8.629" transform="translate(-6.911 -51.252)" fill={theme.palette.secondary.main}/>
    <path id="Path_76" data-name="Path 76" d="M87.523,198.275c.229,2.52.458,5.039.7,7.758l7.826-.711q-.352-3.877-.7-7.747l.077.068-7.98.726Z" transform="translate(-41.07 -92.793)" fill="#08231b"/>
    <path id="Path_77" data-name="Path 77" d="M101.727,190.334l7.734-.7c-.2-2.213-.307-4.383-.668-6.509-.072-.423.458-.964-.138-.965-2.019,0-5.612.211-7.632.342l.062-.086q.36,3.994.719,7.989Z" transform="translate(-47.447 -85.552)"/>
    <path id="Path_78" data-name="Path 78" d="M79.418,184.079l-7.68.7c.254,2.8.47,5.174.711,7.828l7.7-.7-.076.094-.725-7.986Z" transform="translate(-33.692 -86.424)" fill="#08231b"/>
    <path id="Path_79" data-name="Path 79" d="M93.477,176.251c-.219-2.409-.438-4.818-.671-7.4l-7.851.713c.221,2.431.446,4.912.671,7.393l-.074-.064,7.987-.729Z" transform="translate(-39.899 -79.303)" fill="#08231b"/>
    <path id="Path_80" data-name="Path 80" d="M94.066,182.64l-7.987.729q.363,3.993.726,7.986l7.98-.727q-.359-3.994-.718-7.989" transform="translate(-40.428 -85.779)" fill={theme.palette.primary.main}/>
    <rect id="Rectangle_101" data-name="Rectangle 101" width="8.182" height="8.182" transform="matrix(0.996, -0.092, 0.092, 0.996, 53.495, 96.736)" fill="#08231b"/>
    <path id="Path_81" data-name="Path 81" d="M213.735,116.844c.229,2.52.458,5.039.7,7.758l7.826-.711q-.352-3.877-.7-7.747l.077.068-7.98.726Z" transform="translate(-100.347 -54.548)" fill="#08231b"/>
    <path id="Path_82" data-name="Path 82" d="M227.939,108.9l7.735-.7c-.2-2.213-.307-4.383-.668-6.509-.072-.423.458-.964-.138-.965-2.019,0-5.612.211-7.632.342l.062-.086q.36,3.994.719,7.989Z" transform="translate(-106.723 -47.307)"/>
    <path id="Path_83" data-name="Path 83" d="M205.63,102.648l-7.68.7c.254,2.8.47,5.174.711,7.828l7.7-.7-.076.094-.725-7.986Z" transform="translate(-92.969 -48.18)" fill="#08231b"/>
    <path id="Path_84" data-name="Path 84" d="M219.689,94.82c-.218-2.409-.438-4.818-.671-7.4l-7.851.713c.221,2.431.446,4.912.671,7.393l-.074-.064,7.987-.729Z" transform="translate(-99.176 -41.058)" fill="#08231b"/>
    <path id="Path_85" data-name="Path 85" d="M220.278,101.209l-7.987.729q.363,3.993.726,7.986L221,109.2q-.359-3.994-.718-7.989" transform="translate(-99.704 -47.534)" fill={theme.palette.secondary.main}/>
    <rect id="Rectangle_102" data-name="Rectangle 102" width="8.182" height="8.182" transform="translate(120.43 53.55) rotate(-5.27)" fill="#08231b"/>
  </g>

  </SvgIcon>
    
  
}
export default CelebrationImage