import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateCreationState } from '../redux/eventCreationSlice';
import { Box, ClickAwayListener, Dialog, Popover, Popper, Typography } from '@mui/material';
import { useState } from 'react';
import DateTimePicker from './date-time/DateTimePicker';
import { Button } from '@mui/base';
import { getErrorMsg } from '../utils/validator';
import { useEffect } from 'react';


const DateTimePickerPopup = (props) => {

    const {anchorEl, setAnchorEl , extendEditingDate,newEditDatePageData, setNewEditDatePageData } = props

  
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
  
    const [editorStartDateValidationFlag, setEditorStartDateValidationFlag] = useState(false)
    const [dateTime, setDateTime] = useState(new Date(parseInt(commonReducer?.event_details?.editor_start_date*1000)))

    const dispatch = useDispatch()
    

    const handleClose = () => {
        dispatch(updateCreationState({extend_edit_date_popup: true}))
        setAnchorEl(null);
      };

      useEffect(()=>{
        setDateTime(new Date(parseInt(commonReducer?.event_details?.editor_start_date*1000)))
      },[commonReducer?.event_details?.editor_start_date])

   


  return (
    <Popper   
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left',
        // }}
        transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        }}
 
      >
        <ClickAwayListener onClickAway={()=>setAnchorEl(null)}>
        <Box className='flex col' >
            <DateTimePicker 
                  buttonClickHandler={handleClose}
                  extendEditingDate={extendEditingDate}
                  placeholder='When do you want editing to begin?'
                  // onFocus={() => {
                  //   setNameValidationFlag(true)
                  //   setEventTypeValidationFlag(true)
                  //   setVideoLengthValidationFlag(true)
                  //     setStartDateValidationFlag(true)
                  //     setEndDateValidationFlag(true);
                  //     setEditorStartDateValidationFlag(false);
                  //   }}
                    // onBlur={() => setEditorStartDateValidationFlag(true)}
                    required
                    errorFlag={
                      editorStartDateValidationFlag &&
                      getErrorMsg(
                      //"new_editor_start_date_time",
                      'editor_start_date_time',
                      newEditDatePageData?.editor_start_date,
                      //commonReducer?.event_details?.editor_start_date,
                      commonReducer?.event_details?.start_date,
                      ) != ""
                    }
                    errorMsg={getErrorMsg(
                     // "new_editor_start_date_time",
                     'editor_start_date_time',
                      newEditDatePageData?.editor_start_date,
                      //commonReducer?.event_details?.editor_start_date,
                      commonReducer?.event_details?.start_date,
                    )}
                    buttonDisabled={ editorStartDateValidationFlag &&
                      getErrorMsg(
                      "editor_start_date_time",
                      newEditDatePageData?.editor_start_date,
                      //commonReducer?.event_details?.editor_start_date,
                      commonReducer?.event_details?.start_date,
                      ) != ""}
                    
                    disablePast
                    label="End date & time"
                    defaultValue={
                      commonReducer?.event_details?.editor_start_date != ""
                      ? new Date(commonReducer?.event_details?.editor_start_date * 1000)
                      : null
                     }
                    onDateChange={(date) => {
                            setNewEditDatePageData((last) => {
                            return {
                              ...last,
                              editor_start_date: date
                             };
                           });
                           setEditorStartDateValidationFlag(true)
                          }}
                          dateTime={dateTime}
                          setDateTime={setDateTime}
               />        
        </Box>
        </ClickAwayListener>  
    </Popper>
  )
}

export default DateTimePickerPopup