// import React, { useEffect, useState } from 'react'
// import useAppleSignup from './hooks/useAppleSignup';

// const SignInWithApple = () => {
//     const [appleResponse, setAppleResponse] = useState();
//     const [signApple, signAppleStatus] = useAppleSignup();

//     // const afterAppleAuth = async (payload)=> {
//     //   await signApple(payload)
//     // }

//     useEffect(()=>{
//         window.AppleID.auth.init({
//             //config object
//             // clientId : process.env.CLIENT_ID,
//             clientId : 'com.lucihub.app',
//             scope : 'email name', 
//             redirectURI : "https://lucimoments.com/dashboard",
//             state : '',
//             nonce : '',
//             usePopup : true
//         })
//     },[])

//     // useEffect(()=> {
//     //   if(appleResponse) {
//     //     console.log("response apple")
//     //     afterAppleAuth(appleResponse)
//     //   }

//     // },[appleResponse])


//     const handleSignInWithApple = async ()=>{
//         try {
//             const response = await window.AppleID.auth.signIn();
//             console.log({response})
//             const data = await response.json();
//             console.log("response data", data)
//             await signApple({apple_token: data?.authorization?.id_token})
//             //setAppleResponse(data);
//            // console.log(data);
//             // Handle successful response.

//         // on successful auth dtaa object received
//         //     {
//         //         "authorization": {
//         //           "code": "[CODE]",
//         //           "id_token": "[ID_TOKEN]",
//         //           "state": "[STATE]"
//         //         },
//         //         "user": {
//         //           "email": "[EMAIL]",
//         //           "name": {
//         //             "firstName": "[FIRST_NAME]",
//         //             "lastName": "[LAST_NAME]"
//         //           }
//         //         }
//         //    }

//         // if no data.user.email ask user to enter email , organization name. 
//         // if data.user.email exists. ask user to enter organization name.
//        } catch ( error ) {
//         console.log(error)
//             // Handle error.
//             // data object received when error
//             // {
//             //     "error": "[ERROR_CODE]"
//             // }
//        }
//     }

//     // console.log(window.AppleID)

//   return (
//     <><button onClick={handleSignInWithApple}>Continue with Apple</button></>
//   )
// }

// export default SignInWithApple



import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../../components/CustomButton';
import AppleIcon from '@mui/icons-material/Apple';
import { useQuery } from 'react-query';
import { verifyAppleUser } from '../../../../services/api';
import { updateAppleSignState } from '../../../../redux/appleSignSlice';
import { gaEvents } from '../../../../ga/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateState } from '../../../../redux/commonSlice';
import movieIcon from '../../../../assets/movieIcon.svg';
import { getPermissions } from '../../../../commonactions/actions';
import { getTheme } from '../../../whitelabel/actions';
import { setAssetState } from '../../../../redux/assetSlice';
import { PRODUCT_ID } from '../../../../constants/constants';
import { showSnackBar } from '../../../../utils/commonServices';

// const useStyles = makeStyles((theme) => ({
//   appleButton: {
//     minHeight: '18px'
//   },
// }));



const SignInWithApple = () => {
  const popupRef = useRef(null);
  const [verifyAppleUserEnable, setVerifyAppleUserEnable] = useState(false)
  const [appleResponse, setAppleResponse] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: verifyAppleUserData,
    error: verifyAppleUserError,
    isLoading: verifyAppleUserLoading,
  } = useQuery(
    [`verifyAppleUser${appleResponse?.apple_token}`, appleResponse?.apple_token],
    () => verifyAppleUser(appleResponse),
    { enabled: verifyAppleUserEnable, retry: false }
  );

  useEffect(()=> {
    if(verifyAppleUserData) {
      setVerifyAppleUserEnable(false)
      if(verifyAppleUserData?.data?.data?.token && verifyAppleUserData?.data?.data?.user_id) {
        gaEvents.loginEvent()
        window.localStorage.setItem("token", verifyAppleUserData?.data?.data?.token);
        dispatch(
          updateState({
            is_logged: true,
            user_name: verifyAppleUserData?.data?.data?.name,
            user_id: verifyAppleUserData?.data?.data?.user_id,
            company_name:verifyAppleUserData?.data?.data?.company_name,
            show_revision_tutorial: verifyAppleUserData?.data?.data?.show_revision_tutorial,
            is_company_name_available: verifyAppleUserData?.data?.data?.company_name  ? true : false,
          }));
          dispatch(setAssetState({
            is_brand_name_available: verifyAppleUserData?.data?.data?.company_name  ? true : false,
          }))
       // setAppleSignStatus("success");
        getPermissions(dispatch)
        getTheme(dispatch)
        navigate("/", {replace: true});
      } else {
        dispatch(updateAppleSignState({apple_response: verifyAppleUserData?.data?.data}))
        navigate("/applesignin", {replace: true});
      }
     // console.log({verifyAppleUserData})
    }


  }, [verifyAppleUserData])

  useEffect(()=> {

    if(verifyAppleUserError) {
      //console.log("error", verifyAppleUserError)
      const errorObject = verifyAppleUserError?.response?.data;
      showSnackBar(dispatch, errorObject);
      setVerifyAppleUserEnable(false)
      // if(verifyAppleUserError?.response?.data?.http_code === 401 && verifyAppleUserError?.response?.data?.message === "Email is not verified!") {
      //   navigate('/resendlink', {state: {pageData: {email: ""}, title: 'Your email verification is pending', icon: movieIcon, btnText: 'Resend Email', message:'Please check your email and click on the verify now button to continue.', signIn: false}, replace:true})
      // }
    }

  }, [verifyAppleUserError])

  const handleAppleSignIn = async (event) => {
    // Handle the Apple Sign-In response

    //console.log("appleData", event.detail);
    setAppleResponse({apple_token: event.detail.authorization.id_token, product_id: PRODUCT_ID.lucihub})
    dispatch(updateAppleSignState({apple_token: event.detail.authorization.id_token}))
    setVerifyAppleUserEnable(true)
    // Make the API request here using the response
    // Example:
    // fetch('your-api-url', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(response),
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     // Handle the API response
    //     console.log(data);
    //   })
    //   .catch(error => {
    //     // Handle the API request error
    //     console.log(error);
    //   });
  
    // Dispatch a custom event to trigger the handling of the response
    // const event = new CustomEvent('appleSignInCompleted', { detail: response });
    // window.dispatchEvent(event);
  };

  useEffect(() => {
    //console.log("host", window.location.origin)
    window.AppleID.auth.init({
      clientId : 'com.lucihub.app',
      scope : 'email name', 
      redirectURI : `${window.location.origin}/dashboard`,
      state : '',
      nonce : '',
      usePopup : true
    });

   
    const handleAppleSignInFailure = ()=> {
     // console.log("apple sign in failure")
    }

    document.addEventListener('AppleIDSignInOnSuccess', handleAppleSignIn);
  document.addEventListener('AppleIDSignInOnFailure', (event) => {
    // Handle error.
    //console.log("error", event.detail.error);
});

    return () => {
      window.removeEventListener('AppleIDSignInOnSuccess', ()=>{});
      window.removeEventListener('AppleIDSignInOnFailure', ()=>{});
    };
  }, []);

  return (
    <>
      {/* <div id="appleid-signin" style={{height: 20, display: 'inline'}} /> */}
      <CustomButton 
          startIcon = {<AppleIcon style={{fontSize: '13px', marginBottom: '3px'}} />} 
          variant= 'outlined' 
          btnText="Sign In with Apple" 
          //className = 'signin-button'
          ref={popupRef} 
          id= "appleid-signin"
          btnStyle={{textTransform:'none', border: 'none'}} 
          sx={{
                minWidth: { xs: '135px', md: '150px', xl:'200px'},
                // display: 'flex',
                // alignItems: 'bottom',
                // justifyContent: 'center',
                mr: 1,
                fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, 
                fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, 
                //height: '12px !important',
                width:{xs:'65px', lg:'65px',xl:'88px'}, 
                fontFamily:'Rubik'
              }}
      />
    </>
  );
};

export default SignInWithApple;