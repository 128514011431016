import { makeStyles } from "@mui/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Dialog, DialogContent, IconButton, Box, Typography, DialogActions } from "@mui/material";
import { useState } from "react";
import { customTheme as theme } from "../theme/customTheme";
import '../App.css'
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../redux/commonSlice";
import "@fontsource/rubik";
import { isIos } from "../utils/commonServices";

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        position: 'relative',
        paddingBottom: '56.25%', /* 16:9 */
        width: '100%',
        height: 0
      },
    iframe: {
        width: '100%',
  aspectRatio: '16 / 9',
 // boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
  borderRadius: '10px',
      }
}));

export default function PopupVideoPlayer(props) {
  const classes = useStyles();
  const { maxWidth, width, height, url} = props;
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(updateState({play_tutorial: false}))
  }
  return (
    <Dialog
      open={commonReducer.play_tutorial}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    //   sx={{
    //     '& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop' : {
    //         backdropFilter: 'blur(3px)'
    //     }
    //   }}
    slotProps={{
        backdrop: {
            sx: {
                backdropFilter: 'blur(2px)'
            }
        }
        
    }}
      PaperProps={{ 
        sx: { borderRadius: '5px', 
                width: width ?width :{xs: '480px', sm: '640px', md: '720px', lg:'960px', xl:'1600px'}, 
                maxWidth : '1920px !important',
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }}}
      {...props}
    >
      <DialogContent
        sx={{ padding:'0px', backgroundColor: 'transparent'}}
        id="alert-dialog-description"
      >
        <Box className= 'flex col'  >
            <Box className='flex row j-end' sx={{backgroundColor: 'transparent'}} >    
                <Typography onClick={handleClose} 
                    sx={{fontFamily: 'Rubik', textAlign: 'right', 
                         fontSize:{xs:'8px',md:'11px', xl:'14px'}, 
                         color: '#000000',
                         width: '60px', fontWeight:700, marginTop:{xs:'10px',sm:'15px',md:'20px',lg:'20px',xl:'44px'}, 
                         marginBottom:{xs:'10px',md:'14px',lg:'12px',xl:'24px'}, 
                         cursor:'pointer',
                         backgroundColor: 'transparent'
                        }}>Close X</Typography>
            </Box>
            {
               // isIos() ? <iframe className={classes.iframe} src="https://player.vimeo.com/video/800564657?h=c5020b4310&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;loop=1&amp;muted=1" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Filming with your phone"></iframe>
               // : <iframe className={classes.iframe} src="https://player.vimeo.com/video/800564657?h=c5020b4310&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;loop=1" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Filming with your phone"></iframe>
               isIos() ? <iframe className={classes.iframe} src="https://www.youtube.com/embed/4sqVI3ggthk?rel=0&mute=1&autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" width="100%" height="100%" allowfullscreen title="Lucihub Onboarding"></iframe>
                : <iframe className={classes.iframe} src="https://www.youtube.com/embed/4sqVI3ggthk?rel=0&autoplay=1" title="Lucihub Onboarding" frameborder="0" allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"width="100%" height="100%" allowfullscreen></iframe>
            }
            
        </Box>
      </DialogContent>
    </Dialog>
  );
}