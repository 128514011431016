import { Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomCard from "../../components/CustomCard";
import AuthLayout from "../layouts/AuthLayout";
import EventCard from "./components/EventCard";
import useEventList from "./hooks/useEventList";
import luciLogo from "../../assets/luciLogo2.png";
import SearchIcon from "@mui/icons-material/Search";
import useArraySearch from "./hooks/useArraySearch";
import useStatusType from "../../hooks/dropdowns/useStatusType";
import { setDropDownArray } from "../../redux/dropdownSlice";
import AddIcon from "./icons/AddIcon";
import { updateState } from "../../redux/commonSlice";
import {generateLighterContrastColor,  timeStampToString } from "../../utils/commonServices";
import { useLocation, useNavigate } from "react-router-dom";
import EventCreationDrawer from "../eventcreation";
import CustomUserCard from "../events/components/CustomUserCard";
import "@fontsource/rubik";
import CustomSortOptions from "../events/components/CustomSortOptions";
import filmingTutorialImage from "../../assets/filmingTutorialCard.png"
import { eventSortOptions, sortByOptions } from "../../constants/staticData";
import useEventSort from "./hooks/useEventSort";
import useEventTypeList from "../eventcreation/hooks/useEventTypeList";
import EventFilter from "./components/EventFilter";
import CustomPopoverFilter from "./components/CustomPopoverFilter";
import PopupVideoPlayer from "../../components/PopupVideoPlayer";
import DeleteConfirmationPopup from "./components/DeleteConfirmationPopup";
import useDeleteProject from "./hooks/useDeleteProject";
import DeleteDeclinePopup from "./components/DeleteDeclinePopup";
import useFilter from "./hooks/useFilter";
import { updateReviewState } from "../../redux/reviewSlice";
import { updateCreationState } from "../../redux/eventCreationSlice";
import DeclineEditProjectPopup from "./components/DeclineEditProjectPopup";
import { setAllMediaFilesArray } from "../../redux/mediaFilesSlice";
import { gaEvents } from "../../ga/actions";
import MainLogoCard from "./components/MainLogoCard";
import AllTeamsCard from "./components/AllTeamsCard";
import TeamCreationDrawer from "../teams";
import DeleteTeamPopup from "../teams/components/DeleteTeamPopup";
import RenameTeamPopup from "../teams/components/RenameTeamPopup";
import butterfly from './../../assets/butterfly_navigation.png'
import { getPermissions, getRoleNamesFromIds, getRoles, getUserRoles } from "../../commonactions/actions";
import { checkTokenSession, getAllEvents, getAllTeams, viewOrganisationMember } from "./actions";
import InviteOrganisationMembersPopup from "./components/InviteOrganisationMembersPopup";
import RemoveOrganisationMemberConfirmationPopup from "./components/RemoveOrganisationMemberConfirmationPopup";
import AssignAdminRolePopup from "./components/AssignAdminRolePopup";
import { viewProjectMembers } from "../events/actions";
import { updatePermissionState } from "../../redux/permissionSlice";
import ViewOrganisationMembersPopup from "./components/ViewOrganisationMembersPopup";
import BackdropCreateProject from "../eventcreation/components/BackdropCreateProject";
import DownIcon from "../eventcreation/icons/DownIcon";
import { PERMISSION } from "../../constants/constants";
import usePermissions from "../../hooks/usePermissions";
import { updateTeamState } from "../../redux/teamSlice";
import { setSnackbar } from "../../redux/messageSlice";
import EventTypeFilter from "./components/EventTypeFilter";
import useProjectTypeFilter from "./hooks/useProjectTypeFilter";
import useVideoLengthList from "../eventcreation/hooks/useVideoLengthList";
import { getTheme } from "../whitelabel/actions";
import {alpha,   getContrastRatio } from '@mui/material/styles';
// import { customTheme as theme } from "../../theme/customTheme";
import useCustomTheme from "../../theme/useCustomTheme";
import {  goToCopilot } from "../../utils/commonServices";
import { common } from "@mui/material/colors";


let counter = 0;

const Dashboard = () => {
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer)
    const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
    const [searchedArray, setSearchedArray] = useState(commonReducer.events);
    const [searchString, setSearchString] = useState("");
    const [deleteProject, deleteEventStatus] = useDeleteProject(commonReducer.production_event_id);
    const [selectedProjectTypeValue, setSelectedProjectTypeValue] = useState(['View All']);
    const [projectTypeEvents] = useProjectTypeFilter(searchedArray, selectedProjectTypeValue);
    const [sortedEvents] = useEventSort(projectTypeEvents, selectedSortbyValue);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [eventType, selectedEventType] = useEventTypeList()
   // const {eventCountByStatus, statusCheckHandler} = useFilter("status_type")
    const [members, setMembers] = useState(teamsReducer.organization_members)
    const [hasPermission] = usePermissions();
    const [videoLength, selectedVideoLength] = useVideoLengthList()
    const [theme] = useCustomTheme()
   

    useEffect(()=>{
        dispatch(updateTeamState({selected_team: null}))
        getUserRoles(dispatch, navigate, permissionsReducer)
        getAllEvents(dispatch, navigate, {commonReducer});
        if(dropdownReducer.organisation_roles?.length === 0 || dropdownReducer.team_roles?.length === 0 || dropdownReducer.project_roles?.length === 0 ){
            getRoles(dispatch, navigate)
        }
        // if(permissionsReducer?.permissions_list?.length === 0){
        //     getPermissions( dispatch)
        // }
      
        getAllTeams(dispatch, navigate)
        dispatch(updatePermissionState({org_role_id: teamsReducer?.organization_members?.find(mem => mem.id === commonReducer?.user_id)?.role?.id}))
    },[])
   
    useEffect(()=> {
        hasPermission(PERMISSION.ORGANISATION.MEMBER.VIEW) && viewOrganisationMember(dispatch, navigate, commonReducer)
    },[permissionsReducer.permissions_master[PERMISSION.ORGANISATION.MEMBER.VIEW]])

    ////////////////////////////////////////permission ends//////////////////////////////////////

    //***********Rerenders the dashboard as soon as all the events are successfully fetched************ */
    useEffect(()=> {
        setSearchedArray(commonReducer.combined_filter)
    }, [commonReducer.combined_filter])



    useEffect(()=> {
        setMembers(teamsReducer?.organization_members)
        dispatch(updatePermissionState({org_role_id: teamsReducer?.organization_members?.find(mem => mem.id === commonReducer?.user_id)?.role?.id}))
    }, [teamsReducer?.organization_members])



    const onSearch = (event) => {
        setSearchString(event.target.value);
        if (commonReducer.combined_filter?.length > 0) {
            const temp = commonReducer.combined_filter?.filter((searchedEvent) => {
                if (event.target.value == "") {
                    return searchedEvent;
                } else if (
                    searchedEvent?.name
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } else if (
                    searchedEvent?.event_type?.name
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } else if (
                    timeStampToString(searchedEvent?.start_date)
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } else if (
                    timeStampToString(searchedEvent?.end_date)
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                )
                    return searchedEvent;
            });
            setSearchedArray(temp);
        }
        // setFilteredArray(filterArray(event));
    }

    //***********************LOGIC FOR CLICKS ON EVENT CARDS******************************************** */
    const onEventClick = (event) => {
        dispatch(setSnackbar({flag: false, type: 'error', message: "ACCESS DENIED!"}))
        dispatch(updateState({ production_event_id: event.id}));
        const tempEvent =  commonReducer.events?.find((e) => e.id == event.id)
       hasPermission(PERMISSION.PROJECT.MEMBER.VIEW) && viewProjectMembers({event_id: event?.id}, dispatch, navigate, {commonReducer})
        if(tempEvent?.status_type_order <= 3) {
            navigate("/productionevent");
        } else {
            navigate('/completedevent')
        }
    };

    useEffect(()=> {
        checkTokenSession(dispatch)
        dispatch(updateState({ production_event_id: null, open_agreement_popup: false}))
        dispatch(setAllMediaFilesArray({open_previous_project_media_popup:false, import_files_confirmation_popup:false}))
        dispatch(updateCreationState({extend_edit_date_popup: false}))
        dispatch(updateReviewState({open_request_changes_popup: false, open_video_accept_popup: false,}))
      //  dispatch(updateState({under_revision: false, new_video: false}))
    },[])
    // const goToCopilot = ()=> {
    //     const encryptedToken = encryptString(window.localStorage.getItem('token'));
    //     const encodedURIString = encodeURIComponent(encryptedToken);
    //     //window.location.href=`${process.env.REACT_APP_COPILOT_URL}/redirect/${encodedURIString}`;
    //     window.open(`${process.env.REACT_APP_COPILOT_URL}/redirect?k=${encodedURIString}`, '_blank')
    // }

    return <AuthLayout sx={{ padding: '2% 3.2% 2.1% 2.2%' }}>
        <Box gap={2} className='flex row a-center j-end' sx={{ pr: {xs:1, sm:3} }} >
           {commonReducer?.copilot_access && <img onClick={goToCopilot} src={butterfly} style={{ borderRadius: '5px', width: '230px', cursor: 'pointer'}} />}
            <CustomUserCard />
        </Box>
        <Box className='flex row justify-center align-center' >

            <Box id="left" className='flex col' sx={{ maxWidth: { xs: '160px', sm:'28%',md: '23%', lg: '21%', xl: '22%' }, }} >
                {/* <CustomCard
                    
                    margin='0px 0px 20px 0px'
                    padding='17% 27% 11.3% 21.3%'
                    // height={{ xs: '200px', sm: '230px', md: '24vh', lg: '26vh', xl: '28vh' }}
                    sx={{ flex: 3 }}
                // width= {{xs:'200px', sm: '230px', md: '260px', lg: '320px', xl: '18.8vw' }} 
                >
                    <Box height='100%' className='flex col j-start a-center' >

                    <Box textAlign='center' sx={{ height: { xs: 20,sm:22, md: 26, lg: 30, xl: 42 },  }} >
                            <img src={luciLogo} height='100%' />
                        </Box>
                        <Box textAlign='center' sx={{mt: {xs: 2, md: 3, lg: 3, xl: 8}}}>

                        <Typography sx={{fontFamily: 'Rubik', fontWeight: 300, fontSize: {xs:'16px',md:'18px',lg:'23px',xl:'32px'}}} > Hello</Typography>
                        <Typography sx={{fontFamily: 'Rubik', color: '#000000', fontWeight: 300, fontSize: {xs:'10px',md:'12px',lg:'13.5px',xl:'20px'}, textTransform:'capitalize'}} >{commonReducer.user_name}</Typography>
                        </Box>
                    </Box>
                </CustomCard> */}
                <MainLogoCard/>
                <CustomCard
                    //  height={{ xs: '200px', sm: '230px', md: '26vh', lg: '30vh', xl: '32vh' }}
                    // width= {{ sm: '220px', md: '250px', lg: '300px', xl: '18vw' }} 
                    // sx={{ padding: '4vh 3vw', flex: 4 }}
                    
                >
                   {/* <Typography sx={{ fontSize: { xs: 8,sm:9, md: 10, lg: 11, xl: '16px' }, fontFamily: 'Rubik', fontWeight: 300, color: '#000000' , marginBottom:{xs:'10px',sm:'12px',md:'10px',lg:'10px',xl:'20px'}}}>Status Type</Typography> */}
                    {/* <FormGroup>
                        {
                            dropdownReducer.status_type != null && dropdownReducer?.status_type?.map((type, index) => {
                                return <FormControlLabel
                                    control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: { xs: 10, md: 12, lg: 14, xl: 20 } } }} onChange={() => statusCheckHandler(index)} checked={type.checked} />}
                                    label={<Typography
                                        sx={{ fontFamily: 'Rubik', fontSize: { xs: 10, md: 12, lg: 14, xl: 20 }, fontWeight: 600 }}>
                                        {`${type.value} (${eventCountByStatus(type.order)})`}
                                    </Typography>}
                                />
                            })
                        }
                    </FormGroup> */}
                    {/* <EventFilter eventCounter = {eventCountByStatus} checkStatus = {statusCheckHandler} listType = 'status_type' counts/> */}
                    <AllTeamsCard />
                </CustomCard>
                <CustomCard
                    margin='20px 0px 0px 0px'
                    // height={{ xs: '15vh', sm: '230px', md: '18vh', lg: '18vh', xl: '18vh' }}
                    //  width= {{xs:'200px', sm: '230px', md: '260px', lg: '320px', xl: '18.8vw' }} 
                    sx={{ flex: 2, cursor: 'pointer' }}
                    onClick={()=> dispatch(updateState({play_tutorial: true}))}
                >
                 {/* <Box className="rubik" textAlign='center' m={0} p={0} sx={{height:'100%', }}> */}
                     <img src={filmingTutorialImage} height='100%' style={{objectFit:'cover', borderRadius:'5px'}} width='100%'/>
                 {/* </Box> */}
                </CustomCard>
            </Box>
            <Box id="right" width='100%' minWidth= '300px' ml={4.5}>
                <Box width='100%' className= 'flex row j-between' sx={{ marginTop: {xs: '40px', md: '48px', lg:'56px', xl:'64px'}, marginBottom: {xs: '12px', md: '15px', lg:'20px', xl:'30px'}}}>

                <Box id="search" 
                    sx={{flex: 1}}
                    //sx={{ marginBottom: { xs: '12px', md: '15px', lg: '18px', xl: '30px' } }} 
                >
                    <Input
                        // className={classes.text}
                        // inputProps={{ style: {fullWidth: true,
                        //       //color: '#F5F1E9',
                        //       fontSize: 20,
                        //       fontWeight: 400,
                        
                        //     //  borderColor: '#F5F1E9 !important'
                        // }
                        //     }}
                        value={searchString}
                        onChange={onSearch}
                        sx={{
                          //  minWidth: '160px',
                            borderBottom: 2,
                            borderColor: "#333333",
                            input: {
                                fontSize: { xs: '12px', md: '13px', lg: '15px', xl: '22px' },
                                fontWeight: 400,
                                color: "#000000",
                            },
                        }}
                        placeholder="Search"
                        // borderWidth={2}
                        // style={{color:'#F5F1E9', width: "90%", border: 2, borderBottomColor: '#F5F1E9'}} sx={{borderBottomColor:'#F5F1E9'}}
                        startAdornment={
                            <IconButton sx={{paddingBottom:1, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'17px',xl:'24px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                </Box>
                {/* <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', 
                            //marginBottom:{xs:'10px',sm:'14px',md:'16px',lg:'18px',xl:'25px'}, 
                           // marginTop:{xs:'5px',sm:'5px',md:'5px',lg:'10px',xl:'20px'}, 
                            cursor:'pointer'}} gap={{xs:0.3,sm:0.5, md:0.7, lg:1, xl:1.2}} pr={1}>

                    <CustomPopoverFilter listType= 'event_type_filter'  />
                </Box> */}
                <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', 
                            //marginBottom:{xs:'10px',sm:'14px',md:'16px',lg:'18px',xl:'25px'}, 
                           // marginTop:{xs:'5px',sm:'5px',md:'5px',lg:'10px',xl:'20px'}, 
                            cursor:'pointer'}} gap={{xs:0.5,sm:0.7, md:0.9, lg:1.2, xl:1.4}} pr={1}>
                            
                        <EventTypeFilter
                         sortByOptions={eventSortOptions}
                         selectedProjectTypeValue={selectedProjectTypeValue}
                         setSelectedProjectTypeValue={setSelectedProjectTypeValue}
                        />
                       <CustomSortOptions
                         sortByOptions={eventSortOptions}
                         selectedSortbyValue={selectedSortbyValue}
                         setSelectedSortbyValue={setSelectedSortbyValue}
                         menuTitle = "Sort By"
                         // onChange={(newValue) => console.log(newValue)}
                        />

                   </Box>
                </Box>
                {/* <Box id="events" className='flex row f-wrap j-start a-start' sx={{ height: { xs: '78vh', sm: '66vh', md: '62vh', lg: '54vh', xl: '68vh' }, overflowY: "scroll", whiteSpace: 'nowrap', alignContent: 'flex-start' }} > */}
                <Box id="events" className='flex row f-wrap f-start a-start' sx={{ height: { xs: '71vh', sm: '67vh', md: '64vh', lg: '61vh', xl: '66vh' }, overflowY: "scroll", whiteSpace: 'nowrap', alignContent: 'flex-start', backgroundColor:'transparent',scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': {display : 'none'}, }} >
                    {
                        sortedEvents?.length > 0 ? sortedEvents?.map((e) => {
                            return <EventCard onClick={() => onEventClick(e)} event={e} sx={{ mt: 2, mr: 2, cursor: 'pointer' }} />
                        }) :
                        <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'>

                             { hasPermission(PERMISSION.ORGANISATION.PROJECT.CREATE) ?  <Typography sx={{fontSize: 30, color: '#878887', fontWeight: 300, textAlign: 'center', mt:3}} >You have no existing Lucihub Project. <br/>
                                <Box  gap={1.5} className='flex a-center j-center' >Click Add button below to get started.
                                {/* <AddIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ fontSize: { xs: '20px', sm: '24px', md: '30px', lg: '36px', xl: '62px' }, }} />  */}
                                 </Box>
                             </Typography>:
                             <Typography sx={{fontSize: 30, color: '#878887', fontWeight: 300, textAlign: 'center', mt:3}} >You have no existing Lucihub Project. <br/> 
                             </Typography>}
                        </Box>
                    }
                 
                   
                </Box>
                <Box className='flex row j-end a-end' width='100%' sx={{ mt: 2 }} >
                       {hasPermission(PERMISSION.ORGANISATION.PROJECT.CREATE) && <Box onClick={() =>{ 
                            // dispatch(updateCreationState({open_form: true}))
                            checkTokenSession(dispatch, ()=>{
                                dispatch(updateCreationState({open_backdrop_project_creation: true}));
                                gaEvents.eventFormOpened()
                            })
                            // dispatch(updateCreationState({open_backdrop_project_creation: true}))
                            // gaEvents.eventFormOpened()
                        }} 
                        sx={{ cursor: 'pointer', mr: {sm: '12px', md: '16px', lg: '20px', xl: 3} }} >

                        {/* <AddIcon color={getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : '#999999'} sx={{ fontSize: { xs: '40px', sm: '44px', md: '50px', lg: '56px', xl: '82px' }, }} /> */}
                        <AddIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ fontSize: { xs: '40px', sm: '44px', md: '50px', lg: '56px', xl: '82px' }, }} />

                        </Box>}
                    </Box>
              {/* <Box>{{a:""}}</Box> */}

            </Box>
            <Box>

            </Box>
        </Box>
        {
            createEventReducer.open_form == true && <EventCreationDrawer/>
        }
       {
            commonReducer.play_tutorial == true && <PopupVideoPlayer tutorial />
       }
        {
            commonReducer.event_delete_popup == true && <DeleteConfirmationPopup deleteProject = {deleteProject} status = {deleteEventStatus}/>
            
        }
         {
            commonReducer.delete_decline_popup == true && <DeleteDeclinePopup/>
            
        }
        {createEventReducer.edit_decline_popup == true && <DeclineEditProjectPopup/>}

        {teamsReducer.open_create_team_form == true && <TeamCreationDrawer />}

        {teamsReducer.delete_team_popup == true && <DeleteTeamPopup/>}

        {teamsReducer.rename_team_popup == true && <RenameTeamPopup/>}

        {/* {teamsReducer.invite_team_members_popup == true && <InviteMembersPopup members={members} options={dropdownReducer?.organisation_roles} roleNameFromId={getRoleNamesFromIds}/>} */}

        {teamsReducer.invite_team_members_popup == true && <InviteOrganisationMembersPopup members={members} options={dropdownReducer?.organisation_roles} roleNameFromId={getRoleNamesFromIds}/>}

        {teamsReducer.remove_member_confirmation_popup == true && <RemoveOrganisationMemberConfirmationPopup/>}

        {/* {teamsReducer.assign_role_popup == true && <AssignRolePopup/>} */}

        {teamsReducer.assign_role_popup == true && <AssignAdminRolePopup/>}

        {teamsReducer.view_members_popup == true && <ViewOrganisationMembersPopup  options={dropdownReducer?.organisation_roles}/>}

        {createEventReducer?.open_backdrop_project_creation === true && <BackdropCreateProject />}

    </AuthLayout>
}
export default Dashboard;