import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isIos, redirectToApp } from "../../utils/commonServices";
import { iosAppId } from "../../constants/constants";

const InviteCollaborator = ()=> {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const code = query.get('c')
    useEffect(()=> {

        redirectToApp(iosAppId.LUCI_CAM, `c=${code}`)

    //    if (isIos()){
    //     window.location.href = (`https://apps.apple.com/app/${iosAppId.LUCI_CAM}?c=${code}`)
    //    } else {
    //     window.location.href = "/";
    //    }
    }, []);

    return <></>
}

export default InviteCollaborator;