// import axios from "axios";
// import { apiUrlPrefix } from "../../../services/apiConstants";
// //import fileDownload from 'js-file-download';

// export default ()=> {
//     const [progress, setProgress] = useState(0);
//     const download = (data)=> {
//           const method = 'GET';
//           const url = `${apiUrlPrefix}/media_files/download?id=${data.id}`
//           axios.request({
//                 url, 
//                 method,
//                 responseType: 'blob', //important
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem("token")}`
//                 }
//             }).then(({ response }) => {
//               //  fileDownload(data.data, "sample.mov");
//               const totalLength = response?.headers?.get('content-length');
//               let downloadedLength = 0;
          
//               const reader = data?.body?.getReader();
//               const writer = new WritableStream({
//                 write(chunk) {
//                   downloadedLength += chunk.length;
//                   setProgress(Math.round(downloadedLength / totalLength * 100));
//                   return writer.write(chunk);
//                 }
//               });
          
//               const downloadStream = reader.pipeThrough(new TextDecoderStream()).pipeTo(writer);
//               const file = new File([downloadStream], fileName);
          
//               const link = document.createElement('a');
//               link.href = URL.createObjectURL(file);
//               link.download = fileName;
//               document.body.appendChild(link);
//               link.click();
//               document.body.removeChild(link);

//             //   const downloadUrl = window.URL.createObjectURL(new Blob([data]));
//             //   const link = document.createElement('a');
//             //   link.href = downloadUrl;
//             //   link.setAttribute('download', 'file.MOV'); //any other extension
//             //   document.body.appendChild(link);
//             //   link.click();
//             //   link.remove();
//             });
//       }

//       return [download]
      
// }

// import React, { useState } from 'react';

// function DownloadButton({ url, fileName }) {
//   const [progress, setProgress] = useState(0);

//   const downloadFile = async () => {
//     const response = await fetch(url);
//     const totalLength = response.headers.get('content-length');
//     let downloadedLength = 0;

//     const reader = response.body.getReader();
//     const writer = new WritableStream({
//       write(chunk) {
//         downloadedLength += chunk.length;
//         setProgress(Math.round(downloadedLength / totalLength * 100));
//         return writer.write(chunk);
//       }
//     });

//     const downloadStream = reader.pipeThrough(new TextDecoderStream()).pipeTo(writer);
//     const file = new File([await downloadStream], fileName);

//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(file);
//     link.download = fileName;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   }

//   return (
//     <div>
//       <button onClick={downloadFile}>Download</button>
//       <div>Progress: {progress}%</div>
//     </div>
//   );
// }

import React, { useState } from 'react';
import { apiUrlPrefix } from '../../../services/apiConstants';

export default(url, fileName, token )=> {
    const [progress, setProgress] = useState(0);
   
       
        const downloadFile = async (formData, fileName) => {
          const response = await fetch(`${apiUrlPrefix}/media_files/download?id=${formData.id}`, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('token')}`
            }
          });
          const totalLength = response.headers.get('content-length');
          let downloadedLength = 0;
      
          const reader = response.body.getReader();
          const writer = new WritableStream({
            write(chunk) {
              downloadedLength += chunk.length;
              //console.log("progress", Math.round(downloadedLength / totalLength * 100))
              setProgress(Math.round(downloadedLength / totalLength * 100));
              return writer.write(chunk);
            }
          });
      
          const transformer = new TransformStream({
            transform(chunk, controller) {
              controller.enqueue(new TextDecoder().decode(chunk));
            }
          });
      
          const downloadStream = reader.pipeThrough(transformer).pipeTo(writer);
          const file = new File([await downloadStream], "fileName.MOV");
      
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = "fileName.MOV";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
       
      
      }
      return [downloadFile]
      
}

