import { Box, Button, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import RemoveUserIcon from '../../../assets/RemoveUserIcon.svg'
import { updateCreationState } from "../../../redux/eventCreationSlice";
import { generateLighterContrastColor, timeStampToDateString, timeStampToDayString, timeStampToNumberedDateString } from "../../../utils/commonServices";
import { LoadingButton } from "@mui/lab";
import { updateTeamState } from "../../../redux/teamSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { updateProjectState } from "../../../redux/projectSlice";
import { removeProjectMember } from "../actions";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";
// import { customTheme as theme } from "../../../theme/customTheme";
import RemoveUserImage from "../../dashboard/assets/RemoveUserImage";


const RemoveUserConfirmationPopup = (props) => {
    const {open,  requestStatus} = props
   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const projectReducer = useSelector((state) => state.projectReducer);
    const [theme ] = useCustomTheme()
   
   
    const [memberRemoved, setMemberRemoved] = useState(false)
   

    const handleClose = () => {
        dispatch(updateProjectState({remove_user_confirmation_popup : false, loading_state: 'noloading'}))
        
       };

       const removeMemberClickHandler = ()=>{
        const payload ={
          event_id: commonReducer?.production_event_id,
          user_id: projectReducer?.selected_member?.id
        }
      //  api to delete member
      removeProjectMember(payload, dispatch, navigate, {commonReducer})
      setMemberRemoved(true)
       }

   

    return (
        <CustomAlert 
          open={projectReducer.remove_user_confirmation_popup} 
          handleClose={handleClose} 
          height='400px'
          rightComponent={
          <Box sx={{display:'flex', flexDirection:'column', paddingX:{md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
            <Box  sx={{height: '100%'}} className= 'flex col j-center a-center'> 
            <RemoveUserImage sx={{maxWidth: '200px',width: '100%', height: '60%'}} />
                {/* <img src={RemoveUserIcon} style={{maxWidth: '200px'}} height='60%'/> */}
            </Box>
          </Box>}
          >       
            {(projectReducer.loading_state !== 'success' || projectReducer.loading_state === 'loading')  && <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={3} pb={2}>
              <Box flexGrow={1} pt={7}>
                  <Typography sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}}}>Are you sure you want to remove this user from the project?</Typography>

                  <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>
                    <span style={{fontWeight:600}}>Note: </span>This action cannot be undone. 
                  </Typography>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               { projectReducer.loading_state === 'loading'  ? <LoadingButton
                   // size="small"
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.primary.main,
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={projectReducer.loading_state === 'loading'}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> :
               <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {removeMemberClickHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
               }
                  {/* <CheckIcon onClick= {finishedHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:  'pointer', }}/> */}
                </Box>
              <Box>    
                <Button 
                variant="text"
                onClick={handleClose}
                 sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'120px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                Or Go Back
               </Button>
              </Box>
            </Box>
            </Box>}

            {projectReducer.loading_state === 'success' && memberRemoved == true &&
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={3} pb={2}>
              <Box flexGrow={1} pt={10}>
                  <Typography sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}}}>The user has been removed from your team.</Typography>

                  <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>
                    <span style={{fontWeight:600}}>{`${projectReducer.selected_member?.name}`}</span> has been removed. 
                  </Typography>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {handleClose}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
               
                </Box>
              
            </Box>
          </Box>
          }
       
        </CustomAlert>
      )
}

export default RemoveUserConfirmationPopup