import { updateState } from "../../redux/commonSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { updateTeamState } from "../../redux/teamSlice";
import { getTeamEventsListApi } from "../../services/api";



export const getTeamEvents = (values ,dispatch ) => {
  dispatch(updateTeamState({events_loading: true}))
  const payload = values
getTeamEventsListApi(payload) 
.then((response) => {
  dispatch(updateTeamState({events_loading: false}))
  if(response?.data?.http_code === 200){
    dispatch(
      updateTeamState({
       events: response?.data?.data?.event_details,
       status_filtered_events: response?.data?.data?.event_details,
       type_filtered_events: response?.data?.data?.event_details,
       combined_filter: response?.data?.data?.event_details,
      })
    );
  }else {
    dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  // console.error('error:', error);
  dispatch(updateTeamState({events_loading: false}))
  dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
});
}