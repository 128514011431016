import React, { useEffect, useState } from 'react'
import AuthLayout from '../layouts/AuthLayout'
import Header from '../userfiles/components/Header'
import CustomCard from '../events/components/CustomCard'
import { Box, Typography } from '@mui/material'
import GuestVideoPlayer from '../guestreview/components/GuestVideoPlayer'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LogoHeader from './components/LogoHeader'
import useWatchVideoActions from './useWatchVideoActions'
import DownloadIcon from '@mui/icons-material/Download';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { downloadS3File } from '../../utils/commonServices'
import { gaEvents } from '../../ga/actions'
import CustomButton from '../../components/CustomButton'

const WatchVideoPage = () => {
   
    const guestReducer = useSelector(state=> state.guestReducer)
    const {getVideoLinkDetails} = useWatchVideoActions()
    const [dataLoaded, setDataLoaded] = useState(false)
  
    const handleDownload = () => {
      downloadS3File(guestReducer?.video_download_url)
      gaEvents.sharedVideoDownloadButtonClicked()
    };
    

    useEffect(()=>{
        getVideoLinkDetails()
        gaEvents.watchVideoLinkVisited()
    },[])

    return (
        
        guestReducer?.video_download_url == '' ? <></>:
        <AuthLayout sx={{  position:'relative'}} >
            <LogoHeader/>
            <Box 
            sx={{height:{xs:'86vh',sm:'78vh',md:'80vh',lg:'76vh',xl:'76vh'}, width:'100%',alignItems:'flex-start',  position:'absolute', top:{xs:'100px', sm:'130px',md:'90px', lg:'90px', xl:'140px'}}}
             className='flex  j-center ' >
                {/* <Box className='flex a-center j-center' sx={{height:'100%', width:{xs:'100%',md:'85%'}, backgroundColor:'#ffffff88', boxShadow:'0px 5px 15px #00000029', borderRadius:'10px', minWidth:{xs:'320px', sm: 'none'},overflowY:'auto' }}> */}
                <Box className='flex col' gap={{xs:2, xl:4}} sx={{width:{xs:'90%',sm:'80%',md:'60%',lg:'60%'}, minWidth:'250px'}}>
             
                    <Box className='flex a-center j-center'  sx={{height:'100%',
                     width:'100%', minWidth:'250px', borderRadius:'3px'}}>
                        {/* <GuestVideoPlayer src={guestReducer?.watch_video_link}/>
                         */}
                         <video onLoadedData={()=> setDataLoaded(true)} controlsList="nodownload" src={guestReducer?.watch_video_link}  controls autoPlay style={{minWidth:'250px',maxWidth:'100%', borderRadius:'3px', maxHeight: '75vh',  border: dataLoaded == true && '1px solid black',}}>
                         </video>
                    </Box>

                    <Box className='flex a-center' sx={{justifyContent:{xs:'center', md:'end'}}}>
                    <CustomButton 
                  btnText='Download'
                  onClick={handleDownload} 
                  component='button'
                  color='secondary'
                  type='button'
                  variant='contained'
                  style={{
                  borderRadius: '5px',
                  border: "none",          
                  textTransform:'none',
                  }}
                  sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px !important',sm:'20px !important',md:'22px !important',lg:'24px !important',xl:"40px !important"}, width:{xs:'130px',sm:'140px',md:'150px',lg:'160px',xl:'230px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
                />
                    </Box>
                </Box>
            </Box>
        </AuthLayout>
      )
}

export default WatchVideoPage