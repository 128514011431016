import { Box, Button, Typography, getContrastRatio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import CustomAlert from "../../events/components/CustomAlert";
import useCustomTheme from "../../../theme/useCustomTheme";
import { generateLighterContrastColor } from "../../../utils/commonServices";
import { setGuestReviewState } from "../../../redux/guestSlice";
import useGuestReviewActions from "../useGuestReviewActions";

const DeleteGuestLogConfirmationPopup = (props) => {
    const dispatch = useDispatch()
    const guestReducer = useSelector(state=> state.guestReducer)
    const [theme ] = useCustomTheme()
    const {deleteGuestLog} = useGuestReviewActions()

    const handleClose = () => {
        dispatch(setGuestReviewState({delete_log_confirmation_popup : false, log: {}})) 
       };

       const deleteLogHandler =()=>{
        deleteGuestLog({id: guestReducer?.log?.id , param:{guest_reviewer_id: guestReducer?.guest_reviewer_id}}, handleClose)
       }

  return <CustomAlert
          open={guestReducer.delete_log_confirmation_popup}
          handleClose={handleClose}
          width={{ xs: '400px', sm: '400px', md: '420px', lg: '440px', xl: '600px' }}
          height={{ xs: '220px', sm: '220px', md: '230px', lg: '230px', xl: '295px' }}
      >
          <Box p={5} className="flex col j-center" sx={{ height: '100%' }}>
              <Box mb={{ xs: 1, md: 2 }} mt={1}>
                
                    <Typography variant="h4" sx={{ fontFamily: 'Rubik', fontSize: { xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '18px' }, color: 'black' }}>Are you sure you want to delete this log?</Typography> 
                
                 
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end' }} gap={2} mt={{ xs: 2, md: 2.5, lg:3 }} >
                    <Button variant='outlined' color="button" onClick={handleClose} style={{borderRadius: '5px',  color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main), textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, paddingY:0.4, minWidth: '110px !important'}}>Cancel</Button>
                  <LoadingButton   
                    loadingPosition="start"
                    loading={guestReducer?.delete_log_loading}
                   color='button' 
                   onClick={deleteLogHandler} 
                   style={{ borderRadius: '5px', backgroundColor: '#08231B',  color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main), textTransform: 'none', }} 
                   sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, width: { xs: '76px', sm: '76px', md: '76px', lg: '86px', xl: '129px' }, paddingY: 0.4,  minWidth: '110px !important'}}>
                    Continue
                </LoadingButton>
              </Box>
          </Box>
      </CustomAlert>
}

export default DeleteGuestLogConfirmationPopup