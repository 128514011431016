import { Box, Link, Typography } from "@mui/material"
import editImage from "../../../assets/edit.svg";
import CustomCard from "../../events/components/CustomCard";
import EditImage from "../../events/components/assets/EditImage";

const ReEditingStartedCard = (props) => {

  return (
    <CustomCard  style={{height:'100%', }} >
    <Box sx={{display:'flex', height:'100%', width:'100%'}}>
        <Box  gap={{xs:0,md:1}} className= 'flex col j-center a-start' sx={{ paddingLeft:{xs:'10px',sm:'20px',md:'30px',lg:'34px',xl:'44px'}, paddingTop:{xs:'12px',sm:'16px',md:'20px',lg:'27px', xl:'29px'}, paddingBottom:{xs:'12px',sm:'16px',md:'18px',lg:'25px', xl:'20px'},height:'100%' ,  width:'80%'}}>
          <Box >
            <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'14px',lg:'17px',xl:'20px'}, fontWeight:600,letterSpacing:0, fontFamily:'Rubik'}}>Requested revisions are being done to your Lucivideo!</Typography>
          </Box>
          <Box  sx={{display:'flex', alignItems:'center', justifyContent:'start',paddingBottom:{xl:'0.5rem'},  width:'100%', wordWrap:'break-word'}}>
            <Typography sx={{fontSize:{xs:'8px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600,letterSpacing:0, fontFamily:'Rubik'}}>Note: <Typography component='span' sx={{fontSize:{xs:'8px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight: 300, ml: 0.5}}>Your current Lucivideo will be available until a newer version arrives.</Typography></Typography>  
        </Box>
        </Box>
        <Box  sx={{ width:'20%', }}>          
          {/* <img src={editImage} alt='edit' height='100%' width='100%' />       */}
          <EditImage sx={{height: '100%', width: '100%'}} />
        </Box>
        </Box>
    </CustomCard>
  )
}

export default ReEditingStartedCard