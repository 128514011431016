import React, { useEffect, useState } from "react";
import { PERMISSION, PERMISSIONS, ROLE_PERMISSIONS } from "../constants/constants";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


export default () => {
  const location = useLocation();
  const permissionsReducer = useSelector((state) => state.permissionsReducer)
  // const parseRole = ()=>{
  //   switch(location.pathname) {
  //     case '/productionevent': ;
  //     case 'completedevent': return permissionsReducer.project_role_id;
  //     case '/dashboard/teams': return permissionsReducer.team_role_id;
  //     case '/dashboard' : ;
  //     default: return permissionsReducer.org_role_id;
  //   }}
  // const roleId = parseRole()
  // console.log({roleId})
  // console.log(permissionsReducer.permissions_master)
  const hasPermission = (permissionName)=>{
    // console.log('permissionName', permissionName)
        return permissionsReducer.permissions_master[permissionName]
    }
  return [hasPermission];
};

