import { Box, CircularProgress, IconButton, Slider, Tooltip, Typography } from '@mui/material'
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import '../videoPlayer.css'
import {functionFormatTime} from '../../../utils/commonServices'

// import video from '../assets/test video.mp4'

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { videoUrl } from '../../../services/api';
import { updateReviewState } from '../../../redux/reviewSlice';
import usePermissions from '../../../hooks/usePermissions';
import { PERMISSION } from '../../../constants/constants';
import { setGuestReviewState } from '../../../redux/guestSlice';



const GuestVideoPlayer = ({src}) => { 

    const videoRef = useRef(null);
    const videoContainerRef = useRef(null)
    const timelineRef = useRef(null)

    const dispatch = useDispatch()

    const [playing, setPlaying] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [isMute, setIsMute] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isScrubbing, setIsScrubbing] = useState(false)
    
    const [videoDuration, setVideoDuration] = useState("00:00")
    const [currentTime, setCurrentTime] = useState('00:00')
    const [tooltipValue, setTooltipValue] = useState('00:00')
    const [volume, setVolume] = useState(100)
    const [hasPermission] = usePermissions();

    const [enableVideoUrl, setEnableVideoUrl] = useState(true)
   // const [url, setUrl] = useState('')

    const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
    const commonReducer = useSelector((state)=> state.root.commonReducer)
    const guestReducer = useSelector(state=> state.guestReducer)



      useEffect(()=>{
        setPlaying(!videoRef?.current?.paused)
      },[videoRef?.current?.paused])
  

    const togglePlay =()=>{
      if(videoRef.current.paused){
        videoRef.current.play()
        // setPlaying(false)
      }else{
        videoRef.current.pause()
        // setPlaying(true)
        // dispatch(updateReviewState({timestamp: functionFormatTime(videoRef.current.currentTime)}))
        dispatch(updateReviewState({timestamp: currentTime}))
        // dispatch(setGuestReviewState({timestamp: currentTime}))
      }
        // videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
        videoRef.current.paused ? setPlaying(false) : setPlaying(true)   
    }

    useEffect(() => {
      if(videoRef.current.paused){
        dispatch(updateReviewState({timestamp: currentTime}))
        // dispatch(setGuestReviewState({timestamp: currentTime}))
      }
    }, [currentTime, videoRef?.current?.paused])
    

    const forward = () => {
        videoRef.current.currentTime += 10;
      };

    const revert = () => {
        videoRef.current.currentTime -= 10;
      };

    const toggleFullScreen =()=>{
        // if(document.fullscreenElement == null){
        //     videoContainerRef.current.requestFullscreen()
        //     setIsFullScreen(true)
        //     // setIsFullScreen(prev => !prev)
        // }else{
        //     document.exitFullscreen()
        //     setIsFullScreen(false)
        //     // setIsFullScreen(prev => !prev)
        // }

        if (!document.fullscreenElement) {
            videoRef.current.requestFullscreen();
            setIsFullScreen(true);
          } else {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
            } 
            setIsFullScreen(false);
          }
    }

    const toggleMute =()=>{
        videoRef.current.muted = !videoRef.current.muted
        setIsMute(prev => !prev)
      }

    const keyPressedHandler =(e)=>{
        const tagName = document.activeElement.tagName.toLowerCase()

        if(tagName === 'input') return

       
        switch (e.key.toLowerCase()){
            case ' ':
                if(tagName === 'button') return
            case 'k':
                togglePlay()
                break;
            case 'f':
                toggleFullScreen()
                break;
            case 'm':
                toggleMute()
                break;
            case 'arrowright':
                forward()
                break;
            case 'arrowleft':
                revert()
                break;
        }
    }

    const handleLoadedData =  (event)=>{
            setIsLoading(false)
          setVideoDuration(functionFormatTime(event.target.duration))
    }

    const handleTimeUpdate =  ()=>{
        // console.log('Video metadata loaded:', videoRef);
        setCurrentTime(functionFormatTime(videoRef.current.currentTime))
        const percent = videoRef.current.currentTime / videoRef.current.duration;
        timelineRef.current.style.setProperty('--progress-position', percent)
    }

    const toggleScrubbing=(e)=>{
      const rect = timelineRef.current.getBoundingClientRect()           
      const percent = Math.min( Math.max(0, e.clientX - rect.x), rect.width)/rect.width

     if(e.buttons === 1){        
       setIsScrubbing(true)
     }
     
     //  if(isScrubbing){
     //   setWasPaused(videoRef.current.paused)
     //   videoRef.current.pause()
     //   setPlaying(false)
     //  }else{
     //   videoRef.current.currentTime = percent*videoRef.current.duration
     //   if(!wasPaused) {
     //     videoRef.current.play()
     //     setPlaying(true)
     //   }
     //  }
      handleTimelineUpdate(e)
   
}

    const handleTimelineUpdate =(e)=>{
        // console.log(e)
        const rect = timelineRef.current.getBoundingClientRect()
        // console.log(rect.x)
        // console.log(e.clientX)
        // console.log(rect.width)
        const percent = Math.min( Math.max(0, e.clientX - rect.x), rect.width)/rect.width
        // console.log(percent)
        timelineRef.current.style.setProperty('--preview-position', percent)

        const toolTip = (percent)*videoRef.current.duration
        // console.log(videoRef.current.duration)
        // console.log(toolTip)
        setTooltipValue(functionFormatTime(toolTip))
        if(isScrubbing){
          e.preventDefault();
          timelineRef.current.style.setProperty('--progress-position', percent)
          setCurrentTime(functionFormatTime(toolTip))
          updateProgressBar(e)  
        }
        // dispatch(updateReviewState({timestamp: functionFormatTime(videoRef.current.currentTime)}))
    }


    const updateProgressBar =(e)=>{
    //   console.log(e)
      const rect = timelineRef.current.getBoundingClientRect()
      const percent = Math.min( Math.max(0, e.clientX - rect.x), rect.width)/rect.width
    //   console.log(percent)
      timelineRef.current.style.setProperty('--progress-position', percent)
      const toolTip = (percent)*videoRef.current.duration
    //   console.log(videoRef.current.duration)
    //   console.log(toolTip)
      videoRef.current.currentTime = toolTip
  }

  const handleVolumeChange =(event)=>{  
    

    const { value } = event.target;
    setVolume(value);

    if (value === '0') {
      setIsMute(true);
    } else {
      setIsMute(false);
    }

    videoRef.current.volume = value;
    videoRef.current.muted = isMute;
}


    useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);


    useEffect(() => {
        document.addEventListener('keydown', keyPressedHandler);
    
        return () => {
          document.removeEventListener('keydown', keyPressedHandler);
        };
      }, []);

      useEffect(() => {  
        document.addEventListener('mouseup', e => {
          if(isScrubbing) {toggleScrubbing(e)}
          else{setIsScrubbing (false)}
        })
     
        return () => {
          document.removeEventListener('mouseup', e => {
            if(isScrubbing) {toggleScrubbing(e)}
            else{setIsScrubbing (false)}
          })     
        };
      }, []);

 

  return (
    <Box sx={{height:'100%', width:'100%'}}>
        {/* {isLoading && 'Loading'} */}
       {  <Box className='video-container' ref={videoContainerRef}>

        {(   !playing && !isLoading) &&             
            <Box  className='play-icon-container' sx={{display:(isFullScreen || playing )&& 'none'}}>
                <IconButton sx={{cursor:'pointer'}} onClick={togglePlay}>
                    <PlayCircleRoundedIcon sx={{color:'#ffffffbf', cursor:'pointer', backgroundColor:'transparent', fontSize: {xs:'60px',md:'80px',lg:'100px',xl:'150px'}, cursor:'pointer'}}/>
                </IconButton>
          </Box>}
          {isLoading && <Box className='play-icon-container'>
            <CircularProgress style={{color:'white'}}/>
          </Box>}
            {/* <Box className='video-controls-container' sx={{bottom: isFullScreen ? 0: {xs:'-35%',sm:'-25%',md: '-26%' ,lg:'-25%', xl:'-23%'}, backgroundColor: isFullScreen && '#ffffffbf'}}> */}
          <Box className='video-controls-container' sx={{bottom: isFullScreen ? 0: {xs:'-35%',sm:'-60px',md: '-65px' ,lg:'-70px', xl:'-85px'}, backgroundColor: isFullScreen && '#ffffffbf', display: isFullScreen && 'none'}}>

                <Box className='timeline-container' pb={0.6}>
                    <Box className='duration-container' sx={{padding: isFullScreen? '8px 0': 0}}>
                        <Typography sx={{fontFamily:'Rubik', color:'black', fontWeight:600, fontSize:{xs:'8px',md:'9px',xl:'13px'}}}>{currentTime}</Typography>/
                        <Typography  sx={{fontFamily:'Rubik', color:'black', fontWeight:600, fontSize:{xs:'8px',md:'9px',xl:'13px'}}}>{videoDuration}</Typography>
                    </Box>

                    <Tooltip title={tooltipValue} arrow followCursor={true} placement='top'>
                      <div ref={timelineRef} className='timeline' 
                      onMouseMove={(e)=>handleTimelineUpdate(e)} 
                      onMouseDown={e=>{
                      // setIsScrubbing(true)
                      toggleScrubbing(e)
                    }} 
                      onMouseUp={(e)=>{
                      setIsScrubbing(false)
                      
                     }}
                      onClick={updateProgressBar}>
                        <Box className='time'>
                            <div className='thumb'></div>
                        </Box>
                      </div>
                    </Tooltip>
                </Box>

                <Box className='controls' sx={{minHeight:{xs:'20px',md:'26px',lg:'36px',xl:'46px'}}}>
                    <Box className='volume-container' flex={1}>
                      <Box className='volume-icon-box' sx={{ display:'flex', alignItems:'center'}}>
                    {isMute ? (
                      <Tooltip placement='top' title='Unmute (m)'>
                        <IconButton aria-label="mute" onClick={toggleMute}>
                            <VolumeOffRoundedIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'18px',xl:'22px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'18px',xl:'22px'}},color:'black'}}/>              
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip placement='top' title='Mute (m)'>
                        <IconButton aria-label="unmute" onClick={toggleMute}>
                            <VolumeUpRoundedIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'18px',xl:'22px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'18px',xl:'22px'}},color:'black'}}/>              
                        </IconButton> 
                      </Tooltip>
                    )}

                      <Tooltip title='Volume' placement='top'>
                        <input className="volume-slider" id="volume" value={volume} type="range" max="1" min="0" step="0.01" onChange={handleVolumeChange}/>
                      </Tooltip>
                    
                      </Box>
                    </Box>

                    <Box flex={1} textAlign='center'>
                    <Tooltip placement='top' title='Rewind'>
                        <IconButton aria-label="skip-prev" onClick={revert}>
                            <SkipPreviousRoundedIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'18px',xl:'22px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'18px',xl:'22px'}},color:'black'}}/>
                        </IconButton>
                    </Tooltip>

              {playing ? (
                      <Tooltip placement='top' title='Pause(k)'>

                        <IconButton aria-label="pause" onClick={()=>togglePlay()} > 
                            <PauseRoundedIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'18px',xl:'22px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'18px',xl:'22px'}},color:'black'}}/>
                        </IconButton>
                      </Tooltip>
              ):(
                <Tooltip placement='top' title='Play(k)'>
                        <IconButton aria-label="play"  onClick={()=>togglePlay()}> 
                            <PlayArrowRoundedIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'18px',xl:'22px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'18px',xl:'22px'}},color:'black'}}/>
                        </IconButton>
                </Tooltip>
              )}

                      <Tooltip title='Fast forward' placement='top'>
                        <IconButton aria-label="skip-next" onClick={forward}>
                            <SkipNextRoundedIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'18px',xl:'22px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'18px',xl:'22px'}},color:'black'}}/>
                        </IconButton>
                      </Tooltip>

                    </Box>

                    <Box flex={1} textAlign='right'>
                {isFullScreen ? (
                  <Tooltip placement='top' title='Exit fullscreen(f)'>
                        <IconButton aria-label="close-fullscreen"  onClick={toggleFullScreen}>
                            <CloseFullscreenRoundedIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'18px',xl:'22px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'16px',lg:'18px',xl:'22px'}},color:'black'}}/>            
                        </IconButton> 
                  </Tooltip>
                ) : (
                  <Tooltip placement='top' title='Enter fullscreen(f)'>
                        <IconButton aria-label="fullscreen"  onClick={toggleFullScreen}>
                            <OpenInFullRoundedIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'18px',xl:'22px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'16px',lg:'18px',xl:'22px'}},color:'black'}}/>            
                        </IconButton> 
                  </Tooltip>
                )}


                    </Box>
                </Box>
            </Box>
       
         <video  onEnded={()=> setPlaying(false)} onTimeUpdate={handleTimeUpdate} onLoadedData={handleLoadedData} onClick={togglePlay} className='video' id='videoId'  ref={videoRef}  
            // src={ guestReducer?.event_details?.revision_video_url}
            src={src}
            controls={ isFullScreen && true}
           // poster= {videoPlaceholder}
            controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()}
        // src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_1000/q_auto/f_auto/tree_sunset.mp4" 
        ></video>
        
        </Box>}
    </Box>
  )
}

export default GuestVideoPlayer