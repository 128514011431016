import { Box, Link, Typography, getContrastRatio } from "@mui/material"
import CustomButton from "../../../components/CustomButton"
import CustomCard from "./CustomCard"
// import { customTheme as theme } from "../../../theme/customTheme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import QRCode from 'qrcode'
import "@fontsource/rubik";
import { cleanFileName, dateCountdown, generateLighterContrastColor, timeStampToDayInWordsString, timeStampToDayString, timeStampToFormatTimeZone } from "../../../utils/commonServices"
import ExtendEditingStartDatePopup from "./ExtendEditingStartDatePopup"
import DateTimePickerPopup from "../../../components/DateTimePickerPopup"
import { updateCreationState } from "../../../redux/eventCreationSlice"
import useEventRequest from "../../../hooks/useEventRequest"
import useEventList from "../../dashboard/hooks/useEventList"
import { useNavigate } from "react-router-dom"
import { updateState } from "../../../redux/commonSlice"
import CustomTooltip from "../../../components/CustomTooltip"
import usePermissions from "../../../hooks/usePermissions"
import { PERMISSION } from "../../../constants/constants"
import { getEventDetails } from "../actions"
import useCustomTheme from "../../../theme/useCustomTheme"



const ShareLinkCard = (props) => {

  
  const {guestUploadsLink,  shareClickHandler , eventData, refetchEvent, isDisabled} = props
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
  const event = commonReducer.events?.find((event) => event.id == commonReducer.production_event_id)

  const [newEditDatePageData, setNewEditDatePageData] = useState({
   // name : event?.name,
   // start_date: event?.start_date,
   // end_date: event?.end_date,
    editor_start_date: event?.editor_start_date,
   // video_orientation: event?.video_orientation, 
   // description: event?.description,
   // event_type_id: dropdownReducer?.event_type?.find((type)=> type.value == event?.event_type)?.id,
   // video_length_id: dropdownReducer?.video_length?.find((length)=> length.value == event?.video_length)?.id,
});

  
  const createEventReducer = useSelector((state) => state.createEventReducer);
  const dispatch = useDispatch()
  const [pageData, setPageData] = useState(createEventReducer.pageData)
  const [editingStartsIn, setEditingStartsIn] = useState(dateCountdown(commonReducer.event_details?.editor_start_date))
  //const [refetchEvents] = useEventList();
  const [editEvent, editEventStatus] = useEventRequest("edit", ()=> getEventDetails({id: commonReducer.production_event_id}, dispatch))
  const [copyButtonText, setCopyButtonText] = useState("Copy Link");
 
  const [imageUrl, setImageUrl] = useState('')

  const [anchorEl, setAnchorEl] = useState(null);
 
  const navigate = useNavigate()
  const [hasPermission] = usePermissions();
  const [theme ] = useCustomTheme()


  const generateQRCode = async ()=>{
    try {
      const response = await QRCode.toDataURL(`${window.location.host}/guestuploads?c=${eventData?.code}`)
      setImageUrl(response)
      // console.log(response)
    } catch (error) {
     
    }
  }
  // useEffect(()=> {
    

  //   if(imageUrl !==""){
  //     // open popup
  //   }
  // }, [imageUrl])

  useEffect(() => {
    generateQRCode() 
  }, [commonReducer.production_event_id, eventData, ])



  const copyClickHandler = () => {
    setCopyButtonText("Copied!")
    navigator.clipboard.writeText(`${window.location.host}/guestuploads?c=${eventData?.code}`)
    setTimeout(() => {
      setCopyButtonText("Copy Link")
    }, 1500)
  }

  const handleImageDownload =()=>{
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `${cleanFileName(eventData?.name)}`;
    link.click();
  }

  const extendEditingDateClickHandler =(e)=>{
    setAnchorEl(anchorEl ? null : e.currentTarget); 
  }

  useEffect(()=>{
    setEditingStartsIn(dateCountdown(commonReducer.event_details?.editor_start_date))
  },[commonReducer.event_details?.editor_start_date])



  // useEffect(()=> {
  //   refetchEvents();
  // },[])

  // useEffect(()=> {
  //     if( editEventStatus === 'success' ) {
  //         dispatch(updateState({selected_event: commonReducer.events.find((e)=> e.id == commonReducer.production_event_id) }))
  //         navigate('/productionevent')
  //     }
  // },[editEventStatus])

  const submitRequestClickHandler = async ()=>{
    await editEvent({id: commonReducer?.production_event_id, param: newEditDatePageData})
  }

  return (
    <CustomCard  style={{height:'100%', }} >
    <Box sx={{display:'flex', height:'100%', width:'100%'}}>
        <Box  gap={{xs:0,md:1}} sx={{display:'flex', flexDirection:'column', paddingLeft:{xs:'10px',sm:'20px',md:'30px',lg:'34px',xl:'44px'}, paddingTop:{xs:'12px',sm:'16px',md:'20px',lg:'27px', xl:'29px'}, paddingBottom:{xs:'12px',sm:'16px',md:'18px',lg:'25px', xl:'20px'},height:'100%' , justifyContent:'space-around', alignItems:'start', width:'60%'}}>

          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'start', }}>
              <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, fontWeight:600,letterSpacing:0, fontFamily:'Rubik'}}>Share this link at your event</Typography>
          </Box>
          <Box gap={{xs:0.8,sm:1.3,md:2,lg:3,xl:4}} style={{display:'flex', alignItems:'center', justifyContent:'start', }}>
          <CustomTooltip title='Only project owner is authorized' sx={{display:commonReducer.user_name == commonReducer.event_details?.owner_name && 'none' }}>
          <span>
          {// hasPermission(PERMISSION.PROJECT.GUEST_INVITE) && 
          <CustomButton 
            btnText={copyButtonText}
            type='button'
            onClick={copyClickHandler}
            color='secondary'
            variant="contained" 
            disabled={ !hasPermission(PERMISSION.PROJECT.GUEST_INVITE)}
              style={{
              borderRadius: '5px',
              border: "none",
              textTransform:'none',
              // backgroundColor:theme.palette.secondary.main
              }}
              sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'},  height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"36px"},fontWeight:600, width:{xs:'60px',sm:'70px',md:'80px',lg:'90px',xl:'123px'}, fontFamily:'Rubik', }}
            />}
            </span>
            </CustomTooltip>
            <Box className='flex col'>
            <CustomTooltip title='Only project owner is authorized' sx={{display:commonReducer.user_name == commonReducer.event_details?.owner_name && 'none' }}>
            <span>
         { // hasPermission(PERMISSION.PROJECT.EXTEND_EDITING_DATE) &&   
          <CustomButton 
              btnText='Change Editing Start Date'
              disabled = {!hasPermission(PERMISSION.PROJECT.EXTEND_EDITING_DATE) || isDisabled}
              onClick={extendEditingDateClickHandler}
              type='button'    
              color='secondary'
              variant='contained'
              style={{
              borderRadius: '5px',
              border: "none",
              textTransform:'none',
              // backgroundColor:theme.palette.secondary.main
              }}
              sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"36px"}, width:{xs:'150px',sm:'170px',md:'180px',lg:'220px',xl:'290px'}, fontFamily:'Rubik'}}
            />}
            </span>
            </CustomTooltip>
               </Box>
          </Box>
        </Box>

        <Box pr={2} pl={1} sx={{ display:'flex', alignItems:'center', justifyContent: hasPermission(PERMISSION.PROJECT.GUEST_INVITE) ? 'space-between':'flex-end', height:'100%' ,width:'40%', }}
        >
        {hasPermission(PERMISSION.PROJECT.GUEST_INVITE) && (
              <CustomTooltip title = "Download QR" >
                 <Box className='flex j-center a-center' sx={{backgroundColor:'white',  boxShadow:'0px 5px 15px #00000029', borderRadius:'10px',height:{xs:'70px',sm:'70px',md:'80px',lg:'100px',xl:'137px'}, width:{xs:'70px',sm:'70px',md:'80px',lg:'100px',xl:'137px'},}} >
                  <Box onClick={handleImageDownload} sx={{height:{xs:'60px',sm:'60px',md:'70px',lg:'90px',xl:'127px'}, width:{xs:'60px',sm:'60px',md:'70px',lg:'90px',xl:'127px'}, cursor:'pointer',}}>
                    {imageUrl ? (  <img src={imageUrl} alt='Guest upload url' height='100%' width='100%' />  ): null}
                  </Box>   
                </Box>     
              </CustomTooltip>
        )}
  

                <Box py={1} className='flex col' sx={{ height:{xs:'80px',sm:'80px',md:'90px',lg:'110px',xl:'163px'}, width:{xs:'90px',sm:'90px',md:'100px',lg:'130px',xl:'196px'}, backgroundColor:'#08231B', boxShadow:'0px 5px 15px #00000029', borderRadius:'5px', alignItems:'center', justifyContent:'center', marginLeft:'10px'}}>
               
                      <Typography sx={{fontSize:{xs:'9px',sm:'9px',md:'10px',lg:'11px',xl:'18px'}, fontStyle:'Rubik'}} style={{color:'white'}}>Upload videos by</Typography>
                      <Typography mt={1.5} sx={{fontSize:{xs:'11px',sm:'13px',md:'14px',lg:'15px',xl:'24px'}, padding:0, fontStyle:'Rubik', 
                      // lineHeight:{xs:'26px',sm:'26px',md:'38px',lg:'50px',xl:'86px'}, 
                      color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main),
                      textShadow: editingStartsIn == 0 && `0px 0px 6px ${theme.palette.primary.main}`}} style={{ fontWeight:600}}>{timeStampToDayInWordsString(commonReducer?.event_details?.editor_start_date)}</Typography>
                      {/* <Typography sx={{fontSize:{xs:'9px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontStyle:'Rubik'}}  style={{color:theme.palette.primary.main}}>{timeStampToDayInWordsString(commonReducer?.event_details?.editor_start_date)}</Typography> */}
                      <Typography sx={{fontSize:{xs:'9px',sm:'9px',md:'10px',lg:'11px',xl:'18px'}, fontStyle:'Rubik', color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)}}  >{timeStampToFormatTimeZone(commonReducer?.event_details?.editor_start_date)}</Typography>
                      {/* <Typography sx={{fontSize:{xs:'9px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontStyle:'Rubik'}} style={{color:'white'}}>Editing starts in</Typography>
                      <Typography sx={{fontSize:{xs:'30px',sm:'30px',md:'40px',lg:'50px',xl:'80px'}, padding:0, fontStyle:'Rubik', lineHeight:{xs:'36px',sm:'36px',md:'48px',lg:'60px',xl:'96px'}, textShadow: editingStartsIn == 0 && `0px 0px 6px ${theme.palette.primary.main}`}} style={{color:theme.palette.primary.main}}>{editingStartsIn}</Typography>
                      <Typography sx={{fontSize:{xs:'9px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontStyle:'Rubik'}}  style={{color:theme.palette.primary.main}}>{editingStartsIn == 1 ? "Day" : "Days"}</Typography> */}
                   
                </Box>  
        </Box>
        </Box>
            {createEventReducer?.extend_edit_date_popup == true && <ExtendEditingStartDatePopup newEditDatePageData={newEditDatePageData} submitRequestClickHandler={submitRequestClickHandler} requestStatus={editEventStatus}/>}

         {<DateTimePickerPopup anchorEl={anchorEl} setAnchorEl={setAnchorEl} extendEditingDate={true} newEditDatePageData={newEditDatePageData} setNewEditDatePageData={setNewEditDatePageData}/>}
    </CustomCard>
  )
}

export default ShareLinkCard
