import { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useSelector } from 'react-redux';


const EventTypeFilter = (props) => {

   const {   selectedProjectTypeValue, setSelectedProjectTypeValue, sortByOptions} = props
   const [anchorEl, setAnchorEl] = useState(null);
   const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
   
   
     const handleClick = (event) => {
       setAnchorEl(event.currentTarget)
     }
   
     const handleOptionClick = (event, value) => {
      // setSelectedProjectTypeValue(selectedProjectTypeValue === value ? null : value)
      //  onChange(selectedProjectTypeValue === value ? null : value)
      let newSelected = [...selectedProjectTypeValue]
      if(value === 'View All' || selectedProjectTypeValue?.length === 0){
        newSelected = [value];
      }else{
        newSelected = newSelected.filter(item => item !== 'View All')
        if (selectedProjectTypeValue?.includes(value)){
          newSelected = newSelected.filter(item => item !== value)
        }else{
          newSelected = [...newSelected, value]
        }
    }
    setSelectedProjectTypeValue(newSelected);
   }
   
     const handleClose = () => {
       setAnchorEl(null);
     }

    return (
      <>
        <Box onClick={handleClick}  sx={{display:'flex', alignItems:'center', justifyContent:"center"}}>
             <Typography sx={{fontSize:{xs:'8px',sm:'8px',md:'11px',lg:'13px',xl:'17px'}, fontWeight:600, fontFamily:'Rubik'}}>Event Type</Typography>
              <IconButton>
                  <KeyboardArrowDownOutlinedIcon sx={{color:'black',fontSize:{xs:'8px',sm:'8px',md:'11px',lg:'13px',xl:'17px'}}}/>
              </IconButton>  
          </Box>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} 
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: 'right',
             }}
        >
         <MenuItem 
              key='View All' 
              sx={{height:{xs:'20px',sm:'22px',md:'24px',lg:'27px', xl:'34px'}}}
              >
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={selectedProjectTypeValue === 'View All'}
                    checked={selectedProjectTypeValue.includes('View All') || selectedProjectTypeValue?.length === 0}
                    onChange={(event) => handleOptionClick(event, 'View All')}
                    size='small'
                    sx={{ '& .MuiSvgIcon-root': { fontSize:{xs:'8px',sm:'8px',md:'10px',lg:'10px',xl:'13px'}, fontWeight: 600} }}
                  />
                }
        
                label={<Typography sx={{fontSize:{xs:'8px',sm:'8px',md:'10px',lg:'10px',xl:'13px'}, fontWeight:600}}>View All</Typography>}
              />
            </MenuItem>
          {dropdownReducer?.event_type?.map((option) => (
            <MenuItem 
              key={option.value} 
              sx={{height:{xs:'20px',sm:'22px',md:'24px',lg:'27px', xl:'34px'}}}
              >
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={selectedProjectTypeValue === option.value}
                    checked={selectedProjectTypeValue.includes(option.value)}
                    onChange={(event) => handleOptionClick(event, option.value)}
                    size='small'
                    sx={{ '& .MuiSvgIcon-root': { fontSize:{xs:'8px',sm:'8px',md:'10px',lg:'10px',xl:'13px'}, fontWeight: 600} }}
                  />
                }
        
                label={<Typography sx={{fontSize:{xs:'8px',sm:'8px',md:'10px',lg:'10px',xl:'13px', fontWeight: 600}}}>{option.value}</Typography>}
              />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };


 

export default EventTypeFilter

