import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../../redux/commonSlice";
import { eventVideoUploadConsent } from "../../../services/api";
import { useMutation } from "react-query";
import useEventList from "../../dashboard/hooks/useEventList";
import { LoadingButton } from "@mui/lab";
import CustomAlert from "../../events/components/CustomAlert";
import CustomInput from "../../eventcreation/components/CustomInput";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";
import { generateLighterContrastColor } from "../../../utils/commonServices";

const DeleteAccountConfirmationPopup = (props) => {
    const {deleteUserHandler, status} = props
    const [closeHandler, setCloseHandler] = useState(false)
    const isDisabled = false
    const dispatch = useDispatch()
    const commonReducer = useSelector(state=> state.root.commonReducer)
 
    const [text, setText] = useState("")
    const [theme ] = useCustomTheme()

    const handleClose = () => {
        dispatch(updateState({account_delete_popup : false})) 
       };

       


    const confirmHandler= async ()=>{
      //will send api req ,to deleet user, on success redux reset, and logout navigate to signin page
      if(text === "delete") {
        deleteUserHandler(commonReducer.user_id)  
      }
    }

    const cancelHandler =()=>{
      handleClose();
    }
    const handleChange = (event) => {
        setText(event.target.value.toLowerCase())
      }

  return (
    
        <CustomAlert 
          open={commonReducer.account_delete_popup} 
          handleClose={handleClose} 
          width={{xs:'400px',sm:'400px',md:'420px',lg:'440px', xl:'600px'}}
          height={{xs:'250px',sm:'260px',md:'290px',lg:'300px', xl:'380px'}}
        >
           <Box p={5} className="flex col j-center" sx={{ height:'100%'}}>
          
            <Box   mb={{xs:1 ,md:2}} mt= {1}>
              <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'13px',xl:'18px'}, color:'black'}}>{`Are you sure you want to delete your account ? Your projects and data will be deleted permanently and you will be logged out. Please type "delete" in the below box to confirm`}</Typography>
            </Box>
    
            <Box>
             <CustomInput onChange={handleChange} value={text} placeholder = "delete" max={6} light/>
            </Box>
    
            <Box sx={{display:'flex',justifyContent:'end' }} gap={2} mt={{xs:1 ,md:2}} >
    
              <Button variant='outlined' color="button" onClick={cancelHandler} style={{borderRadius: '5px', color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main), textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, paddingY:0.4,}}>Cancel</Button>
    
              <LoadingButton
                   // size="small"
                    disabled={status == 'loading' || text !== 'delete'}
                    onClick={confirmHandler}
                    style={{borderRadius: '5px', backgroundColor:'#08231B',color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main), textTransform:'none', opacity: text !=='delete' && '50%' }} 
                    sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, minWidth: '110px !important'}}
                    loading={status == 'loading'}
                    loadingPosition="start"
                    variant="contained"
                    >
                   Continue
               </LoadingButton> 
            </Box>
           </Box>
        </CustomAlert>
      
  )
}

export default DeleteAccountConfirmationPopup