import * as React from "react";
import Card from "@mui/material/Card";
import { Box, CardActionArea, CardContent } from "@mui/material";

const CustomCard = (props) => {
  const {  children } = props;

  return (
    <Box {...props} sx={{padding:0, margin:0, '& .MuiCard-root':{padding:0, margin:0}, '& .MuiCardContent-root':{padding:0, margin:0},  height:'100%', boxShadow:'0px 5px 15px #00000029', borderRadius:'10px', backgroundColor:'#ffffff'}}>
     
        {children}
      
    </Box>
  );
};

export default CustomCard;