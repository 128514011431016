import { Box, Typography } from "@mui/material"
import CustomButton from "../../../components/CustomButton"

import CustomCard from './CustomCard'
import { PERMISSION } from "../../../constants/constants"
import { useNavigate } from "react-router-dom"
import readyImage from "../../../assets/updatedIcon.svg"
import usePermissions from "../../../hooks/usePermissions"
import UpdatedImage from "./assets/UpdatedImage"

const VideoEditedEventCard = (props) => {
    const {message} = props
    const [hasPermission] = usePermissions();
    const navigate = useNavigate()
  
 
  return (
    <CustomCard  style={{height:'100%', }} >
    <Box sx={{display:'flex', height:'100%', width:'100%'}}>

        <Box  gap={{xs:0,md:1}} sx={{display:'flex', flexDirection:'column', paddingLeft:{xs:'10px',sm:'20px',md:'30px',lg:'34px',xl:'44px'}, paddingTop:{xs:'12px',sm:'16px',md:'20px',lg:'27px', xl:'29px'}, paddingBottom:{xs:'12px',sm:'16px',md:'18px',lg:'25px', xl:'20px'},height:'100%' , justifyContent:'center', alignItems:'start', width:'60%'}}>
          
                  <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, fontWeight:600, letterSpacing:0, fontFamily:'Rubik'}}>{message}</Typography>
                { hasPermission(PERMISSION.PROJECT.REVISION.PAGE_VIEW) && <CustomButton 
                      btnText='Watch Now'
                      onClick={()=> navigate('/completedevent', {replace: true})}    
                      type='button'
                      color='secondary'
                      variant='contained'
                      style={{
                      borderRadius: '5px',
                      border: "none",          
                      textTransform:'none',
                      // backgroundColor:theme.palette.secondary.main
                      }}
                      sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
                    />
                    }
            
        </Box>

        <Box  sx={{ display:'flex', alignItems:'flex-end', justifyContent:'center', height:'100%' ,width:'40%',  flexDirection:'column', mr: "5%"}}>    
        <Box className= 'flex row j-end a-center'  sx={{ height:'100%' }}>    
                
              <UpdatedImage sx={{height: '80%', width: '100%'}} />
            {/* <img src={readyImage} alt='edit' height='80%' width='100%' />  */}
                  
          </Box> 
        </Box>
        </Box>
       </CustomCard>
  )
}

export default VideoEditedEventCard