import { useMutation } from "react-query";
import {  createZippedMedia, downloadZippedMedia } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import useSnackMessage from "../../../hooks/useSnackMessage";
import { useEffect, useRef, useState } from "react";
import { updateState } from "../../../redux/commonSlice";
import { useQuery } from "react-query";
import { downloadS3File } from "../../../utils/commonServices";
import { setAllMediaFilesArray } from "../../../redux/mediaFilesSlice";



export default (requestType, enable)=> {
  
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);

  const [createZippedMediaStatus, setCreateZippedMediaStatus] = useState('')
  const [downloadZippedMediaStatus, setDownloadZippedMediaStatus] =useState('')
  const [enableDownload, setEnableDownload] = useState(false)
  const [downloadZippedMediaID, setDownloadZippedMediaId] = useState(null)
  const [zipUrl, setZipUrl] = useState('')
  const [downloadLink, setDownloadLink] = useState('')

  const enableRef = useRef(enableDownload)
  const downloadRef = useRef(downloadLink)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar] = useSnackMessage();


    //POST API Request to  to send seleceted media files to create download link
    const { mutate: createZippedMediaMutate, isLoading: createZippedMediaLoading } =
    useMutation(createZippedMedia, {
      onSuccess: (response, variables, context) =>
        createZippedMediaSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
        createZippedMediaErrorHandler(error, variables, context),
    });

    useEffect(() => {
      if(createZippedMediaLoading == true){
         setCreateZippedMediaStatus('loading')
        //  setDownloadZippedMediaStatus('loading')
      }
    }, [createZippedMediaLoading])

  const createZippedMediaSuccessHandler =  (response, variables, context) => {
    const responseObject = response?.data;
    const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {
 
     // showSnackbar(responseObject);
      // console.log(responseData.zipped_file_id)
      setDownloadZippedMediaId(responseData.zipped_file_id)
      // setCreateZippedMediaStatus("success");
     
      setEnableDownload(true)
      enableRef.current = true
    }
  };

  const createZippedMediaErrorHandler = (error, variables, context) => {  
    setCreateZippedMediaStatus("error");
    const errorObject = error?.response?.data;
    showSnackbar(errorObject)
  };


 //GET API for download zipped media files ***********************************************************

 const {
    data: downloadZippedMediaData,
    isLoading: downloadZippedMediaLoading,
    error: downloadZippedMediaError,
    refetch: downloadZippedMediaRefetch,
  } = useQuery(
    [
      `downloadZippedFile${downloadZippedMediaID}`,
      downloadZippedMediaID,
    ],
    () => downloadZippedMedia({zipped_file_id: downloadZippedMediaID }),
    { enabled: enableDownload, retry: false }
  );



  
  useEffect(()=> {
    const intervalId = setInterval( async () => {
       if(enableRef.current == true && downloadRef.current == ''){     
        //if(enableDownload == true && downloadRef.current == ''){   
        setCreateZippedMediaStatus('loading')
        setDownloadZippedMediaStatus('loading')
        await downloadZippedMediaRefetch()
        // console.log('refetching url')
      }
     }, 3000);
    
     return () => clearInterval(intervalId);
   },[])

 

  useEffect(() => {
    if(downloadZippedMediaLoading == true){
      setDownloadZippedMediaStatus('loading')
    }
  }, [downloadZippedMediaLoading]);


  useEffect(()=> {
    if(downloadZippedMediaData) {
      const responseObject = downloadZippedMediaData.data.data
      // console.log(responseObject)
      

        if( responseObject.download_link !== ''){
            setEnableDownload(false)
            enableRef.current = false
            setDownloadLink(responseObject.download_link)
            // downloadRef.current = responseObject.download_link
            
            // setTimeout(()=> {
            //   downloadS3File(responseObject.download_link)
            // }, 1000)
            setTimeout(()=> {
              dispatch(setAllMediaFilesArray({download_confirmation_popup : true})) 
              setCreateZippedMediaStatus("success");
              setDownloadZippedMediaStatus("success");
            }, 30000)
           
            
            // dispatch(setAllMediaFilesArray({  enable_media_download:false})) 
           
        }    else{
         
        }
        downloadRef.current = responseObject.download_link
    }
  }, [downloadZippedMediaData])



  

  useEffect(() => {

    if (createZippedMediaLoading) {
        setCreateZippedMediaStatus("loading");
      }

    if (downloadZippedMediaLoading) {
      setDownloadZippedMediaStatus("loading");
    }

    // return ()=>{ setStatus("")}
  }, [createZippedMediaLoading,  downloadZippedMediaLoading]);



  //Functions to request API as per incoming mutation type
  const revisionRequest = (data) => {
    switch (requestType) {
      case "download-zipped-media":
        return downloadLink;
      case "create-zipped-media" :   return createZippedMediaMutate(data);
      default:
        return () =>
          console.log(
            "requested operation is not available. Available options: signin | signup | forgot-password | reset-password"
          );
    }
  };

  return [
    revisionRequest,
    requestType === "download-zipped-media"
      ? downloadZippedMediaStatus
      : requestType === "create-zipped-media"
      ? createZippedMediaStatus
      : "",
    downloadZippedMediaStatus,
    downloadLink
  ];
}