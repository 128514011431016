import { Box, Link, Typography } from "@mui/material"
import CustomButton from "../../../components/CustomButton"
import CustomCard from "./CustomCard"
import editImage from "../../../assets/edit.svg";
import "@fontsource/rubik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import EditImage from "./assets/EditImage";

const EditingStartedCard = (props) => {

  const {message, note} = props
  const commonReducer = useSelector((state) => state.root.commonReducer);
    const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
    const navigate = useNavigate();
  // console.log(guestUploadsLink)
  


  return (
    <CustomCard  style={{height:'100%', }} >
    <Box sx={{display:'flex', height:'100%', width:'100%'}}>
        <Box  gap={{xs:0,md:1}} className= 'flex col j-center a-start' sx={{ paddingLeft:{xs:'10px',sm:'20px',md:'30px',lg:'34px',xl:'44px'}, paddingTop:{xs:'12px',sm:'16px',md:'20px',lg:'27px', xl:'29px'}, paddingBottom:{xs:'12px',sm:'16px',md:'18px',lg:'25px', xl:'20px'},height:'100%' ,  width:'80%'}}>

          <Box >
            <Typography sx={{fontSize:{xs:'12px',sm:'13px',md:'14px',lg:'17px',xl:'20px'}, fontWeight:600,letterSpacing:0, fontFamily:'Rubik'}}>{message}</Typography>
          </Box>

          <Box  sx={{display:'flex', alignItems:'center', justifyContent:'start',paddingBottom:{xl:'0.5rem'},  width:'100%', wordWrap:'break-word'}}>
            <Typography sx={{fontSize:{xs:'8px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600,letterSpacing:0, fontFamily:'Rubik'}}>Note: <Typography component='span' sx={{fontSize:{xs:'8px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight: 300, ml: 0.5}}>{note}</Typography></Typography>
             
            
           
        </Box>
          {
            commonReducer.event_details?.revision?.submitted == true && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id && 
            <CustomButton 
            btnText='Watch Now'
            onClick={()=> navigate('/completedevent', {replace: true})}    
            type='button'
            color='secondary'
            variant='contained'
            style={{
            borderRadius: '5px',
            border: "none",          
            textTransform:'none',
            // backgroundColor:theme.palette.secondary.main
            }}
            sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
          />
          }
         
        </Box>
        <Box  sx={{ width:'20%'}}>    
                        
            <EditImage sx={{height: '100%', width: '100%'}} />          
          {/* <img src={editImage} alt='edit' height='100%' width='100%' />  */}
                
        </Box>
        </Box>
    </CustomCard>
  )
}

export default EditingStartedCard