import { Box, Typography } from "@mui/material"
import CustomCard from "./CustomCard"
import luciLogo from "../../../assets/luciLogo2.png"
import poweredBy from "../../../assets/poweredBy.svg"
// import { customTheme as theme } from "../../../theme/customTheme"
import { makeStyles } from "@mui/styles";
import {   timeStampToDateTime, timeStampToDayString, timeStampToFormatTimeZone,  } from "../../../utils/commonServices";
import "@fontsource/rubik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import usePermissions from "../../../hooks/usePermissions";
import { PERMISSION } from "../../../constants/constants";
import { updateCreationState } from "../../../redux/eventCreationSlice";
import CustomButton from "../../../components/CustomButton";
import { updateState } from "../../../redux/commonSlice";
import CustomTooltip from "../../../components/CustomTooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useCustomTheme from "../../../theme/useCustomTheme";
import { updateProjectState } from "../../../redux/projectSlice";

const useStyles = makeStyles((theme) => ({
 fontWeightNormal:{
  fontWeight: 'normal'
 }, 
fontWeight:{
  fontWeight:600
},
rubik:{
  fontFamily:'Rubik'
}

}));

const EventDetailCard = (props) => {

  const commonReducer = useSelector((state)=> state.root.commonReducer)
  // const event = commonReducer.events?.find((event) => event.id == commonReducer.production_event_id)
  const themeReducer = useSelector((state) => state.root.themeReducer);
  const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [hasPermission] = usePermissions()
  const [theme ] = useCustomTheme()

  const {event} = props

  // const brandLogo = window.localStorage.getItem('brand_logo_url')
  const brandLogo = themeReducer?.brand_logo

  const classes = useStyles();
  const setPageData = ()=> {
    dispatch(updateCreationState({
        pageData: {
            name : event?.name,
            start_date: event?.start_date,
            end_date: event?.end_date,
            editor_start_date: event?.editor_start_date,
            video_orientation: event?.video_orientation, // landscape | portrait
            song_type: commonReducer?.event_details?.song_type,
            description: event?.description,
           // quality_options: [1],
            event_type_id: commonReducer?.event_details?.event_type?.id,
            video_length_id: dropdownReducer.video_length.find((length)=> length.value == event.video_length)?.id,
        }
    }))
}

  const editProjectClickHandler =()=>{
    if(event?.status_type_order < 3){
        setPageData()
       dispatch(updateCreationState({open_form: true, edit: true, event_id: event?.id}))
      }else{
         
      dispatch(updateCreationState({edit_decline_popup:true}))
      }
  }

  const openDocumentClickHandler = async ()=>{

      const documentUrl = commonReducer?.event_details?.note_file_url; 
      window.open(documentUrl, '_blank');
  }


  return (
    <CustomCard className='rubik' style={{ height:'100%', padding:0 , '& .MuiCardContent-root:last-child':{paddingBottom:0}}} >
      <Box className="rubik" sx={{height:'100%', display:'flex', flexDirection:'column', paddingBottom:{xs:'24px',sm:'24px',md:'24px',lg:'25px',xl:'40px'} }}>
        <Box className="rubik flex j-center a-center" sx={{ width: '100%', marginTop:{xs:'25px',sm:'20px',md:'7%', lg: '7%',xl:'8%'}, marginBottom:{xs:'25px',sm:'20px',md:'3%',lg:'3%',xl:'4%'},}} >
          <Box  width='35%'>
           <img src={brandLogo !== null ? brandLogo : luciLogo}  onClick={()=>navigate('/dashboard', {replace: true})} style={{cursor:'pointer', maxWidth:'70px', maxHeight:'35px'}} />
           {/* {
                brandLogo !== null && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
            } */}
            </Box>
           {/* {(hasPermission(PERMISSION.PROJECT.INFO.EDIT) && (event?.status_type_order < 3)) && <EditOutlinedIcon onClick={editProjectClickHandler}  className="flex" sx={{marginLeft:'10px', alignSelf:'flex-end', cursor:'pointer'}}/>} */}
        </Box>

        <Box mt={1} className="rubik" sx={{display:'flex', flexDirection:'column', fontSize: {xs:'10px',md:'12px',lg:'14px',xl:'16px'},  marginRight:{md:'10px',lg:'0.52vw',xl:'0.78vw'}, overflowY:'auto'}}>
          <Box mb={{xs:0.5,md:0.8,   xl:1.3}} sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography sx={{fontWeight:'normal',  fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Lucivideo Name</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px', wordWrap: 'break-word'}, fontFamily:'Rubik'}}>{commonReducer?.event_details?.name}</Typography>
          </Box>
          <Box mb={{xs:0.5, md:0.8,  xl:1.3}} sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Status</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{commonReducer?.event_details?.status_type}</Typography>
          </Box>
          <Box mb={{xs:0.5, md:0.8,  xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Event Type</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{commonReducer?.event_details?.event_type?.name}</Typography>
          </Box>
          <Box mb={{xs:0.5,md:0.8,   xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Creation Date</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{timeStampToDayString(commonReducer?.event_details?.start_date)}, {timeStampToFormatTimeZone(commonReducer?.event_details?.start_date)}</Typography>
          </Box>

                    <Box mb={{xs:0.5,md:0.8,   xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Edit Start Date</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{timeStampToDayString(commonReducer?.event_details?.editor_start_date)}, {timeStampToFormatTimeZone(commonReducer?.event_details?.editor_start_date)}</Typography>
          </Box>
          {/* <Box mb={2}>
            <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>End Date</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{timeStampToDayString(commonReducer?.event_details?.end_date)}, { timeStampToFormatTimeZone(commonReducer?.event_details?.end_date)}</Typography>
          </Box> */}
{(hasPermission(PERMISSION.PROJECT.INFO.EDIT)  || ((commonReducer?.event_details?.status_type_order < 3 || ( commonReducer.event_details?.revision?.submitted == false && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id)) ) ) &&
          <Box mb={{xs:0.5,md:0.8, xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>

          <Box className="flex a-center" gap={{xs:0.5, xl:1}}>
              <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>My Music</Typography>
                   <CustomTooltip title={'View music notes.'}  PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [-35, -4],
                                },
                              },
                            ],
                          }} >
                      <InfoOutlinedIcon onClick={()=> dispatch(updateProjectState({song_desc_popup : true}))} sx={{fontSize: {xs:'9px !important',md:'9px !important',lg:'10.67px !important', xl:'16px !important'}, color:'#08231B', cursor:'pointer'}}/>
                  </CustomTooltip>
            </Box>


            {/* <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>My Music</Typography> */}


             <Typography onClick={()=> dispatch(updateProjectState({upload_song_popup : true}))} sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', cursor:'pointer', color:theme.palette.secondary.main}}>Music Files</Typography>
          </Box>}

         { (hasPermission(PERMISSION.PROJECT.INFO.EDIT)  || commonReducer?.event_details?.description ) && <Box mb={{xs:0.5, md:0.8, xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Box className="flex a-center" gap={{xs:0.5, xl:1}}>
              <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Notes</Typography>
                  { <CustomTooltip title={'View project notes.'}  PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [-35, -4],
                                },
                              },
                            ],
                          }} >
                      <InfoOutlinedIcon onClick={()=> dispatch(updateProjectState({view_notes_to_editor_popup : true}))} sx={{fontSize: {xs:'9px !important',md:'9px !important',lg:'10.67px !important', xl:'16px !important'}, color:'#08231B', cursor:'pointer'}}/>
                  </CustomTooltip>}
            </Box>
{/* 
           {commonReducer?.event_details?.description && <Typography onClick={()=> dispatch(updateProjectState({view_notes_to_editor_popup : true}))} sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', cursor:'pointer', color:theme.palette.secondary.main}}>View Notes</Typography>} */}

           { (hasPermission(PERMISSION.PROJECT.INFO.EDIT)  || ((commonReducer?.event_details?.status_type_order < 3 || ( commonReducer.event_details?.revision?.submitted == false && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id)) ) ) &&  <Typography onClick={()=> dispatch(updateProjectState({upload_documents_to_editor_popup : true}))} sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', cursor:'pointer', color:theme.palette.secondary.main}}>My Documents</Typography>}

            {/* {
              commonReducer?.event_details?.note_file_url && <Typography onClick={openDocumentClickHandler} sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', cursor:'pointer', color:theme.palette.secondary.main}}>Open document</Typography>
            } */}
          </Box>}
          




        </Box>
        { ( hasPermission(PERMISSION.PROJECT.INFO.EDIT) &&  (commonReducer?.event_details?.status_type_order < 3) ) && 
          <Box className='flex col j-end a-center'  mt={{xs:1, md:0,lg:0.5,xl:2}}>
            <CustomButton 
              btnText='Edit Project'
              onClick={editProjectClickHandler}
              type='button'    
              color='secondary'
              variant='contained'
              style={{
                borderRadius: '5px',
                border: "none",
                textTransform:'none',
                // backgroundColor:theme.palette.secondary.main,
                }}
              endIcon={<EditOutlinedIcon style={{fontSize:'14px'}} />}
              sx={{fontSize:{xs:'8px',sm:'9px',lg:'11px',xl:'16px'},  fontWeight:600, height:{xs:'18px',sm:'18px',lg:'22px',xl:"36px"}, width: '100%', maxWidth: {xs:'120px', xl:'180px'} , fontFamily:'Rubik'}}
            />
            </Box> }
      </Box>
    </CustomCard>
  )
}

export default EventDetailCard