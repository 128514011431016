import { Box, Typography } from '@mui/material'
import filmingCard from '../../../assets/filmingTutorialCard.png'
import React from 'react'
import "@fontsource/rubik";

const RightComponent = () => {
  return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', }}  pl={{xs:2,sm:4,md:8,lg: 10,xl:12}}>
        <Box sx={{height:{xs:100,sm:120,md:150,lg:'163px',xl:245}, marginBottom:{xs:'15px', sm:'15px',md:'20px',lg:'22.66px',xl:'34px'}}}>
             <img src={filmingCard} alt="filmingCard" height='100%' style={{objectFit:'cover', borderRadius:'10px'}} />
        </Box>
        <Box textAlign='center' 
        px={{xs:3,sm:5,md:7,lg:9,xl:10}}
        >
            <Typography sx={{fontSize:{xs:'12px',md:'14px',lg:'15px',xl:'22px'}, fontWeight:600, fontFamily:'Rubik', color:'#08231B', letterSpacing:'0px'}}>Coming soon</Typography>
            <Typography sx={{fontSize:{xs:'18px',md:'20px',lg:'25px',xl:'37px'}, fontWeight:800, fontFamily:'Rubik', color:'#08231B', letterSpacing:'0px'}}>Lucihub Academy</Typography>
            <Typography sx={{fontSize:{xs:'10px',md:'12px',lg:'13px',xl:'20px'}, fontWeight:'normal', fontFamily:'Rubik', color:'#08231B', letterSpacing:'0px'}}>Members will soon get premiere access to a video library with tutorials and trainings by Lucihub video experts.</Typography>
        </Box>
    </Box>
  )
}

export default RightComponent