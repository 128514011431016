import { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';


const CustomSortOptions = (props) => {

   const {   selectedSortbyValue, setSelectedSortbyValue, sortByOptions, menuTitle} = props
   const [anchorEl, setAnchorEl] = useState(null);
   //   const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
   
     const handleClick = (event) => {
       setAnchorEl(event.currentTarget)
     }
   
     const handleOptionClick = (event, value) => {
       setSelectedSortbyValue(selectedSortbyValue === value ? null : value)
      //  onChange(selectedSortbyValue === value ? null : value)
     }
   
     const handleClose = () => {
       setAnchorEl(null);
     }

    return (
      <>
        <Box onClick={handleClick}  sx={{display:'flex', alignItems:'center', justifyContent:"center"}}>
             <Typography sx={{fontSize:{xs:'8px',sm:'8px',md:'11px',lg:'13px',xl:'17px'}, fontWeight:600, fontFamily:'Rubik'}}>Sort By</Typography>
              <IconButton>
                  <KeyboardArrowDownOutlinedIcon sx={{color:'black',fontSize:{xs:'8px',sm:'8px',md:'11px',lg:'13px',xl:'17px'}}}/>
              </IconButton>  
          </Box>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} 
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: 'right',
             }}
        >
          {sortByOptions.map((option) => (
            <MenuItem 
              key={option.value} 
              sx={{height:{xs:'20px',sm:'22px',md:'24px',lg:'27px', xl:'34px'}}}
              >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSortbyValue === option.value}
                    onChange={(event) => handleOptionClick(event, option.value)}
                    size='small'
                    sx={{ '& .MuiSvgIcon-root': { fontSize:{xs:'8px',sm:'8px',md:'10px',lg:'10px',xl:'13px'}, fontWeight: 600} }}
                  />
                }
        
                label={<Typography sx={{fontSize:{xs:'8px',sm:'8px',md:'10px',lg:'10px',xl:'13px', fontWeight: 600}}}>{option.label}</Typography>}
              />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };


 

export default CustomSortOptions