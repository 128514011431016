import React from "react";
import { Box, SvgIcon } from "@mui/material";
import useCustomTheme from "../../../../theme/useCustomTheme";

const EditImage = (props)=> {
    const [theme] = useCustomTheme()
    return <SvgIcon 
            {...props} 
            xmlns="http://www.w3.org/2000/svg"  
          //  width="136.971" height="193" 
            viewBox="0 0 136.971 193">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_150" data-name="Rectangle 150" width="136.971" height="193" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_206" data-name="Group 206" transform="translate(0 0.004)">
      <g id="Group_205" data-name="Group 205" transform="translate(0 -0.004)" clip-path="url(#clip-path)">
        <path id="Path_118" data-name="Path 118" d="M269.822,152.111a6.583,6.583,0,0,0-.4,1.147,24.866,24.866,0,0,1-49.457-3.325,36.73,36.73,0,0,1,1.3-8.267,2.469,2.469,0,0,0-.7-2.756c-1.213-1.157-2.284-2.464-3.557-3.863-3.216,3.2-6.221,6.115-9.106,9.144-.429.45-.406,1.436-.406,2.174q-.033,22.013-.016,44.026v2.6h-6.2V150.337c-4.072,1.942-8.037,3.776-11.924,5.766-.438.224-.594,1.328-.6,2.025-.036,10.743-.025,21.486-.025,32.229v2.618h-6.107v-18.42H157.577V193h-5.991V.018h5.946V18.461h24.9V.072h6.273V135.094l.537.248c2.041-2.072,3.878-4.411,6.165-6.162,4.755-3.638,6.012-8.127,5.964-14.107-.3-37.493-.152-74.99-.152-112.486V0h6.259V2.758q0,48.239-.033,96.478a4.812,4.812,0,0,0,1.6,3.852c2.078,1.973,4,4.111,6.212,6.413,2.066-2.167,3.953-4.045,5.691-6.051a2.5,2.5,0,0,0,.309-2.023c-4.864-16.191,5.22-31.374,21.9-32.91,12.324-1.134,24.25,8.279,26.221,20.686.1.614.294,1.212.444,1.817v4.634a6.977,6.977,0,0,0-.369,1.181,24.9,24.9,0,0,1-32.726,20.117,2.146,2.146,0,0,0-1.718.08c-1.625,1.283-3.136,2.712-4.862,4.243,1.663,1.567,2.913,2.6,3.983,3.8a2.464,2.464,0,0,0,3.067.766,24.552,24.552,0,0,1,30.077,13.064c1.184,2.562,1.716,5.426,2.549,8.15ZM157.794,118.234h24.565V74.7H157.794Zm-.078-49.951H182.4V24.9H157.717Zm.019,56.409v43.336H182.4v-9.875c-2.867.237-5.577.464-8.288.683-1.672.135-3.082-.283-3.788-1.976-.68-1.631.119-2.81,1.23-3.913,3.186-3.16,6.3-6.392,9.548-9.489a4.5,4.5,0,0,0,1.49-3.687c-.09-4.277-.026-8.558-.037-12.837,0-.734-.076-1.468-.119-2.243Zm67.106-15.875-43.066,42.259.469.47a38.489,38.489,0,0,0,19.126-9.889c9.365-9.162,18.578-18.479,27.848-27.738.187-.187.3-.448.225-.331l-4.6-4.771M263.5,93.473a18.7,18.7,0,1,0-18.833,18.607A18.8,18.8,0,0,0,263.5,93.473m-18.629,74.691a18.692,18.692,0,1,0-18.759-18.723,18.689,18.689,0,0,0,18.759,18.723m-20.239-34.021,4.808-4.64-3.236-3.13-4.937,4.446,3.365,3.324M207.641,110.9v5.373l2.931-2.457-2.931-2.916" transform="translate(-132.851 0.004)" fill="#08231b"/>
        <rect id="Rectangle_133" data-name="Rectangle 133" width="5.991" height="192.9" transform="translate(0 0.054)" fill="#08231b"/>
        <rect id="Rectangle_134" data-name="Rectangle 134" width="5.945" height="6.084" transform="translate(59.172 74.73)" fill="#08231b"/>
        <path id="Path_119" data-name="Path 119" d="M478.747,201.791h5.92a7.126,7.126,0,0,1,.176.892c.321,5.463-.42,6.075-6.1,4.956Z" transform="translate(-419.576 -176.85)" fill="#08231b"/>
        <rect id="Rectangle_135" data-name="Rectangle 135" width="5.919" height="6.065" transform="translate(9.305 74.736)" fill="#08231b"/>
        <path id="Path_120" data-name="Path 120" d="M79.155,712.431a5.982,5.982,0,0,1-.773.164c-5.683.289-5.98-.056-5.086-6.071h5.86Z" transform="translate(-63.902 -619.201)" fill="#08231b"/>
        <path id="Path_121" data-name="Path 121" d="M478.636,712.582V706.81h5.953a6.719,6.719,0,0,1,.157.84c.305,5.441-.476,6.083-6.11,4.931" transform="translate(-419.479 -619.451)" fill="#08231b"/>
        <path id="Path_122" data-name="Path 122" d="M80.256,307.445h-6.09c0-1.749-.059-3.411.045-5.062a1.43,1.43,0,0,1,.952-1.009c1.653-.108,3.317-.048,5.093-.048Z" transform="translate(-64.985 -264.069)" fill="#08231b"/>
        <path id="Path_123" data-name="Path 123" d="M74.17,811.9c0-1.739-.059-3.4.046-5.054a1.429,1.429,0,0,1,.964-1c1.652-.107,3.316-.047,5.086-.047v6.1Z" transform="translate(-64.989 -706.198)" fill="#08231b"/>
        <path id="Path_124" data-name="Path 124" d="M478.57,811.839V805.8c1.737,0,3.449-.072,5.147.058.357.027.9.779.938,1.23.118,1.52.045,3.055.045,4.753Z" transform="translate(-419.421 -706.187)" fill="#08231b"/>
        <rect id="Rectangle_136" data-name="Rectangle 136" width="5.934" height="6.08" transform="translate(59.219 174.55)" fill="#08231b"/>
        <rect id="Rectangle_137" data-name="Rectangle 137" width="5.958" height="6.016" transform="translate(9.328 112.198)" fill="#08231b"/>
        <rect id="Rectangle_138" data-name="Rectangle 138" width="5.887" height="6.031" transform="translate(59.245 112.22)" fill="#08231b"/>
        <path id="Path_125" data-name="Path 125" d="M74.086,201.495h6.07v6.152c-1.641,0-3.295.073-4.934-.052a1.591,1.591,0,0,1-1.084-1.091c-.127-1.587-.052-3.19-.052-5.009" transform="translate(-64.91 -176.591)" fill="#08231b"/>
        <rect id="Rectangle_139" data-name="Rectangle 139" width="5.99" height="5.989" transform="translate(59.158 49.849)" fill="#08231b"/>
        <rect id="Rectangle_140" data-name="Rectangle 140" width="5.904" height="6.014" transform="translate(9.318 49.898)" fill="#08231b"/>
        <rect id="Rectangle_141" data-name="Rectangle 141" width="5.919" height="6.065" transform="translate(9.305 137.089)" fill="#08231b"/>
        <path id="Path_126" data-name="Path 126" d="M485.23,307.436h-6.055v-6.126c1.661,0,3.373-.072,5.07.058.357.028.9.778.94,1.229.118,1.52.045,3.054.045,4.839" transform="translate(-419.951 -264.051)" fill="#08231b"/>
        <path id="Path_127" data-name="Path 127" d="M80.077,1217.318c-1.681,0-3.282.091-4.859-.06a1.758,1.758,0,0,1-1.139-1.241c-.146-1.515-.055-3.052-.055-4.751h6.053Z" transform="translate(-64.85 -1061.559)" fill="#08231b"/>
        <rect id="Rectangle_142" data-name="Rectangle 142" width="5.976" height="6.006" transform="translate(59.172 12.413)" fill="#08231b"/>
        <rect id="Rectangle_143" data-name="Rectangle 143" width="5.945" height="5.936" transform="translate(9.293 12.452)" fill="#08231b"/>
        <path id="Path_128" data-name="Path 128" d="M80.167,1316.319H74.1c0-1.722-.073-3.38.052-5.024a1.591,1.591,0,0,1,1.1-1.076c1.584-.126,3.185-.052,4.92-.052Z" transform="translate(-64.92 -1148.218)" fill="#08231b"/>
        <path id="Path_129" data-name="Path 129" d="M478.615,1316.348v-6.1c1.749,0,3.41-.059,5.061.045a1.431,1.431,0,0,1,1,.957c.107,1.656.048,3.322.048,5.1Z" transform="translate(-419.461 -1148.292)" fill="#08231b"/>
        <rect id="Rectangle_144" data-name="Rectangle 144" width="5.964" height="5.954" transform="translate(9.326 174.615)" fill="#08231b"/>
        <rect id="Rectangle_145" data-name="Rectangle 145" width="5.991" height="5.926" transform="translate(59.151 62.386)" fill="#08231b"/>
        <rect id="Rectangle_146" data-name="Rectangle 146" width="5.809" height="5.911" transform="translate(9.416 62.352)" fill="#08231b"/>
        <rect id="Rectangle_147" data-name="Rectangle 147" width="5.987" height="5.923" transform="translate(9.312 124.695)" fill="#08231b"/>
        <rect id="Rectangle_148" data-name="Rectangle 148" width="24.565" height="43.538" transform="translate(24.944 74.7)" fill={theme.palette.secondary.main}/>
        <rect id="Rectangle_149" data-name="Rectangle 149" width="24.684" height="43.386" transform="translate(24.866 24.901)" fill={theme.palette.primary.main}/>
        <path id="Path_130" data-name="Path 130" d="M201.342,1008.906h24.7c.043.774.117,1.508.119,2.243.011,4.279-.053,8.56.037,12.837a4.494,4.494,0,0,1-1.49,3.687c-3.245,3.1-6.362,6.329-9.548,9.489-1.111,1.1-1.91,2.282-1.23,3.913.706,1.693,2.116,2.111,3.788,1.976,2.711-.218,5.421-.446,8.288-.683v9.875H201.342Z" transform="translate(-176.457 -884.21)" fill={theme.palette.primary.main}/>
        <path id="Path_131" data-name="Path 131" d="M780.332,645.291a15.55,15.55,0,0,1,31.1,0,15.551,15.551,0,1,1-31.1,0m15.608,9.427a9.369,9.369,0,1,0-9.434-9.343,9.5,9.5,0,0,0,9.434,9.343" transform="translate(-683.887 -551.959)" fill="#08231b"/>
        <path id="Path_132" data-name="Path 132" d="M796.04,1083.833a15.579,15.579,0,1,1-15.674,15.34,15.568,15.568,0,0,1,15.674-15.34m-.311,24.893a9.439,9.439,0,0,0,9.519-9.243,9.356,9.356,0,1,0-9.519,9.243" transform="translate(-683.916 -949.876)" fill="#08231b"/>
      </g>
    </g>
  </SvgIcon>
    
  
}
export default EditImage