import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetState, updateState } from "../redux/commonSlice";
import { addEvent, cancelEvent, createEvent, editEvent } from "../services/api";
import useSnackMessage from "./useSnackMessage";
import { resetCreationState, updateCreationState } from "../redux/eventCreationSlice";
import { gaEvents } from "../ga/actions";
import { setSnackbar } from "../redux/messageSlice";
import { viewProjectMembers } from "../pages/events/actions";
import { getUserRoles } from "../commonactions/actions";
import { getAllEvents } from "../pages/dashboard/actions";
import { getTeamEvents } from "../pages/teamsdashboard/actions";

export default (requestType, afterSuccessCallback) => {
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const permissionsReducer = useSelector((state) => state.permissionsReducer);
  const teamsReducer = useSelector((state) => state.root.teamsReducer);
  const [createEventStatus, setCreateEventStatus] = useState("");
  const [addEventStatus, setAddEventStatus] = useState("");
  const [editEventStatus, setEditEventStatus] = useState("");
  const [cancelEventStatus, setCancelEventStatus] = useState("");
  const [eventId, setEventId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar] = useSnackMessage();

  const updateCancelledProduction = () => {
    const temp = commonReducer.production_events?.map((event) => {
      if(event.id == commonReducer.production_event_id) {
        return {...event, is_cancelled: true}
      } else {
        return event
      }
    })
    const temp2 = commonReducer.ongoing_events?.map((event) => {
      if(event.id == commonReducer.ongoing_event_id) {
        return {...event, is_cancelled: true}
      } else {
        return event
      }
    })
    const temp3 = commonReducer.upcoming_events?.map((event) => {
      if(event.id == commonReducer.upcoming_event_id) {
        return {...event, is_cancelled: true}
      } else {
        return event
      }
    })
    dispatch(updateState({ production_events: temp, ongoing_events: temp2, upcoming_events: temp3, production_event_id: null, ongoing_event_id: null, upcoming_event_id: null }));
   
  }

  //POST API Request to create new event
  const { mutate: createEventMutate, isLoading: createEventLoading } =
    useMutation(createEvent, {
      onSuccess: (response, variables, context) =>
        createEventSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
        createEventErrorHandler(error, variables, context),
    });

  const createEventSuccessHandler = async (response, variables, context) => {
    const responseObject = response?.data;
    const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {
      // await afterSuccessCallback();
      dispatch(updateCreationState({ create_project_backdrop_loader: false}))
      //console.log("event created", commonReducer.events.find((e)=> e.id == responseData?.id));
      dispatch(updateState({ production_event_id: responseData?.id }));
      !!teamsReducer.selected_team ? getTeamEvents({ team_id: teamsReducer?.selected_team?.id}, dispatch) : getAllEvents(dispatch, navigate, {commonReducer});

      dispatch(updateState({selected_event: commonReducer.events.find((e)=> e.id == responseData?.id) }))
      dispatch(resetCreationState());
      navigate('/productionevent')
     // getUserRoles(dispatch, navigate, permissionsReducer, {event_id: responseData?.id})
      viewProjectMembers({event_id: responseData?.id}, dispatch, navigate, {commonReducer})

     showSnackbar(responseObject);
      setCreateEventStatus("success");
      gaEvents.eventCreated()
    }
  };

  const createEventErrorHandler = (error, variables, context) => {
    // setSubmitFlag(false);
    setCreateEventStatus("error");
    gaEvents.failedEventCreaton()
    const errorObject = error?.response?.data;
    // showSnackbar(errorObject)
    dispatch(updateCreationState({ create_project_backdrop_loader: false}))
    dispatch(setSnackbar({flag:true, message: 'Some error occured in creating event', type:'error'}))
    dispatch(resetCreationState())
  };

   //////////////////////POST API Request to add existing event/////////////////////////////////////////////////////////////

   const { mutate: addEventMutate, isLoading: addEventLoading } = useMutation(
    addEvent,
    {
      onSuccess: (response, variables, context) =>
        addEventSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
        addEventErrorHandler(error, variables, context),
    }
  );

  const addEventSuccessHandler = (response, variables, context) => {
    const responseObject = response?.data;
    const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {
      afterSuccessCallback()
      setAddEventStatus("success");
    //  showSnackbar(responseObject);
      dispatch(updateState({ production_event_id: responseData?.id}));
    }
   
  };

  const addEventErrorHandler = (error, variables, context) => {
    const errorObject = error?.response?.data;
   showSnackbar(errorObject)
    dispatch(updateState({ add_event_open: false }));
      setAddEventStatus("error");
      //  console.log("error", data)
    
  };


  //PUT API Request to edit event
  const { mutate: editEventMutate, isLoading: editEventLoading } =
    useMutation(editEvent, {
      onSuccess: (response, variables, context) =>
        editEventSuccessHandler(response, variables, context),
      onError: (error, variables, context) =>
        editEventErrorHandler(error, variables, context),
    });

  const editEventSuccessHandler = async (response, variables, context) => {
    const responseObject = response?.data;
    const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {
      await afterSuccessCallback();
      dispatch(updateCreationState({ create_project_backdrop_loader: false}))
      dispatch(updateState({ production_event_id: responseData?.id }));
      !!teamsReducer.selected_team ? getTeamEvents({ team_id: teamsReducer?.selected_team?.id}, dispatch) : getAllEvents(dispatch, navigate, {commonReducer});
      dispatch(updateState({selected_event: commonReducer.events.find((e)=> e.id == responseData?.id) }))
      dispatch(resetCreationState());
      navigate('/productionevent', {replace: true})

      dispatch(updateCreationState({extend_edit_date_popup : false}))
      setEditEventStatus("success");
      showSnackbar(responseObject);
    }
  };

  const editEventErrorHandler = (error, variables, context) => {
    const errorObject = error?.response?.data;
    // showSnackbar(errorObject)
    dispatch(updateCreationState({ create_project_backdrop_loader: false}))
    dispatch(setSnackbar({flag:true, message: 'Some error occured in updating the event', type:'error'}))
    dispatch(resetCreationState())
    setCreateEventStatus("error");
    // setSubmitFlag(false);
    // if (data) {
    //   if (data?.response?.status == 401) {
    //     window.localStorage.removeItem("token");
    //     dispatch(resetState());
    //   }
    //   setSnackbarProps({
    //     type: "error",
    //     flag: true,
    //     message: data?.response?.data?.error,
    //   });
    //   setEditEventStatus("error");
    //   //  console.log("error", data)
    // }
  };

   //PUT API Request to cancel event
   const { mutate: cancelEventMutate, isLoading: cancelEventLoading } =
   useMutation(cancelEvent, {
     onSuccess: (response, variables, context) =>
       cancelEventSuccessHandler(response, variables, context),
     onError: (error, variables, context) =>
       cancelEventErrorHandler(error, variables, context),
   });

 const cancelEventSuccessHandler = (response, variables, context) => {
   // console.log("event id", data?.data)
   const responseObject = response?.data;
   console.log(response)
   showSnackbar(responseObject)
   // const responseData = responseObject?.data
    if (responseObject?.http_code == 200) {
      updateCancelledProduction()
    //  showSnackbar(responseObject)
      setCancelEventStatus("success");
     // navigate("/dashboard", {replace: true});
    }
  
    
 };

 const cancelEventErrorHandler = (error, variables, context) => {
   // setSubmitFlag(false);
   const errorObject = error?.response?.data;
  showSnackbar(errorObject)
   setCancelEventStatus("error");
  //  if (data) {
  //    if (data?.response?.status == 401) {
  //      window.localStorage.removeItem("token");
  //      dispatch(resetState());
  //    }
  //    setSnackbarProps({
  //      type: "error",
  //      flag: true,
  //      message: data?.response?.data?.error,
  //    });
  //    setEditEventStatus("error");
  //    //  console.log("error", data)
  //  }
 };


  useEffect(() => {
    if (createEventLoading) {
      setCreateEventStatus("loading");
    }
    if (editEventLoading) {
      setEditEventStatus("loading");
    }
    if (cancelEventLoading) {
      setCancelEventStatus("loading");
    }
    if (addEventLoading) {
      setAddEventStatus("loading");
    }

    // return ()=>{ setStatus("")}
  }, [createEventLoading, editEventLoading, cancelEventLoading, addEventLoading]);

  //Functions to request API as per incoming mutation type
  const authRequest = (data) => {
    switch (requestType) {
      case "create":
        return createEventMutate(data);
      case "edit" :   return editEventMutate(data);
      case "cancel" : return cancelEventMutate(data) ;
      case "add": return addEventMutate(data);
      // case "verify-mail": return verifyEmail(data) ;
      // case "verify-token": return checkVerifyToken(data) ;
      // case "reset-password" : return resetPasswordMutate(data) ;
      default:
        return () =>
          console.log(
            "requested operation is not available. Available options: signin | signup | forgot-password | reset-password"
          );
    }
  };

  return [
    authRequest,
    requestType == "create" ? createEventStatus :
      requestType == 'edit' ? editEventStatus : 
        requestType == 'cancel' ? cancelEventStatus: requestType == "add" ? addEventStatus : "",
    eventId,
  ];
};
