import React, { useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
// import { makeStyles } from "@mui/styles";
import { Box, Grid, Button, Typography } from '@mui/material'
//import { updateState } from "../../../redux/selectedFilesSlice";
import { useDispatch, useSelector } from "react-redux";
// import { customTheme as theme } from '../theme/customTheme';
import File from '../assets/file.svg'
import FileIcon from './icons/FileIcon';
import "@fontsource/rubik";
import { updateState } from '../redux/commonSlice';
import AgreementPopup from '../pages/events/components/AgreementPopup';
import CustomAutocomplete from '../pages/eventcreation/components/CustomAutoComplete';
import useUploadedList from '../hooks/useUploadedList';
import { useQuery } from 'react-query';
import { uploadList } from '../services/api';
import { removeDuplicateArrayItem } from '../utils/commonServices';
import UploadFromProjectPopup from '../pages/events/components/UploadFromProjectPopup';
import { setAllMediaFilesArray } from '../redux/mediaFilesSlice';
import usePermissions from '../hooks/usePermissions';
import { PERMISSION } from '../constants/constants';
import useCustomTheme from '../theme/useCustomTheme';


const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


const CustomDropBox = (props) => {

    const [tempFiles, setTempFiles] = useState([]);
    const dispatch = useDispatch()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [isDropped, setIsDropped] = useState(false);
    // const listOfAllEvents = commonReducer?.events?.map(({id, name, owner_name})=> ({id, name, owner_name}))
    const [selectedEventId, setSelectedEventId] = useState(null)
    const [selectedProjectName, setSelectedProjectName] = useState('')
    const [uploadedVideoList, setUploadedVideoList] = useState([]);
    // const [uploadedVideos, refetchList] = useUploadedList(true, selectedEventId);
    const [enableUploadList, setEnableUploadList] = useState(false);
    const [hasPermission] = usePermissions()
    const [theme ] = useCustomTheme()
    // const {
    //     data: uploadListData,
    //     error: uploadListError,
    //     isLoading: uploadListLoading,
    //     refetch: refetchUploadList,
    //   } = useQuery(
    //     [
    //       `uploadList${selectedEventId}`,
    //     // commonReducer.production_event_id
    //     selectedEventId
    //     ],
    //     () => uploadList({ id: selectedEventId }),
    //     { enabled: enableUploadList, retry: false }
    //   );


    //   useEffect(() => {
    //     if (uploadListData) {
    //       const responseObject = uploadListData?.data;
    //     const responseData = responseObject?.data
    //       setEnableUploadList(false);
    //       responseData?.media_files?.length > 0 ? setUploadedVideoList([...removeDuplicateArrayItem(responseData?.media_files)]) : setUploadedVideoList([]);
    //       dispatch(updateState({open_previous_project_media_popup : true}))
    //     }
    //   }, [uploadListData]);

    const {
        height,
        //width,
        setFiles,
        setProgress,
        setUploadStatus,
        isDisabled = true,
        setRequestController,
        eventData,
        setEventData,
        refetch,
        refetchEvent,
        listOfAllEvents
    } = props;

    const baseStyle = {
        // height:'20rem',
        // width:'30rem',
        // flex: 1,
       // height: height,
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2em",
        borderWidth: 3,
        borderRadius: 2,
        boxShadow: '0px 5px 15px #00000029',
        borderColor: "#FFFFFF",
        borderStyle: "dashed",
        backgroundColor: "#e6e6e6b3",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    };

    const {
        open,
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        disabled: isDisabled,
        accept: {
            "image/*": [],
            "video/*": [],
            "audio/*": []
        },

        onDrop: (acceptedFiles) => {
            setIsDropped(true)
            if(commonReducer.event_details.consent_accepted == false){
            // if(eventData.consentStatus == false){
                dispatch(updateState({  open_agreement_popup : true }))
            }else{
                dispatch(updateState({  open_agreement_popup : false }))
                setTempFiles(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                );
            }
         
        },
    });

    useEffect(() => {
        setRequestController(
            tempFiles?.map(() => {
                return new AbortController();
            })
        );
        setProgress(
            tempFiles?.map(() => {
                return 0;
            })
        );
        setUploadStatus(
            tempFiles?.map(() => {
                return { type: "standard", message: "Waiting..." };
            })
        );
        setFiles(tempFiles);
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => tempFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [tempFiles]);

    useEffect(()=> {
        if(refetch) {
            refetch()
            setFiles([])
        }
    }, [])


    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const browseFilesClickHandler = async ()=>{
           
            if(commonReducer.event_details?.consent_accepted == false){
                dispatch(updateState({open_agreement_popup: true}))  
            }else{
                open()   
                // if(refetch) {
                //     await refetch()
                //     setFiles([])
                // }  
            }
    }

    const handleAutocompleteChange = (event, value) => {

        if(value){
            setEnableUploadList(true)
            setSelectedEventId(value.id)
            setSelectedProjectName(value.name)
            // dispatch(updateState({open_previous_project_media_popup : true, imported_project_id: value.id, }))
            dispatch(setAllMediaFilesArray({open_previous_project_media_popup : true, imported_project_id: value.id, }))
        }
      };
    //   console.log(uploadedVideoList)

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

            <Grid className="container" 
                style={{ display: 'flex' }} 
                sx={{ 
                    display: { xs: 'none', md: 'inline' },
                    height: height ? height : {xs: '60vh', md: '55vh',lg: '55vh' , xl: '56vh'}, 
                    width: '100%'
            }}>

                <Grid  {...getRootProps({ style, className: 'dropzone' })} >
                    <input {...getInputProps()} />
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>

                        <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}} >

                            <Box sx={{ width: {xs:'35px',sm:'40px',md:'45px',lg:'50px',xl:'74px'}, height: {xs:'28px',sm:'33px',md:'38px',lg:'43.33px',xl:'64px'}, color: '#878887', marginBottom: {xs:'15px',sm:'20px',md:'28px',lg:'33px',xl:'44px'} }} >
                                <img src={File} height='100%' width='100%'/>
                            </Box>


                            <Typography sx={{ fontSize: {xs:'12px',sm:'14px',md:'15px',lg:'16.67px',xl:'25px'}, fontWeight: 600, margin: 0, padding: 0, textAlign: 'center', letterSpacing: 1.25, color: '#878887', fontFamily: "Rubik" }}>Drag & Drop <span style={{ display: 'block' }}>existing</span> files here</Typography>
                        </Box>

                        <Button color='secondary'  variant='contained' disabled = {isDisabled} onClick={browseFilesClickHandler} style={{fontFamily: "Rubik", backgroundColor: isDisabled == true? "#52636C50"  : theme.palette.secondary.main, borderRadius: '5px', border: 'none',  cursor: 'pointer', fontWeight: 600, textTransform: 'capitalize', minWidth:'76px' }} sx={{fontSize:{xs:'7px',sm:'8px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, 
                        // width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},
                         paddingY:0.4}}>
                            Or Browse
                        </Button>
                        
                        {/* {commonReducer.user_name == commonReducer.event_details?.owner_name && ( */}
                        {( hasPermission(PERMISSION.PROJECT.MEDIA.IMPORT) || hasPermission(PERMISSION.ORGANISATION.PROJECT.IMPORT_FILES)) && (
                            <Box sx={{width:'100%'}} mt={1}>
                                <CustomAutocomplete
                                disabled = {isDisabled}
                                options={listOfAllEvents?.filter(event => event.name !== commonReducer.event_details?.name )}
                                placeholder='Or Import...'
                                onChange={handleAutocompleteChange}
                                // sx={{width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},}}
                                getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.name : ""
                                }
                                />
                            </Box>
                        )} 
                    </Box>
                </Grid>
               
            </Grid>
            {commonReducer?.open_agreement_popup && <AgreementPopup open={open} dropped = {isDropped} eventData={eventData} refetchEvent = {refetchEvent} />}

        {/* {commonReducer.open_previous_project_media_popup && <UploadFromProjectPopup selectedProjectName={selectedProjectName}/>} */}
        </Box>
    );
}

export default CustomDropBox;