import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { guestEventDetails } from "../../../services/api";
import { updateState } from "../../../redux/commonSlice";
import { setSnackbar } from "../../../redux/messageSlice";
import { showSnackBar } from "../../../utils/commonServices";
import { resetTheme, setTheme } from "../../../redux/customThemeSlice";

export default ()=> {
 
  const dispatch = useDispatch();
 const commonReducer = useSelector(state=> state.root.commonReducer)


  const getEventGuestPageDetails = (payload, callback ) => {
    guestEventDetails({collab_code: payload}) 
    .then((response) => {
      dispatch(
        updateState({
            guest_event_detail: response?.data?.data
        })
      );
      if(response?.data?.http_code === 200){
        if(Array.isArray(response?.data?.data?.theme)){
          if(response?.data?.data?.theme?.length > 0){
            dispatch(setTheme({primary:  response?.data?.data?.theme?.primary_colour, secondary: response?.data?.data?.theme?.secondary_colour, brand_logo: response?.data?.data?.theme?.url, brand_logo_download_url: response?.data?.data?.theme?.download_url}))
          }
        }else if(Object.keys(response?.data?.data?.theme)?.length > 0){
            dispatch(setTheme({primary:  response?.data?.data?.theme?.primary_colour, secondary: response?.data?.data?.theme?.secondary_colour, brand_logo: response?.data?.data?.theme?.url, brand_logo_download_url: response?.data?.data?.theme?.download_url}))
           } else {
            dispatch(resetTheme())
           }
        
        callback &&  callback()
   }else {
       dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
   }
    })
    .catch((error) => {
     if(error) {
      const errorObject = error?.response?.data;
      showSnackBar(dispatch, errorObject);
    }
    });
    }
    

  return {getEventGuestPageDetails};
}