import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { showSnackBar } from "../../utils/commonServices";
import { createDeviceInfoApi, createGuestLogApi, deleteGuestRevisionLogApi, deleteRevisionLogApi, getDeviceInfoApi, getGuestEventDetailsApi, registerGuestApi, updateGuestRevisionLogsApi, updateRevisionLogsApi } from "../../services/api";
import { setGuestReviewState } from "../../redux/guestSlice";
import { setTheme } from "../../redux/customThemeSlice";
import { setSnackbar } from "../../redux/messageSlice";



export default ()=> {
 
  const dispatch = useDispatch();
  const commonReducer = useSelector(state=> state.root.commonReducer)
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get('c')
  const guestReducer = useSelector(state=> state.guestReducer)
  const deviceInfoSlug = localStorage.getItem('device_info_slug')

  const createDeviceInfo = (value, callback ) => {
    // dispatch(setGuestReviewState({is_loading: true}))
    const payload ={
      system : value?.system
    }
    createDeviceInfoApi(payload) 
    .then((response) => {
      // dispatch(setGuestReviewState({is_loading: false}))
      if(response?.data?.http_code === 200){
        localStorage.setItem("device_info_slug", response?.data?.data?.slug);
        // console.log(response?.data?.data?.slug)
        getDeviceInfo({slug: response?.data?.data?.slug})
        // getGuestEventDetails({slug:code, guest_reviewer_id: response?.data?.data?.guest_reviewer_id }, callback)
        // dispatch(setGuestReviewState({guest_reviewer_id: response.data.data.guest_reviewer_id}))
     }
    })
    .catch((error) => {
     if(error) {
      const errorObject = error?.response?.data;
      showSnackBar(dispatch, errorObject);
      // dispatch(setGuestReviewState({is_loading: false, show_guest_revision_page: false}))
    }
    });
    }

    const getDeviceInfo = (payload, callback ) => {
      // dispatch(setGuestReviewState({is_loading: true}))
      getDeviceInfoApi({slug: payload?.slug}) 
      .then((response) => {
        // dispatch(setGuestReviewState({is_loading: false}))
        if(response?.data?.http_code === 200){
          dispatch(setGuestReviewState({guest_reviewer_page_loading : false}))

          if((Array.isArray(response?.data?.data?.guest_reviewer) && response?.data?.data?.guest_reviewer?.length === 0) || (typeof response?.data?.data?.guest_reviewer === 'object' && response?.data?.data?.guest_reviewer !== null && Object.keys(response?.data?.data?.guest_reviewer)?.length === 0)
          ){
            dispatch(setGuestReviewState({ guest_user_details_popup: true, guest_reviewer_id: response?.data?.data?.guest_reviewer?.id}))
          }else{
            
            dispatch(setGuestReviewState({ guest_reviewer_id: response?.data?.data?.guest_reviewer?.id, guest_name:response?.data?.data?.guest_reviewer?.name}))
            getGuestEventDetails({slug:code, guest_reviewer_id: response?.data?.data?.guest_reviewer?.id }, callback, true)
          }
       }
      })
      .catch((error) => {
       if(error) {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject);
        dispatch(setGuestReviewState({is_loading: false, show_guest_revision_page: false}))
      }
      });
      }


    const registerGuestUser = (value, callback ) => {
      dispatch(setGuestReviewState({is_loading: true}))
      const payload ={
        ...value,
        slug: code,
        device_info_slug: deviceInfoSlug
      }
      registerGuestApi(payload) 
      .then((response) => {
        dispatch(setGuestReviewState({is_loading: false}))
        if(response?.data?.http_code === 200){
          // console.log(response.data.data.guest_reviewer_id)
          getDeviceInfo({slug: deviceInfoSlug})
          getGuestEventDetails({slug:code, guest_reviewer_id: response?.data?.data?.guest_reviewer_id }, callback, true)
          dispatch(setGuestReviewState({guest_reviewer_id: response.data.data.guest_reviewer_id}))
        //  callback && callback()
          // getGuestEventDetails({slug: code, guest_reviewer_id: 1})
       }
      })
      .catch((error) => {
       if(error) {
        const errorObject = error?.response?.data;
        showSnackBar(dispatch, errorObject);
        dispatch(setGuestReviewState({is_loading: false, show_guest_revision_page: false}))
      }
      });
      }


      const getGuestEventDetails = (payload, callback, isLoader ) => {
        isLoader && dispatch(setGuestReviewState({get_event_details_loading: true}))
        
        getGuestEventDetailsApi(payload) 
        .then((response) => {
          isLoader && dispatch(setGuestReviewState({get_event_details_loading: false}))
          if(response?.data?.http_code === 200){
            // console.log(response.data)
            callback && callback()
            dispatch(setGuestReviewState({is_loading: false, event_details: response?.data?.data , logs: response?.data?.data?.logs}))
            dispatch(setTheme({primary:  response?.data?.data?.theme?.primary_colour, secondary: response?.data?.data?.theme?.secondary_colour, brand_logo: response?.data?.data?.theme?.url, brand_logo_download_url: response?.data?.data?.theme?.download_url}))
        //  dispatch(setGuestReviewState({event_details: , logs: , theme:}))
          }
        })
        .catch((error) => {
         if(error) {
          const errorObject = error?.response?.data;
          showSnackBar(dispatch, errorObject, ()=> dispatch(setGuestReviewState({show_guest_revision_page: false})));
          isLoader && dispatch(setGuestReviewState({is_loading: false}))
        }
        });
      }

      const createGuestUserLog = (payload, callback ) => {
        dispatch(setGuestReviewState({create_log_loading: true}))
        createGuestLogApi(payload) 
        .then((response) => {
          dispatch(setGuestReviewState({create_log_loading: false}))
          if(response?.data?.http_code === 200){
            console.log(response.data)
            getGuestEventDetails({slug:code, guest_reviewer_id: guestReducer?.guest_reviewer_id })
            callback && callback()
           
         }
        })
        .catch((error) => {
         if(error) {
          const errorObject = error?.response?.data;
          showSnackBar(dispatch, errorObject);
          dispatch(setGuestReviewState({create_log_loading: false}))
        }
        });
      }

      const updateGuestLog = (payload ,callback) => {
        dispatch(setGuestReviewState({create_log_loading: true}))
        updateGuestRevisionLogsApi(payload) 
        .then((response) => {
          dispatch(setGuestReviewState({create_log_loading: false}))
          if(response?.data?.http_code === 200){
              dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
              dispatch(setGuestReviewState({edit_flag: false, log_id: ''}))
              getGuestEventDetails({slug:code, guest_reviewer_id: guestReducer?.guest_reviewer_id })
              callback && callback()
          }else {
              dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
          }
        })
        .catch((error) => {
          dispatch(setGuestReviewState({create_log_loading: false}))
          if(error) {
          const errorObject = error?.response?.data;
          showSnackBar(dispatch, errorObject);
          }
        });
        }

      
        const deleteGuestLog = (payload ,callback) => {
          dispatch(setGuestReviewState({delete_log_loading: true}))
          deleteGuestRevisionLogApi(payload) 
          .then((response) => {
            dispatch(setGuestReviewState({delete_log_loading: false}))
            if(response?.data?.http_code === 200){
                dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
                getGuestEventDetails({slug:code, guest_reviewer_id: guestReducer?.guest_reviewer_id })
              }else {
                dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
              }
              callback && callback()
          })
          .catch((error) => {
            dispatch(setGuestReviewState({delete_log_loading: false}))
            if(error) {
            const errorObject = error?.response?.data;
            showSnackBar(dispatch, errorObject);
            }
          });
          }
    

  return { registerGuestUser, createGuestUserLog, updateGuestLog, deleteGuestLog, createDeviceInfo, getDeviceInfo};
}