import { AppBar, Box,  Typography, getContrastRatio } from "@mui/material"
import { useState } from "react"
import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useCustomTheme from "../../../theme/useCustomTheme";
import PageNotFoundImage from "../../shared/assets/PageNotFoundImage";
import UnauthLayout from "../../unauthv2/components/UnauthLayout";



const EventCancelledPage = (props) => {

  const [theme] = useCustomTheme()
  const guestReducer = useSelector(state=> state.guestReducer)


  return (
    <>
    <UnauthLayout sx={{ padding: '2% 3.2% 2.1% 2.2%' }} 
    rightComponent={
    <>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', }}  pl={{xs:2,sm:4,md:8,lg: 10,xl:12}}>
            <Box sx={{height:{xs:180,sm:200,md:220,lg:'240px',xl:300}, marginBottom:{xs:'15px', sm:'15px',md:'20px',lg:'22.66px',xl:'34px'}}}>
                <PageNotFoundImage sx={{height: '100%', width: '100%', maxWidth: '350px'}} />
            </Box>
        </Box>
    </>}
    >
     
      <Box className = 'flex row a-center' sx={{justifyContent: 'space-evenly', flex: 1}} pl={{ sm: 10 }} pb={4}>
        <Box flexDirection='column'>

          <Box sx={{ marginY: { xs: '15px', md: '20px', lg: '25px', xl: '38px' } }}>
            <Typography
              variant="h4"
              color="primary"
              style={{
                fontWeight: 600,
                color: '#08231B'
              }}
              sx={{ fontSize: { xs: '30px', sm: '40px', md: '40px', lg: '50px', xl: '80px' }, fontFamily: 'Rubik' }}
            >Oh Shoot.</Typography>
          </Box>
          <Box item xs={11} sm={8} md={7} gap={0.8} sx={{ display: 'flex', flexDirection: 'column', }}>
            <Typography sx={{ color: '#707070', fontSize: { xs: '14px', md: '16px', lg: '18px', xl: '24px' }, fontFamily: 'Helvetica' }}>{guestReducer?.event_details?.cancel === true ? 'This event no longer exists.' : 'Event is not accepting any Revision logs as of now!!!'}</Typography>
          </Box>

        </Box>
      </Box>
    </UnauthLayout>
</>
  )
}

export default EventCancelledPage;