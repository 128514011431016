import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography , getContrastRatio} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomCard from "../../../components/CustomCard";
import "@fontsource/rubik";
import { borderRadius } from "@mui/system";
import EventStageBar from "./EventStageBar";
import { useDispatch, useSelector } from "react-redux";
import { downloadS3File, getInitials, getStatusNameFromStatusTypeOrder, timeStampToDayString } from "../../../utils/commonServices";
import CustomTooltip from "../../../components/CustomTooltip";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useEventRequest from "../../../hooks/useEventRequest";
import useDeleteProject from "../hooks/useDeleteProject";
import { updateState } from "../../../redux/commonSlice";
import downloadButton from "../../../assets/downloadIcon.svg"
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { updateCreationState } from "../../../redux/eventCreationSlice";
import usePermissions from "../../../hooks/usePermissions";
import { PERMISSION } from "../../../constants/constants";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";


const EventCard = (props) => {
    const { event } = props;
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const reviewEventReducer = useSelector((state)=> state.reviewEventReducer)
    //const event = commonReducer.events?.find((event) => event.id == eventId)
    const dispatch = useDispatch()
    const [theme ] = useCustomTheme()
    var collaborators = event?.collaborators?.map((collaborator) => collaborator.name)
    collaborators?.unshift(event?.owner_name);
    const [anchorEl, setAnchorEl] = useState(null);
    const [hasPermission] = usePermissions()
    const open = Boolean(anchorEl);
    // const [deleteProject, deleteEventStatus] = useDeleteProject(eventId, refetchList);
    // console.log({event, collaborators})
    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const downloadFinalVideo = (e)=> {
        e.stopPropagation();
        downloadS3File(event?.final_video_download_url)
    }

    const setPageData = ()=> {
        dispatch(updateCreationState({
            pageData: {
                name : event?.name,
                start_date: event?.start_date,
                end_date: event?.end_date,
                editor_start_date: event?.editor_start_date,
                video_orientation: event?.video_orientation, // landscape | portrait
                // song:'',
                description: event?.description,
               // quality_options: [1],
                event_type_id: [...dropdownReducer.event_type]?.find((type)=> type.id == event.event_type?.id, ...dropdownReducer.event_type_old)?.id,
                video_length_id: dropdownReducer.video_length.find((length)=> length.value == event.video_length)?.id,
            }
        }))
    }

    return <CustomCard
        {...props}
        sx={{
            ...props.sx,
            width: { xs: '100%', md: 'calc(50% - 16px)', lg: 'calc(33% - 16px)', xl: 'calc(33% - 16px)' },
            minWidth: 325,
            // height: '100%',
            //  maxHeight: {md: '12vh', lg: '23%', xl: '21.33%'},
            backgroundColor: '#FFFFFF',
            mb:{xl:1}
        }}
    >
        <Box className="flex col j-between" sx={{height:{xs:'110px',sm:'120px',md:'125px',lg:'132px',xl:'198px'}, borderRadius:'10px', }}>
        {/* <Box sx={{height:'100%'}}> */}
         <Box>
            <Box
                sx={{
                width: '100%',
                padding: '1.3% 1.6% 2.6% 5.3%'

                }}
            >
            <Box className='flex row a-center' mt={{xs:0.2,md:0.3,lg:0.5,xl:0.8}} sx={{ width: '100%', height: { xs: '9px', md: '11px', lg: '12px', xl: '16px' }, justifyContent: (hasPermission(PERMISSION.ORGANISATION.PROJECT.DELETE) 
                                                                                                                                                                                    || hasPermission(PERMISSION.TEAM.PROJECT.DELETE) 
                                                                                                                                                                                        || hasPermission(PERMISSION.TEAM.PROJECT.DUPLICATE)
                                                                                                                                                                                            || hasPermission(PERMISSION.ORGANISATION.PROJECT.DUPLICATE)  ) ? 'space-between' : 'flex-end' }} >
              { (hasPermission(PERMISSION.ORGANISATION.PROJECT.DELETE) 
                    || hasPermission(PERMISSION.TEAM.PROJECT.DELETE) 
                        || hasPermission(PERMISSION.TEAM.PROJECT.DUPLICATE)
                            || hasPermission(PERMISSION.ORGANISATION.PROJECT.DUPLICATE)  )&& <MoreHorizIcon onClick={handleClick} sx={{fontSize:{xs:'12px',sm:'14px',md:'16px',lg:'18px',xl:'24px'}}}/>}
              
                {
                    // event?.status_type_order == 5 ? hasPermission(PERMISSION.PROJECT.REVISION.FINAL_VIDEO.DOWNLOAD) && <CustomTooltip title="Download Final Video"   PopperProps={{
                    //     modifiers: [
                    //       {
                    //         name: "offset",
                    //         options: {
                    //           offset: [-35, -10],
                    //         },
                    //       },
                    //     ],
                    //   }} >
                    //     <IconButton style= {{padding: "0px", marginTop: '3.6%'}} onClick={downloadFinalVideo} height='18%'><img  src={downloadButton}></img></IconButton></CustomTooltip> 
                    //     :
                        ( event?.status_type_order == 4 ? <CustomTooltip title="Ready for Review"  PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [-35, -4],
                                },
                              },
                            ],
                          }} ><Box sx={{width: '16px', height: '16px', borderRadius: 50, backgroundColor: '#FF0045'}}></Box></CustomTooltip>  : null)
                }
            </Box>
            <Typography sx={{ fontSize: { xs: 7, md: 8,lg:'10px', xl: 13 }, mt: {xs:0.3,md:0.5,lg:0.6,xl:0.8}, fontWeight: 900, minHeight: '13.5px', fontFamily: 'Rubik' , color:'black', }}>{!!event?.event_type && event?.event_type?.name}</Typography>
            <CustomTooltip title={event?.name?.length > 30 && event?.name} 
              placement= "top"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}  >
                <Typography
                    sx={{
                        fontSize: { xs: '10px', sm:'11px', md: '12px',lg:'13.33px', xl: '20px' },
                        fontWeight: 600, fontFamily: 'Rubik',
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        mt:{xs:0.4,md:0.5,lg:0.6,xl:1},
                        // backgroundColor:'red',
                        // height:'20p/x',
                        minHeight:{xs:'14px',md:'18px',lg:'20px',xl:'28px'}
                    }}
                >{event?.name}</Typography>
            </CustomTooltip>

            <Box width='100%' className='flex row j-start' sx={{ fontSize: 10, fontWeight: 300, fontFamily: 'Rubik', pt: {xs:'4px',sm:'5px', md: '6px', lg: '6px', xl: '13px' }, pb: {xs:'2px',sm:'3px', md: '3px', lg: '3px', xl: '13px', overflowX: 'hidden' }}} gap={{xs:0.5,  lg:0,xl: 1.6}}>

                <Typography sx={{ fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 13.5 }, fontWeight: 500, fontFamily: 'Rubik', mr: 1.5 }}>Creation Date
                    <Typography component='span' sx={{ fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 13.5 }, fontFamily: 'Rubik', fontWeight: 900, ml: 0.5 }} >{timeStampToDayString(event?.start_date)} </Typography>
                </Typography>

                <Typography sx={{ fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 13.5 }, fontWeight: 500, fontFamily: 'Rubik' }} >Edit Start Date
                    <Typography component='span' sx={{ fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 13.5 }, fontFamily: 'Rubik', fontWeight: 900, ml: 0.5 }} >{timeStampToDayString(event?.editor_start_date)} </Typography>
                </Typography>
            </Box>

            {/* <Box className='flex j-between' mt={1} > */}
            {
               collaborators?.length > 0  && collaborators?.map((member, index) => {
                    return member != null && <Box className="j-center a-center" sx={{ display: 'inline-flex', borderRadius: '5px', backgroundColor: index == 0 ? theme.palette.primary.main : '#6C00FF', height: {xs:'12px',sm:'13px',md:'15px',lg:'16.67px',xl:'25px'}, width:  {xs:'12px',sm:'13px',md:'15px',lg:'16.67px',xl:'25px'}, fontFamily: 'Rubik', fontSize: { xs: 6, md: 7,lg:'7.33px', xl: 11 }, fontWeight: 600, color: index == 0 ?  getContrastRatio(theme.palette.primary.main , '#ffffff') > 2.5 ? '#ffffff' : '#000000' : '#ffffff', p: 0.5, marginLeft: '4px', marginRight: '4px' , marginTop:0}} >
                      { getInitials(member)}
                    </Box>
                })
            }
            {/* <Typography sx={{ fontSize: { xs: 8, md: 9,lg:10, xl: 14  }, fontFamily: 'Rubik', fontWeight: 600, mr: 0.5}}>{getStatusNameFromStatusTypeOrder(event?.status_type_order)}</Typography> */}
            {/* </Box> */}
            </Box>
            </Box>
            <Box>
                <EventStageBar statusCode={event?.status_type_order} />
            </Box>
            {/* </Box> */}
        </Box>

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                //   vertical: "top",
                //   horizontal: 133,
                vertical: -8,
                horizontal: 12
            }}
        >

           {(hasPermission(PERMISSION.ORGANISATION.PROJECT.DELETE) || hasPermission(PERMISSION.TEAM.PROJECT.DELETE)) &&  <MenuItem
                style={{ height: 24 }}
                onClick={(e) => {
                    e.stopPropagation();
                    //setEventName(event?.name)
                    //deleteProject();
                    if(commonReducer.user_name != event.owner_name) {
                        dispatch(updateState({delete_decline_popup: true, is_collaborator: true, event_delete_name: event?.name, production_event_id: event.id }))
                       
                    } else if(event?.status_type_order > 2 && event?.status_type_order < 5 ) {
                        dispatch(updateState({delete_decline_popup: true, is_collaborator: false, event_delete_name: event?.name, production_event_id: event.id}))
                    } else {
                        dispatch(updateState({event_delete_popup: true, is_collaborator: false, event_delete_name: event?.name, production_event_id: event.id}))
                    }
                    handleClose(e);
                }}
            >
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Delete Project</Typography></ListItemText>
            </MenuItem>}


           {(hasPermission(PERMISSION.ORGANISATION.PROJECT.DUPLICATE) || hasPermission(PERMISSION.TEAM.PROJECT.DUPLICATE)) &&  <MenuItem
                style={{ height: 24 }}
                onClick={(e) => {
                
                    e.stopPropagation();
                    dispatch(updateCreationState({
                pageData: {
                name : event?.name,
                start_date: '',
                end_date: '',
                editor_start_date: '',
                video_orientation: event?.video_orientation, // landscape | portrait
                // song:'',
                description: event?.description,
               // quality_options: [1],
                event_type_id: event?.event_type?.id,
                video_length_id: dropdownReducer.video_length.find((length)=> length.value == event.video_length)?.id,
                }
                }))
                    dispatch(updateCreationState({open_form: true, duplicate: true, event_id: event.id}))
                    //dispatch(updateCreationState({open_backdrop_project_creation: true, duplicate: true, event_id: event.id}))
                    handleClose(e);
                }}
            >
               
                 {/* <ListItemIcon><ContentCopyIcon style={{fontSize: 18, marginLeft: 8}}/></ListItemIcon> */}
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' }}}>Duplicate Project</Typography></ListItemText>
            </MenuItem>}

{hasPermission(PERMISSION.PROJECT.INFO.EDIT) && 
            <MenuItem
            
                style={{ height: 24 }}
                onClick={(e) => {
                    e.stopPropagation();
                    if(event?.status_type_order < 3){
                    setPageData()
                     dispatch(updateCreationState({open_form: true, edit: true, event_id: event.id}))
                   // dispatch(updateCreationState({open_backdrop_project_creation: true, edit: true, event_id: event.id}))
                    }else{
                       
                        dispatch(updateCreationState({edit_decline_popup:true}))
                    }
                    handleClose(e);
                }}
            >
               
                {/* <ListItemIcon><EditIcon style={{fontSize: 18, marginLeft: 8}}/></ListItemIcon> */}
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Edit Project</Typography></ListItemText>
            </MenuItem>}
        </Menu>
       
    </CustomCard>
};
export default EventCard;