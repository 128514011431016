import React from "react";
import { Box, SvgIcon } from "@mui/material";
import useCustomTheme from "../../../theme/useCustomTheme";

const RevisionSentImage = (props)=> {
    const [theme] = useCustomTheme()
    return <SvgIcon 
            {...props} 
            id="Group_156" data-name="Group 156" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 287.163">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_122" data-name="Rectangle 122" width="264" height="287.163" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_189" data-name="Group 189">
    <path id="Path_98" data-name="Path 98" d="M211.582,417.568l-6.83.62c.21,2.314.416,4.578.634,6.98l6.83-.62-.634-6.98" transform="translate(-105.065 -214.267)" fill={theme.palette.secondary.main}/>
    <path id="Path_99" data-name="Path 99" d="M331.862,370.6l-6.83.62c.21,2.314.416,4.578.634,6.98l6.83-.62-.634-6.98" transform="translate(-166.784 -190.165)" fill={theme.palette.primary.main}/>
    <path id="Path_100" data-name="Path 100" d="M5.181,439.509a3.612,3.612,0,1,0,3.292-3.791,3.6,3.6,0,0,0-3.292,3.791" transform="translate(-2.654 -223.574)" fill={theme.palette.secondary.main}/>
    <path id="Path_101" data-name="Path 101" d="M270.135,501.129a14.355,14.355,0,1,1-2.719-28.58,14.355,14.355,0,0,1,2.719,28.58m-.66-7.241a7.127,7.127,0,0,0,6.414-7.725,7.4,7.4,0,0,0-7.908-6.594,7.2,7.2,0,1,0,1.495,14.319" transform="translate(-130.495 -242.448)" fill="#08231b"/>
    <path id="Path_102" data-name="Path 102" d="M269.064,494.927a7.219,7.219,0,0,0,14.375-1.328A7.4,7.4,0,0,0,275.531,487a7.179,7.179,0,0,0-6.467,7.922" transform="translate(-138.045 -249.884)" fill={theme.palette.primary.main}/>
    <path id="Path_103" data-name="Path 103" d="M15.824,244.956a14.355,14.355,0,1,1-2.719-28.58,14.355,14.355,0,0,1,2.719,28.58m-.66-7.241a7.127,7.127,0,0,0,6.414-7.725,7.4,7.4,0,0,0-7.908-6.594,7.2,7.2,0,1,0,1.495,14.319" transform="translate(0 -110.998)" fill="#08231b"/>
    <path id="Path_104" data-name="Path 104" d="M14.752,238.754a7.219,7.219,0,0,0,14.375-1.328,7.4,7.4,0,0,0-7.908-6.594,7.179,7.179,0,0,0-6.467,7.922" transform="translate(-7.55 -118.433)" fill={theme.palette.secondary.main}/>
    <path id="Path_105" data-name="Path 105" d="M117.875,377.627c.21,2.313.42,4.626.647,7.122l7.184-.652q-.323-3.56-.646-7.112l.071.063-7.326.667Z" transform="translate(-60.449 -193.442)" fill="#08231b"/>
    <path id="Path_106" data-name="Path 106" d="M132.026,369.074l7.1-.645c-.184-2.031-.282-4.023-.613-5.976-.066-.389.421-.885-.127-.886-1.853,0-5.152.194-7.006.314l.056-.079q.33,3.667.66,7.334Z" transform="translate(-67.416 -185.532)"/>
    <path id="Path_107" data-name="Path 107" d="M109.146,363.484l-7.05.64c.233,2.568.431,4.75.653,7.187l7.065-.641-.07.087-.666-7.331Z" transform="translate(-52.389 -186.485)" fill="#08231b"/>
    <path id="Path_108" data-name="Path 108" d="M123.136,355.053c-.2-2.211-.4-4.423-.617-6.792l-7.207.654c.2,2.231.409,4.509.615,6.787l-.067-.059,7.332-.669Z" transform="translate(-59.17 -178.704)" fill="#08231b"/>
    <path id="Path_109" data-name="Path 109" d="M123.769,362.049l-7.332.669.666,7.332,7.326-.667q-.33-3.667-.66-7.334" transform="translate(-59.748 -185.779)" fill={theme.palette.primary.main}/>
    <rect id="Rectangle_120" data-name="Rectangle 120" width="7.512" height="7.512" transform="matrix(0.996, -0.092, 0.092, 0.996, 63.89, 176.155)" fill="#08231b"/>
    <path id="Path_110" data-name="Path 110" d="M94.581,560.369l-7.05.64c.233,2.568.431,4.75.653,7.187l7.065-.641-.07.087-.666-7.331Z" transform="translate(-44.915 -287.513)" fill="#08231b"/>
    <path id="Path_111" data-name="Path 111" d="M103.31,574.512c.21,2.313.42,4.626.647,7.122l7.184-.652q-.323-3.56-.646-7.112l.071.063-7.326.667Z" transform="translate(-52.976 -294.47)" fill="#08231b"/>
    <path id="Path_112" data-name="Path 112" d="M117.462,565.959l7.1-.645c-.184-2.031-.282-4.024-.613-5.976-.066-.388.421-.885-.127-.886-1.854,0-5.152.194-7.006.314l.056-.079q.33,3.667.66,7.334Z" transform="translate(-59.942 -286.56)"/>
    <path id="Path_113" data-name="Path 113" d="M108.571,551.938c-.2-2.211-.4-4.423-.617-6.792l-7.207.654c.2,2.231.409,4.509.616,6.787l-.068-.059,7.332-.669Z" transform="translate(-51.696 -279.732)" fill="#08231b"/>
    <path id="Path_114" data-name="Path 114" d="M109.2,558.934l-7.333.669q.333,3.666.667,7.332l7.325-.667q-.329-3.667-.659-7.334" transform="translate(-52.274 -286.807)" fill={theme.palette.secondary.main}/>
    <rect id="Rectangle_121" data-name="Rectangle 121" width="7.512" height="7.512" transform="translate(56.799 272.012) rotate(-5.27)" fill="#08231b"/>
    <path id="Path_115" data-name="Path 115" d="M150.237,164.849c-3.8-1.518-5.274-4.418-5.743-8.4-2.371-20.151-4.956-40.277-7.382-60.421a5.483,5.483,0,0,0-3.161-4.83c-13.712-7.273-27.33-14.726-40.973-22.129-5.762-3.126-8.666-8.616-7.768-14.622.879-5.877,5.108-10.225,11.523-11.585Q152.6,31.022,208.486,19.242,252.857,9.868,297.221.462c3.255-.693,6.291-.957,8.723,1.8s1.868,5.731.677,8.861Q286.834,63.09,267.128,115.088q-6.235,16.422-12.464,32.847c-4,10.581-13.534,13.568-22.766,7.109-10.537-7.372-21.066-14.757-31.768-22.255-2.442,1.672-4.9,3.339-7.336,5.028q-16.961,11.741-33.924,23.48c-1.845,1.272-3.809,2.372-5.718,3.552ZM284.705,29.56l-.375-.278-98.866,76.259,55.624,38.943L284.705,29.56M99.391,56.48a14.415,14.415,0,0,0,1.294.967c13.786,7.482,27.564,14.978,41.4,22.369a3.927,3.927,0,0,0,3.081-.092q58.641-28.292,117.223-56.706c.641-.31,1.249-.686,1.873-1.031l-.123-.271L99.391,56.48m126.917-.024-.315-.3c-.362.149-.732.281-1.084.451Q188.6,74.191,152.273,91.742c-1.556.749-1.586,1.665-1.4,3.058.975,7.449,1.876,14.907,2.817,22.36.584,4.631,1.193,9.259,1.791,13.888a7.312,7.312,0,0,0,1.534-2.531c3.277-8.078,6.463-16.194,9.886-24.21a12.445,12.445,0,0,1,3.59-4.686c9.2-7.267,18.526-14.372,27.807-21.535q14.01-10.811,28.014-21.631m-57.761,80.618.41.349,19-13.169-11.081-7.737-8.328,20.558" transform="translate(-43.642 0)" fill="#08231b"/>
  </g>

  </SvgIcon>
    
  
}
export default RevisionSentImage