import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
 primary: null,
 secondary: null,
 logo: null,
 brand_logo_download_url: null,
 brand_logo: null,
 get_call_pending: false
};

export const customThemeSlice = createSlice({
  name: "theme",
  initialState: initialValue,
  reducers: {
    setTheme: (state, action) => {
      assignValueInState(action.payload, state);
    },
    resetTheme: (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { setTheme, resetTheme } = customThemeSlice.actions;
export default customThemeSlice.reducer;