import React, { useEffect, useState } from 'react'
// import { customTheme as theme } from "../../../theme/customTheme";
import { Backdrop, Box, Slide, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { updateState } from '../../../redux/commonSlice';
import ProjectTypePage from './ProjectTypePage';
import ProjectNamePage from './ProjectNamePage';
import ProjectLengthPage from './ProjectLengthPage';
import ProjectStartEndDatePage from './ProjectStartEndDatePage';
import EditingStartDatePage from './EditingStartDatePage';
import VideoOrientationPage from './VideoOrientationPage';
import ProjectMusicPage from './ProjectMusicPage';
import NotesToEditorPage from './NotesToEditorPage';
import { resetCreationState, updateCreationState } from '../../../redux/eventCreationSlice';
import useEventList from '../../dashboard/hooks/useEventList';
import useEventRequest from '../../../hooks/useEventRequest';
import useCustomTheme from '../../../theme/useCustomTheme';

const BackdropCreateProject = () => {

  const [pageIndex, setPageIndex] = useState(1)
  const createEventReducer = useSelector((state) => state.createEventReducer);
  const [slideDirection, setSlideDirection] = useState('up')
  const [selectedProjectType, setSelectedProjectType] = useState('')
  const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
  const themeReducer = useSelector((state)=> state.root.themeReducer);
  const dispatch = useDispatch()
  const [pageData, setPageData] = useState(createEventReducer.pageData)
  const totalScreens = 7
  // const [refetchEvents] = useEventList();
  const [createEvent, createEventStatus, eventId] = useEventRequest("create")
  const [editEvent, editEventStatus] = useEventRequest("edit")
  const [theme ] = useCustomTheme()
  
  // const primaryColor = localStorage.getItem('primary')


    useEffect(()=>{
      if(createEventReducer.open_backdrop_project_creation === true){
        document.body.style.overflow = 'hidden';
      }
    },[createEventReducer.open_backdrop_project_creation])

    const prevPageHandler =()=>{
        if(pageIndex > 1){
            setPageIndex(prev => prev - 1)
            setSlideDirection('down')
        }
    }

    const nextPageHandler =()=>{
        if(pageIndex < 8 ){
            setPageIndex(prev => prev + 1)
            setSlideDirection('up')
        }
    }

    const finishedHandler =()=>{
      dispatch(updateCreationState({open_backdrop_project_creation: false, create_project_backdrop_loader: true}))
    }

    const closeHandler = () => {
      dispatch(resetCreationState())
    };


  return (
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(15px)', display:'flex', flexDirection:'column', background: themeReducer?.primary ? `${theme.palette.primary.main}30 0% 0% no-repeat padding-box` : '#08231B20 0% 0% no-repeat padding-box' ,}}
    open={createEventReducer.open_backdrop_project_creation}
    >
  {/* {pageIndex === 1 && <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    >
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
          <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={()=>dispatch(updateProjectCreationState({open_backdrop_project_creation: false}))}>Close X</Typography>
          <Box flexGrow={1} className='flex col' ml={30} mr={5} mt={10}>
              <Box>
                <Typography sx={{fontSize:'35px', color:'white'}}>{`${pageIndex}/5`}</Typography>
                <Typography sx={{fontSize:'65px', color:'white'}}>What are you creating today?</Typography>
                <Typography sx={{fontSize:'35px', color:'white'}}>Select a project type.</Typography>
              </Box>
              <Box className='flex' gap={1}>
                {projectType.map(project => (
                  <CustomRadioButton 
                    id={project.id}   
                    name={project.name} 
                    project={project} 
                    setSelectedProjectType={setSelectedProjectType} 
                    selectedProjectType={selectedProjectType} 
                    height='80px'
                    component={
                    <Box className='flex a-center' gap={2}>
                      <Box sx={{width:'20px', height:'20px', backgroundColor: project.color, borderRadius:'50%'}}></Box>
                      <Typography sx={{color:'black', fontSize:'20px'}}>{project.name}</Typography>
                    </Box>}
                  />
                ))}
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end',gap:0.5, mb:3, mr:3}}>
            <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer', opacity: pageIndex === 1 && 0.5}} onClick={pageIndex !== 1 ? prevPageHandler : null}>/\</Box>
            <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer',  opacity: pageIndex === 5 && 0.5}} onClick={pageIndex !== 5 ? nextPageHandler : null}>\/</Box>
          </Box>
        </Box>
    </Backdrop>
    </Slide>
    } */}


    {pageIndex === 1 && <ProjectNamePage
      pageData={pageData}
      setPageData={setPageData}
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      selectedProjectType={selectedProjectType}
      setSelectedProjectType={setSelectedProjectType}
      totalScreens={totalScreens}
      submitRequest={createEventReducer.edit == true ? editEvent : createEvent} 
      requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}
      closeHandler={closeHandler}
    />}

    {pageIndex === 2 && <ProjectTypePage 
      pageData={pageData}
      setPageData={setPageData}
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      selectedProjectType={selectedProjectType}
      setSelectedProjectType={setSelectedProjectType}
      totalScreens={totalScreens}
      submitRequest={createEventReducer.edit == true ? editEvent : createEvent} 
      requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}
      closeHandler={closeHandler}
    />}

    

    {pageIndex === 3 && <ProjectLengthPage
      pageData={pageData}
      setPageData={setPageData}
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      selectedProjectType={selectedProjectType}
      setSelectedProjectType={setSelectedProjectType}
      totalScreens={totalScreens}
      submitRequest={createEventReducer.edit == true ? editEvent : createEvent} 
      requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}
      closeHandler={closeHandler}
    />}

    {/* {pageIndex === 4 && <ProjectStartEndDatePage
      pageData={pageData}
      setPageData={setPageData}
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      selectedProjectType={selectedProjectType}
      setSelectedProjectType={setSelectedProjectType}
      totalScreens={totalScreens}
      submitRequest={createEventReducer.edit == true ? editEvent : createEvent} 
      requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}
      closeHandler={closeHandler}
    />} */}

    {pageIndex === 4 && <EditingStartDatePage
      pageData={pageData}
      setPageData={setPageData}
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      selectedProjectType={selectedProjectType}
      setSelectedProjectType={setSelectedProjectType}
      finishedHandler={finishedHandler}
      totalScreens={totalScreens}
      submitRequest={createEventReducer.edit == true ? editEvent : createEvent} 
      requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}
      closeHandler={closeHandler}
    />}


    
    {pageIndex === 5 && <VideoOrientationPage
      pageData={pageData}
      setPageData={setPageData}
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      selectedProjectType={selectedProjectType}
      setSelectedProjectType={setSelectedProjectType}
      finishedHandler={finishedHandler}
      totalScreens={totalScreens}
      submitRequest={createEventReducer.edit == true ? editEvent : createEvent} 
      requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}
      closeHandler={closeHandler}
    />}


    
    {pageIndex === 6 && <ProjectMusicPage
      pageData={pageData}
      setPageData={setPageData}
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      selectedProjectType={selectedProjectType}
      setSelectedProjectType={setSelectedProjectType}
      finishedHandler={finishedHandler}
      totalScreens={totalScreens}
      submitRequest={createEventReducer.edit == true ? editEvent : createEvent} 
      requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}
      closeHandler={closeHandler}
    />}


    
    {pageIndex === 7 && <NotesToEditorPage
      pageData={pageData}
      setPageData={setPageData}
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      selectedProjectType={selectedProjectType}
      setSelectedProjectType={setSelectedProjectType}
      finishedHandler={finishedHandler}
      totalScreens={totalScreens}
      submitRequest={createEventReducer.edit == true ? editEvent : createEvent} 
      requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}
      closeHandler={closeHandler}
    />}



    {/* {pageIndex === 5 && <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    >
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
          <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={()=>dispatch(updateProjectCreationState({open_backdrop_project_creation: false}))}>Close X</Typography>
          <Box flexGrow={1} className='flex col ' ml={30} mr={5} mt={10}>
              <Box>
                <Typography sx={{fontSize:'35px', color:'white'}}>{`${pageIndex}/5`}</Typography>
                <Typography sx={{fontSize:'65px', color:'white'}}>What is the persona/tone of your script?</Typography>
                <Typography sx={{fontSize:'35px', color:'white'}}>Select one.</Typography>
              </Box>
              <Box className='flex' gap={1}>
                {projectPersona.map(project => (
                  <CustomRadioButton 
                    id={project.id}   
                    name={project.name} 
                    project={project} 
                    setSelectedProjectType={setSelectedProjectType} 
                    selectedProjectType={selectedProjectType} 
                    height='120px'
                    component={
                    <Box px={2} className='flex col a-center j-center' gap={2} sx={{width:'100%'}}>
                      <Typography sx={{color:'black', fontSize:'20px', fontWeight:600,}}>{project.name}</Typography>
                      <Typography textAlign={'center'} sx={{color:'black', fontSize:'12px', }}>{project.description}</Typography>
                    </Box>}
                  />
                ))}
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end',gap:0.5, mb:3, mr:3}}>
            <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer', opacity: pageIndex === 1 && 0.5}} onClick={pageIndex !== 1 ? prevPageHandler : null}>/\</Box>
            <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer'}} 
            // onClick={pageIndex !== 5 ? nextPageHandler : ()=> dispatch(updateState({loader_backdrop_open: true}))}
            onClick={finishedHandler}
            >\/</Box>
          </Box>
        </Box>
    </Backdrop>
    </Slide>
    } */}
    </Backdrop>
    </>
  )
}

export default BackdropCreateProject