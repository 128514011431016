import { Box, Button, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import RequestLog from "./RequestLog";
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import useRevisionRequests from "../hooks/useRevisionRequests";
import revisionSent from '../../../assets/revisionSubmitted.svg'
import usePermissions from "../../../hooks/usePermissions";
import { PERMISSION } from "../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { submitRevisionRequest } from "../actions";
import { generateLighterContrastColor } from "../../../utils/commonServices";
import useCustomTheme from "../../../theme/useCustomTheme";
import RevisionSentImage from "../assets/RevisionSentImage";


const SendChangeRequestPopup = (props) => {
    //const { success} = props
    const [success , setSuccess] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const reviewEventReducer = useSelector((state)=>state.reviewEventReducer)
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [hasPermission] = usePermissions()
    const [theme] = useCustomTheme()
   
    const submitRevisionCallBack = ()=> {
      setSuccess(true)
    //  if(reviewEventReducer.open_request_changes_popup == false) {
       // dispatch(updateReviewState({request_changes: false})) 
        
     // }
     }
    const handleClose = () => {
      if(success) {
        dispatch(updateReviewState({open_request_changes_popup : false, request_changes: false})) 
      } else {
        dispatch(updateReviewState({open_request_changes_popup : false}))
      }
    };

       const finishedHandler = ()=>{
           submitRevisionRequest(dispatch, navigate, {commonReducer, reviewEventReducer}, {id: commonReducer.event_details.revision.id}, submitRevisionCallBack)
       }
     

    return (
        <CustomAlert 
          open={reviewEventReducer.open_request_changes_popup} 
          height = '400px'
          handleClose={handleClose} 
          rightComponent={<Box sx={{display:'flex', flexDirection:'column', paddingX:{md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
           {
            success ?<Box  sx={{height: '100%'}} className= 'flex col j-center a-center'> 
              {/* <img src={revisionSent} style={{maxWidth: '200px'}} height='60%'/> */}
              <RevisionSentImage sx={{width: '100%', maxWidth: '200px', height: '60%'}} />

              </Box> : <RequestLog disabled />}
          </Box>}
          >
           {success && (
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={4} pb={5.8}>

              <Box flexGrow={1} pt={6} >
                <Typography mb={2} sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}}}>You have submitted your revisions.</Typography>
                {/* <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>You now have (<span style={{fontFamily:'Rubik', fontWeight:600, color:'#CE0101'}}>{commonReducer.event_details.revision_left}</span>) change request left. Any changes after that will cost you additional credits.</Typography> */}
                <br/>
                <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>If your editor has any questions, they will send a message to your email.</Typography>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}} pr={1.5}>
                  <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick={handleClose}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:  'pointer', }}/>
              </Box>
            </Box>
            )}

           {!success && (
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={4} pb={2}>
              <Box flexGrow={1} pt={5}>
                  <Typography sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}}}>Are you sure you want to submit your revisions?</Typography>
                  <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>Make sure <span style={{fontFamily:'Rubik', fontWeight:600}}>ALL</span> of your requests are saved on your <span style={{fontFamily:'Rubik', fontWeight:600}}>Revisions Log.</span></Typography>
                  <br/>
                  <Typography  sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px', wordWrap:'break-word'}}>
                    {
                      !commonReducer.event_details.unlimited_revision && <>
                          <span style={{fontFamily:'Rubik', fontWeight:600}}>Note:</span> 
                      You can only submit your revisions (
                      <span style={{fontFamily:'Rubik', fontWeight:600, color:'#CE0101'}}>
                        {commonReducer.event_details.revision_left}
                      </span>
                      ) times. Use it wisely!
                      </>
                    }
                  
                    </Typography>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>
                 {hasPermission(PERMISSION.PROJECT.REVISION.REQUEST.SEND) && <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {finishedHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:  'pointer', }}/>}
                </Box>
              <Box>    
                <Button 
                variant="text"
                onClick={handleClose}
                 sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},  fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                Or Go Back
               </Button>
              </Box>
            </Box>
          </Box>
          )}
        </CustomAlert>
      )
}

export default SendChangeRequestPopup