import { useEffect, useState } from "react";
import { consentDetail,uploadListToLinkEvent } from "../../../services/api";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../../redux/commonSlice";
import { removeDuplicateArrayItem } from "../../../utils/commonServices";

export default ()=> {
    const dispatch = useDispatch();
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);
    const payload = {event_id: mediaFilesReducer.imported_project_id ,current_event_id: commonReducer.production_event_id}
    // const [payload, setPayload] = useState()
    // const [enableConsentDetail, setEnableConsentDetail] = useState(false)
    const [status, setStatus] = useState('')
    const [uploadedVideoList, setUploadedVideoList] = useState([]);
    const [enableUploadListToLinkEvent, setEnableUploadListToLinkEvent] = useState(true)
    ////////////////////////////query for consent detail//////////////////////////


  const {
    data: importProjectData,
    isLoading: importProjectLoading,
    error: importProjectError,
    refetch: importProjectRefetch,
  } = useQuery(
    [`importProject${payload}`, payload],
    () => uploadListToLinkEvent(payload),
    { enabled: enableUploadListToLinkEvent , retry: false }
  );

  
    useEffect(() => {
      if(importProjectLoading == true){
        setStatus('loading')
      }
    }, [importProjectLoading]);
 


  useEffect(()=> {
    if(importProjectData) {
        const responseObject = importProjectData?.data;
        const responseData = responseObject?.data
        setEnableUploadListToLinkEvent(false)  
        
        responseData?.media_files?.length > 0 ? setUploadedVideoList([...removeDuplicateArrayItem(responseData?.media_files)])  : setUploadedVideoList([]);
       if( responseData?.media_files?.length == 0){
        setStatus('empty')
       }
        // console.log(responseData.media_files)
        // console.log(uploadedVideoList)
     
    }
  }, [importProjectData])


return [uploadedVideoList,importProjectRefetch , status]

}