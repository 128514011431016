import { Box, Toolbar, AppBar, Container, Grid } from "@mui/material"
import * as React from 'react';
import { useNavigate } from "react-router-dom";
// import luciLogo from '../../../assets/Luci-Logo.png'
import luciLogo from '../../../assets/luciLogo2.png'
import CustomButton from "../../../components/CustomButton";
import { customTheme as theme } from "../../../theme/customTheme";
import SignInWithApple from "../appleSignin/components/SignInWithApple";

const settings = ['Profile', 'Logout'];

const paths= {
    login: "/signin",
    signup: "/signup"
}

const Header = (props) => {

    const { signup, both, signin } = props

    const navigate = useNavigate();

    const navigateLogin = () => {
        navigate('/signin', {replace: true})
    }

    const navigateSignup = () => {
        navigate('/signup', {replace: true})
    }

    return(<Box >
        <AppBar position="absolute" sx={{ paddingX:{xs:'1vw',sm:'2vw',md:'4vw',lg:'6vw',xl:'6vw'}, backgroundColor:'transparent', paddingTop:{xs:'20px',sm:'25px',md:'25px',lg:'28px',xl:'48px'}}} elevation={0} > 
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ display: "flex", flexDirection: {xs: both ? 'row' : 'column', sm: 'row'}, alignItems: 'center', justifyContent: { xs: "center", sm: "space-between"}}} >
                    <Box mr={2} sx={{ display: {xs: 'flex', md: 'flex'}, alignItems: 'center'}} >
                        <Box sx={{height:{xs:'20px',md:'25px',lg:'30px',xl:'44px'}, mb: 1}}>
                            <img src={luciLogo} height= '100%' />
                        </Box>
                            
                    </Box>
                    {/* <Box mr={2} sx={{height: 120, display: {xs: 'none', md: 'flex'}}} >
                        <img src={luciLogo} height= '120px' />
                    </Box> */}
                    
                    <Box >
                     { signup ?
                                    <Box>
                                        <SignInWithApple />
                                        <CustomButton  variant="contained" btnText="Log In" style={{borderRadius: '5px', border:"none",  textTransform:'none',
                                          backgroundColor:'#00CEFF',
                                         color:'#08231B'}}  onClick={navigateLogin} sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"30px"},  width:{xs:'55px',md:'60px', lg:'70px',xl:'88px'},fontFamily:'Rubik'}}/>
                                    </Box>
                                : 
                                both ? 
                                        <Box>
                                        <CustomButton variant="text" btnText="Log In" style={{borderRadius: '5px', border:"none",  textTransform:'none', color:'#08231B'}}  onClick={navigateLogin} sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'60px',lg:'65px',xl:'88px'}, fontFamily:'Rubik'}}/>
                                  
                                        <CustomButton variant="contained"  btnText="Join"  style={{borderRadius: '5px', border:"none",  textTransform:'none', 
                                        backgroundColor:'#00CEFF',
                                        color:'#08231B'}} onClick={navigateSignup}   sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"30px"}, width:{xs:'40px',sm:'50px',md:'55px',lg:'59px',xl:'88px'}, fontFamily:'Rubik'}}/>
                                   </Box>
                                : signin ?
                                    <Box >
                                        <SignInWithApple/>
                                        <CustomButton variant="contained"  btnText="Join"  style={{borderRadius: '5px', border:"none",  textTransform:'none', 
                                        backgroundColor:'#00CEFF',
                                        color:'#08231B'}} onClick={navigateSignup}   sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"30px"}, width:{xs:'40px',sm:'50px',md:'55px',lg:'59px',xl:'88px'}, fontFamily:'Rubik'}}/>
                                    </Box> : <></>
                                }
                       
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    </Box>)
}


export default Header