import React, { useState, useEffect, useRef } from "react";
import './timer.css'
import { BsChevronUp } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";

const Timer = (props) => {
  const listRef = useRef(null);
  const [theme ] = useCustomTheme()
  const { circularTime, finalTime, setFinalTime, numIntervals = 5, selectedColor, handleClose, finalDate, setFinalValue , extendEditingDate} = props;
  const temparayTimeArr = [...circularTime]
  const [primaryColor, setPrimaryColor] = useState(`${theme.palette.primary.main}99`);
  const [primaryColorLight, setPrimaryColorLight] = useState(`${theme.palette.primary.main}70`);
  const [selectedTime, setSelectedTime] = useState(()=>{
    if( circularTime.includes(finalTime))
    {
      return finalTime
    }
    else {
      return "00:00 AM"
    }
  })
  const [currentIndex, setCurrentIndex] = useState(() => {
    const selectedIndex = circularTime.indexOf(selectedTime);
    return selectedIndex === -1 ? 0 : selectedIndex;
  });

  // const setValues = (time) => {
  //   // let dateString = `${finalValue.toDateString()}, ${time}`

  //   setSelectedTime(time);
  //   setFinalTime(time);
  //   // setFinalValue(new Date(Date.parse(dateString)))
  // }
  const setValues = (time) => {
    // let dateString = `${finalValue.toDateString()}, ${time}`
    // console.log({time})
    setSelectedTime(time);
    let tempValue = new Date(Date.parse(`${finalDate.toDateString()}, ${time}`))
    setFinalTime(time);
    setFinalValue(tempValue.toLocaleString('en-US', {weekday: 'short', day: "2-digit", month: "2-digit", year: "numeric", hour: 'numeric', hour12: true, minute:'numeric'}))
    // setFinalValue(new Date(Date.parse(dateString)))
  }

  useEffect(() => {
    const selectedIndex = circularTime.indexOf(selectedTime);
    if (selectedIndex !== -1) {
      setCurrentIndex(selectedIndex);
    }
    else{
      setCurrentIndex(0);
    }
  }, [selectedTime, circularTime]);

  const downScroll = () => {
    if (currentIndex === circularTime.length - 1) {
      setCurrentIndex(0);
      setValues(circularTime[0]);
    } else {
      setCurrentIndex(currentIndex + 1);
      setValues(circularTime[currentIndex+1]);
    }
  };

  const upScroll = () => {
    if (currentIndex === 0) {
      setCurrentIndex(circularTime.length - 1);
      setValues(circularTime[circularTime.length - 1]);
    } else {
      setCurrentIndex(currentIndex - 1);
      setValues(circularTime[currentIndex - 1]);
    }
  };

  const handleTimeSelection = (selectedTime) => {
    
    let tempValue = new Date(Date.parse(`${finalDate.toDateString()}, ${selectedTime}`))

    setValues(selectedTime);
    setFinalValue(tempValue.toLocaleString('en-US', {weekday: 'short', day: "2-digit", month: "2-digit", year: "numeric", hour: 'numeric', hour12: true, minute:'numeric'}))
    // handleClose(false)
    
    if(extendEditingDate != true){
      handleClose(false)
     
    }
  };

  const handleScroll = (event) => {
    const direction = Math.sign(event.deltaY);
    const currentIndex = circularTime.indexOf(selectedTime) === -1 ? 0 : circularTime.indexOf(selectedTime);
    const nextIndex = (currentIndex + direction) % circularTime.length;
    setValues(circularTime[nextIndex < 0 ? nextIndex + circularTime.length : nextIndex]);
  };

  if (currentIndex + numIntervals > circularTime.length) {
    const remaining = currentIndex + numIntervals - circularTime.length;
    temparayTimeArr.push(...circularTime.slice(0, remaining));
  }

  return (
    <div className="timer"  style={{'--primary-color': primaryColor, '--primary-light': primaryColorLight}}>
        <div className="up-icon" >
          <button className="button" onClick={upScroll}><BsChevronUp />
          </button>
        </div>
        <div className="list" ref={listRef} onWheel={handleScroll}>
        {temparayTimeArr.slice(currentIndex, currentIndex + 5).map((time, i) => (
            <p key={`${circularTime.indexOf(time) === currentIndex ? "selected" : "nonSelected"}_${time}`} onClick={() => handleTimeSelection(time)}
              className={circularTime.indexOf(time) === currentIndex ? "selected time-list" : "time-list"}
              style={{ backgroundColor: circularTime.indexOf(time) === currentIndex ? selectedColor : "" }}
            >{time}</p>
          ))}
        </div>
        <div className="down-icon">
          <button className="button" onClick={downScroll}><BsChevronDown />
          </button>
        </div>
      
    </div>
  );
};

export default Timer;