import { Box, IconButton, ListItemText, Menu, MenuItem, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddIcon from '../icons/AddIcon'
import { useDispatch, useSelector } from 'react-redux';
import { updateTeamState } from '../../../redux/teamSlice';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import CustomSwitch from '../../../components/CustomSwitch';
import usePermissions from '../../../hooks/usePermissions';
import { updateTeamScope, viewTeamMembers } from '../../teams/actions';
import { PERMISSION } from '../../../constants/constants';
import CustomTooltip from '../../../components/CustomTooltip';
// import { customTheme as theme } from '../../../theme/customTheme';
import { generateLighterContrastColor } from '../../../utils/commonServices';
import useCustomTheme from '../../../theme/useCustomTheme';

const AllTeamsCard = () => {

  const commonReducer = useSelector((state) => state.root.commonReducer);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer);
    const dispatch = useDispatch()
    const [teams, setTeams] = useState(teamsReducer.all_teams)
    const [selectedTeam , setSelectedTeam]= useState(teamsReducer.selected_team?.name)
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedTeamId, setSelectedTeamId] = useState(null)
    const [hasPermission] = usePermissions()
    const [theme ] = useCustomTheme()

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [checked, setChecked] = useState(false)

    // const [hasPermission] = usePermissions()

    useEffect(()=>{
      setTeams(teamsReducer.all_teams)
    },[teamsReducer.all_teams])
   
    const teamOptionsHandler =(e, team)=>{
      e.stopPropagation()
      setSelectedTeamId(team.id);
      setMenuAnchorEl(e.currentTarget)
      setChecked(!team.public)
    }

    const closeMenuHandler = (e) => {
      // e.stopPropagation()
      setMenuAnchorEl(null);
      setSelectedTeamId(null);
    };
  

    const deleteTeamHandler = (e, team) => {
      e.stopPropagation()
      dispatch(updateTeamState({delete_team_popup :true , selected_team: team}))
      // console.log("Delete Team:", team);
      closeMenuHandler();
    };
  
 
    const renameTeamHandler = (e,team) => {
      e.stopPropagation()
      dispatch(updateTeamState({rename_team_popup:true,  selected_team: team}))
      // console.log("Rename Team:", team);
      closeMenuHandler();
    };
 

    useEffect(()=>{
      setSelectedTeam(teamsReducer.selected_team?.name)
    },[teamsReducer.selected_team])

    const openCreateTeamsHandler =()=>{
        dispatch(updateTeamState({open_create_team_form: true}))
    }


    const selectTeamHandler =(team)=>{
      // console.log({team})
      setSelectedTeam(team.name)
      // setSelectedTeamId(team.id)
      dispatch(updateTeamState({selected_team: team}))
      const payload = {
        team_id: team?.id
      }
      // console.log({payload})
      viewTeamMembers(payload, dispatch, navigate, {commonReducer})
      // navigate('/dashboard/teams')
    }

    const privacyChangeHandler =(e, team)=>{
      e.stopPropagation()
      const payload = {
        team_id: team?.id,
        public: !team?.public
      }
      // console.log({team})
      // console.log({payload})
      updateTeamScope(payload, dispatch, closeMenuHandler)
      if(e.target.checked == true){
        setChecked(true)
      }else{
        setChecked(false)
      }
    }

   

  return (
    <Box className='flex col' sx={{width:'100%', height:'100%', paddingBottom:'20px', borderRadius:'10px', backgroundColor:'#ffffff'}}>
        <Box className='flex j-between a-center' mb={2} sx={{ padding:'20px 20px 0 20px',}}>
            <Typography sx={{fontSize:{xs:'10px',sm:'11px',md:'13px',lg:'15px',xl:'22px'}}}>Teams</Typography>
            {hasPermission(PERMISSION.ORGANISATION.TEAM.CREATE) && <AddIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' : generateLighterContrastColor(theme.palette.primary.main)} onClick= {openCreateTeamsHandler}  sx={{fontSize:{xs:'20px',sm:'30px',md:'30px', lg:'26px',xl:'50px'}, cursor: 'pointer', }}/>}
            {/* <AddIcon onClick= {openCreateTeamsHandler}  sx={{fontSize:{xs:'20px',sm:'30px',md:'30px', lg:'26px',xl:'50px'}, cursor: 'pointer', }}/> */}
        </Box>
        <Box className='flex col' style={{overflowY:'auto', height:'20vh'}}>
          {teams.map((team, index )=>(
            <Box  key={team.id} className='flex a-center j-between' sx={{cursor:'pointer', backgroundColor:location.pathname !== '/dashboard' && team.id === teamsReducer?.selected_team?.id && `${theme.palette.primary.main}99`, padding:'8px 20px'}} >
                <Box onClick={()=>selectTeamHandler(team)} flex={5} sx={{ width:'80%'}}>
                  <CustomTooltip
              title={team?.name}
              placement= "top"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [-20, -20],
                    },
                  },
                ],
              }} 
              >
                  <Typography  sx={{fontSize:{xs:'8px',sm:'8px',md:'10px',lg:'12px',xl:'17.5px'}, fontWeight:600, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'100%', fontFamily:'Rubik', color:'#000', textTransform:'capitalize'}}>{team?.name}</Typography>
                  </CustomTooltip>
                </Box>
                 
                  {location.pathname =='/dashboard' && hasPermission(PERMISSION.ORGANISATION.TEAM.DELETE) && (<>
                  <Box flex={2} className='flex a-center j-end' >
                    <MoreHorizIcon onClick={(e)=>teamOptionsHandler(e,team)} sx={{fontSize:{xs:'10px',sm:'11px',md:'13px',lg:'15px',xl:'21px'}, color:'black'}}/>
                  </Box>
                  <Menu
                  anchorEl={menuAnchorEl}
                  open={selectedTeamId === team.id}
                  onClose={closeMenuHandler}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: 0,
                    horizontal: 15
                  }}
                  elevation={3}
                  PaperProps={{sx:{
                     width:'180px'
                  }}}
                  
                >
              {  hasPermission(PERMISSION.ORGANISATION.TEAM.UPDATE_SCOPE) &&  <MenuItem disableRipple gap={2} sx={{display:'flex', justifyContent:'space-between', '&:hover':{backgroundColor:`${theme.palette.primary.main}70`}}}>
                    <ListItemText >
                      <Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px' } , color:'#08231B', fontWeight: 600,}}>Private</Typography>      
                    </ListItemText>
                    <div style={{justifySelf:'flex-end'}}>
                        <CustomSwitch
                            checked={checked}
                            onChange={(e)=> privacyChangeHandler(e, team)}
                        />
                    </div>
                  </MenuItem>}
                  { hasPermission(PERMISSION.ORGANISATION.TEAM.RENAME) && <MenuItem disableRipple onClick={(e) => renameTeamHandler(e,team)} sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70` }}}>
                    <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px' }  , color:'#08231B', fontWeight: 600,}}>Rename team</Typography></ListItemText>
                  </MenuItem>}
                 {hasPermission(PERMISSION.ORGANISATION.TEAM.DELETE) &&  <MenuItem disableRipple onClick={(e) => deleteTeamHandler(e, team)} sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70` }}}>
                    <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px'} , fontWeight: 600, color: '#ce0101' }}>Delete team</Typography></ListItemText>
                  </MenuItem>}
                </Menu>
                  </>)  }
              {location.pathname !== '/dashboard' && team.id === teamsReducer?.selected_team?.id && <CheckRoundedIcon style={{fontSize:'16px', fontWeight:600}}/>} 
            </Box>
          ))}
        </Box>
    </Box>
  )
}

export default AllTeamsCard