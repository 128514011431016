import { Box, Button, Typography, getContrastRatio } from "@mui/material";
import CustomAlert from "../../events/components/CustomAlert"
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import CustomCarousel from "./CustomCarousel";
import step1 from '../../../assets/step1.png'
import step2 from '../../../assets/step2.png'
import step3 from '../../../assets/step3.png'
import step4 from '../../../assets/step4.png'
import useRevisionRequests from "../hooks/useRevisionRequests";
import { useEffect } from "react";
import { generateLighterContrastColor } from "../../../utils/commonServices";
import useCustomTheme from "../../../theme/useCustomTheme";


const SubmitChangeRequestTutorialPopup = (props) => {

    const dispatch = useDispatch()
    const reviewEventReducer = useSelector((state)=>state.reviewEventReducer)
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [theme] = useCustomTheme()

    const slides = [
        {img:step1, alt:'step1'},
        {img:step2, alt:'step2'},
        {img:step3, alt:'step3'},
        {img:step4, alt:'step4'},
    ]


    const handleClose = () => {
        dispatch(updateReviewState({open_change_request_tutorial_popup : false}))       
       };



    return (
        <CustomAlert 
          open={reviewEventReducer.open_change_request_tutorial_popup} 
          style={{overflowX:'auto'}}
          height ={ {xs:'400px',lg:'480px', xl:'580px'}}
          width = {{sm: "80%", md:"70%", lg: "65%"}}
          handleClose={handleClose} 
          rightComponent={
          <Box pt={{xs:3,md:2, xl:4}} sx={{display:'flex', flexDirection:'column', paddingX:{xs:'5px',md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%', minWidth:'200px'}} gap={{xs:0.5,md:1,lg:2}}>
         
                <Box mb={1}>
                    <Typography sx={{fontSize:{xs:'10px', lg:'12px', xl:'16px'}, fontWeight:600, color:'#000', fontFamily:'Rubik'}}>How to submit revision</Typography>
                </Box>
                <Box>
                    <Typography sx={{fontSize:{xs:'9px', lg:'10px', xl:'15px'}, fontWeight:600, color:'#000', fontFamily:'Rubik'}}>STEP 1</Typography>
                    <Typography sx={{fontSize:{xs:'9px', lg:'10px', xl:'15px'}, fontWeight:500, color:'#000', fontFamily:'Rubik'}}>Pause the video at the exact spot where you’d like to request a change.</Typography>
                </Box>
                <Box>
                    <Typography sx={{fontSize:{xs:'9px', lg:'10px', xl:'15px'}, fontWeight:600, color:'#000', fontFamily:'Rubik'}}>STEP 2</Typography>
                    <Typography sx={{fontSize:{xs:'9px', lg:'10px', xl:'15px'}, fontWeight:500, color:'#000', fontFamily:'Rubik'}}>Type in your request in the text box.</Typography>
                </Box>
                <Box>
                    <Typography sx={{fontSize:{xs:'9px', lg:'10px', xl:'15px'}, fontWeight:600, color:'#000', fontFamily:'Rubik'}}>STEP 3</Typography>
                    <Typography sx={{fontSize:{xs:'9px', lg:'10px', xl:'15px'}, fontWeight:500, color:'#000', fontFamily:'Rubik'}}>Ensure that the video is timestamped at the correct place. </Typography>
                </Box>
                <Box>
                    <Typography sx={{fontSize:{xs:'9px', lg:'10px', xl:'15px'}, fontWeight:600, color:'#000', fontFamily:'Rubik'}}>STEP 4</Typography>
                    <Typography sx={{fontSize:{xs:'9px', lg:'10px', xl:'15px'}, fontWeight:500, color:'#000', fontFamily:'Rubik'}}>Click ‘Save to Log’ to log your request.</Typography>
                </Box>
          
          </Box>
          }
          >

          

          <Box sx={{display:'flex',  height:'100%'}} px={3} pt={4} pb={1}>
              <Box flexGrow={1} pt={4} pl={{xs:0.5, xl:2}}>
                  <CustomCarousel slides={slides}/>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>
                  <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {handleClose}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'50px',xl:'82px'}, cursor:  'pointer', }}/>
                </Box>
              <Box>    
                <Button 
                variant="text"
                onClick={handleClose}
                 sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline', minWidth:'80px'}}
                >
                Or Go Back
               </Button>
              </Box>
            </Box>
          </Box>
        
        </CustomAlert>
      )
}

export default SubmitChangeRequestTutorialPopup