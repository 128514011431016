import React from "react";
import { Box, SvgIcon } from "@mui/material";
import useCustomTheme from "../../../../theme/useCustomTheme";

const DuplicateFilesImage = (props)=> {
    const [theme] = useCustomTheme()
    return <SvgIcon 
            {...props} 
            xmlns="http://www.w3.org/2000/svg"  
          //  width="136.971" height="193" 
          viewBox="0 0 177.213 196.883">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_157" data-name="Rectangle 157" width="177.213" height="196.883" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_212" data-name="Group 212">
    <path id="Path_148" data-name="Path 148" d="M58.5,167.246c-6.142,0-12.09.062-18.038-.016-7.067-.093-10.677-3.714-10.787-10.788-.087-5.644-.052-11.291-.087-16.936a11.349,11.349,0,0,0-.435-1.735c-5.377,0-10.97.031-16.562-.007C3.352,137.7.015,134.35.01,125.087Q-.009,68.793.013,12.5C.018,3.684,3.605.035,12.379.026q46.839-.05,93.682,0c8.45.011,12.029,3.7,12.136,12.209.067,5.311.088,10.622.153,15.933a6.454,6.454,0,0,0,.594,1.46c5.688,0,11.62-.049,17.552.013,7.372.076,10.958,3.655,11.044,11.037.068,5.934.014,11.869.014,18.428,6.267,0,12.041-.06,17.814.015,8.279.107,11.816,3.638,11.823,11.917q.045,56.793,0,113.585c0,8.5-3.652,12.21-12.119,12.222q-47.09.064-94.179-.005c-8.127-.013-11.763-3.772-11.875-11.962-.073-5.312-.057-10.626-.1-15.939a11.086,11.086,0,0,0-.427-1.7m-38.5-49.365h78.05V20.108H19.993Zm137.1,59.08V79.009h-9.537v5.614q0,34.6,0,69.2c0,10.635-2.841,13.421-13.657,13.423q-24.15.006-48.3,0H79.125v9.711ZM127.519,147.25V49.791H118.2v6.3q0,34.35-.007,68.7c-.009,9.614-3.287,12.949-12.778,12.969-16.763.034-33.529,0-50.292.021-1.77,0-3.537.145-5.384.226v9.241Z" fill="#08231b"/>
    <path id="Path_149" data-name="Path 149" d="M157.1,176.961H79.126V167.25h6.468q24.153,0,48.305,0c10.814,0,13.654-2.789,13.656-13.424q.009-34.6,0-69.2V79.009H157.1Z" fill={theme.palette.secondary.main}/>
    <path id="Path_150" data-name="Path 150" d="M127.519,147.25H49.742v-9.241c1.847-.081,3.614-.225,5.384-.226,16.763-.018,33.529.013,50.292-.021,9.491-.02,12.769-3.355,12.778-12.969q.033-34.35.007-68.7v-6.3h9.316Z" fill={theme.palette.primary.main}/>
    <path id="Path_151" data-name="Path 151" d="M54.672,59.128c0,1.6-.022,3.2.012,4.8.012.556-.159.713-.709.709-3.059-.024-6.117-.013-9.176-.009a2.16,2.16,0,0,0-2.477,2.446q-.006,1.92,0,3.838a2.162,2.162,0,0,0,2.427,2.444c3.059,0,6.117.019,9.176-.014.623-.006.766.189.76.782-.028,3.1-.017,6.2-.01,9.3a2.129,2.129,0,0,0,2.305,2.323c1.3.01,2.6-.037,3.9.012a2.3,2.3,0,0,0,2.505-2.525c-.067-3.056-.006-6.118-.031-9.176,0-.553.156-.713.707-.709,3.1.024,6.2.015,9.3.01a2.155,2.155,0,0,0,2.356-2.344c.011-1.319.007-2.638,0-3.957a2.17,2.17,0,0,0-2.448-2.427c-3.079,0-6.157-.013-9.236.009-.528,0-.68-.153-.676-.679.023-3.119.015-6.239.009-9.356a2.129,2.129,0,0,0-2.324-2.362q-2.038-.013-4.078,0a2.1,2.1,0,0,0-2.285,2.272c-.01,1.539,0,3.08,0,4.618" fill="#08231b"/>
    <path id="Path_152" data-name="Path 152" d="M54.673,59.128c0-1.538-.008-3.079,0-4.618a2.1,2.1,0,0,1,2.285-2.272q2.04-.013,4.078,0A2.128,2.128,0,0,1,63.361,54.6c.007,3.117.014,6.237-.008,9.356-.005.526.147.683.676.679,3.078-.022,6.158-.013,9.235-.009a2.17,2.17,0,0,1,2.449,2.427c0,1.319.009,2.638,0,3.957a2.155,2.155,0,0,1-2.357,2.344c-3.1.005-6.2.014-9.295-.01-.552,0-.712.156-.708.709.025,3.058-.036,6.12.032,9.176a2.3,2.3,0,0,1-2.506,2.525c-1.3-.049-2.6,0-3.9-.012a2.13,2.13,0,0,1-2.306-2.323c-.007-3.1-.018-6.2.01-9.3.006-.593-.135-.788-.76-.782-3.058.033-6.117.018-9.175.014a2.162,2.162,0,0,1-2.428-2.444q0-1.917,0-3.838A2.161,2.161,0,0,1,44.8,64.626c3.058,0,6.117-.015,9.175.009.55,0,.721-.153.709-.709-.034-1.6-.012-3.2-.01-4.8" fill="#08231b"/>
  </g>
  </SvgIcon>
    
  
}
export default DuplicateFilesImage