

import React, { useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
// import { makeStyles } from "@mui/styles";
import { Box, Grid, Button, Typography } from '@mui/material'
//import { updateState } from "../../../redux/selectedFilesSlice";
import { useDispatch, useSelector } from "react-redux";
// import { customTheme as theme } from '../theme/customTheme';
import File from '../../../assets/file.svg'
import "@fontsource/rubik";
import usePermissions from '../../../hooks/usePermissions';
import { PERMISSION } from '../../../constants/constants';
import useCustomTheme from '../../../theme/useCustomTheme';


const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


const DocumentDropzone = (props) => {

    const [tempFiles, setTempFiles] = useState([]);
    const dispatch = useDispatch()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [isDropped, setIsDropped] = useState(false);
    // const listOfAllEvents = commonReducer?.events?.map(({id, name, owner_name})=> ({id, name, owner_name}))
    const [selectedEventId, setSelectedEventId] = useState(null)
    const [selectedProjectName, setSelectedProjectName] = useState('')
    const [uploadedVideoList, setUploadedVideoList] = useState([]);
    // const [uploadedVideos, refetchList] = useUploadedList(true, selectedEventId);
    const [enableUploadList, setEnableUploadList] = useState(false);
    const [hasPermission] = usePermissions()
    const [theme ] = useCustomTheme()
 

    const {
        height,
        //width,
        setFiles,
        setProgress,
        setUploadStatus,
        isDisabled = true,
        setRequestController,
        eventData,
        setEventData,
        refetch,
        refetchEvent,
        listOfAllEvents,flex
    } = props;

    const baseStyle = {
        // height:'20rem',
        // width:'30rem',
        // flex: 1,
       // height: height,
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2em",
        borderWidth: 3,
        borderRadius: 2,
        boxShadow: '0px 5px 15px #00000029',
        borderColor: "#FFFFFF",
        borderStyle: "dashed",
        backgroundColor: "#e6e6e6b3",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    };

    const {
        open,
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        disabled: isDisabled,
        accept:{ 
            'application/pdf': ['.pdf'], 
            'application/msword': ['.doc'], 
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], 
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            //'application/vnd.oasis.opendocument.presentation': ['.odp'],
            //'application/vnd.apple.keynote': ['.key'],
            //'application/vnd.google-apps.presentation': ['.gslides'],
            'text/plain': []
        },
     
        onDrop: (acceptedFiles) => {
            setIsDropped(true)
                setTempFiles(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                );
            // }
         
        },
    });

    useEffect(() => {
        setRequestController(
            tempFiles?.map(() => {
                return new AbortController();
            })
        );
        setProgress(
            tempFiles?.map(() => {
                return 0;
            })
        );
        setUploadStatus(
            tempFiles?.map(() => {
                return { type: "standard", message: "Waiting..." };
            })
        );
        setFiles(tempFiles);
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => tempFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [tempFiles]);

    useEffect(()=> {
        if(refetch) {
            refetch()
            setFiles([])
        }
    }, [])


    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const browseFilesClickHandler = async ()=>{
           
            // if(commonReducer.event_details?.consent_accepted == false){
            //     dispatch(updateState({open_agreement_popup: true}))  
            // }else{
                open()   
                
            
    }



    return (
        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

            <Grid className="container" 
                style={{ display: 'flex' }} 
                sx={{ 
                    display: { xs: 'none', md: 'inline' },
                    height: height ? height : {xs: '60vh', md: '55vh',lg: '55vh' , xl: '56vh'}, 
                    width: '100%'
            }}>

                <Grid  {...getRootProps({ style, className: 'dropzone' })} >
                    <input {...getInputProps()} />
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', width: '100%',}} gap={2}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', width: '50%',}} >

                                <Box sx={{ width:  {xs:'25px',md:'30px',lg:'50px',xl:'74px'}, height:{xs:'20px',md:'25px',lg:'38px',xl:'50px'}, color: '#878887', marginBottom: flex === 'row' ? 0 : {xs:'5px',md:'10px',lg:'15px',xl:'30px'}, }} >
                                 <img src={File} height='100%' width='100%'/>
                             </Box>
                            </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', width: '80%',gap:0.5 }}>

                            <Typography sx={{ fontSize: {xs:'8px',sm:'9px',md:'10px',lg:'14px',xl:'25px'}, fontWeight: 600, margin: 0, padding: 0, textAlign: 'center', letterSpacing: 1.25, color: '#878887', fontFamily: "Rubik" }}>Drag & Drop existing files here</Typography>

                            <Button color='secondary' variant='contained' disabled = {isDisabled} onClick={browseFilesClickHandler} style={{ fontFamily: "Rubik", backgroundColor: isDisabled == true? "#52636C50"  : theme.palette.secondary.main, borderRadius: '5px', border: 'none',  cursor: 'pointer', fontWeight: 600, textTransform: 'capitalize', minWidth:'76px' }} sx={{fontSize:{xs:'7px',sm:'8px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, 
                            // width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},
                            paddingY:{xs:0.2,lg:0.2}}}>
                                Or Browse
                            </Button>
                        </Box>

                        </Box>
                        
                    </Box>
                </Grid>
               
            </Grid>

        </Box>
    );
}

export default DocumentDropzone;