import CustomInput from "../../eventcreation/components/CustomInput";
import { makeStyles } from "@mui/styles";
import { customTheme as theme } from "../../../theme/customTheme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FocusTrap from "@mui/base/FocusTrap";
import useValidator from "../../../hooks/useValidator";
import { userForgotPassword } from "../../../services/api";
import { afterValidate, setInput } from "../../../utils/commonServices";
import { updateState } from "../../../redux/commonSlice";
// import ForgotPasswordPopup from "./ForgotPasswordPopup";
import useAuthRequest from "../../../hooks/useAuthRequest";
// import EmailVerificationPopup from "./EmailVerificationPopup";
// import CustomSnackbar from "../../components/CustomSnackbar";
// import CustomSnackbarDrawer from "../../components/CustomSnackbarDrawer";
import movieIcon from '../../../assets/movieIcon.svg';
import { Box, Grid, Paper, Typography } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import CustomButton from "../../../components/CustomButton";
import { LoadingButton } from "@mui/lab";
import "@fontsource/rubik";
import { PRODUCT_ID } from "../../../constants/constants";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const ForgotPassword = (props) => {

    const navigate = useNavigate();
    const [snackbarProps, setSnackbarProps] = useState({});
    const {openHandler, closeHandler} = props;
    const [buttonText, setButtonText] = useState("Next");
    const [successMsg, setSuccessMsg] = useState("");
    const classes = useStyles();
    const [pageData, setPageData] = useState({email: "", product_id: PRODUCT_ID.lucihub});
    const [disableNext, setDisableNext] = useState(false)
    const [forgotPassword, status] = useAuthRequest("forgot-password", setSnackbarProps, ()=>setDisableNext(false))
    
const [errorObjectArray, checkEmptyFields, checkValue] = useValidator(pageData, "forgot-password");

// useEffect(() => {
//   const onEndEditHandler = setTimeout(() => {
    
//   //   checkExistingUser(pageData?.email)
   
//   //   // Send Axios request here
//   // }, 1000)

//   // if(!errorObjectArray[0]?.errorFlag) {
//   //   setDisableNext(false);
//   // }

  

//   return () => clearTimeout(onEndEditHandler)
// }, [pageData])
// useEffect(()=> {
//   if(errorObjectArray[0]?.error == "A user already exists for this mail. Please try another email!" ) {
//     afterValidate(afterValidatCallback);
//   } else if(errorObjectArray[0]?.error == "new email") {
//     setLocalError("No Luci account has been found associated with this email addresss.")
//   }

// }, [errorObjectArray[0].error])

 useEffect(()=> {
  if(status == "success") {
    setTimeout(()=> {
      navigate('/signin', {replace: true})
    }, 6000)
   // setSuccessMsg("A password reset link has been sent to your Email Address.")
   // setButtonText("Close")
  }
// dispatch(updateState({
//     is_logged: true
// }));
// navigate('/');



 }, [status])

    const handleClose = () => {
        setTimeout(()=> {
          navigate('/signin', {replace: true});
        }, 200);
        closeHandler(false);
      };

      const onBlurHandler = async (event)=> {
        await checkValue(event, true)
      }

      const nextCLickHandler = async (event)=> {
        
      //  console.log("button", event.type)
       setDisableNext(true)
       await checkEmptyFields(event);
       await checkValue(event, true);
       await onBlurHandler({target: {id: "email", value: pageData?.email}})
       
         
         //  afterValidate(afterValidateAccountCheck);
           setTimeout(()=> {
            afterValidate(afterValidateEmailSubmition)
           }, 1000)
         
          // setDisableNext(true)
        
        // } else{
        //   setDisableNext((last)=> {
        //     return !last
        //   })
        // }
        
      }

      // const afterValidateAccountCheck = async ()=> {
        
      //   await checkExistingUser(pageData?.email)
      // }

      const afterValidateEmailSubmition = ()=> {
             forgotPassword(pageData)
      }

        //code to detect enter key for editing Organization Name
  useEffect(() => {
    const keyDownHandler = (event) => {
    //  console.log("child", event.key)
        if (event.key === 'Enter') {
            event.preventDefault();
            nextCLickHandler(event);


        }
    };
    document.addEventListener('keydown', keyDownHandler);

    return () => {
        document.removeEventListener('keydown', keyDownHandler);
    };
}, [pageData]);
  
    

  return (
    <UnauthLayout both>
         <Box  pb={4}>
           <Box className={classes.centerBox} flexDirection='column'>
            
           <Box sx={{marginY:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
           <Typography
                variant="h4"
                color="primary"
                style={{
                  fontWeight: 600,
                  color:'#08231B'
                }}
                sx={{fontSize:{xs:'30px',sm:'40px',md:'40px',lg:'50px',xl:'80px'}, fontFamily:'Rubik'}}
              >Forgot Password</Typography>
            </Box>
            <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column',}}>
              <CustomInput
                id= {errorObjectArray[0]?.id}
                onFocus = {(e)=>{checkEmptyFields(e); setDisableNext(false) }}
                onBlur = {onBlurHandler}
                required
               // msgColor = {successMsg != "" && "rgb(0,157,42)"}
                // errorFlag= {successMsg == "" ? errorObjectArray[0]?.errorFlag : true}
                // errorMsg= {successMsg != "" ? successMsg : errorObjectArray[0]?.error}
                errorFlag= {errorObjectArray[0]?.errorFlag}
                errorMsg= {errorObjectArray[0]?.error}
                // == false && "No Luci account has been found associated with this email addresss." }
               // label="Email Address*"
                type="text"
                max={40}
                placeholder="Email Address*"
                 onChange={(event) => setInput(pageData?.email != "" ? event.target.value : event.target.value.trim() , "email", pageData, setPageData)}
                 value={pageData?.email}       
              />
              </Box>
            <Box width='100%' sx={{mt: 1, display: 'flex', flexDirection: {xs: 'column-reverse', sm: 'row'}, justifyContent: { xs: 'flex-start', sm: 'flex-end'}, alignItems: {xs: 'center', sm: 'flex-start'} }} >
                {/* <CustomButton
                    btnText= 'BACK'
                   // disabled = {status == "loading"}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick = {()=> navigate('/signin', {replace: true})}
                    style={{
                    fontSize: 18,
                    borderRadius: 50,
                    maxWidth: 170,
                    minWidth: 90,
                    marginTop: 30
                    }}
                /> */}
                 <CustomButton variant="text" 
                    btnText="Back" 
                    style={{borderRadius: '5px', border:"none",  textTransform:'none', color:'#08231B'}}  
                    onClick= {()=> navigate('/signin', {replace: true})}
                    sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'60px',lg:'65px',xl:'88px'}, fontFamily:'Rubik'}}/>
                
                {/* <CustomButton
                    btnText={buttonText}
                    disabled = {(disableNext == true && !errorObjectArray[0]?.errorFlag) || status == 'loading' || status == 'success'}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick = {buttonText == "Next" ? nextCLickHandler : handleClose}
                    style={{
                    fontSize: 18,
                    borderRadius: 50,
                    maxWidth: 170,
                    minWidth: 90,
                    marginTop: 30
                    }}
                /> */}
                 <LoadingButton
                   // size="small"
                    disabled={(disableNext == true && !errorObjectArray[0]?.errorFlag) || status == 'loading' || status == 'success'}
                    onClick = {buttonText == "Next" ? nextCLickHandler : handleClose}
                    style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#21D644', textTransform:'none', }} 
                    sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,  minWidth: '94px !important' }}
                   // sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},  opacity: !checked && 0.7, minWidth: '110px !important'}}
                    loading={status == 'loading'}
                    loadingPosition="start"
                 // loading = {true}
                    variant="contained"
                    >
                   {buttonText}
               </LoadingButton> 
            </Box>
                  
           </Box>

         </Box>
    </UnauthLayout>
  )
}

export default ForgotPassword
