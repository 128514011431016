import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { logout, resetAllState } from "../../utils/commonServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../redux/commonSlice";
import { updateAppleSignState } from "../../redux/appleSignSlice";
import { encryptString } from "../../utils/encryptionServices";
import CustomRedirectBackdrop from "../../components/CustomRedirectBackdrop";

const AuthRedirect = (props) => {
  const [searchParams] = useSearchParams();
  const requestType = searchParams.get('request');
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const appleSignReducer = useSelector((state) => state.appleSignReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [banner, setBanner] = useState("Please wait while we redirect for authentication...")

  useEffect(() => {

    if (requestType == 'logout') {
      if (!!window.localStorage.getItem('token')) {
        // dispatch(updateAppleSignState({is_redirected_from_butterfly: true}))
        logout(dispatch)
      }
      navigate('/signin')

    }

    if (requestType == 'login') {

      if (!!window.localStorage.getItem('token')) {
        console.log("plain token", window.localStorage.getItem('token'))
        // console.log("encrypted token", encryptString(window.localStorage.getItem('token')) )
        const encryptedToken = encryptString(window.localStorage.getItem('token'));
        const encodedURIString = encodeURIComponent(encryptedToken);
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_COPILOT_URL}/redirect?k=${encodedURIString}`;
        }, 3000)

      } else {
        if (window.localStorage.getItem('logout_all') !== 'true') {
          dispatch(updateAppleSignState({ is_redirected_from_butterfly: true }))

        }
        navigate('/signin')
      }

    }
    if (requestType == 'timeout') {
       setBanner("Your request is timed out! This may be due to slower internet.")
    }

  }, [requestType, window.localStorage.getItem('token'), commonReducer.logout_all])

  return <Box sx={{ display: 'flex', color: '#000000', backgroundColor: '#ffffff', width: '100%', height: '100vh', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Typography fontSize={34} p={8}>{banner}</Typography>
  </Box>


}

export default AuthRedirect