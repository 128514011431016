import { Box, Typography } from "@mui/material";
import React from "react";

const PasswordInstructions = ()=> {
    return <Box>
        <Typography variant="body1"> Password must include atleast: </Typography>
        <Typography variant="body1"> - 1 uppercase character (A-Z)  </Typography>
        <Typography variant="body1"> - 1 lowercase character (a-z) </Typography>
        <Typography variant="body1"> - 1 digit (0-9) </Typography>
        <Typography variant="body1"> - 1 special character (!,@,#,$,%,&,*,?) </Typography>
        <Typography variant="body1"> - 8 charcters in length (max 40) </Typography>
    </Box>
}
export default PasswordInstructions

   //"The password must be minimum 8 characters with at least 1 number, 1 lowercase, 1 uppercase and 1 special character" 
           