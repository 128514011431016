import { Box, Checkbox, IconButton, Typography } from '@mui/material'
import React from 'react'
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StarIcon from '@mui/icons-material/Star';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { fileFormat, removeExtension, sizeConverter, timeStampToNumberedDateString } from '../../../utils/commonServices';
import { makeStyles } from "@mui/styles";
import CustomTooltip from '../../../components/CustomTooltip';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    checkboxRoot: {
        pointerEvents: 'none',
      },
    container: {
        width:'100%',
        borderCollapse: 'collapse',
       // paddingRight:'4%'
       scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
                 display : 'none'
               }
    },
    tableRowHead:{
        textAlign:'left',
        verticalAlign:'top',
        color:'#08231B',
        fontSize:'15px',
        fontFamily: "Rubik",
        fontWeight:500,
        letterSpacing: '0.75px',
        zIndex: 15
    },
    tableRowData:{
        borderBottom:'2px solid #EBEBEB',
        // height:{xs:'50px',md:'55px',lg:'60px', xl:'68px'},
        // height:'62px',
        backgroundColor:'transparent',
        fontFamily: "Rubik",
        textAlign:'left',
        width:'100%',
        fontSize:'15px',
        // font: 'normal normal 600 15px/18px ',
        fontWeight:600,
        color:'#08231B',
        letterSpacing: '0.75px',
        zIndex: 15
    },
 
  }));

const UserMediaFilesPopup = (props) => {

    const classes = useStyles();
    const {files, handleSelectAllChange, handleFileCheckboxChange, selectAll, popup, isLoading}= props
    // console.log(popup)
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);

    const selectedProjectName = commonReducer.events?.filter(event => event.id == mediaFilesReducer.imported_project_id)[0]?.name
    // console.log(selectedProjectName)

  return (
    // <Box>
           <Box mt={3} className='flex col' sx={{minWidth:'600px'}}>
                <Box sx={{ display:'flex', alignItems:'center'}} mb={{xs:2,xl:4}}>
                    <Box flex={0.3}  className='flex a-center'  style={{  minWidth:'90px', justifyContent:'flex-start'}}>
                        <Checkbox 
                        size='medium'
                           sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'},},  zIndex:0}} 
                           onChange={(e)=>handleSelectAllChange(e)} 
                           checked={selectAll}    
                           checkedIcon={<CheckBoxOutlinedIcon/>}          
                       />
                    </Box>
                    <Box flex={4} style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24,  minWidth:'220px'}}><Typography  sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik' , color:'#08231B'}}>File Name</Typography></Box>

                    <Box flex={0.7} style={{  minWidth:'90px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'},  fontFamily: 'Rubik' , color:'#08231B'}}>File Type</Typography></Box>

                    <Box flex={0.7} style={{  minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik' , color:'#08231B' }}>Size</Typography></Box>

                    <Box flex={0.7} style={{  minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}>Upload Date</Typography></Box>

                    {/* <Box flex={0.7} style={{  minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize:{xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}>Available Until</Typography></Box> */}

                    <Box flex={0.7}  style={{  minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}>Uploaded By</Typography></Box>

                    <Box flex={1} style={{  minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}>Project Name</Typography></Box>

                    <Box flex={0.5}   ><Typography  sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}></Typography></Box>
                </Box>
                <Box sx={{overflowY:'scroll', height:popup == true ? '30vh':'50vh'}}>

                {/* {isLoading == true ? <Typography textAlign={'center'} mt={'5%'}>Loading...</Typography>:(files?.length == 0 &&  <Typography textAlign={'center'} mt={'5%'}>No Media Files present for this event.</Typography>) } */}

                {/* {files?.length == 0 &&  <Typography textAlign={'center'} mt={'5%'}>No Media Files present for this event.</Typography>} */}
                    {files?.length == 0 ? (<Typography textAlign={'center'} mt={'5%'}>{isLoading == 'loading'? 'Loading...': isLoading =='empty' && 'No Media Files to show.'}</Typography>) : files?.map((file, index) => (
                        <Box className={classes.tableRowData}  key={file?.size + index} sx={{display:'flex',  alignItems:'center', height: popup == true ?{md:'40px',lg:'45px', xl:'60px'}:{lg:'55px',xl:'65px'}}}>
                            <Box flex={0.3}  className='flex'  style={{  minWidth:'90px', justifyContent:'flex-start'}}>
                                <Checkbox 
                                
                                size='medium'
                                sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'}, '& .MuiSvgIcon-root':{
                                    fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'}, },  zIndex:0, }} 
                                onChange={()=>handleFileCheckboxChange(index)} 
                                checked={file?.checked}  
                                checkedIcon={<CheckBoxOutlinedIcon/>}           
                                />
                            </Box>
                
                    <CustomTooltip title = {file?.name} >
                            <Box flex={4} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, wordWrap:'break-word', minWidth:'220px'}}><Typography  sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, wordWrap:'break-word', fontFamily: 'Rubik', }}>{removeExtension(file?.name)}</Typography></Box>
                    </CustomTooltip> 

                            <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'90px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik' }}>{file?.content_type}</Typography></Box>

                            <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{sizeConverter(parseInt(file?.size))}</Typography></Box>

                            <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(file?.upload_time)}</Typography></Box>

                            {/* <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{(file?.uploadDate)}</Typography></Box> */}

                            <Box flex={0.7} className='flex j-center' style={{ minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{file?.uploaded_by}</Typography></Box>

                            <Box flex={1}  className='flex j-center'  style={{ minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{file?.event_name ? file?.event_name : selectedProjectName}</Typography></Box>

                            <Box flex={0.5}  sx={{ display:'flex', justifyContent:'end'}}>
                            <Box sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, display:'flex', alignItems:'center',justifyContent:'end' }} gap={2}>
                
                            <>           
                            <Checkbox
                        //    disabled={true}
                            classes={{
                            root: classes.checkboxRoot,
                            }}
                            sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'}},  zIndex:0}} 
                        //    onClick={()=>favoriteHandler(file)} 
                            checked={file?.favourite}  
                            icon={<StarOutlineIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'},  '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'},
                            }}}/>} 
                            checkedIcon={<StarIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'},
                            } }}/>} /></>
                           
                      {/* { popup != true &&  <IconButton 
                            //   disabled={file?.video_url == null || file?.video_url == ""}  
                            //   onClick={()=>videoDownloadHandler(file)} 
                              sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'}, 
                                  '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'},}}} 
                            >
                              <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'},  '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'},}}}/>
                            </IconButton>} */}
                        </Box>
                    </Box>
               </Box>
               ))}
            </Box>
            </Box>
    // </Box>
  )
}

export default UserMediaFilesPopup