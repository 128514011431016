import React, { useEffect, useState } from 'react'
import { getInitials } from '../../../utils/commonServices'
import { Avatar, Box, Collapse, IconButton, ListItemText, Menu, MenuList, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeamState } from '../../../redux/teamSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateProjectState } from '../../../redux/projectSlice';
import { updateProjectMemberRole } from '../actions';
import usePermissions from '../../../hooks/usePermissions';
import { PERMISSION } from '../../../constants/constants';
import { colors } from '../../../constants/staticData';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';

const ProjectMembersList = ({member, index, searchedArray, setTestmembers, roleOptions}) => {

  
    // const colorsCollaborator = ['#08231B', '#49A086', '#00CDFE'];
  

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRole, setSelectedRole] = useState(member?.role?.name);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer);
    const projectReducer = useSelector((state) => state.projectReducer);
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [hasPermission] = usePermissions();
    const [theme ] = useCustomTheme()

    // const roleOptions = ['Owner', 'Contributor', 'Filmer'];
    const [numberOfAdmins, setNumberOfAdmins] = useState(searchedArray.filter(member => member.role?.id == 5)?.length)

    const [numberOfProjectMembers, setNumberOfProjectMembers] = useState(searchedArray.filter(member => member.role?.id == 5)?.length)

    // useEffect(()=>{
    //   dispatch(updateProjectState({selected_member: member}))
    // },[member])

    // useEffect(()=>{
    //   setSelectedRole(member.role.name)
    // },[member.role.name, member])

    // console.log({numberOfAdmins})

    useEffect(()=>{
      setNumberOfAdmins(commonReducer?.selected_project_members?.filter(member => member.role?.id == 5)?.length)  
  },[searchedArray, commonReducer?.selected_project_members])
    
    
    // useEffect(()=>{
    //   if(location.pathname == '/dashboard'){
    //     setNumberOfAdmins(teamsReducer.organization_members?.filter((item) => item.role == 'Administrator').length)
    //   }
    //   if(location.pathname == '/dashboard/teams'){
    //     setNumberOfAdmins(teamsReducer.selected_team.team_members?.filter((item) => item.role == 'Administrator').length)
    //   }
    // },[testmembers,teamsReducer.organization_members , teamsReducer.selected_team.team_members])
   

  
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
  
    const handleRoleSelect = (role) => {  
      if (numberOfAdmins === 1 && member?.role?.id == 5){
        // assign new project manager popup
        dispatch(updateProjectState({remove_owner_popup: true, invite_project_members_popup : false, change_project_admin_flag: true}))
      }else{
      const payload = {
          event_id: commonReducer?.production_event_id,
          user_id: member?.id,
          role_id: role?.id
      }
      // console.log(payload)
      // console.log({role})
      updateProjectMemberRole(payload, dispatch, navigate, {commonReducer, permissionsReducer}, setSelectedRole, role)
      // setSelectedRole(role.name);
     }
    setAnchorEl(null);
    };


    const handleDeleteMember =()=>{  
      // console.log({payload}, 'project members list')

      if(member.role.id == 5){
       
        dispatch(updateProjectState({selected_member: member}))
        if (numberOfAdmins == 1) {
          dispatch(updateProjectState({remove_owner_popup : true}))
        } else {
          dispatch(updateProjectState({remove_user_confirmation_popup : true}))
        }
      }else{
        dispatch(updateProjectState({selected_member: member}))
        dispatch(updateProjectState({remove_user_confirmation_popup : true}))
      }
      dispatch(updateProjectState({invite_project_members_popup : false}))   


    //   console.log({member})
    //   console.log(teamsReducer?.selected_team_members?.users)
    //   if(member.role?.id == 3){
    //     console.log({numberOfAdmins})
    //     if (numberOfAdmins == 1) {
    //       dispatch(updateTeamState({assign_role_popup : true}))
    //     } else {
    //       dispatch(updateTeamState({selected_member: member}))
    //       dispatch(updateTeamState({remove_member_confirmation_popup : true}))
    //     }
    //   }else{
    //     dispatch(updateTeamState({selected_member: member}))
    //     dispatch(updateTeamState({remove_member_confirmation_popup : true}))
    //   }
    // dispatch(updateTeamState({invite_team_members_popup : false}))  
    }
  
   

  return (
    <div style={{width:'90%'}} >
        {searchedArray?.length > 0 &&
            <>
            <Box mb={{xs:1, xl:3}} className='flex a-center' gap={2}>
    
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: colors[index % colors.length],
                                    color: 'white',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    fontWeight:600
                                    }}
                                >
                                    {getInitials(member.name)}
                                </Avatar>
                <Box flex={8} className='flex col' sx={{fontSize:{xs:'9px', md:'10px',lg:'13px', xl:'18px'}, fontWeight:600, color:'#000', fontFamily:'Rubik'}}>
                   {member.name}
                    <br />
                    {/* <span style={{fontSize:'10px', fontWeight:500}}>{member.email}</span> */}
                </Box>
             <Box flex={3} className='flex a-center' style={{justifyContent:'end', minWidth: '150px'}}>
                    <Typography sx={{ fontSize: {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500 , color: member?.role?.change_allowed ? '#000000': 'gray', fontFamily:'Rubik'}}>
                        {/* { selectedRole || member.role} */}
                        {member?.role?.name}
                    </Typography>
                
                    {projectReducer.invite_project_members_popup == true && 
                    <IconButton disabled={projectReducer.is_loading ||  !member?.role?.change_allowed} edge="end"  onClick={handleClick}>
                        <ExpandMoreIcon  style={{ fontSize: '14px' }} />
                    </IconButton>
                    }
                
                </Box>
            </Box>
     
            </>}
    

         <Menu
            id="role-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
               
                vertical: 0,
                horizontal: 150
            }}
          >
            {hasPermission(PERMISSION.PROJECT.MEMBER.UPDATE) && roleOptions.map((role, index) => (
              <MenuItem key={index} onClick={() => handleRoleSelect(role)} sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70`, color:'black'}, backgroundColor: selectedRole == role.name && `${theme.palette.primary.main}99`}}>
              {/* {testmembers.filter(item => item.id == member.id)[0]?.role} */}
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Set as {role?.name}</Typography></ListItemText>
                {selectedRole == role.name && <CheckRoundedIcon style={{fontSize:'10px', fontWeight:600}}/>}
              </MenuItem>
            ))}
           {hasPermission(PERMISSION.PROJECT.MEMBER.REMOVE) && <MenuItem onClick={() => handleDeleteMember()} sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70`, color:'black'}}}>
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#CE0101' } }}>Remove from Project</Typography></ListItemText>
            </MenuItem>}
     
        </Menu> 
    </div>
  )
}

export default ProjectMembersList