import React from "react";
import { Box, SvgIcon } from "@mui/material";
import useCustomTheme from "../../../../theme/useCustomTheme";

const UpdatedImage = (props)=> {
    const [theme] = useCustomTheme()
    return <SvgIcon 
            {...props} 
            id="Group_225" data-name="Group 225" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 278.102 167.089">
    <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_170" data-name="Rectangle 170" width="278.102" height="167.089" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_224" data-name="Group 224" clip-path="url(#clip-path)">
    <path id="Path_156" data-name="Path 156" d="M1.973,131.007l12.141,1.1c-.374,4.113-.739,8.137-1.127,12.408l-12.14-1.1c.374-4.128.731-8.052,1.126-12.408" fill={theme.palette.primary.main}/>
    <path id="Path_157" data-name="Path 157" d="M259.47,144.091a5.624,5.624,0,1,1-5.125-5.9,5.6,5.6,0,0,1,5.125,5.9" fill={theme.palette.secondary.main}/>
    <path id="Path_158" data-name="Path 158" d="M268.333,84.493c-.266,2.928-.532,5.856-.818,9.015l-9.1-.826q.409-4.506.818-9l-.09.079,9.273.844Z" fill="#08231b"/>
    <path id="Path_159" data-name="Path 159" d="M259.238,83.68l-8.988-.816c.233-2.571.357-5.093.776-7.565.083-.491-.533-1.119.16-1.12,2.346,0,6.521.245,8.868.4l-.071-.1q-.417,4.643-.835,9.284Z"/>
    <path id="Path_160" data-name="Path 160" d="M269.178,75.4l8.924.81c-.295,3.251-.545,6.013-.826,9.1l-8.943-.812.088.11.843-9.281Z" fill="#08231b"/>
    <path id="Path_161" data-name="Path 161" d="M260.054,74.575c.254-2.8.508-5.6.781-8.6l9.123.828c-.256,2.825-.518,5.708-.78,8.592l.086-.075-9.281-.847Z" fill="#08231b"/>
    <path id="Path_162" data-name="Path 162" d="M259.983,74.475l9.281.848-.843,9.28-9.273-.844q.417-4.643.835-9.284" fill={theme.palette.primary.main}/>
    <rect id="Rectangle_160" data-name="Rectangle 160" width="9.508" height="9.509" transform="translate(249.808 82.924) rotate(-84.732)" fill="#08231b"/>
    <path id="Path_163" data-name="Path 163" d="M13.292,44.967l6.366-2.76c1.006,2.319,1.86,4.29,2.814,6.49l-6.38,2.766.1.042-2.869-6.621Z" fill="#08231b"/>
    <path id="Path_164" data-name="Path 164" d="M16.092,51.463C17,53.552,17.9,55.64,18.88,57.894l-6.488,2.812L9.608,54.284l-.032.087,6.617-2.866Z" fill="#08231b"/>
    <path id="Path_165" data-name="Path 165" d="M9.608,54.284,3.2,57.064c-.8-1.835-1.647-3.595-2.279-5.43-.125-.365-.777-.563-.306-.821,1.593-.873,4.523-2.253,6.175-3.02L6.7,47.751l2.876,6.62Z"/>
    <path id="Path_166" data-name="Path 166" d="M6.786,47.793,4.127,41.659l6.509-2.821,2.656,6.129.031-.083L6.7,47.751Z" fill="#08231b"/>
    <path id="Path_167" data-name="Path 167" d="M6.7,47.751l6.623-2.867,2.869,6.621L9.576,54.371,6.7,47.751" fill={theme.palette.secondary.main}/>
    <rect id="Rectangle_161" data-name="Rectangle 161" width="7.363" height="7.363" transform="translate(0 50.508) rotate(-23.355)" fill="#08231b"/>
    <path id="Path_168" data-name="Path 168" d="M132.688,167.082q-32.844,0-65.688,0c-17.822-.01-27.866-9.929-27.876-27.586q-.033-55.948,0-111.9C39.133,10.109,49.147.021,66.5.014q66.186-.026,132.372,0c16.827.009,27.1,10.134,27.12,26.83q.07,56.694,0,113.389c-.021,16.689-10.3,26.818-27.116,26.84q-33.093.042-66.186.007m83.137-127.33H49.378V127.24H215.825ZM127.4,10.225h-19.11V29.267H127.4Zm10.411,19.034h19.055V10.208H137.812Zm-29.542,127.5h18.912V137.774H108.27Zm48.55-18.923H137.837v19.142H156.82ZM97.764,10.221h-18.8V29.327h18.8Zm69.633,19.1h18.8V10.195H167.4ZM97.891,137.762H79.058v19.105H97.891Zm69.371,19.017h18.8V137.843h-18.8ZM68.523,10.335c-13.506-1.552-21.555,6.694-18.79,18.919h18.79ZM215.6,29.6c1.831-13.582-6.338-21.54-18.87-19.028V29.346c1.28.1,2.244.236,3.208.24,5.116.022,10.232.011,15.662.011M49.523,137.792c-1.93,13.134,6.244,21.146,18.89,18.754V137.792ZM196.66,156.644c13.113,2,21.184-6.091,18.834-18.858H196.66Z"/>
    <rect id="Rectangle_162" data-name="Rectangle 162" width="19.11" height="19.042" transform="translate(108.291 10.225)" fill={theme.palette.primary.main}/>
    <rect id="Rectangle_163" data-name="Rectangle 163" width="19.055" height="19.051" transform="translate(137.812 10.208)" fill={theme.palette.secondary.main}/>
    <rect id="Rectangle_164" data-name="Rectangle 164" width="18.912" height="18.988" transform="translate(108.27 137.774)" fill={theme.palette.secondary.main}/>
    <rect id="Rectangle_165" data-name="Rectangle 165" width="18.983" height="19.142" transform="translate(137.836 137.838)" fill={theme.palette.primary.main}/>
    <rect id="Rectangle_166" data-name="Rectangle 166" width="18.803" height="19.106" transform="translate(78.961 10.22)" fill={theme.palette.secondary.main}/>
    <rect id="Rectangle_167" data-name="Rectangle 167" width="18.799" height="19.127" transform="translate(167.396 10.194)" fill={theme.palette.primary.main}/>
    <rect id="Rectangle_168" data-name="Rectangle 168" width="18.833" height="19.105" transform="translate(79.058 137.761)" fill={theme.palette.primary.main}/>
    <rect id="Rectangle_169" data-name="Rectangle 169" width="18.8" height="18.936" transform="translate(167.261 137.843)" fill={theme.palette.secondary.main}/>
    <path id="Path_169" data-name="Path 169" d="M68.523,10.335V29.254H49.733c-2.765-12.225,5.284-20.471,18.79-18.919" fill={theme.palette.primary.main}/>
    <path id="Path_170" data-name="Path 170" d="M215.6,29.6c-5.429,0-10.546.011-15.662-.01-.964,0-1.927-.141-3.208-.241V10.568c12.533-2.512,20.7,5.447,18.87,19.028" fill={theme.palette.secondary.main}/>
    <path id="Path_171" data-name="Path 171" d="M49.522,137.792H68.413v18.754c-12.647,2.391-20.821-5.621-18.891-18.754" fill={theme.palette.secondary.main}/>
    <path id="Path_172" data-name="Path 172" d="M196.66,156.644V137.786h18.834c2.35,12.767-5.721,20.853-18.834,18.858" fill={theme.palette.primary.main}/>
    <path id="Path_173" data-name="Path 173" d="M107.892,83.4c0-7.445-.068-14.891.021-22.335.078-6.477,3.152-8.425,8.907-5.58,15.262,7.546,30.521,15.1,45.648,22.911,1.878.97,3.932,3.414,4.092,5.326.129,1.549-2.127,3.985-3.906,4.9-15.288,7.858-30.684,15.509-46.1,23.113-5.418,2.673-8.549.648-8.635-5.5-.1-7.609-.025-15.221-.026-22.832m10,16.686,33.1-16.567-33.1-16.471Z"/>
  </g>

  </SvgIcon>
    
  
}
export default UpdatedImage