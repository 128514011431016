import React from "react";
import { Box, SvgIcon } from "@mui/material";
import useCustomTheme from "../../../theme/useCustomTheme";

const RenameTeamImage = (props)=> {
    const [theme] = useCustomTheme()
    return <SvgIcon 
    {...props}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.209 176.142">
     <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_190" data-name="Rectangle 190" width="239.209" height="176.142" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_264-2" data-name="Group 264">
    <path id="Path_158" data-name="Path 158" d="M194.277,52.015,176.811,53.6c.538,5.916,1.063,11.705,1.621,17.849L195.9,69.864c-.539-5.937-1.052-11.583-1.621-17.849" transform="translate(43.312 12.742)" fill={theme.palette.secondary.main}/>
    <path id="Path_159" data-name="Path 159" d="M35.214,4.691a4.45,4.45,0,1,0,8.867-.781,4.453,4.453,0,0,0-8.867.781" transform="translate(8.624 0)" fill={theme.palette.primary.main}/>
    <path id="Path_160" data-name="Path 160" d="M148.73,121.511a11.619,11.619,0,1,1-15.7-17.128,11.619,11.619,0,0,1,15.7,17.128m-3.96-4.354a5.768,5.768,0,0,0,.371-8.118,5.993,5.993,0,0,0-8.324-.4,5.826,5.826,0,1,0,7.953,8.517" transform="translate(31.65 24.822)" fill="#08231b"/>
    <path id="Path_161" data-name="Path 161" d="M135.38,115.767a5.843,5.843,0,0,0,8.634-7.873,5.993,5.993,0,0,0-8.324-.4,5.811,5.811,0,0,0-.31,8.272" transform="translate(32.776 25.967)" fill={theme.palette.primary.main}/>
    <path id="Path_162" data-name="Path 162" d="M12.077,57.036,0,58.133c.4,4.4.74,8.137,1.118,12.311l12.1-1.1-.12.149q-.571-6.28-1.142-12.559Z" transform="translate(0 13.947)" fill="#08231b"/>
    <path id="Path_163" data-name="Path 163" d="M10.643,67.12c.36,3.963.72,7.924,1.108,12.2L24.057,78.2q-.553-6.1-1.106-12.183l.122.107-12.55,1.143Z" transform="translate(2.578 16.172)" fill="#08231b"/>
    <path id="Path_164" data-name="Path 164" d="M20.723,68.549l12.163-1.1c-.315-3.48-.483-6.892-1.049-10.237-.113-.665.721-1.514-.217-1.516-3.176,0-8.826.331-12,.538l.1-.137q.566,6.282,1.13,12.564Z" transform="translate(4.806 13.642)"/>
    <path id="Path_165" data-name="Path 165" d="M22.256,58.411c-.344-3.788-.687-7.577-1.056-11.635L8.853,47.9c.347,3.822.7,7.725,1.056,11.627l-.116-.1,12.559-1.147Z" transform="translate(2.169 11.458)" fill="#08231b"/>
    <path id="Path_166" data-name="Path 166" d="M22.167,56.013,9.607,57.16q.571,6.28,1.14,12.559L23.3,68.577q-.566-6.282-1.13-12.564" transform="translate(2.353 13.721)" fill={theme.palette.secondary.main}/>
    <rect id="Rectangle_189" data-name="Rectangle 189" width="12.868" height="12.868" transform="translate(24.295 69.537) rotate(-5.268)" fill="#08231b"/>
    <path id="Path_167" data-name="Path 167" d="M40.34,139.795c-4.639-14.549,1.753-27.174,10.08-39.93C65.324,77.035,79.129,53.49,93.637,30.4c7.7-12.262,21.428-18.1,34.626-15.39,14.245,2.918,25.219,13.5,26.555,28.208.634,6.969-.707,15.317-4.192,21.259C133.7,93.339,115.8,121.637,98.043,150.011a17.279,17.279,0,0,1-6.888,5.891c-10.769,5.234-21.691,10.16-32.627,15.042-10.113,4.515-17.8-.321-18.153-11.421-.2-6.195-.035-12.4-.035-19.728m22.673,3c11.871-2.974,16.6-6.308,22.429-15.721,9.3-15,18.586-30.009,28.08-45.337L95.953,70.957c-1.423,2.129-2.595,3.775-3.658,5.488-7.947,12.8-15.2,26.089-24.024,38.248-6.534,9-6.006,18.223-5.257,28.1m45.07-91.9,17.649,10.968c3.124-6.514,9.819-11.551,5.211-18.915a13.04,13.04,0,0,0-9.437-5.3c-7.965-.015-9.922,7.293-13.423,13.244" transform="translate(9.499 3.517)" fill="#08231b"/>
    <path id="Path_168" data-name="Path 168" d="M58.329,131.659c-.748-9.881-1.276-19.1,5.257-28.1C72.409,91.4,79.664,78.1,87.611,65.308c1.063-1.713,2.235-3.359,3.658-5.488L108.839,70.6c-9.494,15.328-18.784,30.335-28.08,45.337-5.834,9.413-10.557,12.747-22.429,15.721" transform="translate(14.183 14.654)" fill={theme.palette.primary.main}/>
    <path id="Path_169" data-name="Path 169" d="M94.447,46.313c3.5-5.951,5.458-13.259,13.423-13.244a13.04,13.04,0,0,1,9.437,5.3c4.608,7.364-2.088,12.4-5.211,18.915L94.447,46.313" transform="translate(23.136 8.101)" fill={theme.palette.secondary.main}/>
  </g>

  </SvgIcon>
    
  
}
export default RenameTeamImage