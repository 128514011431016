import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { resetState, updateState } from "../redux/commonSlice";
import { uploadList } from "../services/api";
import { removeDuplicateArrayItem } from "../utils/commonServices";
import useSnackMessage from "./useSnackMessage";

export default (enable = true, ) => {
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer)
  const dispatch = useDispatch();
  //Get approver dropdown array from Server
  //const uploadListLabel = 'value';
  const [uploadedVideoList, setUploadedVideoList] = useState([]);
  const [uploadListStatus, setUploadListStatus] = useState('')
  const [enableUploadList, setEnableUploadList] = useState(enable);
  const [showSnackbar] = useSnackMessage();
  // const [approverError, setApproverError] = useState("Please select approver");
  const {
    data: uploadListData,
    error: uploadListError,
    isLoading: uploadListLoading,
    refetch: refetchUploadList,
    isRefetching: uploadListRefetching
  } = useQuery(
    [
      `uploadList${commonReducer.production_event_id}${Math.random}`,
    commonReducer.production_event_id
    ],
    () => uploadList({ id: commonReducer.production_event_id }),
    { enabled: enableUploadList, retry: false }
  );

  useEffect(() => {
    if (uploadListData) {
      setUploadListStatus('success')
      const responseObject = uploadListData?.data;
    const responseData = responseObject?.data
      setEnableUploadList(false);
      responseData?.media_files?.length > 0 ? setUploadedVideoList([...removeDuplicateArrayItem(responseData?.media_files)]) : setUploadedVideoList([]);
    }
  }, [uploadListData]);

  useEffect(() => {
    if(uploadListError){
      setUploadListStatus("error")
      const errorObject = uploadListError?.response?.data;
      showSnackbar(errorObject);
    }
    // if (uploadListError?.response?.status == 401) {
    //   window.localStorage.removeItem("token");
    //   dispatch(resetState());
    // }
    // if (uploadListError) {
    //     console.log({uploadListError})
    // }
  }, [uploadListError]);

  useEffect(()=> {
    if(uploadListRefetching == true) {
      setUploadListStatus("loading")
      dispatch(updateState({is_media_loading: true}))
    } else {
      dispatch(updateState({is_media_loading: false}))
    }
     

  },[uploadListRefetching])

  const selecteduploadList = (id) => {
    if (uploadList.length > 0 && uploadList.find((item) => item.id == id)) {
      // setApproverError("");
      return uploadList.find((item) => item.id == id);
    } else {
      return {};
    }
  };

  const enableGetUploadedMediaFiles = ()=>{
    setEnableUploadList(true)
  }

  return [uploadedVideoList, refetchUploadList, uploadListStatus, uploadListLoading, enableGetUploadedMediaFiles];
};
