import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { appleSign } from "../../../../services/api";
import { updateState } from "../../../../redux/commonSlice";
import { updateAppleSignState } from "../../../../redux/appleSignSlice";
import { gaEvents } from "../../../../ga/actions";
import { useNavigate } from "react-router-dom";
import { setAssetState } from "../../../../redux/assetSlice";
import { getPermissions } from "../../../../commonactions/actions";
import { getTheme } from "../../../whitelabel/actions";
import { showSnackBar } from "../../../../utils/commonServices";


export default () => {
  const [appleSignStatus, setAppleSignStatus] = useState("");
  //const [eventsListStatus, setEventsListStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //POST API Request to create new event
  const { mutateAsync: appleSignMutate, isLoading: appleSignLoading} = useMutation(appleSign, {
    onSuccess: (response, variables, context) =>
      appleSignSuccessHandler(response, variables, context),
    onError: (error, variables, context) =>
      appleSignErrorHandler(error, variables, context),
  });

  const appleSignSuccessHandler = (response, variables, context) => {
    if (response?.data && response.data?.http_code == 200 ) {
      
        //console.log("luci response", response?.data?.message);
        if(response.data?.data?.email_verified == false) {
          setAppleSignStatus("check_mail");
        } else {
          gaEvents.loginEvent()
          window.localStorage.setItem("token", response.data?.data?.token);
          dispatch(
            updateState({
              is_logged: true,
              user_name: response.data?.data?.name,
              user_id: response.data?.data?.user_id,
              company_name:response.data?.data?.company_name,
              show_revision_tutorial: response.data?.data?.show_revision_tutorial,
              is_company_name_available: response.data?.data?.company_name  ? true : false,
            }));
            dispatch(setAssetState({
              is_brand_name_available: response.data?.data?.company_name  ? true : false,
            }))
          setAppleSignStatus("success");
          getPermissions(dispatch)
          getTheme(dispatch)
          navigate("/", {replace: true});
        } 
      //  setSnackbarProps({type: "success", flag: true, message: "Congratulations! Event created successfully"})
      
    }
  };

  const appleSignErrorHandler = (error, variables, context) => {
    // setSubmitFlag(false);
    if (error) {
      const errorObject = error?.response?.data;
      showSnackBar(dispatch, errorObject);
      setAppleSignStatus("error");
      //  console.log("error", data)
    }
  };

  useEffect(() => {
    if (appleSignLoading == true) {
      setAppleSignStatus("loading");
    }

    // return ()=>{ setStatus("")}
  }, [appleSignLoading]);
  // const selectedeventList = (id) => {
  //     if (eventList.length > 0 && eventList.find((item)=> item.id == id) ) {
  //        // setApproverError("");
  //         return eventList.find((item)=> item.id == id)
  //     } else {
  //         return {}
  //     }
  // };

  return [appleSignMutate, appleSignStatus];
};
