import { Box,  Typography, getContrastRatio, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import CustomAlert from "../../events/components/CustomAlert";
import CustomInput from "../../eventcreation/components/CustomInput";
import { afterValidate, generateLighterContrastColor, setInput } from "../../../utils/commonServices";
import { getErrorMsg } from "../../../utils/validator";
import { useNavigate } from "react-router-dom";
import useCustomTheme from "../../../theme/useCustomTheme";
import { setGuestReviewState } from "../../../redux/guestSlice";
import useGuestReviewActions from "../useGuestReviewActions";

const GuestUserInfoPopup = (props) => {

    const {resetList,  name, deleteProject, status} = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const guestReducer = useSelector(state=> state.guestReducer)
    const [pageData, setPageData] = useState({
      name:'',
      // email:''
    })
    const [nameValidationFlag, setNameValidationFlag] = useState(false)
    const [emailValidationFlag, setEmailValidationFlag] = useState(false)
    const [theme] = useCustomTheme()
    const {registerGuestUser} = useGuestReviewActions()



    const handleClose = () => {
        dispatch(setGuestReviewState({guest_user_details_popup: false}))
    };


    const confirmHandler= async ()=>{
      if(pageData?.name !== "") {
        registerGuestUser(pageData, handleClose)
        // console.log('api call')
      }
    }

    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(confirmHandler);
  }

  const validateInputs = (flag) => {
      setNameValidationFlag(flag)
      // setEmailValidationFlag(flag)
  }


    return (
    
        <CustomAlert 
          open={guestReducer.guest_user_details_popup} 
          handleClose={handleClose} 
          hideCloseHandler
          width={{xs:'440px',sm:'440px',md:'460px',lg:'490px', xl:'650px'}}
          height={{xs:'280px',sm:'280px',md:'280px',lg:'310px', xl:'410px'}}
        >
           <Box p={5} className="flex col j-center" sx={{ height:'100%'}}>
          
            <Box   mb={{xs:1 ,md:2, lg:2}} mt= {1}>
              {/* <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, color:'black'}}>{commonReducer.consent_detail}</Typography> */}
              <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'13px',xl:'18px'}, color:'black'}}>{`Please enter your name to continue`}</Typography>
            </Box>
    
            <Box className='flex col' gap={{xs:1, lg:1.5}}>
             {/* <CustomInput onChange={handleChange} value={text} max={6} placeholder = "delete" light/> */}
             <CustomInput
             onFocus={() => setNameValidationFlag(false)}
             onBlur={() => setNameValidationFlag(true)}
             required
            //  errorFlag={false}
             errorFlag={
                  nameValidationFlag &&
                  getErrorMsg("name", pageData?.name) != ""
                }
              errorMsg={getErrorMsg("name", pageData?.name)}
              type="text"
              max={40}
              placeholder="Guest User Name"
              onChange={(event) => {
                setInput(
                  pageData?.name != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "name",
                  pageData,
                  setPageData
                );
              
                // checkEmptyFields(event);
              }}
              value={pageData?.name}
            />
                            {/* <CustomInput
                                onFocus={()=>setEmailValidationFlag(false)}
                                onBlur={()=>setEmailValidationFlag(true)}
                                required
                                errorFlag={
                                  emailValidationFlag &&
                                  getErrorMsg("email", pageData?.email) != ""
                                }
                                errorMsg={getErrorMsg("email", pageData?.email)}
                                type="email"
                                max={40}
                                placeholder="email"
                                onChange={(event) => {
                                  setInput(
                                    pageData?.email != ""
                                    ? event.target.value
                                    : event.target.value.trim(),
                                    "email",
                                    pageData,
                                    setPageData
                                  );
                                }}
                                value={pageData?.email}
                              /> */}
            </Box>
    
            <Box sx={{display:'flex',justifyContent:'end' }} gap={2} mt={{xs:1.5 ,md:2.5, lg:3}} >
    
            
              <LoadingButton
                   
                    disabled={( pageData?.name === '' ||  guestReducer?.is_loading)}
                    onClick={finishedHandler}
                    style={{borderRadius: '5px', color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main), opacity:  pageData?.name === ''  && 0.6 , textTransform:'none', minWidth: '92px' }} 
                    sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, minWidth: '110px !important'}}
                    loading={guestReducer?.is_loading}
                    loadingPosition="start"
                    color='button'
                    variant="contained"
                    >
                   Continue
               </LoadingButton> 
            </Box>
           </Box>
        </CustomAlert>
      
  )
}

export default GuestUserInfoPopup