import { Box } from "@mui/material";
import { useState } from "react";
import CustomAlert from "./CustomAlert"

const AddEventPopup = ({open, setOpen}) => {

    const [closeHandler, setCloseHandler] = useState(false)

    const handleClose = () => {
        // setCloseHandler(false);
        setOpen(false)
        // dispatch(updateState({add_event_open: false}))
        
       };

  return (
    <CustomAlert 
      open={open} 
      handleClose={handleClose} 
      rightComponent={<Box sx={{display:'flex', flexDirection:'column', paddingX:{md:'1rem', lg:'1.6rem', xl:'2.5rem'},}}>
        right component
      </Box>}
      >
        left component
    </CustomAlert>
  )
}

export default AddEventPopup