import { Box } from "@mui/material";
import React from "react";

const CustomCard = (props)=> {
    return <Box
                {...props}
                sx={{
                    ...props.sx,
                    boxShadow: '0px 5px 15px #00000029',
                    borderRadius: '10px',
                    // padding: props.padding,
                    // margin: props.margin
                }}
        >
            {props.children}
        </Box>
}
export default CustomCard;