import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "./CustomAlert"
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../../redux/commonSlice";
import { eventVideoUploadConsent } from "../../../services/api";
import { useMutation } from "react-query";
import useEventList from "../../dashboard/hooks/useEventList";
import { LoadingButton } from "@mui/lab";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";
import { generateLighterContrastColor } from "../../../utils/commonServices";

const AgreementPopup = (props) => {
    const {open, setOpen, eventData, dropped, refetchEvent} = props;
    const [closeHandler, setCloseHandler] = useState(false)
    const [theme ] = useCustomTheme()
    const isDisabled = false
    const dispatch = useDispatch()
    const commonReducer = useSelector(state=> state.root.commonReducer)
   // const [refetchEvents] =  useEventList(commonReducer.is_logged);
    const [checked, setChecked] = useState(false)

    const handleClose = () => {
        // setOpen(false)
        dispatch(updateState({open_agreement_popup : false}))        
       };

         //POST API Request  to add consent for video upload
  const { mutate: addConsentMutate, isLoading: addConsentLoading } =
  useMutation(eventVideoUploadConsent, {
    onSuccess: (response, variables, context) =>
      consentSuccessHandler(response, variables, context),
    onError: (error, variables, context) =>
      consentErrorHandler(error, variables, context),
  });

const  consentSuccessHandler = async (response, variables, context) => {
  const responseObject = response?.data;
  const responseData = responseObject?.data
  // console.log(responseObject)
  if (responseData) {
   //dispatch(updateState({selected_event: {...commonReducer.selected_event, consent_accepted: true}}))
   await refetchEvent()
   dispatch(updateState({open_agreement_popup : false})) 
  }

};

useEffect(()=> {
    if(commonReducer.event_details?.consent_accepted == true && !dropped) {
        open()
    }
}, [commonReducer.event_details?.consent_accepted])


const consentErrorHandler = (error, variables, context) => {
  // setSubmitFlag(false);
  // setCreateEventStatus("error");
  const errorObject = error?.response?.data;
};


    const agreementAcceptHandler= ()=>{
      //will send api req , flag will become true for that particular event , list of events will be refetched
      if(commonReducer.is_logged == true && window.location.pathname != "/guestuploads"){
        addConsentMutate({event_id: commonReducer.production_event_id, consent_id: commonReducer.consent_id })
      } else {
        dispatch(updateState({guest_events: [...commonReducer.guest_events, {consent_status: true, event_id: eventData.id}], event_details: {...commonReducer.event_details, consent_accepted: true} }))
        setTimeout(()=> {
            dispatch(updateState({open_agreement_popup : false})) 
        },500)
      } 
     
    }

    const agreementDeclineHandler =()=>{
      dispatch(updateState({open_agreement_popup : false})) 
    }
    const handleChange = (event) => {
        setChecked(event.target.checked)
      }

  return (
    
        <CustomAlert 
          open={commonReducer.open_agreement_popup} 
         //open={true}
          handleClose={handleClose} 
          width={{xs:'400px',sm:'400px',md:'420px',lg:'440px', xl:'600px'}}
          height={{xs:'260px',sm:'270px',md:'290px',lg:'295px', xl:'370px'}}
        >
           <Box p={5} className="flex col" sx={{justifyContent:'space-evenly', height:'100%'}}>
          
            <Box   mb={{xs:1 ,md:2}}>
              {/* <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, color:'black'}}>{commonReducer.consent_detail}</Typography> */}
              <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'13px',xl:'18px'}, color:'black'}}>{commonReducer.consent_detail} </Typography>
            </Box>
    
            <Box>
              <FormGroup >
                <FormControlLabel  control={<Checkbox size="small" checked={checked} onChange={handleChange} sx={{ '& .MuiSvgIcon-root': { fontSize: {xs:12 ,sm:12,md:13,lg:13, xl:20} } }}/>} label={<Typography sx={{fontSize:{xs:11 ,sm:11 ,md:12,lg:12, xl:16}, }}>I Agree</Typography>} />
              </FormGroup>
            </Box>
    
            <Box sx={{display:'flex',justifyContent:'end' }} gap={2} mt={{xs:1 ,md:2}} >
    
              <Button variant='outlined' onClick={agreementDeclineHandler} style={{borderRadius: '5px', backgroundColor:'#08231B', color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main), textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, paddingY:0.4,}}>Cancel</Button>
    
              {/* <Button disabled={!checked} variant='contained' onClick={agreementAcceptHandler} style={{borderRadius: '5px', backgroundColor:'#08231B', color:theme.palette.primary.main, textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, paddingY:0.4,  opacity: !checked && 0.7}}>Continue</Button> */}
              <LoadingButton
                   // size="small"
                    disabled={!checked || addConsentLoading}
                    onClick={agreementAcceptHandler}
                    style={{borderRadius: '5px', backgroundColor:'#08231B', color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main), textTransform:'none', }} 
                    sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},  opacity: !checked && 0.7, minWidth: '110px !important'}}
                    loading={addConsentLoading}
                    loadingPosition="start"
                 // loading = {true}
                    variant="contained"
                    >
                   Continue
               </LoadingButton> 
            </Box>
           </Box>
        </CustomAlert>
      
  )
}

export default AgreementPopup