
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, getContrastRatio,} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetState, updateState } from "../../../redux/commonSlice";
import { useNavigate } from "react-router-dom";
import "@fontsource/rubik";
import { resetAllMediaFilesArray } from "../../../redux/mediaFilesSlice";
import { resetReviewState } from "../../../redux/reviewSlice";
import { resetSnackbar } from "../../../redux/messageSlice";
import { resetCreationState } from "../../../redux/eventCreationSlice";
import useDeleteUser from "../../dashboard/hooks/useDeleteUser";
import { getInitials, logout } from "../../../utils/commonServices";
import usePermissions from "../../../hooks/usePermissions";
// import { customTheme as theme } from "../../../theme/customTheme";
import { setAssetState } from "../../../redux/assetSlice";
import useCustomTheme from "../../../theme/useCustomTheme";

const useStyles = makeStyles((theme) => ({
  center: {
    //  display:'flex',
    //   alignItems: 'center',
    //   justifyContent:'center'
  },
  text: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightBold,
  
  },
}));

const GuestUserCard = (props) => {

  const classes = useStyles();
  const { avatarImg, userName, size = 'small' } = props;
  const [theme ] = useCustomTheme()
  const guestReducer = useSelector(state=> state.guestReducer)


  return (
    <Box
      {...props}
      className='flex row a-center j-center'
      spacing={2}
    >

    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}   gap={2}>

        { 
          size == "small" ?<Avatar sx={{width:{xs:'20px',sm:'24px' ,md:'27px' , lg:'26px' ,xl:'35px'}, height:{xs:'20px',sm:'24px' ,md:'27px' , lg:'26px' ,xl:'35px'},backgroundColor:theme.palette.primary.main, borderRadius:'8px', fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight: 600, textTransform:'uppercase',  color:getContrastRatio( theme.palette.primary.main , '#ffffff') > 2.5 ? '#ffffff' : '#000000'}}>{guestReducer?.guest_name ? getInitials(guestReducer?.guest_name) :getInitials('Guest User')  }</Avatar>
        :  <Avatar sx={{width:{xs:'20px',sm:'22px' ,md:'24px' , lg:'25px' ,xl:'35px'}, height:{xs:'20px',sm:'22px' ,md:'24px' , lg:'25px' ,xl:'35px'},backgroundColor:theme.palette.primary.main, borderRadius:'8px', fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, fontWeight: 600, textTransform:'uppercase',   color:getContrastRatio( theme.palette.primary.main , '#ffffff') > 2.5 ? '#ffffff' : '#000000'}}>{guestReducer?.guest_name ? getInitials(guestReducer?.guest_name) :getInitials('Guest User')  }</Avatar>}
        <Typography
          className={classes.text}
          variant="body2"
          sx={{
            fontFamily: 'Rubik',
            fontSize: {xs:'10px',md:'12px',lg:'14px',xl:'20px'},
            fontWeight: 600,
            color: 'black !important',
            textTransform:'capitalize'
          }}
        >
          {guestReducer?.guest_name ? guestReducer?.guest_name : 'Guest User'}
        </Typography>
    </Box>

    </Box>
  );
};

export default GuestUserCard;