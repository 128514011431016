import { makeStyles, SvgIcon } from "@material-ui/core";
import React from "react";


const BoldCloseIcon =(props) => {

    return (
        <SvgIcon {...props} width="28.847" height="28.847" viewBox="0 0 28.847 28.847" >    
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_43" data-name="Rectangle 43" width="20.359" height="20.437" fill="#08231b"/>
    </clipPath>
  </defs>
  <g id="x" transform="translate(14.451) rotate(45)">
    <g id="Group_61" data-name="Group 61" clip-path="url(#clip-path)">
      <path id="Path_40" data-name="Path 40" d="M7.53,4.2c0,.975-.014,1.95.007,2.925.007.338-.1.435-.433.432-1.864-.014-3.729-.008-5.593-.006A1.318,1.318,0,0,0,0,9.047q0,1.17,0,2.34a1.317,1.317,0,0,0,1.479,1.489c1.864,0,3.729.012,5.593-.008.38,0,.467.115.464.477-.017,1.889-.011,3.778-.006,5.667a1.3,1.3,0,0,0,1.406,1.416c.792.006,1.585-.022,2.376.008a1.4,1.4,0,0,0,1.527-1.54c-.041-1.864,0-3.729-.019-5.593,0-.337.1-.435.431-.432,1.889.014,3.778.009,5.666.006a1.313,1.313,0,0,0,1.437-1.428c.007-.8,0-1.609,0-2.413a1.322,1.322,0,0,0-1.492-1.479c-1.877,0-3.753-.008-5.63.006-.322,0-.415-.093-.412-.414.014-1.9.009-3.8.005-5.7A1.3,1.3,0,0,0,11.411,0Q10.168,0,8.925,0A1.281,1.281,0,0,0,7.532,1.389c-.006.938,0,1.877,0,2.815" transform="translate(0 0)" fill="#08231b"/>
      <path id="Path_41" data-name="Path 41" d="M7.531,4.2c0-.938,0-1.877,0-2.815A1.281,1.281,0,0,1,8.925,0Q10.168,0,11.411,0a1.3,1.3,0,0,1,1.417,1.439c0,1.9.008,3.8-.006,5.7,0,.321.09.417.412.414,1.877-.014,3.753-.008,5.63-.006a1.322,1.322,0,0,1,1.492,1.479c0,.8.006,1.609,0,2.413a1.313,1.313,0,0,1-1.436,1.428c-1.889,0-3.778.009-5.666-.006-.336,0-.434.1-.431.432.015,1.864-.022,3.73.019,5.593a1.4,1.4,0,0,1-1.527,1.54c-.791-.03-1.584,0-2.376-.008a1.3,1.3,0,0,1-1.406-1.416c0-1.889-.011-3.778.006-5.667,0-.362-.083-.481-.464-.477-1.864.02-3.729.011-5.593.008A1.317,1.317,0,0,1,0,11.387q0-1.17,0-2.34a1.317,1.317,0,0,1,1.51-1.492c1.864,0,3.729-.008,5.593.006.335,0,.44-.094.433-.432C7.516,6.155,7.53,5.18,7.531,4.2" transform="translate(0 0)" fill="#08231b"/>
    </g>
  </g>

  
         </SvgIcon>
    )
}

export default BoldCloseIcon