import React, { useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
// import { makeStyles } from "@mui/styles";
import { Box, Grid, Button, Typography } from '@mui/material'
//import { updateState } from "../../../redux/selectedFilesSlice";
import { useDispatch, useSelector } from "react-redux";
// import { customTheme as theme } from '../../../theme/customTheme';
import File from '../../../assets/file.svg'
// import FileIcon from './icons/FileIcon';
import "@fontsource/rubik";
import { updateState } from '../../../redux/commonSlice';
// import AgreementPopup from '../pages/events/components/AgreementPopup';
// import CustomAutocomplete from '../pages/eventcreation/components/CustomAutoComplete';
import { setAllMediaFilesArray } from '../../../redux/mediaFilesSlice';
// import usePermissions from '../hooks/usePermissions';
import { PERMISSION } from '../../../constants/constants';
import usePermissions from '../../../hooks/usePermissions';
import AgreementPopup from '../../events/components/AgreementPopup';
import useCustomTheme from '../../../theme/useCustomTheme';


const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


const BrandboxUploadZone = (props) => {

    const [tempFiles, setTempFiles] = useState([]);
    const dispatch = useDispatch()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [isDropped, setIsDropped] = useState(false);
    // const listOfAllEvents = commonReducer?.events?.map(({id, name, owner_name})=> ({id, name, owner_name}))
    const [selectedEventId, setSelectedEventId] = useState(null)
    const [selectedProjectName, setSelectedProjectName] = useState('')
    const [uploadedVideoList, setUploadedVideoList] = useState([]);
    // const [uploadedVideos, refetchList] = useUploadedList(true, selectedEventId);
    const [enableUploadList, setEnableUploadList] = useState(false);
    const [theme] = useCustomTheme()
   


    const {
        height,
        //width,
        setFiles,
        setProgress,
        setUploadStatus,
        isDisabled = true,
        setRequestController,
        accept,
        eventData,
        setEventData,
        refetch,
        refetchEvent,
        listOfAllEvents,
        setEnableFileUpload
    } = props;

    const baseStyle = {
        // height:'20rem',
        // width:'30rem',
        // flex: 1,
       // height: height,
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2em",
        borderWidth: 3,
        borderRadius: 2,
        boxShadow: '0px 5px 15px #00000029',
        borderColor: "#FFFFFF",
        borderStyle: "dashed",
        backgroundColor: "#e6e6e6b3",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    };

    const {
        open,
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        disabled: isDisabled,
        accept: accept,

        onDrop: (acceptedFiles) => {
            setIsDropped(true)
            setEnableFileUpload(false)
            // if(commonReducer.event_details.consent_accepted == false){
            //     dispatch(updateState({  open_agreement_popup : true }))
            // }else{
                // dispatch(updateState({  open_agreement_popup : false }))
                setTempFiles(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                );
               
            // }
         
        },
    });

    useEffect(() => {
        setRequestController(
            tempFiles?.map(() => {
                return new AbortController();
            })
        );
        setProgress(
            tempFiles?.map(() => {
                return 0;
            })
        );
        setUploadStatus(
            tempFiles?.map(() => {
                return { type: "standard", message: "" };
            })
        );
        setFiles(tempFiles);
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => tempFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [tempFiles]);

    useEffect(()=> {
        if(refetch) {
            refetch()
            setFiles([])
        }
    }, [])


    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const browseFilesClickHandler = async ()=>{
            // if(commonReducer.event_details?.consent_accepted == false){
            //     dispatch(updateState({open_agreement_popup: true}))  
            // }else{
                open()   
            // setEnableFileUpload(false)
            // }
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

            <Grid className="container" 
                style={{ display: 'flex' }} 
                sx={{ 
                    // display: { xs: 'none', md: 'inline' },
                    height: height ? height : {xs: '60vh', md: '55vh',lg: '55vh' , xl: '56vh'}, 
                    width: '100%'
            }}>

                <Grid  {...getRootProps({ style, className: 'dropzone' })} >
                    <input {...getInputProps()} />
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}} >

                            <Box sx={{ width: {xs:'25px',md:'30px',lg:'50px',xl:'74px'}, height: {xs:'20px',md:'25px',lg:'38px',xl:'50px'}, color: '#878887', marginBottom: {xs:'5px',md:'5px',lg:'5px',xl:'15px'} }} >
                                <img src={File} height='100%' width='100%'/>
                            </Box>


                            <Typography sx={{ fontSize: {xs:'10px',sm:'10px',md:'12px',lg:'16.67px',xl:'25px'}, fontWeight: 600, margin: 0, padding: 0, textAlign: 'center', letterSpacing: 1.25, color: '#878887', fontFamily: "Rubik" }}>Drag & Drop existing files here</Typography>
                        </Box>

                        <Button color='secondary' variant='contained' disabled = {isDisabled} onClick={browseFilesClickHandler} style={{ fontFamily: "Rubik", backgroundColor: isDisabled == true? "#52636C50"  : theme.palette.secondary.main, borderRadius: '5px', border: 'none',  cursor: 'pointer', fontWeight: 600, textTransform: 'capitalize', minWidth:'76px' }} sx={{fontSize:{xs:'7px',sm:'8px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, mt:'5px',
                        // width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},
                         paddingY:{xs:0.2,lg:0.4}}}>
                            Or Browse
                        </Button>

                    </Box>
                </Grid>
               
            </Grid>
        </Box>
    );
}

export default BrandboxUploadZone;