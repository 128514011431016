import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../redux/commonSlice";
import { setSnackbar } from "../redux/messageSlice";
import { logout } from "../utils/commonServices";

export default ()=> {
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const messageReducer = useSelector((state) => state.messageReducer);
    const dispatch = useDispatch()
    // const logout = ()=> {
    //     window.localStorage.removeItem("token");
    //     dispatch(resetState());
    // }
    const setSnackMessageType = (dataObject) => {
        const tempSnackObject = {flag: true, message: dataObject?.message}
        switch (dataObject?.http_code) {
          case 200:  dispatch(setSnackbar({...tempSnackObject, type : "success"}));
                     break;
          case 401:  logout(dispatch);
                     dispatch(setSnackbar({...tempSnackObject, type : "error"}));
                     break;
          case 404: ;
          case 422: ;
          case 400: ;
          default: dispatch(setSnackbar({...tempSnackObject, type : "error"}));
        }
      }

      return [setSnackMessageType]
}