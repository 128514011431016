import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { PRODUCT_ID, errorConstant } from "../constants/constants";
import { checkEmail } from "../services/api";
import { getErrorMsg } from "../utils/validator";

export default (data, page) => {
  //Quick GET api to check whether user already exists
  // const [errorObject, setErrorObject] = useErrorObject(data);
  const [checkEmailEnable, setCheckEmailEnable] = useState(false);
  const [emailToCheck, setEmailToCheck] = useState("");
  const {
    data: checkEmailData,
    error: checkEmailError,
    isLoading: checkEmailLoading,
  } = useQuery(
    [`checkMail${emailToCheck}`, emailToCheck],
    () => checkEmail({ email: emailToCheck , product_id: PRODUCT_ID.lucihub}),
    { enabled: checkEmailEnable, retry: false }
  );

  useEffect(() => {
    if (checkEmailError) {
      setCheckEmailEnable(false);
      if (checkEmailError?.response?.data?.http_code == 422) {
        var tempArr = [];
        errorObject.map((object, index) => {
          var tempObj = { ...object };

          if (index === inputFieldIds?.findIndex((key) => key === "email")) {
            tempObj = {
              ...tempObj,
              errorFlag: page !== "forgot-password" ? true : false,
              error:
                page !== "forgot-password"
                  ? "User already exists for this email. Please try another email!"
                  : "",
            };
          }

          tempArr = [...tempArr, tempObj];
        });
        setErrorObject(tempArr);
      }
    }
  }, [checkEmailError]);

  useEffect(() => {
    if (checkEmailData) {
      setCheckEmailEnable(false);
      if (checkEmailData?.data?.http_code == 200) {
        //console.log("User Already exists.")
        var tempArr = [];
        errorObject.map((object, index) => {
          // console.log("here", i)
          var tempObj = { ...object };

          if (index === inputFieldIds?.findIndex((key) => key === "email")) {
            tempObj = {
              ...tempObj,
              errorFlag: page === "forgot-password" ? true : false,
              error:
                page === "forgot-password"
                  ? "No Luci account has been found associated with this email addresss."
                  : "",
            };
          }

          tempArr = [...tempArr, tempObj];
        });
        setErrorObject(tempArr);
      }
      // else {
      //   console.log("User Already exists.")
      //   var tempArr = [];
      //   errorObject.map((object, index)=> {
      //        // console.log("here", i)
      //     var tempObj = {...object}

      //         if(index == inputFieldIds.findIndex("email")) {
      //           tempObj = {...tempObj, errorFlag: isForgotPopup && true, error: isForgotPopup && "No Luci account has been found associated with this email addresss."}
      //         }

      //     tempArr = [...tempArr, tempObj];
      //   })
      //   // setErrorObject(tempArr);
      // }
    }
  }, [checkEmailData]);

  // const checkExistingUser =  (email)=> {
  //   console.log("email", email)
  //   setEmailToCheck(email);
  //   setCheckEmailEnable(true);
  // }

  const inputFieldIds = useMemo(() => {
    return Object.keys(data);
  }, [data]);

  const inputFieldErrorObject = inputFieldIds?.map((item) => {
    return { id: item, errorFlag: false, error: "" };
  });

  const [errorObject, setErrorObject] = useState(inputFieldErrorObject);

  // var errorObject= [...inputFieldErrorObject];

  // function to check if a field is empty
  const checkEmptyFields = (event) => {
    // const temp = inputFieldIds.findIndex( item => item == event.target.id)

    var tempArr = [];
    errorObject.map((object, index) => {
      // console.log("here", i)
      var tempObj = { ...object };
      if (
        (event.type === "click" || event.key === "Enter") &&
        data[inputFieldIds[index]] == ""
      ) {
        if (inputFieldIds[index] === "name" && page === "create_event") {
          tempObj = {
            ...tempObj,
            errorFlag: true,
            error: `Please enter ${errorConstant.event_name}`,
          };
        } else {
          tempObj = {
            ...tempObj,
            errorFlag: true,
            error: `Please enter ${errorConstant[tempObj.id]}`,
          };
        }
      } else {
        if (
          index == inputFieldIds.findIndex((item) => item == event.target.id)
        ) {
          tempObj = { ...tempObj, errorFlag: false, error: "" };
        } else if (
          data[inputFieldIds[index]] == "" &&
          index < inputFieldIds.findIndex((item) => item == event.target.id)
        ) {
          if (inputFieldIds[index] === "name" && page === "create_event") {
            tempObj = {
              ...tempObj,
              errorFlag: true,
              error: `Please enter ${errorConstant.event_name}`,
            };
          } else {
            tempObj = {
              ...tempObj,
              errorFlag: true,
              error: `Please enter ${errorConstant[tempObj.id]}`,
            };
          }
        }
      }
      tempArr = [...tempArr, tempObj];
    });

    setErrorObject(tempArr);
  };

  //Function to check if a the entered value is valid
  const checkValue = (event, checkEmailFlag) => {
    var tempArr = [];
    if (event.type === "click" || event.key === "Enter") {
      errorObject?.map((object, index) => {
        var tempObj = { ...object };

        // console.log("password", getErrorMsg(event.target.id, data[inputFieldIds[index-1]], data[inputFieldIds[index]]))
        if (object.id == "password_confirmation" || object.id == "end_date") {
          if (
            getErrorMsg(
              object.id,
              data[inputFieldIds[index - 1]],
              data[inputFieldIds[index]]
            ) != ""
          ) {
            tempObj = {
              ...tempObj,
              errorFlag: true,
              error: getErrorMsg(
                object.id,
                data[inputFieldIds[index - 1]],
                data[inputFieldIds[index]]
              ),
            };
          }
        } else if (
          page !== "signin" &&
          object.id === "email" &&
          getErrorMsg(object.id, data[inputFieldIds[index]]) == ""
        ) {
          setEmailToCheck(data[inputFieldIds[index]]);
          checkEmailFlag && setCheckEmailEnable(true);
        } else if (getErrorMsg(object.id, data[inputFieldIds[index]]) != "") {
          tempObj = {
            ...tempObj,
            errorFlag: true,
            error: getErrorMsg(object.id, data[inputFieldIds[index]]),
          };
        }

        tempArr = [...tempArr, tempObj];
      });
    } else {
      // var tempArr = [];
      errorObject?.map((object, index) => {
        var tempObj = { ...object };
        if (
          index == inputFieldIds.findIndex((item) => item == event.target.id)
        ) {
          // console.log("password", getErrorMsg(event.target.id, data[inputFieldIds[index-1]], data[inputFieldIds[index]]))
          if (
            event.target.id == "password_confirmation" ||
            event.target.id == "end_date"
          ) {
            if (
              getErrorMsg(
                event.target.id,
                data[inputFieldIds[index - 1]],
                data[inputFieldIds[index]]
              ) != ""
            ) {
              tempObj = {
                ...tempObj,
                errorFlag: true,
                error: getErrorMsg(
                  event.target.id,
                  data[inputFieldIds[index - 1]],
                  data[inputFieldIds[index]]
                ),
              };
            }
          } else if (
            page !== "signin" &&
            event.target.id === "email" &&
            getErrorMsg(event.target.id, event.target.value) == ""
          ) {
            setEmailToCheck(event.target.value);
            checkEmailFlag && setCheckEmailEnable(true);
          } else if (
            getErrorMsg(event.target.id, data[inputFieldIds[index]]) != ""
          ) {
            tempObj = {
              ...tempObj,
              errorFlag: true,
              error: getErrorMsg(event.target.id, data[inputFieldIds[index]]),
            };
          }
        }
        tempArr = [...tempArr, tempObj];
      });
    }
    setErrorObject(tempArr);
  };

  // console.log(errorObject);

  return [errorObject, checkEmptyFields, checkValue];

  /*1. errorObject contains an array of objects {id: <String>, errorFlag: <Boolean>, error: <String}.
      each such object represents an input field from top to bottom
   2. checkEmptyFields function takes event object that calls it from parent component and checks
      if all the previous inputs are empty or not. if found empty, it will update the respective
      error properties of the input fields.
   3. checkValue function takes event object that calls it from parent component and checks if that
      input value is valid as per client side rules or not. If invalid, it will update the error Object
      properties for that field.
   4. checkExistingUser takes an email address in string form and checks on the server database 
      for existing user. If user is found, it will update the error object for email field
      to ask for another mail address.
   
        
 */
};
