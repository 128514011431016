
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, CircularProgress, IconButton, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StarIcon from '@mui/icons-material/Star';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { downloadS3File, fileFormat, removeExtension, sizeConverter, timeStampToNumberedDateString } from '../../../utils/commonServices';
import { makeStyles } from "@mui/styles";
import CustomTooltip from '../../../components/CustomTooltip';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { setAllMediaFilesArray } from '../../../redux/mediaFilesSlice';
import CustomScrollToBottom from '../../../components/CustomScrollToBottom';
import { BRANDBOX_OPTIONS } from '../../../constants/staticData';
import DeleteIcon from '@mui/icons-material/Delete';
import { setAssetState } from '../../../redux/assetSlice';
import { deleteAsset } from '../actions';
import '../table.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useCustomTheme from '../../../theme/useCustomTheme';

const useStyles = makeStyles((theme) => ({
    checkboxRoot: {
        pointerEvents: 'none',
      },
    container: {
        width:'100%',
        borderCollapse: 'collapse',
       // paddingRight:'4%'
       scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
                 display : 'none'
               }
    },
    tableRowHead:{
        textAlign:'left',
        verticalAlign:'top',
        color:'#08231B',
        fontSize:'15px',
        fontFamily: "Rubik",
        fontWeight:500,
        letterSpacing: '0.75px',
        zIndex: 15
    },
    tableRowData:{
        borderBottom:'2px solid #EBEBEB',
        // height:{xs:'50px',md:'55px',lg:'60px', xl:'68px'},
        // height:'62px',
        backgroundColor:'transparent',
        fontFamily: "Rubik",
       // textAlign:'left',
        width:'100%',
        fontSize:'15px',
        // font: 'normal normal 600 15px/18px ',
        fontWeight:600,
        color:'#08231B',
        letterSpacing: '0.75px',
        zIndex: 15,
        '&:last-child':{
            borderBottom:'none'
         }
    },
 
  }));

const MobileTable = (props) => {

    const classes = useStyles();
    const {files, popup, expanded, setExpanded}= props
    const [anchorEl, setAnchorEl] = useState(null);
    const assetReducer = useSelector((state) => state.assetReducer);
    const [selectedFileId, setSelectedFileId] = useState(null)
  //  const [expanded, setExpanded] = useState('');
    const dispatch = useDispatch()
    const [fileId, setFileId] = useState('')
    const open = Boolean(anchorEl);
    const [theme ] = useCustomTheme()

  //   const handleClose = (e) => {
  //     e.stopPropagation();
  //     setAnchorEl(null);
  //     setSelectedFileId(null)
    
  // };

    const handleAccordianChange = (project) => (event, isExpanded) => {
     
      setExpanded(isExpanded ? project?.label : false);
      dispatch(setAssetState({selected_asset_files: project?.files}))
    };

    const fileDownloadHandler =  (file)=>{
      // setFileId(file?.id)
      dispatch(setAssetState({file_id: file.id}))
      if(file?.url != null && file?.url != "") {
        downloadS3File(file?.download_url)
      }
    }
      const deleteFileHandler =  (id)=>{
      
      dispatch(setAssetState({file_id: id}))
      deleteAsset({id: id},dispatch , resetExpandedState)

      }

      const resetExpandedState =()=>{
        // setExpanded('')
      }

      

    //   const handleMenuOpen = (event, id) => {
    //     event.stopPropagation()
    //     setAnchorEl(event.currentTarget);
    //     console.log(id)
    //     setSelectedFileId(id)
     
    // };

   


    const deleteClickHandler = (e,id)=> {
      e.stopPropagation(); 
      e.preventDefault(); 
      dispatch(setAssetState({file_id: id}))
      deleteFileHandler(id)
      
    }

    const downloadClickHandler = (e, file)=> {
      e.stopPropagation(); 
      e.preventDefault(); 
      dispatch(setAssetState({file_id: file.id}))
      fileDownloadHandler(file)
    }

  return (
   
           <Box mt={1.5} className='flex col' sx={{minWidth:'60px'}}>
                <Box sx={{overflowY:'scroll', height: popup == true ? '30vh':'55vh',}}>

        
           {  assetReducer.all_assets?.length === 0 ?  <Typography sx={{fontSize: 30, color: '#878887', fontWeight: 300, textAlign: 'center', mt:10}} >Click upload to add files. </Typography> : 
         
         assetReducer.all_assets?.map((project)=> ( 
                <>

                {/* {expanded === project?.label && <CustomScrollToBottom /> } */}
                <Accordion id= 'assets'  key={project?.label} expanded={expanded === project?.label} style={{backgroundColor:'transparent', }} disableGutters={true} onChange={handleAccordianChange(project)}>
                  
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#08231B' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography  sx={{fontSize: {xs:'14px',sm:'15px'}, fontFamily: 'Rubik' , color:'#08231B', textTransform:'capitalize', fontWeight:800}}>{project?.label}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{pl:'5px', py:1, pr:0}}>
                 <table className="responsive-table" >
        <thead>
          <tr style={{display:'flex', width:'100%'}}>
            <th><Typography sx={{fontSize: {xs:'13px',sm:'14px'}, fontFamily: 'Rubik', fontWeight: 800, color:'#08231B'}}>File Name</Typography></th>
          
            <th><Typography sx={{fontSize: {xs:'13px',sm:'14px'}, fontFamily: 'Rubik', fontWeight: 800, color:'#08231B'}}>Upload Date</Typography></th>
            <th></th> 
          </tr>
        </thead>
        <tbody>
          {project?.files?.length  == 0 ? (<Typography textAlign={'center'} mt={'5%'}>{'No files to show'}</Typography>) : project?.files?.map((file, index) => (
            <tr key={index}>
              <td data-label="Name">
                <div className="file-name"><Typography sx={{fontSize: {xs:'12px',sm:'13px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik', fontWeight:600}}>{file.filename}</Typography></div>
                <div className="file-details">
                  <span><Typography sx={{fontSize: {xs:'11px',sm:'12px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik', display:'inline-block'}}>{`${file.created_by} `}</Typography></span> | <span><Typography sx={{fontSize: {xs:'11px',sm:'12px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik', display:'inline-block'}}>{sizeConverter(parseInt(file?.size))}</Typography></span>
                </div>
              </td>
            
              <td data-label="File Type"><Typography  sx={{fontSize: {xs:'12px',sm:'13px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(file.created_at)}</Typography></td>
              <td data-label="Options" className="options">


              <Box sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, display:'flex', alignItems:'center',justifyContent:'end' }} gap={1.5}>
                
                <>     
               { assetReducer?.delete_asset_loading === true && assetReducer?.file_id == file?.id ?   <CircularProgress    
                    sx={{
                      height:'3px', width:'3px',
                      margin:{xs:0.75,md:0.87,lg:1.1, xl:1.4},
                      color:'#666'
                      }}
                    size={14}
                    loading={ assetReducer?.delete_asset_loading === true && assetReducer?.file_id == file?.id}
                    disabled 
                  >    
                  </CircularProgress> : 
                <DeleteIcon disabled = {assetReducer?.delete_asset_loading === true}
                onClick={(e)=>deleteClickHandler(e, file?.id)} sx={{fontSize: {xs:'12px',md:'12px',lg:'15px',xl:'20px'}, '& .MuiSvgIcon-root':{
                fontSize:{xs:'12px',md:'12px',lg:'15px',xl:'20px'},
               }}}/>
                }   


                  <FileDownloadOutlinedIcon  disabled = {assetReducer?.delete_asset_loading === true} 
                  onClick={(e)=>downloadClickHandler(e, file)}  sx={{fontSize: {xs:'13px',md:'13px',lg:'16px',xl:'21px'},  '& .MuiSvgIcon-root':{fontSize:{xs:'13px',md:'13px',lg:'16px',xl:'21px'},}}}/>
                
                </>
            </Box>

                {/* <MoreHorizIcon onClick={(e) => { e.preventDefault();handleMenuOpen(e, file.id)}}  sx={{ fontSize: {xs:'16px', sm:'17px'}}}/> */}
                {/* <Menu
            id="role-menu"
            anchorEl={anchorEl}
            open={selectedFileId === file.id}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
   
  disableAutoFocusItem
  disablePortal 
  getContentAnchorEl={null} 
          
           
          >
            <MenuItem onClick={(e)=>handleDelete(e,file.id)}  sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70`, color:'black'}}}>
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '11px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#CE0101' } }}>Delete</Typography></ListItemText>
            </MenuItem>
            <MenuItem  onClick={(e)=>handleDownload(e, file.id)} sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70`, color:'black'}}}>
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '11px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Download</Typography></ListItemText>
            </MenuItem>
          
     
        </Menu>  */}

              </td>
            </tr>
          ))}
        </tbody>
                 </table>
              

               </AccordionDetails>
          </Accordion>
          </>
          ))}
            </Box>
            </Box>
  )
}

export default MobileTable