import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    pageData: {
                // name: "",
                // start_date: "",
                // end_date: "",
                // editor_start_date:"",
                // genre_id: null,
                // event_tones: null,
                // video_orientation: null,
                // phone_count_id: null,
                // phone_count_id: null,
                // quality_options: null,
                // event_type: null,
                // video_length: null,
                name : "",
                start_date: "",
                // end_date: "",
                editor_start_date:"",
                video_orientation: "landscape", // landscape | portrait
                song:'',
                description: "",
                quality_options: [1],
                event_type_id:'',
                video_length_id:'',
                time_offset: 0,
                song_type: '',
                note:''
            },
            event_id: null,
            open_form: false,
            edit: false,
            duplicate: false,
            edit_decline_popup: false,
            extend_edit_date_popup: false,
            date_time_picker_open:false,
            open_backdrop_project_creation: false,
            checked: false,
            create_project_backdrop_loader: false
}
export const eventCreationSlice = createSlice({
    name: 'createEvent',
    initialState: initialValue,
    reducers: {
        updateCreationState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetCreationState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateCreationState, resetCreationState } = eventCreationSlice.actions
export default eventCreationSlice.reducer