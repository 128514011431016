import { makeStyles } from "@mui/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Dialog, DialogContent, IconButton, Box, Typography, DialogActions } from "@mui/material";
import { useState } from "react";
import { customTheme as theme } from "../theme/customTheme";
import '../App.css'
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../redux/commonSlice";
import "@fontsource/rubik";
import { isIos } from "../utils/commonServices";

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        position: 'relative',
        paddingBottom: '56.25%', /* 16:9 */
        width: '100%',
        height: 0
      },
    iframe: {
        width: '100%',
       // height: '100%',
  aspectRatio: '16 / 9',
 // boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
  borderRadius: '10px',
  backgroundColor: '#000000'
      }
}));

export default function MediaPreview(props) {
  const classes = useStyles();
  const { maxWidth, width, height, url, fileType} = props;
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(updateState({open_media_preview: false}))
  }
  return (
    <Dialog
      open={commonReducer.open_media_preview}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    //   sx={{
    //     '& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop' : {
    //         backdropFilter: 'blur(3px)'
    //     }
    //   }}
    slotProps={{
        backdrop: {
            sx: {
                backdropFilter: 'blur(2px)'
            }
        }
        
    }}
      PaperProps={{ 
        sx: { borderRadius: '5px', 
                width: width ?width :{xs: '480px', sm: '640px', md: '720px', lg:'960px', xl:'1600px'}, 
                maxWidth : '1920px !important',
                height: '100%',
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }}}
      {...props}
    >
      <DialogContent
        sx={{ padding:'0px'}}
        id="alert-dialog-description"
      >
        <Box className= 'flex col' height='100%' >
            <Box className='flex row j-end' sx={{backgroundColor: 'transparent'}} >    
                <Typography onClick={handleClose} 
                    sx={{fontFamily: 'Rubik', textAlign: 'right', 
                         fontSize:{xs:'8px',md:'11px', xl:'14px'}, 
                         color: '#000000',
                         width: '60px', fontWeight:700, marginTop:{xs:'10px',sm:'15px',md:'20px',lg:'20px',xl:'44px'}, 
                         marginBottom:{xs:'10px',md:'14px',lg:'12px',xl:'24px'}, 
                         cursor:'pointer',
                         backgroundColor: 'transparent'
                        }}>Close X</Typography>
            </Box>

            { fileType == 'video' ? (
            <video className={classes.iframe} src={url} controls >
                <source src={url} type="video/mp4" />
                <source src={url} type="video/ogg" />
                <source src={url} type="video/quicktime" />
                <source src={url} type="video/webm" />
            </video>
            ) : fileType == 'image' ? (
              <Box height='90%'  className={classes.iframe} sx={{ display:'flex', justifyContent:'center', alignItems:'center',}}>
                <img src={url}   height='100%' style={{objectFit:'scale-down'}}/>
              </Box>
            ) : fileType == 'audio' ? (
              <audio className={classes.iframe} src={url}>
                <source src={url} type="audio/ogg" />
                <source src={url} type="audio/mpeg" />
                <source src={url} type="audio/wav" />
              </audio>
            ) : (
              <iframe className={classes.iframe} src={url}>
                <source src={url} type="video/mp4" />
                <source src={url} type="video/ogg" />
                <source src={url} type="video/quicktime" />
                <source src={url} type="video/webm" />
                <source src={url} type="audio/ogg" />
                <source src={url} type="audio/mpeg" />
                <source src={url} type="audio/wav" />
            </iframe>
            )}

            {/* <iframe className={classes.iframe} src={url}>
              <source src={url} type="video/mp4" />
              <source src={url} type="video/ogg" />
              <source src={url} type="video/quicktime" />
              <source src={url} type="video/webm" />
            </iframe> */}
            
            
        </Box>
      </DialogContent>
    </Dialog>
  );
}