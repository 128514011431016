import { createSlice } from '@reduxjs/toolkit'

const initialValue = {

    max_change_request:3,
    request_changes: false,
    open_request_changes_popup: false,
    open_video_accept_popup: false,
    final_video_id: '',
    final_video_url: "",
    download_link: "",
    final_video_accepted: false,
    revision_logs: [],
    timestamp:'00:00',
    open_change_request_tutorial_popup: false,
    is_loading: false,
    edit_flag: false,
    log_id: '',
    delete_log_confirmation_popup: false,
    log: {}
   // new_video: false,
   // under_revision: false,
}


export const reviewSlice = createSlice({
    name: 'common',
    initialState: initialValue,
    reducers: {
        updateReviewState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetReviewState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateReviewState, resetReviewState } = reviewSlice.actions
export default reviewSlice.reducer