import { useEffect, useState } from "react";
import Bottleneck from "bottleneck";
import { useDispatch, useSelector } from "react-redux";
import { createAssetApi } from "../../../services/api";
import { setSnackbar } from "../../../redux/messageSlice";
import { setSnackMessageType } from "../../../utils/commonServices";


export default (labelId, isGuestUser, uploadedVideos, refetch, afterUploadCallback) => {
  // const commonReducer = useSelector((state) => state.root.commonReducer);
  const [progress, setProgress] = useState([]);
  const [requestController, setRequestController] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([{ type: "", message: "" }]);
  const [imgSrc, setImgSrc] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const dispatch = useDispatch();
  const maxSizeGb = 10;
  const [enableFileUpload, setEnableFileUpload] = useState(false)

  const refresh = async (index) => {

    // setUploadStatus((last) => {
    //   var temp = last?.map((num, i) => {
    //     if (i == index) {
    //       return { type: "standard", message: "Uploaded!" };
    //     } else {
    //       return num;
    //     }
    //   });
    //   return temp;
    // });

    if (index == files?.length - 1 && !isGuestUser) {
      setTimeout(() => {
        //  refetch();
        //  setFiles([])
        // setEnableFileUpload(false)
      
        dispatch(setSnackbar({type: 'success', flag: true, message: "Files Uploaded successfully!"}))
        afterUploadCallback()
        setIsloading(false);
        
      }, 1500);
    }
  };

  const stopUpload = (index) => {
    requestController[index].abort();
    setUploadStatus((last) => {
      var temp = last?.map((num, i) => {
        if (i == index) {
          return { type: "error", message: "Cancelled!" };
        } else {
          return num;
        }
      });
      return temp;
    });
  };

  const stopList = () => {
    setUploadStatus((last) => {
      var temp = last?.map((num, i) => {
        if(num.type == "standard" ) {
          requestController[i].abort();
          return { type: "error", message: "Cancelled!" };
        } else {
          return num;
        }
        
      });
      return temp;
    });

  };

  useEffect(() => {
    const limiter = new Bottleneck({
      maxConcurrent: 1,
      minTime: 200,
    });

    if (
      files?.length > 0
      // progress?.length == files?.length &&
      // uploadStatus?.length == files?.length
    ) {
      const uploadRequest = () => {
        var tempControllers = [];
        for (let index = 0; index < files?.length; index++) {
          
          if (files[index].size / (1024 * 1024 * 1024) > maxSizeGb) {
            setIsloading(false);
            setUploadStatus((last) => {
              var temp = last?.map((status, i) => {
                if (i == index) {
                  return {
                    type: "error",
                    message: `Max allowed size: ${maxSizeGb} GB `,
                  };
                } else {
                  return status;
                }
              });
              return temp;
            });
            //  refresh(index)
          } else if (
            uploadedVideos != null &&
            uploadedVideos.filter((item) => (item.filename == files[index].name) )
              ?.length > 0
          ) {
            setIsloading(false);
            setUploadStatus((last) => {
              var temp = last?.map((status, i) => {
                if (i == index) {
                  return { type: "error", message: "File already exists" };
                } else {
                  return status;
                }
              });
              return temp;
            });

            //  refresh(index)
          } else {

           limiter
              .schedule(() =>
                uploadVideoService(
                  { label_id: labelId, asset_file: files[index] },
                  index,
                  requestController[index]
                )
              )
              .then((result) => {
               // setIsloading(false);
                // setUploadStatus((last) => {
                //   var temp = last?.map((num, i) => {
                //     if (i == index) {
                //       return {
                //         type: "success",
                //         message: "Uploaded Successfully!",
                //       };
                //     } else {
                //       return num;
                //     }
                //   });
                //   return temp;
                // });
// console.log(uploadedVideos, 'uploadedvideos')
                // console.log(files[index].name)
                // console.log( uploadedVideos.filter((item) => (item.filename == files[index].name) ), 'filter')
                //  refresh(index)

              
              })
              .catch((err) => {
                
                setIsloading(false);
                // setUploadStatus((last) => {
                //   var temp = last?.map((num, i) => {
                //     if (i == index) {
                //       if (
                //         err?.response?.data?.error == "File already present"
                //       ) {
                //         return {
                //           type: "error",
                //           message: err?.response?.data?.error,
                //         };
                //       } else if (err?.message == "canceled") {
                //         return { type: "error", message: "Upload Cancelled!" };
                //       } else {
                //         if(err?.response?.data?.http_code === 403) {
                //           dispatch(setSnackbar({type:'error', flag:true, message: err?.response?.data?.message}))
                //         }
                //         return { type: "error", message: "Upload Failed!" };
                //       }
                //     } else {
                //       return num;
                //     }
                //   });
                //   return temp;
                // });
                //  refresh(index)
              });
          }
        }
      };
      uploadRequest();
    }

    return () => {
      limiter.stop().then(() => {
        // setEnableFileUpload(false)
        //console.log("Shutdown completed!");
      });
    };
  }, [files, enableFileUpload]);

  const uploadVideoService = (fData, index, controller) => {
    if(enableFileUpload === false) return
    // if(enableFileUpload === true){
    setIsloading(true);
    return new Promise((resolve, reject) => {
      createAssetApi({
        data: fData,
        progress: (number) => {
          setProgress((last) => {
            var temp = last?.map((num, i) => {
              if (i == index) {
                return number;
              } else {
                return num;
              }
            });
            return temp;
          });

          setUploadStatus((last) => {
            var temp = last?.map((num, i) => {
              if (i == index) {
                if (number > 99) {
                  return { type: "standard", message: "Uploading..." };
                } else {
                  return { type: "standard", message: "Uploading..." };
                }
              } else {
                return num;
              }
            });
            return temp;
          });
        },
        header: { "content-type": "multipart/form-data" },
        controller: controller,
      })
        .then((r) => {
          resolve(r.data); 
          setUploadStatus((last) => {
            var temp = last?.map((num, i) => {
              if (i == index) {
                return { type: "standard", message: "Uploaded!" };
              } else {
                return num;
              }
            });
            return temp;
          });
          refresh(index)
        })
        .catch((e) => reject(e));
    });
  // }
  };

  return {
    files,
    setFiles,
    uploadStatus,
    setUploadStatus,
    progress,
    setProgress,
    imgSrc,
    setImgSrc,
    isLoading,
    requestController,
    setRequestController,
    stopUpload,
    stopList,
    enableFileUpload, setEnableFileUpload
  };
};