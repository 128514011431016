import { AppBar, Box, Button, Grid, Toolbar, Typography, getContrastRatio } from "@mui/material"
import { useEffect, useState } from "react"
import { customTheme as theme } from "../../theme/customTheme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import notFoundImage from '../../assets/404.svg';
import UnauthLayout from '../unauthv2/components/UnauthLayout'
import CustomButton from "../../components/CustomButton";
import AuthLayout from "../layouts/AuthLayout";
import luciLogo from "../../assets/luciLogo2.png";
import poweredBy from "../../assets/poweredBy.svg";
import { useSelector } from "react-redux";
import { generateLighterContrastColor } from "../../utils/commonServices";
import useCustomTheme from "../../theme/useCustomTheme";
import PageNotFoundImage from "./assets/PageNotFoundImage";
import Footer from "../unauthv2/components/Footer";

const PageNotFound = (props) => {

  const { componentStack, error } = props;
  const [displayError, updateDisplay] = useState(false)
  const navigate = useNavigate();
  const [theme] = useCustomTheme()
  const themeReducer = useSelector((state) => state.root.themeReducer);
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [delay, setDelay] = useState(true)
    const brandLogo = themeReducer?.brand_logo

    console.log('Lucihub Pagenot found', commonReducer?.is_redirected_from_butterfly)

    useEffect(()=>{
      setTimeout(() => {
        setDelay(false)
      }, 3000);
    },[])


  return (
    (    delay ? <></> : <>
    <AuthLayout sx={{ padding: '2% 3.2% 2.1% 2.2%' }} >
      <AppBar position='sticky' sx={{backgroundColor:'transparent',}} elevation={0} >
        <Toolbar  sx={{  display:'flex', justifyContent:'left', alignItems:'center'}}>
        <Box sx={{ width:"10%"}}>
                <Box >
                    <img src={brandLogo !== null ? brandLogo : luciLogo} width= '100%' />
                    {/* {
                        brandLogo !== null && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
                    } */}
                </Box>
        </Box>
       
        </Toolbar>
    </AppBar>
      <Box className = 'flex row a-center' sx={{justifyContent: 'space-evenly', flex: 1}} pl={{ sm: 10 }} pb={4}>
        <Box flexDirection='column'>

          <Box sx={{ marginY: { xs: '15px', md: '20px', lg: '25px', xl: '38px' } }}>
            <Typography
              variant="h4"
              color="primary"
              style={{
                fontWeight: 600,
                color: '#08231B'
              }}
              sx={{ fontSize: { xs: '30px', sm: '40px', md: '40px', lg: '50px', xl: '80px' }, fontFamily: 'Rubik' }}
            >Oh Shoot.</Typography>
          </Box>
          
          <Box item xs={11} sm={8} md={7} gap={0.8} sx={{ display: 'flex', flexDirection: 'column', }}>
            <Typography sx={{ color: '#707070', fontSize: { xs: '12px', md: '14px', lg: '16px', xl: '20px' }, fontFamily: 'Helvetica' }}>The page you were looking for does not exist.</Typography>
          </Box>

          <Box width='100%' sx={{ mt: 3, display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, justifyContent: { xs: 'flex-start', sm: 'flex-start' }, alignItems: { xs: 'center', sm: 'flex-start' } }} >

            <CustomButton 
            variant="contained"
              btnText="Go Home"
              color='button'
              style={{ borderRadius: '5px', border: "none", textTransform: 'none', }}
              onClick={() => navigate('/dashboard', { replace: true })}
              sx={{ fontSize: { xs: '10px', sm: '10px', md: '11px', lg: '12px', xl: '16px' }, fontWeight: 600, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, width: { xs: '100px', lg: '120px', xl: '160px' }, fontFamily: 'Rubik', backgroundColor: '#08231B',color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main) }} />

          </Box>

        </Box>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', }}  pl={{xs:2,sm:4,md:8,lg: 10,xl:12}}>
        <Box sx={{height:{xs:180,sm:200,md:220,lg:'240px',xl:300}, marginBottom:{xs:'15px', sm:'15px',md:'20px',lg:'22.66px',xl:'34px'}}}>
              <PageNotFoundImage sx={{height: '100%', width: '100%', maxWidth: '350px'}} />
             {/* <img src={notFoundImage} alt="notFoundImage" height='100%' style={{objectFit:'cover', borderRadius:'10px'}} /> */}
        </Box>
    </Box>

      </Box>
     
    </AuthLayout>
     <Footer />
</>)
  )
}

export default PageNotFound;