
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../../redux/commonSlice";
import { LoadingButton } from "@mui/lab";
import CustomAlert from "../../events/components/CustomAlert";
import CustomInput from "../../eventcreation/components/CustomInput";
import { setAssetState } from "../../../redux/assetSlice";
import { generateLighterContrastColor, setInput } from "../../../utils/commonServices";
import { getErrorMsg } from "../../../utils/validator";
import { useNavigate } from "react-router-dom";
import { updateCompanyName } from "../../../commonactions/actions";
import useCustomTheme from "../../../theme/useCustomTheme";

const CompanyNamePopup = (props) => {
    const {resetList,  name, deleteProject, status} = props
    const [closeHandler, setCloseHandler] = useState(false)
    const isDisabled = false
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const assetReducer = useSelector(state=> state.assetReducer)
    const [text, setText] = useState("")
    const [pageData, setPageData] = useState({
      name:''
    })
    const [companyNameValidationFlag, setCompanyNameValidationFlag] = useState(false)
    const [theme] = useCustomTheme()

    const handleClose = () => {
        // dispatch(updateState({event_delete_popup : false})) 
        dispatch(setAssetState({brand_name_popup: false}))
    };

       


    const confirmHandler= async ()=>{
      if(pageData?.name !== "") {
       //console.log(pageData, 'hello')
      if(assetReducer.brand_box_clicked) {
        updateCompanyName(pageData, dispatch, ()=> navigate('/brandbox', {replace: true}))
        
      } else {
        updateCompanyName(pageData, dispatch, ()=> dispatch(updateState({change_theme_popup:true})))
      }
      
      }
  
     
    }

    const cancelHandler =()=>{
      handleClose();
    }
    const handleChange = (event) => {
     
      setText(event.target.value.toLowerCase())
   
      }

      const afterSuccess =()=>{
        navigate('/brandbox', {replace: true})
      }

  return (
    
        <CustomAlert 
          open={assetReducer.brand_name_popup} 
          handleClose={handleClose} 
          // hideCloseHandler
          width={{xs:'400px',sm:'400px',md:'420px',lg:'440px', xl:'600px'}}
          height={{xs:'250px',sm:'250px',md:'250px',lg:'280px', xl:'380px'}}
        >
           <Box p={5} className="flex col j-center" sx={{ height:'100%'}}>
          
            <Box   mb={{xs:1 ,md:2, lg:2}} mt= {1}>
              {/* <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, color:'black'}}>{commonReducer.consent_detail}</Typography> */}
              <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'13px',xl:'18px'}, color:'black'}}>{`Please enter company name to continue"`}</Typography>
            </Box>
    
            <Box>
             {/* <CustomInput onChange={handleChange} value={text} max={6} placeholder = "delete" light/> */}
             <CustomInput
             onFocus={() => setCompanyNameValidationFlag(false)}
             onBlur={() => setCompanyNameValidationFlag(true)}
             required
            //  errorFlag={false}
             errorFlag={
                  companyNameValidationFlag &&
                  getErrorMsg("company_name", pageData?.name) != ""
                }
              errorMsg={getErrorMsg("company_name", pageData?.name)}
              type="text"
              max={40}
              placeholder="Company Name"
              onChange={(event) => {
                setInput(
                  pageData?.name != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "name",
                  pageData,
                  setPageData
                );
              
                // checkEmptyFields(event);
              }}
              value={pageData?.name}
            />
            </Box>
    
            <Box sx={{display:'flex',justifyContent:'end' }} gap={2} mt={{xs:1 ,md:2}} >
    
              <Button variant='outlined' onClick={cancelHandler} color="button" style={{borderRadius: '5px', color:  theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main), textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, paddingY:0.4,}}>Cancel</Button>
    
              {/* <Button disabled={!checked} variant='contained' onClick={agreementAcceptHandler} style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, paddingY:0.4,  opacity: !checked && 0.7}}>Continue</Button> */}
              <LoadingButton
                   // size="small"
                    disabled={status == 'loading' || pageData?.name === ''}
                    onClick={confirmHandler}
                    style={{borderRadius: '5px', backgroundColor: '#08231B', color:'#00D744', textTransform:'none', opacity: pageData?.name =='' && '50%' }} 
                    sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, minWidth: '110px !important'}}
                    loading={status == 'loading'}
                    loadingPosition="start"
                 // loading = {true}
                    variant="contained"
                    >
                   Continue
               </LoadingButton> 
            </Box>
           </Box>
        </CustomAlert>
      
  )
}

export default CompanyNamePopup