import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    all_media_files: [],
    open_previous_project_media_popup:false,
    imported_project_id: null,
    import_files_confirmation_popup: false,
    download_confirmation_popup:false,
    enable_media_download: false,
    my_files_projects: [],
    my_projects_loading: false,
    my_files_project_media_files:[],
    my_files_loading: false,
};

export const mediaFilesSlice = createSlice({
  name: "mediaFiles",
  initialState: initialValue,
  reducers: {
    setAllMediaFilesArray: (state, action) => {
      assignValueInState(action.payload, state);
    },
    resetAllMediaFilesArray: (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { setAllMediaFilesArray, resetAllMediaFilesArray } = mediaFilesSlice.actions;
export default mediaFilesSlice.reducer;