import { Box, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import { useNavigate } from "react-router-dom";
import { generateLighterContrastColor } from "../../../utils/commonServices";
import useCustomTheme from "../../../theme/useCustomTheme";
import { updateProjectState } from "../../../redux/projectSlice";
import useSequentialDocumentUpload from "../hooks/useSequentialDocumentUpload";
import DocumentDropzone from "./DocumentDropzone";
import { getAllEventNotes } from "../actions";
import UploadedDocumentsTable from "./UploadedDocumentsTable";
import CustomTooltip from "../../../components/CustomTooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const UploadDocumentsPopup = (props) => {
    // const { event  } = props
   
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const projectReducer = useSelector((state) => state.projectReducer);
    const [uploadedVideosArray, setUploadedVideosArray] = useState([]);
    // let label = getNamesFromIds(selectedBrandboxOption?.option_id, dropdownReducer.asset_options)?.name
    const [uploadedNotes , setUploadedNotes] = useState (projectReducer?.uploaded_event_notes)
    const [acceptType, setAcceptType] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [theme] = useCustomTheme()
    // const [enableFileUpload, setEnableFileUpload] = useState(false)
    // const uploadedVideos = assetReducer?.all_assets?.find( option => option.label == label)?.files
    // const [requestController , setRequestController] = useState('')
    const { files, 
        setFiles,
        progress, 
        uploadStatus,
        setUploadStatus, 
        setProgress,
        isLoading,
        requestController, 
        setRequestController,
        stopUpload, 
        stopList ,
        enableFileUpload,
        setEnableFileUpload} = useSequentialDocumentUpload(commonReducer?.production_event_id , false, uploadedNotes,  ()=>afterUploadCallback()) 
   
 

    const handleClose = () => {
      // setEnableFileUpload(false);
      // console.log({isLoading})
      if(isLoading === true){
        stopList( ()=> dispatch(updateProjectState({upload_documents_to_editor_popup : false}))  )
      }else{
        dispatch(updateProjectState({upload_documents_to_editor_popup : false}))      
      }
    
    };

 

  // const removeFileHandler =(file, index)=>{
  // const updatedFiles = files?.filter(data => data.name !== file.name)
  // if(!!enableFileUpload) {
  //   stopUpload(index)
  // } else {
  //   setUploadStatus((last) => {
  //     var temp = last?.filter((_, i) => i !== index);
  //     return temp;
  //   });
  //   setFiles(updatedFiles)
  // }
  
  // }

  useEffect(()=>{
    getAllEventNotes({event_id: commonReducer?.production_event_id}, dispatch)
  },[])

  useEffect(() => {
     setUploadedNotes(projectReducer?.uploaded_event_notes)

  }, [ projectReducer?.uploaded_event_notes])

  const afterUploadCallback =()=>{
    getAllEventNotes({event_id: commonReducer?.production_event_id}, dispatch) 
  }

    return (
        <CustomAlert 
          open={projectReducer.upload_documents_to_editor_popup} 
          handleClose={handleClose} 
          height={{xs:'550px',md:'520px',lg:'530px' ,xl:'740px'}}
          // height={{xs:'550px',md:'610px',lg:'605px' ,xl:'700px'}}
          width={{xs:'600px',md:'700px',lg:'740px',xl:'1130px'}}
          >       
            <Box sx={{display:'flex', flexDirection:'column', height:'100%', }} px={4} pt={1} pb={2}>
              <Box flexGrow={1} pt={{xs:3,sm:1, md:1.5, lg:2.5, xl:3}} sx={{overflowY:'hidden'}} >    
                 
                  <Box className='flex col' gap={{xs:0.5, lg:1}}  sx={{ height:'100%', }}>
                    <Box className='flex j-between a-center' style={{width:{xs:'90%', md:'80%'}}}>
                      <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'#000', fontSize:{xs:'14px',sm:'14px',md:'16px',lg:'20px',xl:'30px'}}}>My Documents</Typography>
                      
                    </Box>
          

                     
                  {   ((commonReducer?.event_details?.status_type_order < 3 || ( commonReducer.event_details?.revision?.submitted == false && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id)) ) &&   <Box  sx={{height:'100px', width: '90%' , minWidth: '320px', marginTop:{xs: '20px', md: '10px'}, marginBottom:{xs:'10px', sm:'15px',md:'5px',lg:'25px', xl:'70px'}}}>
                        <DocumentDropzone
                        flex='row'
                         isDisabled={
                             isLoading
                           }
                         files={files}
                         setFiles={setFiles}
                         setUploadStatus={setUploadStatus}
                         setProgress={setProgress}
                         setRequestController={setRequestController}
                        //  refetch={()=> getAllAssets(dispatch, navigate)}
                         height={{xs: '80px',sm:'75px',md:'85px',lg:'105px', xl:'140px'}}
                         setEnableFileUpload={setEnableFileUpload}
                       />
                        <Box className='flex row j-end ' gap={0.5} sx={{alignItems: 'flex-end'}} >
                            <Typography textAlign={"end"} sx={{fontSize: {xs:'9px',md:'10px',lg:'11px',xl:'15px'}, fontWeight:600, mt:'3px'}}>Upload files upto 100 mb.</Typography>
                            <CustomTooltip title=" Files Allowed: .pdf, .txt, .docx, .doc, .ppt, .pptx" >
                              <InfoOutlinedIcon sx={{fontSize: {xs:'9px !important',md:'9px !important',lg:'10.67px !important', xl:'16px !important'}, color: '#000000',  marginBottom: '2px'}} />
                            </CustomTooltip>
                          </Box>
                        </Box>}
                        <Box height= '100%'  className= 'flex row j-between a-start' sx={{width:'100%', }}>
                      

                          <Box pl={1} component='div' sx={{  maxHeight: {xs: '130px', sm: '120px', md: '210px', lg: '150px', xl: '215px'} ,  maxWidth:'85%', width:'85%'}}>

                            <UploadedDocumentsTable
                              progress= {progress} 
                              stopUpload = {stopUpload}
                              uploadStatus = {uploadStatus}
                              files = {files}
                              uploadedNotes={uploadedNotes}
                              stopList={stopList}
                              isUploading={isLoading}
                              // refetch={refetchList}
                              height={{xs: '100px',sm:'85px',md:'100px',lg:'140px', xl:'180px'}}
                              setUploadedNotes={setUploadedNotes}
                            />

                          </Box>

                         
                        
                        <Box height= '100%' className='flex col j-end a-end'>
                          <CheckIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} 
                          onClick={()=> !isLoading && handleClose()}  
                          sx={{fontSize:{xs:'28px !important',sm:'30px !important',md:'42px !important', lg:'56px !important',xl:'75px !important'}, cursor:  !isLoading  &&  'pointer', opacity: isLoading  && 0.5}}/>
                        </Box>
                    </Box>
                  </Box>
  
              </Box>
          </Box>
       
        </CustomAlert>
      )
}

export default UploadDocumentsPopup