import React from 'react';
import '../customRadioButton.css';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { ChildCare } from '@mui/icons-material';
// import {customTheme as theme} from '../../../theme/customTheme'
import useCustomTheme from '../../../theme/useCustomTheme';

const CustomRadioBox = ({ id, name, label, children , component, height, selectedValue, setSelectedValue, boldText, text, width}) => {

  const [theme ] = useCustomTheme()

  const handleRadioChange = (event) => {
    // setSelectedValue(event.target.value)
    // console.log(event.target.value)
    setSelectedValue((last) => {
      return { ...last, video_orientation: event.target.value }
    })
  };

  // console.log(selectedValue)

  return (
    <div className='flex col'>
      <input checked={selectedValue === label} className='radio-input' id={id} value={label} name={name} type='radio' onChange={handleRadioChange} style={{display:'none'}}/>
      <label htmlFor={id} label={label}>
        <Box className='custom-radio'  sx={{width: width, backgroundColor:'#fff', border: '1px solid #08231B', borderRadius:'10px', height: height, position:'relative', boxShadow: selectedValue === label ? `0px 0px 20px ${theme.palette.primary.main}` : 'none', cursor:'pointer'}}>
          <Box className='flex a-center j-center' sx={{width:'20px', height:'20px', border: '1px solid #08231B', borderRadius:'50%', position:'absolute', top:10, left:10}}>
            {selectedValue === label  ? <Box sx={{width:'14px', height:'14px', borderRadius:'50%',backgroundColor:'#08231B'}}></Box> : null}
          </Box>
          <Box sx={{width:'100%',height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {component} 
          </Box>
        </Box>
      </label>

    { text &&  <Box mt={{xs:'5px',sm:2}} className='flex' gap={1} sx={{ justifyContent:'center'}}>
        <Typography  sx={{color:'white', fontWeight:600, fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13.5px',xl:'20px'}}}>{boldText}</Typography>
        <Typography sx={{color:'white', fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13.5px',xl:'20px'},}}>{text}</Typography>
      </Box>}
    </div>
  );
};

export default CustomRadioBox;