import { updateState } from "../../redux/commonSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { updatePermissionState } from "../../redux/permissionSlice";
import { updateTeamState } from "../../redux/teamSlice";
import { 
    addTeamMemberApi, 
    changeTeamAdminApi, 
    createTeamApi, 
    removeTeamAdminApi, 
    removeTeamApi, 
    removeTeamMemberApi, 
    renameTeamApi, 
    updateTeamMemberRoleApi, 
    updateTeamPrivacyApi, 
    viewTeamMembersApi
} from "../../services/api"
import { getAllTeams } from "../dashboard/actions";
import { getTeamEvents } from "../teamsdashboard/actions";


///////////////////////////////// CREATE TEAM API //////////////////////////////////////

export const createTeam = (values ,dispatch, navigate ) => {
    const payload = values
    dispatch(updateState({is_loading: true}))
createTeamApi(payload) 
  .then((response) => {
    if(response?.data?.http_code === 200){
      getAllTeams(dispatch)
      dispatch(updateState({is_loading: false}))
      dispatch(updateTeamState({ open_create_team_form: false}))
    }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      dispatch(updateState({is_loading: false}))
    }
  })
  .catch((error) => {
    // console.error('error:', error);
    dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    dispatch(updateState({is_loading: false}))
  });
}


/////////////////////////// UPDATE TEAM PRIVACY STATUS API ///////////////////////////////

export const updateTeamScope = (values ,dispatch, handleClose ) => {
  // api call files teamlogoCard, allTeamsCard
  const payload = values  
updateTeamPrivacyApi(payload) 
  .then((response) => {
    if(response?.data?.http_code === 200){
      getAllTeams(dispatch)
      handleClose()
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        handleClose()
    }
  })
  .catch((error) => {
    dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    handleClose()
  });
}


//////////////////////////////// RENAME TEAM API ///////////////////////////////////////

export const renameTeam = (values ,dispatch, handleClose ) => {
  //////Rename team popup//////
  dispatch(updateTeamState({is_loading: true}))
    const payload = values  
renameTeamApi(payload) 
  .then((response) => {
    if(response?.data?.http_code === 200){
       getAllTeams(dispatch)
       dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
       dispatch(updateTeamState({is_loading: false}))
       handleClose()
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        dispatch(updateTeamState({is_loading: false}))
    }
  })
  .catch((error) => {
    dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    dispatch(updateTeamState({is_loading: false}))
  });
}


//////////////////////////////////// DELETE TEAM API /////////////////////////////////

export const removeTeam = (values ,dispatch, navigate , handleClose) => {
  ////deleteTeamPopup
  dispatch(updateState({is_loading: true}))
    const payload = values  
removeTeamApi(payload) 
  .then((response) => {
    if(response?.data?.http_code === 200){
       dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
       getAllTeams(dispatch)
       dispatch(updateState({is_loading: false}))
       handleClose()
       navigate('/dashboard', {replace:true})
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        dispatch(updateState({is_loading: false}))
    }
  })
  .catch((error) => {
    dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    dispatch(updateState({is_loading: false}))
  });
}


//////////////////////////// ADD MEMBERS TO THE TEAM API /////////////////////////

export const addTeamMembers = (values ,dispatch, navigate, state ) => {

  // ////////////inviteMembersPopup
    const payload = values   
addTeamMemberApi(payload) 
  .then((response) => {
    // console.log({response})
   
    if(response?.data?.http_code === 200){
      getAllTeams(dispatch)
      viewTeamMembers({team_id: payload?.team_id}, dispatch, navigate, state)
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  });
}


//////////////////////////// VIEW TEAM MEMBERS API ////////////////////////////////////

export const viewTeamMembers = (values ,dispatch, navigate , state) => {
const payload = values

viewTeamMembersApi(payload) 
  .then((response) => {
    // console.log({response})
   
    if(response?.data?.http_code === 200){
     
      dispatch(updateTeamState({selected_team_members: response?.data?.data}))
      dispatch(updatePermissionState({team_role_id:  response?.data?.data?.users?.find(mem => mem.id === state.commonReducer?.user_id)?.role?.id}))
      navigate && navigate('/dashboard/teams')
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  });
}


///////////////////////////// CHANGE ROLE OF TEAM MEMBER API ///////////////////////////////

export const updateTeamMemberRole = (values ,dispatch, navigate, state,setSelectedRole, role ) => {
  dispatch(updateTeamState({is_loading:true}))  
  const payload = values
updateTeamMemberRoleApi(payload) 
  .then((response) => {
    // console.log({response})
    dispatch(updateTeamState({is_loading:false}))  
    if(response?.data?.http_code === 200){
        getAllTeams(dispatch)
        setSelectedRole(role?.name)
        viewTeamMembers({team_id: payload?.team_id}, dispatch, navigate, state)
        dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(updateTeamState({is_loading:false}))  
    // console.error('error:', error);
    dispatch(setSnackbar({type:'error', flag:true, message: error?.response?.data?.message}))
  });
}


///////////////////////////  CHANGE TEAM ADMIN ///////////////////////////////////////

export const changeTeamAdmin = (values ,dispatch, navigate, state ) => {
    dispatch(updateTeamState({loading_state: 'loading'}))
    const payload = values
changeTeamAdminApi(payload) 
  .then((response) => {
    // console.log({response})
   
    if(response?.data?.http_code === 200){
      getAllTeams(dispatch)
      viewTeamMembers({team_id: payload?.team_id}, dispatch, navigate, state)
      dispatch(updateTeamState({loading_state: 'success'}))
      // setNewAdminAssigned(true)
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        dispatch(updateTeamState({loading_state: 'error'}))
    }
  })
  .catch((error) => {
    // console.error('error:', error);
    dispatch(setSnackbar({type:'error', flag:true, message: error?.response?.data?.message}))
    dispatch(updateTeamState({loading_state: 'error'}))
  });
}


//////////////////////////////// REMOVE TEAM ADMIN ///////////////////////////////////////

export const removeTeamAdmin = (values ,dispatch, navigate, state ) => {
  dispatch(updateTeamState({loading_state: 'loading'}))
    const payload = values
removeTeamAdminApi(payload) 
  .then((response) => {
    // console.log({response})
   
    if(response?.data?.http_code === 200){
      getAllTeams(dispatch)
      viewTeamMembers({team_id: payload?.team_id}, dispatch, navigate, state)
      dispatch(updateTeamState({loading_state: 'success'}))
      // setNewAdminAssigned(true)
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        dispatch(updateTeamState({loading_state: 'error'}))
    }
  })
  .catch((error) => {
    // console.error('error:', error);
    dispatch(updateTeamState({loading_state: 'error'}))
    dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
  });
}


/////////////////////////////////// REMOVE TEAM MEMBER ////////////////////////////////////////

export const removeTeamMember = (values ,dispatch, navigate , state) => {
    const payload = values
    dispatch(updateTeamState({loading_state:'loading'}))
removeTeamMemberApi(payload) 
  .then((response) => {
    // console.log({response})
   
    if(response?.data?.http_code === 200){
      dispatch(updateTeamState({loading_state:'success'}))
      getAllTeams(dispatch)
      viewTeamMembers({team_id: payload?.team_id}, dispatch, navigate, state)
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      dispatch(updateTeamState({loading_state:'error'}))
    }
  })
  .catch((error) => {
    dispatch(setSnackbar({type:'error', flag:true, message: error.response.data.message}))
    dispatch(updateTeamState({loading_state:'error'}))
  });
}







