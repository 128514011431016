
import { TroubleshootOutlined } from "@mui/icons-material";
import { updateState } from "../../redux/commonSlice";
import { resetTheme, setTheme } from "../../redux/customThemeSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { createThemeApi, deleteThemeApi, getThemeApi, updateThemeApi } from "../../services/api";
import { showSnackBar } from "../../utils/commonServices";



export const getTheme = (dispatch) => {
  dispatch(setTheme({get_call_pending: true}))
  getThemeApi()
   .then((response) => {
    dispatch(setTheme({get_call_pending: false}))
     if(response?.data?.http_code === 200){
          if(Array.isArray(response?.data?.data)){
            if(response?.data?.data?.length > 0){
              localStorage.setItem("primary", response?.data?.data?.primary_colour);
              localStorage.setItem("secondary", response?.data?.data?.secondary_colour);
              localStorage.setItem('brand_logo_url',  response?.data?.data?.url)
              // localStorage.setItem('brand_logo_download_url',  response?.data?.data?.download_url)
              dispatch(updateState({brand_logo: response?.data?.data?.url, brand_logo_download_url: response?.data?.data?.download_url }))
              dispatch(setTheme({primary:  response?.data?.data?.primary_colour, secondary: response?.data?.data?.secondary_colour, brand_logo: response?.data?.data?.url, brand_logo_download_url: response?.data?.data?.download_url}))
            }
          }else {
            if(Object.keys(response?.data?.data)?.length > 0){
              localStorage.setItem("primary", response?.data?.data?.primary_colour);
              localStorage.setItem("secondary", response?.data?.data?.secondary_colour);
              localStorage.setItem('brand_logo_url',  response?.data?.data?.url)
              // localStorage.setItem('brand_logo_download_url',  response?.data?.data?.download_url)
              dispatch(setTheme({primary:  response?.data?.data?.primary_colour, secondary: response?.data?.data?.secondary_colour, brand_logo: response?.data?.data?.url, brand_logo_download_url: response?.data?.data?.download_url}))
              dispatch(updateState({brand_logo: response?.data?.data?.url, brand_logo_download_url: response?.data?.data?.download_url }))
             } else {
               dispatch(resetTheme())
             }
          } 
          // window.location.href = window.location.href;
          // window.location.reload(false)
     }else {
         dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
     }
   })
   .catch((error) => {
    dispatch(setTheme({get_call_pending: false}))
     if(error) {
     const errorObject = error?.response?.data;
     showSnackBar(dispatch, errorObject);
     }
   });
}




export const createTheme = (values ,dispatch, callback) => {
    dispatch(updateState({theme_loading: true}))
  const payload = values
  createThemeApi(payload) 
  .then((response) => {
    dispatch(updateState({theme_loading: false}))
    if(response?.data?.http_code === 200){
      getTheme(dispatch)
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
      callback()
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }

  })
  .catch((error) => {
    dispatch(updateState({theme_loading: false}))
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    }
  });
  }



  export const updateTheme = (values ,dispatch, callback) => {
    dispatch(updateState({theme_loading: true}))
  const payload = values
  updateThemeApi(payload) 
  .then((response) => {
    dispatch(updateState({theme_loading: false}))
    if(response?.data?.http_code === 200){
      callback()
      getTheme(dispatch)
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(updateState({theme_loading: false}))
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    }
  });
  }



  export const removeTheme = (values ,dispatch, callback) => {
    dispatch(updateState({theme_loading: true}))
  deleteThemeApi(values) 
  .then((response) => {
    dispatch(updateState({theme_loading: false}))
    if(response?.data?.http_code === 200){
      // window.localStorage.removeItem("primary");
      // window.localStorage.removeItem("secondary");
      // window.localStorage.removeItem("brand_logo_url");
      // window.localStorage.removeItem("brand_logo_download_url");
      dispatch(resetTheme())
      getTheme(dispatch)
      callback()
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(updateState({theme_loading: false}))
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    }
  });
  }