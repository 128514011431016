import { type } from '@testing-library/user-event/dist/type';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDropDownArray } from '../../redux/dropdownSlice';
import { eventStatusType, toneList } from '../../services/api';
export default (preSelected)=> {
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const dispatch = useDispatch();
    //Get event status type dropdown array from Server

    const { data: statusData, isLoading: statusLoading, refetch: refetchStatus } = useQuery( ["status_types"],
                                               () => eventStatusType(),
                                               { enabled: dropdownReducer.status_type.length < 1, retry: false }
                                            )
    useEffect(() => {
        if (statusData) {
            var temArr = statusData?.data?.status_type?.map((type)=> { 
                return {...type, checked: false} 
            })
            temArr.unshift({id:0,  value:"View-All", order:0, checked:true})
            dispatch(setDropDownArray({status_type: temArr}))

        }
    }, [statusData])

   
  
    return [refetchStatus]
    
}