import { makeStyles } from "@mui/styles";
import {  useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Backdrop, Box, Grid, Paper, Typography } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import "@fontsource/rubik";
import useCustomTheme from "../../../theme/useCustomTheme";
import { approveNewUser } from "../../../commonactions/actions";
import CreateProjectSpinner from "../../eventcreation/components/CreateProjectSpinner";


const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const SigninAction = () => {


    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token')
    const projectReducer = useSelector((state) => state.projectReducer);
    const themeReducer = useSelector((state) => state.root.themeReducer);
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')

    
     const classes = useStyles();
     const [theme ] = useCustomTheme()
  

      useEffect(()=> {
        const payload = {
          token: token
        }
        approveNewUser(payload, dispatch, (response)=> {

          let message = ""
          let title = 'Registration Approval'
      
          switch(response?.data?.status) {
            case "accepted" :         message = "User registration has been accepted.";
                                      title = 'Registration accepted'
                                      break;
            case "already_accepted" : message = "User Registration has already been accepted.";
                                      title = 'Registration accepted'
                                      break;
            case "declined" :         message = "User registration has been declined.";
                                      title = 'Registration declined'
                                      break;
            case "already_declined" : message = "User registration has already been declined.";
                                      title = 'Registration declined'
                                      break;
            default: message = response?.message

          }
         setMessage(message)
         setTitle(title)
        })
      }, [])


        
  return (
    <UnauthLayout >
        <Box className={classes.centerBox} flexDirection='column'>

<Box px={4} pb={3}>
     <Box className={classes.centerBox} flexDirection='column' mb={3}>
        <Typography 
                color="primary"
                style={{
                  fontWeight: 600,
                  color:'#08231B',
                  textAlign: 'center',
                  textTransform: 'none'
                }}
                sx={{fontSize:{xs:'30px',sm:'40px',md:'47px',lg:'54px',xl:'80px'}, fontFamily:'Rubik'}}>{title? title :''}</Typography>

   

     </Box>
     <Box sx={{width:'100%', textAlign:'center'}}>
       
         <Typography color="primary"
                style={{
                  fontWeight: 300,
                  color:'#08231B',
                  textAlign: 'center',
                  textTransform: 'none'
                }}
                sx={{fontSize:{xs:'10px',sm:'13px',md:'15px',lg:'17px',xl:'30px'}, fontFamily:'Rubik'}}> {message? message :''}
         
         </Typography>
     </Box>
 </Box>

</Box>


{ projectReducer.signin_action_loader &&  <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(16px) !important', display:'flex', flexDirection:'column',
                }}
                open={projectReducer.signin_action_loader}
                >
              
                  <Box className='flex col' sx={{justifyContent:'center', alignItems:'center'}}>
                    <CreateProjectSpinner/>
                    <Typography sx={{fontSize:'50px', color:'white', fontWeight:600, fontFamily:'Rubik'}}>Please Wait…</Typography>
                  </Box>
          
            </Backdrop>}
    </UnauthLayout>
  )
}

export default SigninAction