import CustomInput from "../../eventcreation/components/CustomInput";
import { makeStyles } from "@mui/styles";
// import { customTheme as theme } from "../../../theme/customTheme";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { checkEmail, userSignup } from "../../../services/api";
import { afterValidate, getInitials, setInput } from "../../../utils/commonServices";
import useValidator from "../../../hooks/useValidator";
// import SignupSuccessPopup from "./SignupSuccessPopup";
import useAuthRequest from "../../../hooks/useAuthRequest";
// import CustomSnackbar from "../../components/CustomSnackbar";
import CustomTooltip from "../../../components/CustomTooltip";
import PasswordInstructions from "../../../components/PasswordInstructions";
import movieIcon from '../../../assets/movieIcon.svg';
import { Avatar, Box, Grid, Paper, Typography } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import CustomButton from "../../../components/CustomButton";
import { LoadingButton } from "@mui/lab";
import { gaEvents } from "../../../ga/actions";
import { PRODUCT_ID } from "../../../constants/constants";
import { inviteOrganisationMember } from "../../../commonactions/actions";
import { useDispatch, useSelector } from "react-redux";
import useCustomTheme from "../../../theme/useCustomTheme";


const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const MemberSignUp = () => {

  const classes = useStyles();
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [searchParams] = useSearchParams();
  const tempEmail = searchParams.get('email')
  const email = tempEmail?.replace(/ /g, '+')
  const organisation_name = searchParams.get('company_name')
  const invitedBy = searchParams.get('invited_by')
  const token = searchParams.get('token')
  const dispatch = useDispatch()

  console.log({invitedBy})

  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    name: "",
    organisation_name: organisation_name !== ''? organisation_name : "",
    // phone_number: "",
    email: email !== '' ? email : '',
    password: "",
    password_confirmation: "",
    product_id: PRODUCT_ID.lucihub
  });

  const [snackbarProps, setSnackbarProps] = useState({});
  const [errorObjectArray, checkEmptyFields, checkValue] =
    useValidator(pageData);
  const [signup, signupStatus] = useAuthRequest("signup", setSnackbarProps);
  const [theme ] = useCustomTheme()


  //Submit button handler function
  const submitClickHandler = (event) => {
    
    checkEmptyFields(event);
    if(tempEmail === null){
      checkValue(event, true);
    }else{
      checkValue(event, false);
    }
    afterValidate(afterValidatCallback);
  };

  
  const afterValidatCallback = async () => {
    console.log('here')
    const payload = {
      name: pageData?.name,
      password: pageData?.password,
      token: token
    }
    inviteOrganisationMember(payload, dispatch, navigate)
    gaEvents.joinClicked();
    console.log(pageData)
  };


  useEffect(() => {
    // if (signupStatus == "success") {
    //   setOpenSuccessPopup(true);
    // }
     if (signupStatus == "success") {
      // setOpenSuccessPopup(true);
      // navigate('/resendlink', {state: pageData})
      navigate('/resendlink', {state: {pageData: pageData, title: 'Your account has been created', icon: movieIcon, btnText: 'Resend Email', message:'Please check your email and click on the verify now button to continue.', signIn: false}, replace:true})
     }
  }, [signupStatus]);

  //code to detect enter key for editing Organization Name
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        submitClickHandler(event);
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [pageData]);

  //handler function to check the entered email in database for existing user

  // const emailValidations = (event)=> {

  //    checkValue(event);
  //    setTimeout(()=> {
  //     checkEmailForUser();
  //   }, 200)

  // }
  // console.log("erroor", errorObjectArray )
  // const checkEmailForUser = ()=> {
  //   if(errorObjectArray[2].errorFlag == false && pageData?.email != "") {
  //     checkExistingUser(pageData?.email);
  //    }
  // }


  return (
    <UnauthLayout 
    rightComponent={<>
        <Box gap={3} className='flex' sx={{flexDirection:'column', alignItems:'center'}}>
                <Avatar
                    sx={{
                        borderRadius: '5px',
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        width: { xs: '36px', sm: '36px', md: '46px', lg: '56px', xl: '83px' },
                        height: { xs: '36px', sm: '36px', md: '46px', lg: '56px', xl: '83px' },
                        paddingRight: 0,
                        marginRight: 0,
                        fontSize: { xs: '16px', md: '16px', lg: '18px', xl: '28px' },
                        textTransform: 'uppercase',
                        fontFamily: 'Rubik',
                        fontWeight:600
                        }}
                >
                    {getInitials(invitedBy)}
                </Avatar>
                <Typography textAlign={'center'} sx={{ fontSize: { xs: '20px', md: '28px', lg: '33px', xl: '50px' },fontFamily:'Rubik', color:'#000'}}>You have accepted<br/> <span style={{fontWeight:600,fontFamily:'Rubik', color:'#000'}}>{invitedBy}'s</span> invitation <br/> to join their team.</Typography>
        </Box>
    </>}
    >
      <Box className={classes.center} square component="Box">
        <Box
          container
          justifyContent="flex-start"
          spacing={3}
        >
          <Box item xs={11} sm={8} md={7}>
            <Box sx={{marginY:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
              <Typography
                variant="h4"
                color="primary"
                style={{
                  fontWeight: 600,
                  color:'#08231B'
                }}
                sx={{fontSize:{xs:'30px',sm:'40px',md:'47px',lg:'54px',xl:'80px'}, fontFamily:'Rubik'}}
              >
                Let’s get rolling.
              </Typography>
            </Box>
          </Box>

          <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column'}}>
            <CustomInput
              id={errorObjectArray[0]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              required
              errorFlag={errorObjectArray[0]?.errorFlag}
              errorMsg={errorObjectArray[0]?.error}
              // errorFlag= {true}
              // errorMsg= "jsnkdjbfskfj"
              // label="Name*"
              type="text"
              max={40}
              placeholder="Name"
              onChange={(event) => {
                setInput(
                  pageData?.name != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "name",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.name}
              // required
              // error={submitFlag && getErrorMsz('name', pageData.name) != ""}
              // errorMsz={getErrorMsz('name', pageData.name)}
            />
            {organisation_name === null ? (
              <CustomInput
              id={errorObjectArray[1]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
             required
             errorFlag={errorObjectArray[1]?.errorFlag}
             errorMsg={errorObjectArray[1]?.error}
             disabled={organisation_name !== null}
              type="text"
              max={40}
              placeholder="Company Name"
              onChange={(event) => {
                setInput(
                  pageData?.organisation_name != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "organisation_name",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.organisation_name}
            />
            ): (
              <CustomInput
              id={errorObjectArray[1]?.id}
              // onFocus={checkEmptyFields}
              // onBlur={checkValue}
             required
             errorFlag={false}
            //  errorFlag={errorObjectArray[1]?.errorFlag}
            //  errorMsg={errorObjectArray[1]?.error}
             disabled
              type="text"
              max={40}
              placeholder="Company Name"
              onChange={(event) => {
                setInput(
                  pageData?.organisation_name != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "company_name",
                  pageData,
                  setPageData
                );
                // checkEmptyFields(event);
              }}
              value={pageData?.organisation_name}
            />
            )}
           
            {/* <CustomInput
              id={errorObjectArray[2]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              // required
              // errorFlag={errorObjectArray[2]?.errorFlag}
              // errorMsg={errorObjectArray[2]?.error}
              // label="Phone Number"
              type="text"
              max={10}
              placeholder="Phone Number"
              onChange={(event) => {
                !isNaN(event.target.value) &&
                  setInput(
                    event.target.value.trim(),
                    "phone_number",
                    pageData,
                    setPageData
                  );
                checkEmptyFields(event);
              }}
              value={pageData?.phone_number}
            /> */}
            {tempEmail === null ? (
              <CustomInput
              id={errorObjectArray[2]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              required
              errorFlag={errorObjectArray[2]?.errorFlag}
              errorMsg={errorObjectArray[2]?.error}
              // label="Email Address*"
              type="text"
              max={40}
              placeholder="Email Address*"
              onChange={(event) => {
                setInput(
                  pageData?.email != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "email",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.email}
            />
            ):(
              <CustomInput
               id={errorObjectArray[2]?.id}
              // label="Email Address*"
              type="text"
              max={40}
              disabled={true}
              placeholder="Email Address*"
              onChange={(event) => {
                setInput(
                  pageData?.email != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "email",
                  pageData,
                  setPageData
                );
              }}
              value={pageData?.email}
            />
            )}
            
            <CustomTooltip
              arrow
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={<PasswordInstructions />}
              placement={window.innerWidth <= 1100 ? "bottom" : "right"}
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: window.innerWidth >= 1100 && [0, 40],
                    },
                  },
                ],
              }}
            >
              {/* <Box width='100%' height={(errorObjectArray[3]?.errorFlag && errorObjectArray[3]?.error != "Please enter password" )&& '50px'}> */}
              <CustomInput
                id={errorObjectArray[3]?.id}
                onFocus={checkEmptyFields}
                onBlur={checkValue}
                required
               // bottom ={errorObjectArray[3]?.error != "Please enter password" && -22}
                errorFlag={errorObjectArray[3]?.errorFlag}
                errorMsg={errorObjectArray[3]?.error}
                // label="Create Password*"
                type="password"
                max={40}
                placeholder="Create Password*"
                onChange={(event) => {
                  setInput(
                    pageData?.password != ""
                      ? event.target.value
                      : event.target.value.trim(),
                    "password",
                    pageData,
                    setPageData
                  );
                  checkEmptyFields(event);
                }}
                value={pageData?.password}
              />
              {/* </Box> */}
            </CustomTooltip>
            <CustomInput
              id={errorObjectArray[4]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              required
              errorFlag={errorObjectArray[4]?.errorFlag}
              errorMsg={errorObjectArray[4]?.error}
              // label="Confirm Password*"
              type="password"
              max={40}
              placeholder="Confirm Password*"
              onChange={(event) => {
                setInput(
                  pageData?.password_confirmation != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "password_confirmation",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.password_confirmation}
            />
            
          </Box>

          <Box item xs={11} sm={8} md={7}>
            <Box
              container
              justifyContent="space-between"
              sx={{
                flexDirection: { xs: "column-reverse", sm: "row" },
                alignItems: "center",
              }}
            >

              <Box item xs={8} sm={4} sx={{display:'flex', justifyContent:'flex-end', marginTop:{xs:'20px',sm:'28px',md:'28px',lg:'33px',xl:'50px'}, gap:2}}>
                {/* <CustomButton
                  btnText="Join Now"
                  disabled={signupStatus === "loading"}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={submitClickHandler}
                  style={{borderRadius: '5px', backgroundColor:'#08231B', color:theme.palette.primary.main, textTransform:'none', }} 
                  sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,}}
                /> */}
                 <LoadingButton
                   // size="small"
                  //  disabled={signupStatus === "loading"}
                  disabled={commonReducer?.is_loading === true}
                    onClick={submitClickHandler}
                    style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#21D644', textTransform:'none', }} 
                    sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,  minWidth: '110px !important' }}
                   // sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},  opacity: !checked && 0.7, minWidth: '110px !important'}}
                    loading={commonReducer?.is_loading === true}
                    loadingPosition="start"
                 // loading = {true}
                    variant="contained"
                    >
                   Join Now
               </LoadingButton> 
              </Box>

              <Box
                 sx={{ marginTop: { xs:'20px',sm:'30px',md:'40px',lg:'45px',xl:'70px' } }}
                item
                xs={12}
                sm={8}
                textAlign='center'
              ><Typography  sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13px',xl:'20px'}, fontFamily:'Rubik', color:'#000000'}}>
                {`Already Have an account? `}
                <Link
                  to="/signin"
                  style={{
                    textDecoration: "none",
                    fontWeight:600,
                    color:'#000000'
                  }}
                  sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13px',xl:'20px'}, fontFamily:'Rubik', color:'#000000', fontWeight:600}}
                >
                  Log In.
                </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </UnauthLayout>
  )
}

export default MemberSignUp