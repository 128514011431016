import { Avatar, Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Input, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import edit from '../../../assets/editExtendIcon.svg'
import { updateCreationState } from "../../../redux/eventCreationSlice";
import { afterValidate, generateDarkColor, getInitials, setInput, timeStampToDateString, timeStampToDayString, timeStampToNumberedDateString } from "../../../utils/commonServices";
import { LoadingButton } from "@mui/lab";
import { updateTeamState } from "../../../redux/teamSlice";
import CustomInput from "../../eventcreation/components/CustomInput";
import CustomAutocomplete from "../../eventcreation/components/CustomAutoComplete";
import CustomButton from "../../../components/CustomButton";
import { getErrorMsg } from "../../../utils/validator";
import { exampleTeamMembers } from "../../../constants/staticData";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
// import { customTheme as theme } from "../../../theme/customTheme";
import { addOrganisationMember } from "../../dashboard/actions";
import { getRoleNamesFromIds } from "../../../commonactions/actions";
import MembersList from "../../teams/components/MembersList";
import OrganisationMembersList from "./OrganisationMembersList";
import useCustomTheme from "../../../theme/useCustomTheme";




const useStyles = makeStyles((theme) => ({

  expanded: {
    width:'60%'
  }
}));



const InviteOrganisationMembersPopup = (props) => {
    const {  options } = props
   
    const dispatch = useDispatch()
    const [searchTerm, setSearchTerm] = useState('');
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const [emailValidationFlag, setEmailValidationFlag] = useState(false);
    const [roleValidationFlag, setRoleValidationFlag] = useState(false);
    const [inviteButtonState, setInviteButtonState] = useState('Invite')
    const [members, setMembers] = useState(teamsReducer.organization_members)
    const [copilotAccessChecked, setCopilotAccessChecked] = useState(false)
    // const [testmembers, setTestmembers] = useState(members)
   
    const [searchedArray, setSearchedArray] = useState(members)
    const colors = ['red', 'pink', 'black'];

    const initialPageData = {
      email: "" ,
      role_id:""      
    }

    const [pageData, setPageData] = useState(initialPageData)

    const isButtonDisabled = pageData.email == '' || pageData.role_id == '' 
    const location = useLocation()
    const navigate = useNavigate()
    const [theme ] = useCustomTheme()





    
    useEffect(()=>{
      setSearchedArray(teamsReducer?.organization_members)
      // setMembers(teamsReducer?.organization_members)
    },[teamsReducer?.organization_members])

  
  
    const inviteClickHandler = () => {
      setEmailValidationFlag(true)
      setRoleValidationFlag(true)
      afterValidate(sendInvite)
    }

    const sendInvite = () =>{
      if(copilotAccessChecked){
        addOrganisationMember({...pageData, new_product_id: 3}, dispatch, navigate)
        // console.log('checked api', {...pageData, new_product_id: 3})
      }else{
        addOrganisationMember(pageData , dispatch, navigate)
        // console.log('unchecked api', pageData)
      }
      setInviteButtonState("Invite Sent!")
      setTimeout(() => {
        setInviteButtonState('Invite')
        setPageData(initialPageData)
        setCopilotAccessChecked(false)
        setEmailValidationFlag(false)
        setRoleValidationFlag(false)
      }, 1500)

    }

    const handleClose = () => {
      !teamsReducer.is_loading && dispatch(updateTeamState({invite_team_members_popup : false}))      
    };

  

  const handleAutocompleteChange = (event, value) => {
    setPageData((last) => {
      return { ...last, role_id: value.id };
  });
  }; 

  const handleSearch = (e) => {
    const searchedInput = e.target.value.toLowerCase();
    if (!searchedInput.startsWith(' ')) {
      setSearchTerm(searchedInput);
    }

    const updatedFiles = [...members];

    const results = updatedFiles?.filter(item =>
      item.name?.toLowerCase().includes(searchedInput) ||
      item.email?.toLowerCase().includes(searchedInput)||
      item.role?.name?.toLowerCase().includes(searchedInput)
    );
    setSearchedArray(results);
  };

  
  const handleCopilotAccessChange = (event)=>{
    setCopilotAccessChecked(event.target.checked)
  }

    return (
        <CustomAlert 
          open={teamsReducer.invite_team_members_popup} 
          handleClose={handleClose} 
          height={{xs:'420px', md:'78%',lg:'73%',xl:'650px'}}
          width={{md:'68%',lg:'65%', xl:'1400px'}}
          rightComponent={
          <Box sx={{display:'flex', flexDirection:'column', paddingX:{xs:'5px',sm:'8px',md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
            <Box  sx={{height: '100%'}} className= 'flex col j-center a-center' style={{alignItems:'flex-start'}}> 
              <Typography  sx={{fontSize:{ xs: 8.5, md: 9.5,lg:'12px', xl: 16 }, fontWeight:600, fontFamily:'Rubik', color:'#000'}}>Administrators</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0} >
                <Typography component='li' sx={{fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Add or remove organization members</Typography>
                <Typography component='li' sx={{fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Change organization member roles</Typography>
                <Typography component='li' sx={{fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Create and manage teams</Typography>
                <Typography component='li' sx={{fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Have full access to all the teams and projects of organization</Typography>
              </Box>
              <Typography sx={{fontSize:{ xs: 8.5, md: 9.5,lg:'12px', xl: 16 }, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Organization Members</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}>
                <Typography component='li' sx={{fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of other organization members</Typography>
                <Typography component='li' sx={{fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of projects they are associated with</Typography>
                <Typography component='li' sx={{fontSize: { xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of teams they are associated with</Typography>
              </Box>
            </Box>
          </Box>}
          >       
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={4} pt={{xs:1,lg:3}} pb={2}>
              <Box flexGrow={1} pt={1} >
                  <Typography mb={1} sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'27px'}, color:'#000'}}>Invite with email</Typography>
                  
                  <Box gap={2} style={{display:'flex'}} >
                            <Box style={{width:'40%'}}>
                              <CustomInput
                                onFocus={()=>setEmailValidationFlag(false)}
                                onBlur={()=>setEmailValidationFlag(true)}
                                disabled = {teamsReducer.is_loading}
                                required
                                errorFlag={
                                  emailValidationFlag &&
                                  getErrorMsg("email", pageData?.email) != ""
                                }
                                errorMsg={getErrorMsg("email", pageData?.email)}
                                type="email"
                                max={40}
                                placeholder="Add members by email..."
                                onChange={(event) => {
                                  setInput(
                                    pageData?.email != ""
                                    ? event.target.value
                                    : event.target.value.trim(),
                                    "email",
                                    pageData,
                                    setPageData
                                  );
                                }}
                                value={pageData?.email}
                                bottom={{xs:'-9px',sm:'-10px',lg:'-12px',xl:'-20px'}}
                              />
                              
                    
                            </Box>
                            <Box style={{width:'25%'}}>         
                              <CustomAutocomplete
                                options={options}
                                placeholder='Set as...'
                                onFocus={() => {
                                  setEmailValidationFlag(true)
                                  setRoleValidationFlag(false)
                                }}
                                disabled = {teamsReducer.is_loading}
                                onBlur={() => setRoleValidationFlag(true)}
                                required
                                errorFlag= {roleValidationFlag && getErrorMsg("role_type", pageData?.role_id) != ""}
                                errorMsg= {getErrorMsg("role_type", pageData?.role_id)}
                                onChange={handleAutocompleteChange}
                                value={getRoleNamesFromIds(pageData?.role_id, options)}
                                getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.name : ""
                                }
                                />
                              <Box className='flex a-center ' sx={{justifyContent:'flex-end'}} gap={0.5} mb={0.5} mt={{xs:2.5, md:1.7,lg:1, xl:1}}>
                                <FormGroup sx={{fontSize:'11px', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                  <FormControlLabel sx={{marginRight:0}} control={<Checkbox checked={copilotAccessChecked} onChange={handleCopilotAccessChange} sx={{ '& .MuiSvgIcon-root': { fontSize:{xs:'9px',sm:'10px',lg:'14px',xl:'16px'}, margin:0, padding:0,} , borderRadius:'5px', }} />}  />             
                                </FormGroup>
                                <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'7px',sm:'8px',lg:'11px',xl:'13px'}, fontWeight:600}}>Enable Copilot Access</Typography>
                              </Box>
                            </Box>
                            <Box>
                              <CustomButton variant='contained' color='secondary' btnText={inviteButtonState} disabled = {teamsReducer.is_loading} type='button' onClick={inviteClickHandler}  sx={{
                              // backgroundColor:theme.palette.secondary.main,
                               borderRadius:'5px', border:'none', fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, padding:'10px', width:'6.1rem', fontWeight:600, textTransform:'none', height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"36px"},fontFamily:'Rubik' }}/>
       
                            </Box>
                  </Box>
                
               
                  <Box className='flex col' gap={1.5} >
                    <Box className='flex j-between a-center' style={{width:{xs:'90%', md:'80%'}}}>
                      <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'#000', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'27px'}}}>Members ({searchedArray?.length})</Typography>
                      <Box id="search" className='flex j-end' pr={2}>
                        <Input
                          id="search-input"
                          disabled = {teamsReducer.is_loading}
                        value={searchTerm}
                        onChange={handleSearch}
                        inputProps={{ maxLength: 50 }}
                        sx={{
                            borderColor: "transparent",
                            input: {
                                fontSize: { xs: '12px', md: '12px', lg: '13px', xl: '16px' },
                                fontWeight: 400,
                                color: "#000000",
                              
                            },
                           
                            justifySelf:'flex-end',
                            width:'60%', 
                            transition: 'width 0.3s ease',
                    
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton sx={{paddingBottom:0.5, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'15px',xl:'20px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                    </Box>
                    </Box>
                    <Box  pr={2}>
                        <div style={{width:{xs:'90%', md:'80%'}, marginTop:'8px'}}>
                          <Box mb={1} className='flex a-center' gap={2}>
                            <Box flex={8} className='flex col' sx={{fontSize:{xs:'9px', md:'10px',lg:'13px', xl:'18px'}, fontWeight:600, fontFamily:'Rubik', color:'#000000'}}>
                              <Typography sx={{ fontSize: {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500, color: '#000000',  fontFamily:'Rubik'}}>
                                 Name
                              </Typography>
                            </Box>

                            <Box flex={6} className='flex a-center' style={{justifyContent:'end'}}>
                              <Typography sx={{ fontSize: {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500, color: '#000000', fontFamily:'Rubik', textAlign:'right'}}>
                                Role
                              </Typography>
                            </Box>

                            <Box flex={2} className='flex a-center' style={{justifyContent:'end'}}>
                              <Typography sx={{ fontSize: {xs:'9px', md:'10px',lg:'12px', xl:'16px'}, fontWeight: 500, color: '#000000', fontFamily:'Rubik', textAlign:'right'}}>
                                 Enable Copilot
                              </Typography>
                            </Box>
                           </Box>
                        </div>
                        {/* <div sx={{ overflowY:'auto', height:'90%'}} > */}
                      <Box sx={{height:{xs:'22vh',lg:'22vh', xl:'25vh'}, overflowY:'auto'}}>
                      {searchedArray.length > 0 ? searchedArray.map((member, index) => {
                        return  <OrganisationMembersList color={generateDarkColor(member, 'name')}   member={member} index={index} searchedArray={searchedArray} roleOptions={options}/>
                      }) : 
                       <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'>
                          <Typography sx={{fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'27px'}, color: '#878887', fontWeight: 300, textAlign: 'center', fontFamily:'Rubik'}} >No results found.
                          </Typography>
                        </Box>
                      }
                      </Box>
                      {/* </div> */}
                  
                    </Box>
                  </Box>
  
              </Box>

              {/* <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               { requestStatus == 'loading'  ? <LoadingButton
                  
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.primary.main,
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={requestStatus == 'loading'}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> :
               <CheckIcon onClick= {submitRequestClickHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
               }
                 
                </Box>
              <Box>    
                <Button 
                variant="text"
                onClick={handleClose}
                 sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'120px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                Or Go Back
               </Button>
              </Box>
            </Box> */}
          </Box>
       
        </CustomAlert>
      )
}

export default InviteOrganisationMembersPopup












// import { Avatar, Box, Button, IconButton, Input, Typography } from "@mui/material";
// import { useEffect, useState } from "react";
// import CustomAlert from "../../events/components/CustomAlert"
// import { updateReviewState } from "../../../redux/reviewSlice";
// import { useDispatch, useSelector } from "react-redux";
// import CheckIcon from "../../../components/icons/CheckIcon";
// import edit from '../../../assets/editExtendIcon.svg'
// import { updateCreationState } from "../../../redux/eventCreationSlice";
// import { afterValidate, getInitials, setInput, timeStampToDateString, timeStampToDayString, timeStampToNumberedDateString } from "../../../utils/commonServices";
// import { LoadingButton } from "@mui/lab";
// import { updateTeamState } from "../../../redux/teamSlice";
// import CustomInput from "../../eventcreation/components/CustomInput";
// import CustomAutocomplete from "../../eventcreation/components/CustomAutoComplete";
// import CustomButton from "../../../components/CustomButton";
// import { getErrorMsg } from "../../../utils/validator";
// import { exampleTeamMembers } from "../../../constants/staticData";
// import SearchIcon from "@mui/icons-material/Search";
// import { makeStyles } from "@mui/styles";
// import { useLocation, useNavigate } from "react-router-dom";
// import { customTheme as theme } from "../../../theme/customTheme";
// import { addOrganisationMember } from "../../dashboard/actions";
// import { getRoleNamesFromIds } from "../../../commonactions/actions";
// import MembersList from "../../teams/components/MembersList";
// import OrganisationMembersList from "./OrganisationMembersList";




// const useStyles = makeStyles((theme) => ({

//   expanded: {
//     width:'60%'
//   }
// }));



// const InviteOrganisationMembersPopup = (props) => {
//     const {  options ,members} = props
   
//     const dispatch = useDispatch()
//     const [searchTerm, setSearchTerm] = useState('');
//     const teamsReducer = useSelector((state) => state.teamsReducer);
//     const commonReducer = useSelector((state) => state.root.commonReducer);
//     const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
//     const [emailValidationFlag, setEmailValidationFlag] = useState(false);
//     const [roleValidationFlag, setRoleValidationFlag] = useState(false);
//     const [inviteButtonState, setInviteButtonState] = useState('Invite')
//     // const [members, setMembers] = useState(teamsReducer.organization_members)

  
//     // const [testmembers, setTestmembers] = useState(teamsReducer.organization_members)
//     const [testmembers, setTestmembers] = useState(members)
   
//     const [searchedArray, setSearchedArray] = useState(testmembers)
//     const colors = ['red', 'pink', 'black'];

//     const initialPageData = {
//       email: "" ,
//       role_id:""      
//     }

//     const [pageData, setPageData] = useState(initialPageData)

//     const isButtonDisabled = pageData.email == '' || pageData.role_id == '' 
//     const location = useLocation()
//     const navigate = useNavigate()

  

//     // console.log({isOrganisationScope})
//     // console.log({isTeamScope})

  
//     // const remainingMembers =  teamsReducer.organization_members.filter(
//     //   (member) => !teamsReducer.selected_team?.team_members.find((teamMember) => teamMember.id === member.id)
//     // );

  
//     console.log({members}, 'invite page')
//     console.log({testmembers}, 'invite page')
//     console.log({searchedArray}, 'invite page')

    
//     useEffect(()=>{
//       setTestmembers(members)
//     },[members, teamsReducer.organization_members])

    
  
//     const inviteClickHandler = () => {
//       setEmailValidationFlag(true)
//       setRoleValidationFlag(true)
//       afterValidate(sendInvite)
//     }

//     const sendInvite = () =>{
   
//         addOrganisationMember(pageData , dispatch, navigate)
    
//       // console.log({pageData})
//       setInviteButtonState("Invite Sent!")
//       setTimeout(() => {
//         setInviteButtonState('Invite')
//         setPageData(initialPageData)
//         setEmailValidationFlag(false)
//         setRoleValidationFlag(false)
//       }, 1500)

//     }

//     const handleClose = () => {
//       dispatch(updateTeamState({invite_team_members_popup : false}))      
//     };

  

//   const handleAutocompleteChange = (event, value) => {
//     setPageData((last) => {
//       return { ...last, role_id: value.id };
//   });
//   }; 

//   const handleSearch = (e) => {
//     const searchedInput = e.target.value.toLowerCase().trim();
//     setSearchTerm(searchedInput);

//     const updatedFiles = [...testmembers];

//     const results = updatedFiles?.filter(item =>
//       item.name?.toLowerCase().includes(searchedInput) ||
//       item.email?.toLowerCase().includes(searchedInput)||
//       item.role?.toLowerCase().includes(searchedInput)
//     );
//     setSearchedArray(results);
//   };

//     return (
//         <CustomAlert 
//           open={teamsReducer.invite_team_members_popup} 
//           handleClose={handleClose} 
//           height={{xs:'420px', xl:'600px'}}
//           rightComponent={
//           <Box sx={{display:'flex', flexDirection:'column', paddingX:{xs:'5px',sm:'8px',md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
//             <Box gap={1}  sx={{height: '100%'}} className= 'flex col j-center a-center' style={{alignItems:'flex-start'}}> 
//               <Box mt={1} >
//                 <Typography sx={{fontSize:'12px', fontWeight:600}}>Lucihub Teams</Typography>
//                 <Typography sx={{fontSize:'10px'}}>There are different types of members that can join your team.</Typography>
//               </Box>
//               <Box mt={1}>
//                 <Typography sx={{fontSize:'12px', fontWeight:600}}>Administrators</Typography>
//                 <Typography sx={{fontSize:'10px'}}>Has full access to all your projects and team members</Typography>
//               </Box>
//               <Box>
//                 <Typography sx={{fontSize:'12px', fontWeight:600}}>Team Managers</Typography>
//                 <Typography sx={{fontSize:'10px'}}>Has full access to all your projects and team members.</Typography>
//               </Box>
//               <Box>
//                 <Typography sx={{fontSize:'12px', fontWeight:600}}>Manager</Typography>
//                 <Typography sx={{fontSize:'10px'}}>Has access to all your projects</Typography>
//               </Box>
//               <Box>
//                 <Typography sx={{fontSize:'12px', fontWeight:600}}>Associates</Typography>
//                 <Typography sx={{fontSize:'10px'}}>Can view projects</Typography>
//               </Box>
//             </Box>
//           </Box>}
//           >       
//             <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={4} pt={3} pb={2}>
//               <Box flexGrow={1} pt={1} >
//                   <Typography mb={1} sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}}}>Invite with email</Typography>
//                   <Box gap={2} style={{display:'flex'}} mb={1}>
//                             <Box style={{width:'40%'}}>
//                               <CustomInput
//                                 onFocus={()=>setEmailValidationFlag(false)}
//                                 onBlur={()=>setEmailValidationFlag(true)}
//                                 required
//                                 errorFlag={
//                                   emailValidationFlag &&
//                                   getErrorMsg("email", pageData?.email) != ""
//                                 }
//                                 errorMsg={getErrorMsg("email", pageData?.email)}
//                                 type="email"
//                                 max={40}
//                                 placeholder="Add members by email..."
//                                 onChange={(event) => {
//                                   setInput(
//                                     pageData?.email != ""
//                                     ? event.target.value
//                                     : event.target.value.trim(),
//                                     "email",
//                                     pageData,
//                                     setPageData
//                                   );
//                                 }}
//                                 value={pageData?.email}
//                               />
                    
//                             </Box>
//                             <Box style={{width:'25%'}}>         
//                               <CustomAutocomplete
//                                 options={options}
//                                 placeholder='Set as...'
//                                 onFocus={() => {
//                                   setEmailValidationFlag(true)
//                                   setRoleValidationFlag(false)
//                                 }}
//                                 onBlur={() => setRoleValidationFlag(true)}
//                                 required
//                                 errorFlag= {roleValidationFlag && getErrorMsg("role_type", pageData?.role_id) != ""}
//                                 errorMsg= {getErrorMsg("role_type", pageData?.role_id)}
//                                 onChange={handleAutocompleteChange}
//                                 value={getRoleNamesFromIds(pageData?.role_id, options)}
//                                 getOptionLabel={(option) =>
//                                 Object.keys(option).length > 0 ? option.name : ""
//                                 }
//                                 />
//                             </Box>
//                             <Box>
//                               <CustomButton color='secondary' btnText={inviteButtonState}  type='button' onClick={inviteClickHandler}  style={{color:'#08231B', backgroundColor:theme.palette.secondary.main, borderRadius:5, border:'none', fontSize:'12px', padding:'10px', width:'6.1rem', fontWeight:600, textTransform:'none', height:'20px', }}/>
       
//                             </Box>
//                   </Box>
//                   <Box className='flex col' gap={1.5}>
//                     <Box className='flex j-between a-center' style={{width:{xs:'100%', md:'80%'}}}>
//                       <Typography style={{fontWeight:600}}>Members ({testmembers?.length})</Typography>
//                       <Box id="search" className='flex j-end'>
//                         <Input
//                           id="search-input"
//                         value={searchTerm}
//                         onChange={handleSearch}
//                         sx={{
//                             borderColor: "transparent",
//                             input: {
//                                 fontSize: { xs: '12px', md: '12px', lg: '13px', xl: '16px' },
//                                 fontWeight: 400,
//                                 color: "#000000",
                              
//                             },
                           
//                             justifySelf:'flex-end',
//                             width:'60%', 
//                             transition: 'width 0.3s ease',
                    
//                         }}
//                         placeholder="Search"
//                         startAdornment={
//                             <IconButton sx={{paddingBottom:0.5, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'15px',xl:'20px'}}, paddingRight:2}} >
//                                 <SearchIcon style={{ color: "#000000" }} />
//                             </IconButton>
//                         }
//                         />
//                     </Box>
//                     </Box>
//                     <Box sx={{height:'22vh', overflowY:'auto'}}>

//                       {searchedArray.length > 0 && searchedArray.map((member, index) => {
//                         return  <OrganisationMembersList  member={member} index={index} searchedArray={searchedArray} setTestmembers={setTestmembers} roleOptions={options}/>
//                       })}
                  
//                     </Box>
//                   </Box>
  
//               </Box>

//               {/* <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
//                <Box pr={1.5}>

//                { requestStatus == 'loading'  ? <LoadingButton
                  
//                     sx={{
//                         '& .MuiLoadingButton-loadingIndicator': {
//                             color: theme.palette.primary.main,
//                         },
//                         height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
//                        minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
//                         backgroundColor: '#08231B'
//                     }}
//                     loading={requestStatus == 'loading'}
//                     variant="outlined"
//                     disabled
//                     >
                   
//                </LoadingButton> :
//                <CheckIcon onClick= {submitRequestClickHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
//                }
                 
//                 </Box>
//               <Box>    
//                 <Button 
//                 variant="text"
//                 onClick={handleClose}
//                  sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'120px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
//                 >
//                 Or Go Back
//                </Button>
//               </Box>
//             </Box> */}
//           </Box>
       
//         </CustomAlert>
//       )
// }

// export default InviteOrganisationMembersPopup



