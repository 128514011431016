import { Avatar, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Input, Typography , getContrastRatio} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomCard from "../../components/CustomCard";
import AuthLayout from "../layouts/AuthLayout";
import luciLogo from "../../assets/luciLogo2.png";
import SearchIcon from "@mui/icons-material/Search";
import useStatusType from "../../hooks/dropdowns/useStatusType";
import { setDropDownArray } from "../../redux/dropdownSlice";
import { updateState } from "../../redux/commonSlice";
import { generateLighterContrastColor, getInitials, goToCopilot, timeStampToString } from "../../utils/commonServices";
import { useNavigate } from "react-router-dom";
import EventCreationDrawer from "../eventcreation";
import CustomUserCard from "../events/components/CustomUserCard";
import "@fontsource/rubik";
import CustomSortOptions from "../events/components/CustomSortOptions";
import filmingTutorialImage from "../../assets/filmingTutorialCard.png"
import { eventSortOptions, sortByOptions } from "../../constants/staticData";
import useEventTypeList from "../eventcreation/hooks/useEventTypeList";
import PopupVideoPlayer from "../../components/PopupVideoPlayer";
import { updateReviewState } from "../../redux/reviewSlice";
import { updateCreationState } from "../../redux/eventCreationSlice";
import { setAllMediaFilesArray } from "../../redux/mediaFilesSlice";
import { gaEvents } from "../../ga/actions";
import CreateTeamDrawer from "../teams";
import { updateTeamState } from "../../redux/teamSlice";
import TeamCreationDrawer from "../teams";
import DeleteTeamPopup from "../teams/components/DeleteTeamPopup";
import RenameTeamPopup from "../teams/components/RenameTeamPopup";
import InviteMembersPopup from "../teams/components/InviteMembersPopup";
import CustomButton from "../../components/CustomButton";
import AssignRolePopup from "../teams/components/AssignRolePopup";
import RemoveMemberConfirmationPopup from "../teams/components/RemoveMemberConfirmationPopup";
import AllTeamsCard from "../dashboard/components/AllTeamsCard";
import ViewMembersPopup from "../teams/components/ViewMembersPopup";
import MainLogoCard from "../dashboard/components/MainLogoCard";
import DeclineEditProjectPopup from "../dashboard/components/DeclineEditProjectPopup";
import useDeleteProject from "../dashboard/hooks/useDeleteProject";
import DeleteDeclinePopup from "../dashboard/components/DeleteDeclinePopup";
import useFilter from "../dashboard/hooks/useFilter";
import DeleteConfirmationPopup from "../dashboard/components/DeleteConfirmationPopup";
import useEventSort from "../dashboard/hooks/useEventSort";
import EventCard from "../dashboard/components/EventCard";
import useEventList from "../dashboard/hooks/useEventList";
import AddIcon from "../dashboard/icons/AddIcon";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import TeamLogoCard from "./components/TeamLogoCard";
import { viewTeamMembers } from "../teams/actions";
import { checkTokenSession, getAllTeams, viewOrganisationMember } from "../dashboard/actions";
import { getTeamEvents } from "./actions";
import { viewProjectMembers } from "../events/actions";
import BackdropCreateProject from "../eventcreation/components/BackdropCreateProject";
import { getUserRoles } from "../../commonactions/actions";
import usePermissions from "../../hooks/usePermissions";
import { PERMISSION } from "../../constants/constants";
import { setSnackbar } from "../../redux/messageSlice";
import EventTypeFilter from "../dashboard/components/EventTypeFilter";
import useProjectTypeFilter from "../dashboard/hooks/useProjectTypeFilter";
import useCustomTheme from "../../theme/useCustomTheme";
import butterfly from './../../assets/butterfly_navigation.png'




const TeamsDashboard = () => {
    // const [refetchEvents] = useEventList();
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const snackbarProps = useSelector((state) => state.messageReducer);
    const [eventType, selectedEventType] = useEventTypeList()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer);
    
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
    const [searchedArray, setSearchedArray] = useState(teamsReducer.events);
    const [searchString, setSearchString] = useState("");
    const [openCreateEvent, setOpenCreateEvent] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [deleteProject, deleteEventStatus] = useDeleteProject(commonReducer.production_event_id, refetchEvents);
    const [deleteProject, deleteEventStatus] = useDeleteProject(commonReducer.production_event_id, teamsReducer.selected_team?.id);
    const [selectedProjectTypeValue, setSelectedProjectTypeValue] = useState(['View All']);
    const [projectTypeEvents] = useProjectTypeFilter(searchedArray, selectedProjectTypeValue);
    const [sortedEvents] = useEventSort(projectTypeEvents, selectedSortbyValue);
    const [refetchStatus] = useStatusType();
    const {eventCountByStatus, statusCheckHandler} = useFilter("status_type")
    const [members, setMembers] = useState(teamsReducer?.selected_team_members?.users)
    const [hasPermission] = usePermissions()
    const [theme] = useCustomTheme()
    
  
    //////////////////////////////////
    useEffect(()=>{
        checkTokenSession(dispatch)
        getAllTeams(dispatch, navigate)
    },[])

    useEffect(()=>{
        const payload = {
            team_id: teamsReducer?.selected_team?.id
          }
        getUserRoles(dispatch, navigate, permissionsReducer, payload)
        getTeamEvents(payload, dispatch)
        viewTeamMembers(payload, dispatch, navigate, {commonReducer})
    },[teamsReducer?.selected_team?.id])

    ////////////////////////////////


    ////////sets team members to render on dahsboard as soon as some change is made in teammembers in redux
    useEffect(()=> {
        setMembers(teamsReducer?.selected_team_members?.users)
    }, [teamsReducer?.selected_team_members?.users, teamsReducer.selected_team])

    //***********Rerenders the dashboard as soon as all the events are successfully fetched************ */
    useEffect(()=> {
        setSearchedArray(teamsReducer.combined_filter)
    }, [teamsReducer.combined_filter])


    const onSearch = (event) => {
        setSearchString(event.target.value);
        if (teamsReducer.combined_filter?.length > 0) {
            const temp = teamsReducer.combined_filter?.filter((searchedEvent) => {
                if (event.target.value == "") {
                    return searchedEvent;
                } else if (
                    searchedEvent?.name
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } else if (
                    searchedEvent?.event_type?.name
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } else if (
                    timeStampToString(searchedEvent?.start_date)
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } else if (
                    timeStampToString(searchedEvent?.end_date)
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                )
                    return searchedEvent;
            });
            setSearchedArray(temp);
        }
        // setFilteredArray(filterArray(event));
    }

    //***********************LOGIC FOR CLICKS ON EVENT CARDS******************************************** */
    const onEventClick = (event) => {
        dispatch(setSnackbar({flag: false, type: 'error', message: "ACCESS DENIED!"}))
        dispatch(updateState({ production_event_id: event.id}));
       hasPermission(PERMISSION.PROJECT.MEMBER.VIEW) && viewProjectMembers({event_id: event?.id}, dispatch, navigate, {commonReducer})
        const tempEvent =  teamsReducer.events?.find((e) => e.id == event.id)
        if(tempEvent?.status_type_order <= 3) {
            navigate("/productionevent" );
        } else {
            navigate('/completedevent') 
            // dispatch(setSnackbar({flag: true, type: 'error', message: "ACCESS DENIED!"}))
        }
    };

    useEffect(()=> {
        dispatch(updateState({ production_event_id: null, open_agreement_popup: false}))
        dispatch(setAllMediaFilesArray({open_previous_project_media_popup:false, import_files_confirmation_popup:false}))
        dispatch(updateCreationState({extend_edit_date_popup: false}))
        dispatch(updateReviewState({open_request_changes_popup: false, open_video_accept_popup: false,}))
      //  dispatch(updateState({under_revision: false, new_video: false}))
    },[])

  

    return <AuthLayout sx={{ padding: '2% 3.2% 2.1% 2.2%' }}>
        <Box gap={2} className='flex row a-center j-end' sx={{ pr: {xs:1, sm:3} }} >
            {commonReducer?.copilot_access && <img onClick={goToCopilot} src={butterfly} style={{ borderRadius: '5px',  width: '230px', cursor: 'pointer'}} />}
            <CustomUserCard />
        </Box>
        <Box className='flex row justify-center align-center' >

            <Box id="left" className='flex col' sx={{ maxWidth: { xs: '160px', sm:'28%',md: '23%', lg: '21%', xl: '22%' }, }}  >
        
                <TeamLogoCard/>
                <CustomCard
                    // sx={{ padding: '4vh 3vw', flex: 4 }}
                    sx={{  flex: 4, }}
                >
                   {/* <Typography sx={{ fontSize: { xs: 8,sm:9, md: 10, lg: 11, xl: '16px' }, fontFamily: 'Rubik', fontWeight: 300, color: '#000000' , marginBottom:{xs:'10px',sm:'12px',md:'10px',lg:'10px',xl:'20px'}}}>Status Type</Typography>
                
                    <EventFilter eventCounter = {eventCountByStatus} checkStatus = {statusCheckHandler} listType = 'status_type' counts/> */}
                    <AllTeamsCard/>
                </CustomCard>
                <CustomCard
                    margin='20px 0px 0px 0px'
                    sx={{ flex: 2, cursor: 'pointer' }}
                    onClick={()=> dispatch(updateState({play_tutorial: true}))}
                >
                
                     <img src={filmingTutorialImage} height='100%' style={{objectFit:'cover', borderRadius:'5px'}} width='100%'/>
                
                </CustomCard>
            </Box>
            <Box id="right" width='100%' minWidth= '300px' ml={4.5}>
                <Box sx={{width:'100%', }}>

                <Box gap={1} sx={{display:'flex', justifyContent:'start', alignItems:'center', color:'black' }}>
                    <IconButton  onClick={()=>{navigate('/dashboard', {replace: true})}}>
                      <KeyboardBackspaceOutlinedIcon sx={{color:'black',}}/>
                    </IconButton>
                    <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'14px',xl:'20px'}}}>Back to All Team Projects</Typography>
                </Box>
              </Box>
                <Box width='100%' className= 'flex row ' sx={{ marginTop: {xs: '20px', md: '20px', lg:'26px', xl:'34px'}, marginBottom: {xs: '12px', md: '15px', lg:'20px', xl:'30px'}, justifyContent: 'flex-end', alignItems:'center'}}>
                <Box flex={1}>
                    <Typography sx={{fontSize:{xs:'12px',sm:'12px',md:'14px',lg:'16.5px',xl:'24px'}, fontWeight:600, textTransform:'capitalize'}}>{`${teamsReducer.selected_team?.name} Projects`}</Typography>
                </Box>

                <Box id="search" 
                   // sx={{flex: 1}}
                    sx={{ marginRight: { xs: '12px', md: '15px', lg: '18px', xl: '30px' } }} 
                >
                    <Input
                        value={searchString}
                        onChange={onSearch}
                        sx={{
                          //  minWidth: '160px',
                            borderBottom: 2,
                            borderColor: "#333333",
                            input: {
                                fontSize: { xs: '12px', md: '13px', lg: '14px', xl: '20px' },
                                fontWeight: 400,
                                color: "#000000",
                            },
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton sx={{paddingBottom:1, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'17px',xl:'24px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                </Box>
                {/* <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', 
                            //marginBottom:{xs:'10px',sm:'14px',md:'16px',lg:'18px',xl:'25px'}, 
                           // marginTop:{xs:'5px',sm:'5px',md:'5px',lg:'10px',xl:'20px'}, 
                            cursor:'pointer'}} gap={{xs:0.3,sm:0.5, md:0.7, lg:1, xl:1.2}} pr={1}>

                    <CustomPopoverFilter listType= 'event_type_filter'  />
                </Box> */}
                <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', 
                            //marginBottom:{xs:'10px',sm:'14px',md:'16px',lg:'18px',xl:'25px'}, 
                           // marginTop:{xs:'5px',sm:'5px',md:'5px',lg:'10px',xl:'20px'}, 
                            cursor:'pointer'}} gap={{xs:0.3,sm:0.5, md:0.7, lg:1, xl:1.2}} pr={1}>
                        <EventTypeFilter
                         sortByOptions={eventSortOptions}
                         selectedProjectTypeValue={selectedProjectTypeValue}
                         setSelectedProjectTypeValue={setSelectedProjectTypeValue}
                        />
                       <CustomSortOptions
                         sortByOptions={eventSortOptions}
                         selectedSortbyValue={selectedSortbyValue}
                         setSelectedSortbyValue={setSelectedSortbyValue}
                         menuTitle = "Sort By"
                         // onChange={(newValue) => console.log(newValue)}
                         />

                   </Box>
                </Box>
                {/* <Box id="events" className='flex row f-wrap j-start a-start' sx={{ height: { xs: '78vh', sm: '66vh', md: '62vh', lg: '54vh', xl: '68vh' }, overflowY: "scroll", whiteSpace: 'nowrap', alignContent: 'flex-start' }} > */}
                <Box id="events" className='flex row f-wrap f-start a-start' sx={{ height: { xs: '71vh', sm: '67vh', md: '64vh', lg: '61vh', xl: '66vh' }, overflowY: "scroll", whiteSpace: 'nowrap', alignContent: 'flex-start', backgroundColor:'transparent',scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': {display : 'none'}, }} >
                    {
                       teamsReducer.events_loading ? <Typography variant="h3"  sx={{color: '#878887', textAlign: 'center', width: '100%', marginTop: '30%'}}>Loading...</Typography>
                       : (sortedEvents?.length > 0 ? sortedEvents?.map((event) => {
                            return <EventCard onClick={() => onEventClick(event)} event={teamsReducer.events?.find((e) => e.id == event.id)} sx={{ mt: 2, mr: 2, cursor: 'pointer' }} />
                        }) :
                        <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'>
                            { hasPermission(PERMISSION.TEAM.PROJECT.CREATE) ?  <Typography sx={{fontSize: 30, color: '#878887', fontWeight: 300, textAlign: 'center', mt:3}} >You have no existing Lucihub Project. <br/>
                                <Box  gap={1.5} className='flex a-center j-center' >Click Add button below to get started.
                                {/* <AddIcon sx={{ fontSize: { xs: '20px', sm: '24px', md: '30px', lg: '36px', xl: '62px' }, }} />   */}
                                </Box>
                             </Typography>:
                             <Typography sx={{fontSize: 30, color: '#878887', fontWeight: 300, textAlign: 'center', mt:3}} >You have no existing Lucihub Project. <br/> 
                             </Typography>}
                        </Box>)
                    }
                 
                   
                </Box>
                <Box className='flex row j-end a-end' width='100%' sx={{ mt: 2 }} >
                        {hasPermission(PERMISSION.TEAM.PROJECT.CREATE) && <Box onClick={() =>{ 
                            // dispatch(updateCreationState({open_form: true}))
                            checkTokenSession(dispatch)
                            dispatch(updateCreationState({open_backdrop_project_creation: true}))
                            gaEvents.eventFormOpened()
                        }} 
                        sx={{ cursor: 'pointer', mr: {sm: '12px', md: '16px', lg: '20px', xl: 3} }} >
                            <AddIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ fontSize: { xs: '40px', sm: '44px', md: '50px', lg: '56px', xl: '82px' }, }} />
                        </Box>}
                    </Box>
              {/* <Box>{{a:""}}</Box> */}

            </Box>
            <Box>

            </Box>
        </Box>
        {
            createEventReducer.open_form == true && <EventCreationDrawer/>
        }
       {
            commonReducer.play_tutorial == true && <PopupVideoPlayer tutorial />
       }
        {
            commonReducer.event_delete_popup == true && <DeleteConfirmationPopup deleteProject = {deleteProject} status = {deleteEventStatus}/>
            
        }
         {
            commonReducer.delete_decline_popup == true && <DeleteDeclinePopup/>
            
        }
        {createEventReducer.edit_decline_popup == true && <DeclineEditProjectPopup/>}

        {teamsReducer.open_create_team_form == true && <TeamCreationDrawer />}

        {teamsReducer.delete_team_popup == true && <DeleteTeamPopup/>}

        {teamsReducer.rename_team_popup == true && <RenameTeamPopup/>}

        {teamsReducer.invite_team_members_popup == true && <InviteMembersPopup members={members} options={dropdownReducer?.team_roles}/>}

        {teamsReducer.remove_member_confirmation_popup == true && <RemoveMemberConfirmationPopup/>}
        
        {teamsReducer.assign_role_popup == true && <AssignRolePopup/>}

        {teamsReducer.view_members_popup == true && <ViewMembersPopup options={dropdownReducer?.team_roles}/>}

        {createEventReducer?.open_backdrop_project_creation === true && <BackdropCreateProject />}

    </AuthLayout>
}
export default TeamsDashboard;