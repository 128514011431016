import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Alert, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../redux/messageSlice";
import BoldCloseIcon from "./icons/BoldCloseIcon";

// const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert ref={ref}  {...props} />;
//   });

export default function CustomSnackbar(props) {
  // const { snackbarProps, setSnackbarProps } = props;
  const snackbarProps = useSelector((state) => state.messageReducer);
  const dispatch = useDispatch();

  // snackbarProps = {type: "error" | "success", flag: <Boolean>, message: ""}

  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = state;
  const error = "rgba(255,148,148,0.2)";
  const success = "rgba(0, 151, 19, 0.2)";

  // const handleClick = () => {
  //   setState({...state, open: true  });
  // };

  React.useEffect(()=> {
    
    const timer = setTimeout(()=> {
     if(snackbarProps?.flag == true) {
         handleClose()
     }
     }, 4000)
 return ()=> clearTimeout(timer)
},[snackbarProps?.flag])

  const handleClose = (event, reason) => {
    // setSnackbarProps({});
    dispatch(setSnackbar({ flag: false }));
    // return;
    // if (reason === 'clickaway'){
    //     return;
    // }
    // setState({ ...state, open: false });
  };

  return (
    <Box textAlign='center'>
      {/* <Button
        variant='outlined'
        onClick={handleClick}>
        Test Snackbar
      </Button> */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarProps?.flag}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
        // message={snackbarProps?.flag == true && snackbarProps?.message}
        sx={{
          minHeight: { xs: "calc(6vh + 10rem)", sm: "calc(2vh + 10rem)" },
          // textAlign:'center',
          // display:'flex',
          // alignItems:'center',
          "& .MuiSnackbarContent-root": {
            // backgroundColor: '#08231B',
            // color: snackbarProps?.type === "error" ? "#FF0045" : "#00D744",
            // boxShadow: "none",
            // width: { xs: "90vw", sm: "70vw", md: "60vw",lg:'60vw', xl: "50vw" },
            // border:'1px solid #707070',
            // fontFamily:'Rubik',
         
            // fontSize:{xs:'9px',md:'10px',lg:'11px',xl:'13px'},
            // textAlign:'center'
          },
        
          
        }}

        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <BoldCloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert  onClose={handleClose} icon={false} severity={snackbarProps.type} sx={{backgroundColor:'#08231B', color: snackbarProps?.type === "error" ? "#FF0045" : "#21D644",  width: { xs: "90vw", sm: "70vw", md: "60vw",lg:'60vw', xl: "50vw" }, textAlign:'center','& .MuiAlert-message':{textAlign:"center", width:'inherit', fontSize:{xs:'9px',md:'10px',lg:'11px',xl:'13px'},},  border:'1px solid #707070',   minHeight:'40px',display:'flex', alignItems:'center', height:{md:'45px',lg:'45px',xl:'50px'}, fontFamily:'Rubik'}}>
          {snackbarProps?.message && snackbarProps?.message }
        </Alert>
      </Snackbar>
    </Box>
  );
}