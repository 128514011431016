import React from 'react'
import CustomAlert from './CustomAlert'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography, getContrastRatio } from '@mui/material'
import { useState } from 'react'
import CustomSortOptions from './CustomSortOptions'
import CustomButton from '../../../components/CustomButton'
import CheckIcon from '../../../components/icons/CheckIcon'
import { mediaFilesPopupSortOptions } from '../../../constants/staticData'
import useImportProject from '../hooks/useImportProject'
import { useEffect } from 'react'
import useSort from '../hooks/useSort'
import { setAllMediaFilesArray } from '../../../redux/mediaFilesSlice'
import UserMediaFilesPopup from '../../userfiles/components/UserMediaFilesPopup'
import useCustomTheme from '../../../theme/useCustomTheme'
import { generateLighterContrastColor } from '../../../utils/commonServices'


const UploadFromProjectPopup = (props) => {

    const {importFiles, displayCloseButton} = props
    const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);
    const dispatch = useDispatch()
    const [theme ] = useCustomTheme()
    const [uploadedVideoList, importProjectRefetch,  uploadedVideoListStatus] = useImportProject()
    // const [importFiles, importFilesStatus] = useImportMediaFiles("import-files")
    
// console.log(selectedSortbyValue)

    // const [mediaFiles, setMediaFiles] = useState(exampleFiles);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [sortedFiles] = useSort(mediaFiles, selectedSortbyValue)

    // console.log(uploadedVideoListStatus)

    useEffect(()=>{

      // console.log(uploadedVideoList)
      setMediaFiles(uploadedVideoList)
      // console.log(mediaFiles)

    },[uploadedVideoList])

    

    const handleClose = () => {
        // setOpen(false)
        dispatch(setAllMediaFilesArray({open_previous_project_media_popup : false}))        
    };

    
    const handleFileCheckboxChange = (index) => {
        const updatedFiles = [...mediaFiles];
        updatedFiles[index].checked = !updatedFiles[index].checked;
        setSelectAll(false)
        setMediaFiles(updatedFiles);
      };

      const handleSelectAllChange = (event) => {
        const checked = event.target.checked;
        const updatedFiles = mediaFiles.map((file) => ({ ...file, checked: checked }));
        setMediaFiles(updatedFiles);
        setSelectAll(checked);
      };

      const selectAllFavouritesHandler =()=>{
        // const favFiles = fileState?.filter(file => file.favourite )
    
        const favFiles = mediaFiles.filter((file) => file.favourite);
        const updatedFiles = mediaFiles.map((file) => ({...file, checked: favFiles.includes(file)}));
        setMediaFiles(updatedFiles);

        // setSelectAll(favFiles.length == updatedFiles.length);
        setSelectAll(favFiles.length == mediaFiles.length);

      }

      const duplicateSelectedFiles = async()=>{
        displayCloseButton(false)
        // const selectedFiles = mediaFiles?.filter(file => file.checked)?.map(file => ({id: file.id}));
        const selectedFiles = mediaFiles?.map(({id, checked})=> ({id: id, checked: checked}))
       await importFiles({destination_event_id: commonReducer.production_event_id, media_files: JSON.stringify(selectedFiles)})

        dispatch(setAllMediaFilesArray({open_previous_project_media_popup:false, import_files_confirmation_popup:true}))
      }

  return (
    <CustomAlert
        open={mediaFilesReducer?.open_previous_project_media_popup} 
        handleClose={handleClose} 
        width={{xs:'95%',sm:'92%',md:'92%',lg:'92%', xl:'92%'}}
        height={{xs:'80%',sm:'88%',md:'88%',lg:'88%', xl:'946px'}}
        maxWidth='1766px'
     
        
        // height='88%'
        // width='92%'
    >
        <Box px={5} pt={4} pb={2} width='100%' height='100%' className='flex col'>
            <Box textAlign={'right'} mb={1}>
            <CustomButton 
              btnText='Select All Favorites'
            //   disabled = {isDisabled}
              onClick={selectAllFavouritesHandler}
              color='secondary'
              variant='contained'
              type='button'    
              style={{
              borderRadius: '5px',
              border: "none",
              textTransform:'none',
              // backgroundColor:theme.palette.secondary.main
             
              }}
              sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'120px',md:'150px',lg:'170px',xl:'210px'}, fontFamily:'Rubik'}}
            />
            </Box>
            <Box className='flex a-center j-between'>
                <Typography sx={{color:'#000', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}, fontWeight:600}}>Select files you’d like to duplicate to your project.</Typography>
                <CustomSortOptions
                    sortByOptions={mediaFilesPopupSortOptions}
                    selectedSortbyValue={selectedSortbyValue}
                    setSelectedSortbyValue={setSelectedSortbyValue}
                          // onChange={(newValue) => console.log(newValue)}
                />
            </Box>
            <Box flexGrow={1}>
                <UserMediaFilesPopup
                popup={true}
                files={sortedFiles}
                isLoading={uploadedVideoListStatus}
                handleSelectAllChange={handleSelectAllChange}
                handleFileCheckboxChange={handleFileCheckboxChange}
                selectAll={selectAll}
                // selectedProjectName={selectedProjectName}
                />
            </Box>
            <Box textAlign={'right'} className='flex a-center j-end' gap={2}>
            <CustomButton 
              btnText='Cancel'
              variant='text'
              onClick={handleClose}
              type='button' 
              color='secondary'   
              style={{
              borderRadius: '5px',
              border: "none",
              textTransform:'none',
              // backgroundColor:theme.palette.secondary.main
              textDecoration:'underline'
             
              }}
              sx={{fontSize:{xs:'10px',sm:'10px',md:'12px',lg:'14px',xl:'21px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'20px',sm:'30px',md:'60px',lg:'80px',xl:'120px'}, fontFamily:'Rubik'}}
            />
              <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick={duplicateSelectedFiles}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:'pointer', }}/>
            </Box>
        </Box>

    </CustomAlert>
  )
}

export default UploadFromProjectPopup