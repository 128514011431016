import { createSlice } from '@reduxjs/toolkit'

const initialValue = {

   // signup_with_apple_popup: true,
    apple_response: null,
    apple_token:"",
    is_redirected_from_butterfly: false,
}


export const appleSignSlice = createSlice({
    name: 'apple_sign',
    initialState: initialValue,
    reducers: {
        updateAppleSignState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetAppleSignState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateAppleSignState, resetAppleSignState } = appleSignSlice.actions
export default appleSignSlice.reducer