import { useSelector } from "react-redux";
import { timeStampToNumberedDateString } from "../../../utils/commonServices";


export default (filesArray, sortQuery) => {
   // const commonReducer = useSelector((state) => state.root.commonReducer);
    // console.log(filesArray)
   // const filesArray = commonReducer.filtered_events;
    let sortedFiles=[]

        if (sortQuery == 'date_added'){
      const sorted = [...filesArray].sort((a, b) => a.created_at > b.created_at ? -1 : 1, );
   sortedFiles = sorted
    }
   
    if (sortQuery == 'start_dates'){
      const sorted = [...filesArray].sort((a, b) =>
    a.start_date > b.start_date ? -1 : 1,
   );
   sortedFiles = sorted
    }


if (sortQuery == 'end_dates'){
    const sorted = [...filesArray].sort((a, b) =>
  a.editor_start_date > b.editor_start_date ? -1 : 1,
 );
 sortedFiles = sorted
  }
    
if(sortQuery == 'a_z'){
  const sorted = [...filesArray].sort((a, b) =>
  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
 );
 sortedFiles = sorted

  }

    if(sortQuery == "" || sortQuery == null){
      sortedFiles = [...filesArray]
    }

  
    return [sortedFiles];
  };