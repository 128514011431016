import { Backdrop, Box, Slide, Typography, getContrastRatio } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomAutocomplete from './CustomAutoComplete';
import { updateCreationState } from '../../../redux/eventCreationSlice';
import CheckIcon from '../../../components/icons/CheckIcon';
import useEventTypeList from '../hooks/useEventTypeList';
import { useState } from 'react';
import { getErrorMsg } from '../../../utils/validator';
import { afterValidate, generateLighterContrastColor } from '../../../utils/commonServices';
import UpIcon from '../icons/UpIcon';
import DownIcon from '../icons/DownIcon';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';

const ProjectTypePage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex, selectedProjectType, setSelectedProjectType, totalScreens, closeHandler} = props
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const [eventType, selectedEventType] = useEventTypeList()
    const [projectTypeValidationFlag, setProjectTypeValidationFlag] = useState(false)
    const dispatch = useDispatch()
    const [theme ] = useCustomTheme()

    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(nextPageHandler);
  }

  const validateInputs = (flag) => {
      setProjectTypeValidationFlag(flag)
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      // console.log("key Parent", event.key)
      if (event.key === "Enter") {
        event.preventDefault();

        finishedHandler();
      }
    };
      document.addEventListener("keydown", keyDownHandler)
      
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [pageData]);


  return (
    <>
    <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createEventReducer.open_backdrop_project_creation}
    >
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
          <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3, fontFamily:'Rubik'}} onClick={closeHandler}>Close X</Typography>
          <Box flexGrow={1}  className='flex col ' sx={{ paddingLeft:'14vw', marginTop:{xs:'20%',sm:'14%',md:'8%', lg:'7%'}}}>
              <Box mb={6}>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>What is your project type?</Typography>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>Select one from the list.</Typography>
              </Box>
              <Box  className='flex' sx={{width:'80%',}} gap={1}>
                      <Box  sx={{width:'80%' , textAlign:'center',}}>              
                        <CustomAutocomplete
                            id="event_type"
                            options={eventType}
                            onFocus={() => {
                            setProjectTypeValidationFlag(false)
                            }}
                            onBlur={() => setProjectTypeValidationFlag(true)}
                            medium
                            required
                            errorFlag= {projectTypeValidationFlag && getErrorMsg("event_type", pageData?.event_type_id) != ""}
                            errorMsg= {getErrorMsg("event_type", pageData?.event_type_id)}
                            value={selectedEventType(pageData?.event_type_id)}

                            onChange={(event, newValue) => {
                                setPageData((last) => {
                                    return { ...last, event_type_id: [newValue.id] };
                                });
                            }}
                            label=""
                            placeholder='Project Type'
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.value : ""
                            }
                            msgColor='red'
                        />
                      </Box> 
                      <Box sx={{ pt:'2px'}}>
                        <CheckIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick={pageIndex !== 8 ? finishedHandler : null} sx={{fontSize:{xs:'50px',xl:'60px'}, cursor: 'pointer'}}/>
                      </Box>
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:5, mr:5}}>
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer', opacity: pageIndex === 1 && 0.5}} onClick={pageIndex !== 1 ? prevPageHandler : null}>/\</Box> */}
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer',  opacity: pageIndex === 8 && 0.5}} onClick={pageIndex !== 8 ? finishedHandler : null}>\/</Box> */}
            <UpIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', opacity: pageIndex === 1 && 0.5, fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <DownIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 8 ? finishedHandler : null}/>
          </Box>
        </Box>
    </Backdrop>
    </Slide>
    </>
  )
}

export default ProjectTypePage