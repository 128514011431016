import { Box, Button, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import renameTeamIcon from '../../../assets/TeamRenameIcon.svg'
import { updateCreationState } from "../../../redux/eventCreationSlice";
import { afterValidate, generateLighterContrastColor, setInput, timeStampToDateString, timeStampToDayString, timeStampToNumberedDateString } from "../../../utils/commonServices";
import { LoadingButton } from "@mui/lab";
import { updateTeamState } from "../../../redux/teamSlice";
import CustomInput from "../../eventcreation/components/CustomInput";
import { getErrorMsg } from "../../../utils/validator";
import { renameTeam } from "../actions";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";
// import { customTheme as theme } from "../../../theme/customTheme";
import RenameTeamImage from "../assets/RenameTeamImage";


const RenameTeamPopup = (props) => {
    const {open, requestStatus} = props
   
    const dispatch = useDispatch()
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [nameValidationFlag, setNameValidationFlag] = useState(false);
    const [theme ] = useCustomTheme()
    const [pageData, setPageData] = useState({
      name: teamsReducer?.selected_team?.name
    })
   

    const handleClose = () => {
        dispatch(updateTeamState({rename_team_popup : false}))
        
       };

    const renameTeamClickHandler =()=>{

      setNameValidationFlag(true)
      afterValidate(requestCallback);  
     
    }
    const requestCallback=  ()=>{
      ///rename team api
  
      // console.log(pageData)
      // console.log(teamsReducer?.selected_team)
      const payload = {
        name: pageData?.name,
        team_id: teamsReducer?.selected_team?.id
      }

      // console.log({payload})
      /////////////////// api to update team name////////////////
      renameTeam(payload, dispatch, handleClose)
    }
   

    return (
        <CustomAlert 
          open={teamsReducer.rename_team_popup} 
          handleClose={handleClose} 
          rightComponent={
          <Box sx={{display:'flex', flexDirection:'column', paddingX:{md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
            <Box  sx={{height: '100%'}} className= 'flex col j-center a-center'> 
            <RenameTeamImage sx={{maxWidth: '200px', width: '100%', height: '40%'}} />
                {/* <img src={renameTeamIcon} style={{maxWidth: '200px'}} height='40%'/> */}
            </Box>
          </Box>}
          >       
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={3} pt={4} pb={2}>
              <Box gap={2} flexGrow={1} pt={6}>
                  <Typography mb={1} sx={{fontWeight:600, fontFamily:'Rubik', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'30px'}, color:'#000000'}}>Rename your Team.</Typography>
                  <Box sx={{width:"80%"}}>
                  <CustomInput
                      id='rename_team'
                      onFocus={() => setNameValidationFlag(false) }
                      onBlur={() => setNameValidationFlag(true)}
                      autoFocus
                      required
                      errorFlag={ nameValidationFlag && getErrorMsg("team_name", pageData?.name) != ""}
                      errorMsg={getErrorMsg("team_name", pageData?.name)}
                      fullWidth
                      //   label="Event Name*"
                      type="text"
                      max={100}
                      placeholder="Rename Team (Max Character 100)"
                      onChange={(event) =>
                        {
                          // setNewName(event.target.value)
                          setInput(
                          pageData.name != ""
                          ? event.target.value.replace(/[<>:"/\\|?*]/g, '')
                          : event.target.value.trim(),
                          "name",
                          pageData,
                          setPageData
                        )
                        }
                      }
                      value={pageData.name}
                    />
                  </Box>
              </Box>

              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'end'}}>
               <Box pr={1.5}>

               { teamsReducer?.is_loading === true  ? <LoadingButton
                   // size="small"
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: theme.palette.primary.main,
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={teamsReducer?.is_loading === true}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> :
               <CheckIcon color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {renameTeamClickHandler}   sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: pageData?.name !== '' ? 'pointer': 'not-allowed', opacity : pageData?.name === '' && 0.6, paddingTop:'5px'}}/>
               }
                  {/* <CheckIcon onClick= {finishedHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor:  'pointer', }}/> */}
                </Box>
              <Box>    
                <Button 
                variant="text"
                onClick={handleClose}
                 sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'120px'}, fontFamily:'Rubik', textTransform:'none', letterSpacing:'0.65px', textDecoration:'underline'}}
                >
                Or Go Back
               </Button>
              </Box>
            </Box>
          </Box>
       
        </CustomAlert>
      )
}

export default RenameTeamPopup