import { PRODUCT_ID } from "../constants/constants";
import { setAssetState } from "../redux/assetSlice";
import { updateState } from "../redux/commonSlice";
import { setDropDownArray } from "../redux/dropdownSlice";
import { setAllMediaFilesArray } from "../redux/mediaFilesSlice";
import { setSnackbar } from "../redux/messageSlice";
import { updatePermissionState } from "../redux/permissionSlice";
import { updateProjectState } from "../redux/projectSlice";
import { allMediaFiles, approveUserApi, eventDetails, getPermissionsApi, getRolesApi, memberInvitationApi, myMediaFileProjectsApi, myMediaFilesOfProjectApi, showRoleApi, updateOrganisationNameApi, updateRoleApi, userRoleApi } from "../services/api";
import { findKeyAnywhereInJson, removeDuplicateArrayItem, showSnackBar } from "../utils/commonServices";



export const getMediaFileProjects = ( dispatch, navigate, state, value) => {
  dispatch(setAllMediaFilesArray({my_projects_loading: true}))
myMediaFileProjectsApi() 
.then((response) => {
  dispatch(setAllMediaFilesArray({my_projects_loading: false}))
  if(response?.data?.http_code === 200){
      dispatch(setAllMediaFilesArray({my_files_projects: response?.data?.data?.event_details}))
      response?.data?.data?.event_details?.length > 0 && getMediaFilesOfProject(dispatch, navigate, state, response?.data?.data?.event_details[0]?.id)
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
  dispatch(setAllMediaFilesArray({my_projects_loading: false}))
 // dispatch(setSnackbar({type:'error', flag:true, message: error.message}))
  }
});
}


export const getMediaFilesOfProject = ( dispatch, navigate, state, value) => {
  dispatch(setAllMediaFilesArray({my_files_loading: true}))
  myMediaFilesOfProjectApi({event_id: value}) 
  .then((response) => {

    dispatch(setAllMediaFilesArray({my_files_loading: false}))

    if(response?.data?.http_code === 200){
        dispatch(setAllMediaFilesArray({my_files_project_media_files: response?.data?.data?.media_files}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    if(error) {
    const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
    dispatch(setAllMediaFilesArray({my_files_loading: false}))
    //dispatch(setSnackbar({type:'error', flag:true, message: error.message}))
    }
  });
  }

export const getPermissions = ( dispatch ) => {
    const payload = {
        id: PRODUCT_ID?.lucihub
    } 
  getPermissionsApi(payload) 
  .then((response) => {
    if(response?.data?.http_code === 200){
        dispatch(updatePermissionState({permissions_list: response?.data?.data}))
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
   // dispatch(setSnackbar({type:'error', flag:true, message: error.message}))
    }
  });
}

export const getUserRoles = ( dispatch, navigate, state, values , callback) => {
userRoleApi(values) 
.then((response) => {
  if(response?.data?.http_code === 200){
      const userPermissionArray = findKeyAnywhereInJson(response?.data?.data?.permissions, "key")
      const mappedObject = Object.keys(state.permissions_master).reduce((result, key) => {
        // Perform some operation on the value and store it in the result object
         if(userPermissionArray?.includes(key)) {
          result[key] = true //{...state.permissions_master[key], hasPermission: true}
         } else {
          // result[key] = state.permissions_master[key]
          result[key] = false // {...state.permissions_master[key], hasPermission: false}
         }
       // result[key] = myObject[key].toUpperCase();
        return result;
      }, {});
      // console.log({mappedObject})
      if(response?.data?.data?.id === 1){
        dispatch(updateState({is_admin: true}))
      }
      dispatch(updatePermissionState({user_permissions: response?.data?.data, permissions_master: mappedObject}))
      callback && callback(mappedObject)
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
 // dispatch(setSnackbar({type:'error', flag:true, message: error.message}))
  }
});
}



export const getRoles = (dispatch, navigate ) => {
  const payload = {
    product_id: 1
  }
getRolesApi(payload) 
.then((response) => {

  if(response?.status === 200){
     dispatch(setDropDownArray({organisation_roles: response?.data?.organisation_roles, team_roles: response?.data?.team_roles, project_roles: response?.data?.project_roles}))
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  if(error) {
  // console.error('error:', error);
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
 // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
  }
});
}


export const getRoleNamesFromIds = (id, arr) => {
  // console.log({arr})
  if (arr.length > 0 && arr.find((item)=> item.id === id) ) {
      return arr.find((item)=> item.id === id)
  } else {
      return {}
  }
};

export const getNamesFromIds = (id, arr) => {
  // console.log({arr})
  if (arr?.length > 0 && arr?.find((item)=> item.id === id) ) {
      return arr?.find((item)=> item.id === id)
  } else {
      return {}
  }

};

export const inviteOrganisationMember = (values, dispatch, navigate ) => {
 
  dispatch(updateState({is_loading:true}))
  const payload = {
    name: values?.name,
    password: values?.password
  }

  const header ={
    Authorization: `Bearer ${values?.token}`,
    "product": process.env.REACT_APP_PRODUCT_ID
  }

memberInvitationApi(payload, header) 
.then((response) => {
  // console.log({response})
  if(response?.data?.http_code === 200){
    dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    dispatch(updateState({is_loading:false}))
    navigate('/signin', {replace:true})
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      dispatch(updateState({is_loading:false}))
  }
})
.catch((error) => {
  if(error) {
  // console.error('error:', error);
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
  //dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
  }
  dispatch(updateState({is_loading:false}))
  
});
}





export const getAllMediaFiles = (dispatch, navigate , setStatus) => {
  setStatus('loading')
  // console.log('inside all media files actions')
allMediaFiles() 
.then((response) => {
  if(response?.status === 200){
    const mediaFiles = response?.data?.data?.media_files;
    if(mediaFiles?.length == 0){
      setStatus('empty')
    }else{
      setStatus('success')
    }
  dispatch(setAllMediaFilesArray({all_media_files: mediaFiles,}));
  }else {
    setStatus('error')
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  if(error) {
  // console.error('error:', error);
 // const errorObject = error?.response?.data;
  setStatus('error')
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
 // dispatch(setSnackbar({type:'error', flag:true, message: errorObject}))
  }
});
}



export const getProjectMediaFiles = (dispatch, navigate, state,  ) => {
  const payload = { id: state.production_event_id }
  dispatch(updateState({is_media_loading: true}))
getRolesApi(payload) 
.then((response) => {

  if(response?.status === 200){
    const responseObject = response?.data;
    const responseData = responseObject?.data
    responseData?.media_files?.length > 0 ? dispatch(updateState({project_media_files : [...removeDuplicateArrayItem(responseData?.media_files)]})) : dispatch(updateState({project_media_files : []}));
  
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
  }
});
}


export const updateCompanyName = (values ,dispatch, callback) => {
  
updateOrganisationNameApi(values) 
.then((response) => {
  if(response?.data?.http_code === 200){
    dispatch(updateState({is_company_name_available: true}))
    dispatch(setAssetState({brand_name_popup: false, brand_box_clicked: false}))
    !!callback && callback()
    dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
  }else {
    dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
   
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
  }
});
}




/////////////////////////////approve user /////////////////////////////
export const approveNewUser = (values, dispatch, callback ) => {

  const payload = { token: values?.token }
 
approveUserApi(payload) 
.then((response) => {
  dispatch(updateProjectState({signin_action_loader:false}))
  if(response?.data?.http_code === 200){
    dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
    callback(response?.data)
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
  callback(errorObject)
  showSnackBar(dispatch, errorObject);
  }
  dispatch(updateProjectState({signin_action_loader:false}))
  
});
}
