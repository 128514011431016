import React, { useRef, useEffect } from "react";

const CustomScrollToBottom = () => {
  const divRef = useRef(null);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  });

  return <div ref={divRef} />;
};

export default CustomScrollToBottom;
