import { Box, Button, IconButton, Input, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { updateReviewState } from "../../../redux/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import edit from '../../../assets/editExtendIcon.svg'
import { updateCreationState } from "../../../redux/eventCreationSlice";
import { generateDarkColor, setInput, timeStampToDateString, timeStampToDayString, timeStampToNumberedDateString } from "../../../utils/commonServices";
import { LoadingButton } from "@mui/lab";
import { updateTeamState } from "../../../redux/teamSlice";
import CustomInput from "../../eventcreation/components/CustomInput";
import CustomAutocomplete from "../../eventcreation/components/CustomAutoComplete";
import CustomButton from "../../../components/CustomButton";
import SearchIcon from "@mui/icons-material/Search";
import OrganisationMembersList from "./OrganisationMembersList";



const ViewOrganisationMembersPopup = (props) => {
    const {  options } = props
   
    const dispatch = useDispatch()
    const [searchTerm, setSearchTerm] = useState('');
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const [members, setMembers] = useState(teamsReducer.organization_members)
  
   
    const [searchedArray, setSearchedArray] = useState(members)
    const colors = ['red', 'pink', 'black'];

   



    
    useEffect(()=>{
      setSearchedArray(teamsReducer?.organization_members)
    },[teamsReducer?.organization_members])
  


    const handleClose = () => {
      dispatch(updateTeamState({view_members_popup : false}))      
    };

  

    const handleSearch = (e) => {
      const searchedInput = e.target.value.toLowerCase();
      if (!searchedInput.startsWith(' ')) {
        setSearchTerm(searchedInput);
      }
        // const searchedInput = e.target.value.toLowerCase().trim();
        // setSearchTerm(searchedInput);
    
        const updatedFiles = [...members];
    
        const results = updatedFiles?.filter(item =>
          item.name?.toLowerCase().includes(searchedInput) ||
          item.email?.toLowerCase().includes(searchedInput)||
          item.role?.name?.toLowerCase().includes(searchedInput)
        );
        setSearchedArray(results);
      };

    return (
        <CustomAlert 
          open={teamsReducer.view_members_popup} 
          handleClose={handleClose} 
          height={{xs:'420px', md:'75%',lg:'70%',xl:'600px'}}
          width={{md:'65%',lg:'65%', xl:'1400px'}}
          rightComponent={
          <Box sx={{display:'flex', flexDirection:'column', paddingX:{xs:'10px', md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
            <Box  sx={{height: '100%'}} className= 'flex col j-center a-center' style={{alignItems:'flex-start'}}> 
              <Typography sx={{fontSize:{ xs: 8.5, md: 9.5,lg:'12px', xl: 16 }, fontWeight:600, fontFamily:'Rubik', color:'#000'}}>Administrators</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0} >
                <Typography component='li' sx={{fontSize:{ xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Add or remove organization members</Typography>
                <Typography component='li' sx={{fontSize:{ xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Change organization member roles</Typography>
                <Typography component='li' sx={{fontSize:{ xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Create and manage teams</Typography>
                <Typography component='li' sx={{fontSize:{ xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Have full access to all the teams and projects of organization</Typography>
              </Box>
              <Typography sx={{fontSize:{ xs: 8.5, md: 9.5,lg:'12px', xl: 16 }, fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Organization Members</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}>
                <Typography component='li' sx={{fontSize:{ xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of other organization members</Typography>
                <Typography component='li' sx={{fontSize:{ xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of projects they are associated with</Typography>
                <Typography component='li' sx={{fontSize:{ xs: 7.5, md: 8.5,lg:'11px', xl: 15 }, fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of teams they are associated with</Typography>
              </Box>
           
              {/* <Box>
                <Typography sx={{fontSize:'12px', fontWeight:600, fontFamily:'Rubik', color:'#000'}}>Team Managers</Typography>
                <Typography sx={{fontSize:'10px', fontFamily:'Rubik', color:'#000'}}>Has full access to all your projects and team members.</Typography>
              </Box>
              <Box>
                <Typography sx={{fontSize:'12px', fontWeight:600, fontFamily:'Rubik', color:'#000'}}>Manager</Typography>
                <Typography sx={{fontSize:'10px', fontFamily:'Rubik', color:'#000'}}>Has access to all your projects</Typography>
              </Box>
              <Box>
                <Typography sx={{fontSize:'12px', fontWeight:600, fontFamily:'Rubik', color:'#000'}}>Associates</Typography>
                <Typography sx={{fontSize:'10px', fontFamily:'Rubik', color:'#000'}}>Can view projects</Typography>
              </Box> */}
            </Box>
          </Box>}
          >       
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={4} pt={3} pb={2}>
              <Box flexGrow={1} pt={4} >    
                  {/* <Box className='flex col' gap={1.5}>
                    <Box className='flex j-between a-center'>
                      <Typography style={{fontWeight:600}}>Members ({testmembers?.length})</Typography>
                      <Box id="search" >
                        <Input
                        value={searchTerm}
                        onChange={handleSearch}
                        sx={{
                          //  minWidth: '160px',
                            borderBottom: 1,
                            borderColor: "#333333",
                            input: {
                                fontSize: { xs: '12px', md: '12px', lg: '13px', xl: '16px' },
                                fontWeight: 400,
                                color: "#000000",
                            },
                            width:'60%', 
                            // mr:5
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton sx={{paddingBottom:0.5, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'15px',xl:'20px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                    </Box>
                    </Box>
                    <Box sx={{height:'28vh', overflowY:'auto'}}>

                      {searchedArray.length > 0 && searchedArray.map((member, index) => {
                        return  <MembersList  member={member} index={index} testmembers={searchedArray} setTestmembers={setSearchedArray}/>
                      })}
                    </Box>
                  </Box> */}
                  <Box className='flex col' gap={1.5} >
                    <Box className='flex j-between a-center' style={{width:{xs:'90%', md:'80%'}}}>
                      <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'#000', fontSize:{xs:'16px',sm:'16px',md:'18px',lg:'20px',xl:'27px'}}}>Members ({members?.length})</Typography>
                      <Box id="search" className='flex j-end' pr={2}>
                        <Input
                          id="search-input"
                        value={searchTerm}
                        onChange={handleSearch}
                        inputProps={{ maxLength: 50 }}
                        sx={{
                            borderColor: "transparent",
                            input: {
                                fontSize: { xs: '12px', md: '12px', lg: '13px', xl: '16px' },
                                fontWeight: 400,
                                color: "#000000",
                              
                            },
                           
                            justifySelf:'flex-end',
                            width:'60%', 
                            transition: 'width 0.3s ease',
                    
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton sx={{paddingBottom:0.5, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'15px',xl:'20px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                    </Box>
                    </Box>
                    <Box sx={{height:'30vh', overflowY:'auto'}} pr={2}>

                      {searchedArray.length > 0 ? searchedArray.map((member, index) => {
                        return  <OrganisationMembersList color={generateDarkColor(member, 'name')} member={member} index={index} searchedArray={searchedArray} roleOptions={options}/>
                      }): 
                       <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'>
                          <Typography sx={{fontSize: 20, color: '#878887', fontWeight: 300, textAlign: 'center', fontFamily:'Rubik'}} >No results found.
                          </Typography>
                        </Box>
                      }
                  
                    </Box>
                  </Box>
  
              </Box>
          </Box>
       
        </CustomAlert>
      )
}

export default ViewOrganisationMembersPopup
