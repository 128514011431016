import { Box, Button, Grid, Typography } from "@mui/material"
import '../../../App.css'
import { makeStyles } from "@mui/styles";
// import { customTheme as theme } from "../../../theme/customTheme";
 import CustomUploadBox from "../../../components/CustomUploadBox";
 import CustomVideoUploads from "../../../components/CustomVideoUploads";
import { base64ToBlob, isEventDate, isEventEnd, isEventPending, timeStampToDayString, timeStampToString } from "../../../utils/commonServices";
import LuciLogo from '../../../assets/lucilogomobile.png'
import poweredBy from '../../../assets/poweredBy.svg'
import "@fontsource/rubik";
import useCustomTheme from "../../../theme/useCustomTheme";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
         position: "absolute",
        // minHeight: "100vh",
        height:'100%',
        minWidth: '100vw',
       
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    leftContainer: {

    },
    scroll: {
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }

    }
}));


const PhoneGuestPage = (props) => {

    const {
        files,
        progress,
        uploadStatus,
        stopUpload,
        setFiles,
        setUploadStatus,
        setProgress,
        setRequestController,
        stopList,
        isLoading,
        id,
        name,
        startDate,
        endDate,
        eventData
    } = props

     const classes = useStyles()
     const [theme ] = useCustomTheme()
     const d = new Date();
     let year = d.getFullYear();
     const commonReducer = useSelector((state) => state.root.commonReducer);
     const themeReducer = useSelector((state) => state.root.themeReducer);
     // const brandLogo = window.localStorage.getItem('brand_logo_url')
    //  const primaryColor = localStorage.getItem('primary')
     const primaryColor = themeReducer?.primary
     const brandLogo = themeReducer?.brand_logo

     const inlineStyles = {
       background: ` 
       linear-gradient(180deg, white, transparent, transparent 80%),
       linear-gradient(180deg, white, #ffffff30, #ffffff10 97%),
       linear-gradient(270deg,  ${theme.palette.secondary.main}, ${theme.palette.primary.main}, ${theme.palette.secondary.main}00 80%), 
       linear-gradient(276deg, ${theme.palette.primary.main} , ${theme.palette.primary.main}80 30%), 
       linear-gradient(130deg, #00000090, #000000  60%)
       `
     };

    return (
        <Box className={classes.root} sx={{ display:{xs:'block', md:'none'}}}>
            {/* <AuthHeader openDrawer={setOpenDrawer} /> */}
            <Box width='100%' className="linear-gradient a-center" style={primaryColor ?  inlineStyles: {}}  sx={{ height:'100%', display:'flex', flexDirection:'column', overflowY:'auto'}}>
                
                        <Box flex={1} textAlign='center' sx={{ backgroundColor:'transparent', marginTop:'8%', width:'22.5%'}}>
                            {/* luci logo                        */}
                            <img src={brandLogo !== null ? brandLogo : LuciLogo} alt="Lucilogo" width='100%'/>
                            {/* {
                                brandLogo !== null && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
                            } */}
                        </Box>
                        <Box flex={1.5} textAlign='center' style={{color:'#000', marginLeft: 4, marginRight: 4 }} >
                            <Typography sx={{fontWeight:600, fontSize:{xs:36, sm: 42}, marginBottom:'2.1%', fontFamily: 'Rubik'}}>Let’s Collab!</Typography>
                            <Typography sx={{fontSize:{xs:16, sm: 20}, fontWeight:400, marginBottom:'0.8%', fontFamily: 'Rubik'}}>Send videos to be featured on</Typography>
                            <Typography sx={{fontSize:{xs:20, sm: 24}, marginBottom:'1.4%', fontWeight:600, fontFamily: 'Rubik'}}>{eventData?.name}</Typography>
                            <Typography sx={{fontSize: {xs:12, sm: 15}, fontStyle:'italic', fontWeight: 400, fontFamily: 'Rubik'}}>Submit videos until <Typography component='span' sx={{fontSize: 12, fontStyle:'italic', fontWeight: 600}} >{timeStampToDayString(eventData?.editor_start_date, "phone")}</Typography></Typography>
                        </Box>
                        <Box sx={{paddingX:{xs:'5%', sm: '8%'}}} flex={7}>

                        {/* {!isEventDate(startDate, endDate) && <Box pb={2} pt={{ xs: 2, md: 4 }} sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: { xs: 'center', md: 'flex-end' }, alignItems: { xs: 'center', md: 'center' }, textAlign: 'center' }}  >
                                <Typography width='100%' p={{ xs: 1, md: 0 }} sx={{ color: '#08231B', fontSize: theme.typography.h6 }}>{isEventPending(startDate) ? "You cannot upload media to this event because the event has not started yet.": isEventEnd(endDate)  && "Event has been finished and you cannot upload anymore media to this event."}</Typography>
                            </Box>} */}
                                <CustomVideoUploads
                                    files={files}
                                    progress={progress}
                                    uploadStatus={uploadStatus}
                                    stopUpload={stopUpload}
                                    stopList={stopList}
                                    isUploading={isLoading}
                                    mdHeight='63'
                                />
                        </Box>
                        <Box flex={2} sx={{paddingX:{xs: '1rem',sm:'1.2rem'}, bottom:'5.6rem'}} textAlign='center'>
                            {/* <Box sx={{ height: 60, boxShadow: 3, width: '100%', justifyContent: { xs: 'center', sm: 'center', md: 'end' }, opacity: 1, backgroundColor: theme.palette.white.main, position: { xs: 'sticky', md: 'inherit' }, top: 0 }} px={4} style={{ display: 'flex', alignItems: 'center' }} > */}

                                <Typography sx={{ fontSize: {xs:15, sm:19},  color: theme.palette.primary.main, marginBottom:'1.8%', fontFamily: 'Rubik' }} style={{ textAlign: 'center' , color: '#000', fontWeight:500}}>
                                    {`${files?.length}  Upload(s)`}
                                </Typography>
                            {/* </Box> */}
                            <CustomUploadBox
                                    isDisabled={!isEventDate(eventData?.start_date, eventData?.editor_start_date) || isLoading}
                                    style={{ display: { xs: 'none', md: 'flex' } }}
                                    height='auto' width='auto'
                                    files={files}
                                    setFiles={setFiles}
                                    setUploadStatus={setUploadStatus}
                                    setProgress={setProgress}
                                    setRequestController={setRequestController}
                                    eventData={eventData}
                                />
                        </Box>
                        <Box flex={1.2} textAlign='center' style={{height:'5.56rem',  display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop: '2.1%', marginBottom: '1%' }} >
                            <Typography sx={{fontSize: {xs: 12, sm: 15} , color: '#fff', fontFamily: 'Rubik'}}>Copyright <span style={{fontWeight:600}}>Luci</span>hub {year}</Typography>
                        </Box>
                  
            </Box>
        </Box>

    )
}

export default PhoneGuestPage;