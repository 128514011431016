import { Backdrop, Box, Slide, Typography, getContrastRatio } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomRadioBox from './CustomRadioBox';
import { useState } from 'react';
import { updateCreationState } from '../../../redux/eventCreationSlice';
import { afterValidate, generateLighterContrastColor } from '../../../utils/commonServices';
import UpIcon from '../icons/UpIcon';
import DownIcon from '../icons/DownIcon';
import CheckIcon from '../../../components/icons/CheckIcon';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';

const VideoOrientationPage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex, selectedProjectType, setSelectedProjectType, totalScreens, closeHandler} = props
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const [selectedValue, setSelectedValue] = useState('')
    const [videoOrientationValidationFlag, setVideoOrientationValidationFlag] = useState(false)
    const dispatch = useDispatch()
    const [theme ] = useCustomTheme()

    const selectLandscape = () => {
        setPageData((last) => {
            return { ...last, video_orientation: 'landscape' }
        })
    }
    
    const selectPortrait = () => {
        setPageData((last) => {
            return { ...last, video_orientation: "portrait" }
        })
    }

    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(nextPageHandler);
    }

    const validateInputs = (flag) => {
      setVideoOrientationValidationFlag(flag)
    }

    useEffect(() => {
      const keyDownHandler = (event) => {
        // console.log("key Parent", event.key)
        if (event.key === "Enter") {
          event.preventDefault();
  
          finishedHandler();
        }
      };
        document.addEventListener("keydown", keyDownHandler)
        
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [pageData]);

  return (
    <>
    <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createEventReducer.open_backdrop_project_creation}
    >
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
          <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3, fontFamily:'Rubik'}} onClick={closeHandler}>Close X</Typography>
          <Box flexGrow={1} className='flex col'  sx={{ paddingLeft:{xs:'6vw',sm:'7vw',md:'14vw'}, marginTop:{xs:'10px',sm:'14%',md:'8%', lg:'7%'}}}>
              <Box mb={{xs:4,sm:6}}>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>Select the orientation of your Lucihub Video:</Typography>
                {/* <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white'}}>Select a project type.</Typography> */}
              </Box>
            
              <Box className='flex' gap={{xs:3, md:6, lg:8, xl:10}} pl={{xs:0, sm:2,md:8, lg:10, xl:12}} sx={{ flexDirection: {xs:'column', sm:'row'}, alignItems:{xs:'center',sm:'normal'},}}>
    
                    <CustomRadioBox
                    label='landscape'
                    id='landscape'   
                    name='projectType' 
                    // project={project} 
                    setSelectedProjectType={setSelectedProjectType} 
                    selectedProjectType={selectedProjectType} 
                    height={{xs:'110px',sm:'140px',lg:'160px', xl:'240px'}}
                    width={{xs:'200px',sm:'250px', lg:'280px',xl:'440px'}}
                    selectedValue={pageData?.video_orientation}
                    setSelectedValue={setPageData}
                    component={
                    <Box className='flex col a-center'  >    
                            <Box sx={{width:{xs:'90px',md:'100px',lg:'130px',xl:'202px'}, border:{xs:`3px solid ${theme.palette.primary.main}`,sm:`3px solid  ${theme.palette.primary.main}`,lg:`4px solid  ${theme.palette.primary.main}`,xl:`5px solid ${theme.palette.primary.main}`}, height:{xs:'50px',md:'55px',lg:'75px',xl:'118px'}, borderRadius:'5px', display:'flex'}}>
                              <Box sx={{borderRight:{xs:`3px solid  ${theme.palette.primary.main}`,sm:`3px solid ${theme.palette.primary.main}`,lg:`4px solid  ${theme.palette.primary.main}`,xl:`5px solid ${theme.palette.primary.main}`}, width:{xs:'12px',lg:'14px',xl:'16px'}}}></Box>
                              <Box flexGrow={1} sx={{display:'grid', placeItems:'center'}}>

                                <Typography style={{fontFamily: 'Rubik', color: 'black'}}  sx={{fontSize:{xs:'15px',md:'18px',lg:'22px',xl:'33px'}, fontWeight:600,}}>16:9</Typography>

                              </Box>
                              <Box sx={{borderLeft:{xs:`3px solid ${theme.palette.primary.main}`,sm:`3px solid  ${theme.palette.primary.main}`,lg:`4px solid  ${theme.palette.primary.main}`,xl:`5px solid ${theme.palette.primary.main}`},  width:{xs:'12px',lg:'14px',xl:'16px'}}}></Box>
                        </Box>
                        <Typography mt={1} sx={{fontSize:{xs:'8px',md:'11px',xl:'15px'}, color:'black', fontFamily:'Rubik'}}>Recommended</Typography>
                    </Box>}
                    text='Most common and great for most screens'
                    boldText='16:9'
                  />

                    <CustomRadioBox
                    label='portrait'
                    id='portrait'  
                    name='projectType' 
                    setSelectedProjectType={setSelectedProjectType} 
                    selectedProjectType={selectedProjectType} 
                    height={{xs:'110px',sm:'140px',lg:'160px', xl:'240px'}}
                    width={{xs:'200px',sm:'250px', lg:'280px',xl:'440px'}}
                    selectedValue={pageData?.video_orientation}
                    setSelectedValue={setPageData}
                    component={
                    <Box className='flex a-center' gap={2}>
                       <Box sx={{width:{xs:'50px',md:'60px',lg:'80px',xl:'118px'}, border:{xs:`3px solid  ${theme.palette.primary.main}`,sm:`3px solid  ${theme.palette.primary.main}`,lg:`4px solid  ${theme.palette.primary.main}`,xl:`5px solid ${theme.palette.primary.main}`}, height:{xs:'90px',md:'100px',lg:'130px',xl:'202px'}, borderRadius:'5px', display:'flex', flexDirection:'column'}}>

                              <Box sx={{borderBottom:{xs:`3px solid ${theme.palette.primary.main}`,sm:`3px solid  ${theme.palette.primary.main}`,lg:`4px solid  ${theme.palette.primary.main}`,xl:`5px solid ${theme.palette.primary.main}`}, height:{xs:'12px',lg:'14px',xl:'16px'}}}></Box>
                              
                              <Box flexGrow={1} sx={{display:'grid', placeItems:'center',}}>
                                <Typography style={{fontFamily: 'Rubik', color: 'black'}} sx={{fontSize:{xs:'15px',md:'18px',lg:'22px',xl:'33px'}, fontWeight:600,}}>9:16</Typography>
                              </Box>

                              <Box sx={{borderTop: {xs:`3px solid ${theme.palette.primary.main}`,sm:`3px solid  ${theme.palette.primary.main}`,lg:`4px solid ${theme.palette.primary.main}`,xl:`5px solid ${theme.palette.primary.main}`}, height:{xs:'12px',lg:'14px',xl:'16px'}}}></Box>
                        </Box>
                    </Box>}
                    text='Good for Reels, Stories, Shorts, & TikTok’s'
                    boldText='9:16'
                  />
                   <Box sx={{ alignSelf: 'flex-end', pt:'2px', mb: '45px'}}>
                        <CheckIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick={pageIndex !== 8 ? finishedHandler : null} sx={{fontSize:{xs:'50px',xl:'60px'}, cursor: 'pointer'}}/>
                      </Box>
              </Box>
             
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:5, mr:5}}>
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer', opacity: pageIndex === 1 && 0.5}} onClick={pageIndex !== 1 ? prevPageHandler : null}>/\</Box> */}
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer',  opacity: pageIndex === 5 && 0.5}} onClick={pageIndex !== 8 ? finishedHandler : null}>\/</Box> */}
            <UpIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', opacity: pageIndex === 1 && 0.5, fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <DownIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 8 ? finishedHandler : null}/>
          </Box>
        </Box>
    </Backdrop>
    </Slide>
    </>
  )
}

export default VideoOrientationPage

