
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StarIcon from '@mui/icons-material/Star';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { fileFormat, removeExtension, sizeConverter, timeStampToNumberedDateString } from '../../../utils/commonServices';
import { makeStyles } from "@mui/styles";
import CustomTooltip from '../../../components/CustomTooltip';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getMediaFilesOfProject } from '../../../commonactions/actions';
import { useNavigate } from 'react-router-dom';
import { setAllMediaFilesArray } from '../../../redux/mediaFilesSlice';
import CustomScrollToBottom from '../../../components/CustomScrollToBottom';
import '../userMediaTable.css';

const useStyles = makeStyles((theme) => ({
    checkboxRoot: {
        pointerEvents: 'none',
      },
    container: {
        width:'100%',
        borderCollapse: 'collapse',
       // paddingRight:'4%'
       scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
                 display : 'none'
               }
    },
    tableRowHead:{
        textAlign:'left',
        verticalAlign:'top',
        color:'#08231B',
        fontSize:'15px',
        fontFamily: "Rubik",
        fontWeight:500,
        letterSpacing: '0.75px',
        zIndex: 15
    },
    tableRowData:{
        borderBottom:'2px solid #EBEBEB',
        // height:{xs:'50px',md:'55px',lg:'60px', xl:'68px'},
        // height:'62px',
        backgroundColor:'transparent',
        fontFamily: "Rubik",
        textAlign:'left',
        width:'100%',
        fontSize:'15px',
        // font: 'normal normal 600 15px/18px ',
        fontWeight:600,
        color:'#08231B',
        letterSpacing: '0.75px',
        zIndex: 15,
        '&:last-child':{
            borderBottom:'none'
         }
    },
 
  }));



const UserMediaMobileTable = (props) => {

    const classes = useStyles();
    const {files, handleSelectAllChange, handleFileCheckboxChange, selectAll, popup, isLoading}= props
    // console.log(popup)
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);
    const [expanded, setExpanded] = useState(mediaFilesReducer.my_files_projects[0]?.id);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleAccordianChange = (eventId) => (event, isExpanded) => {
      setExpanded(isExpanded ? eventId : false);
      dispatch(setAllMediaFilesArray({my_files_project_media_files: []}))
    //   console.log(eventId, '-----------eventID')
     isExpanded && getMediaFilesOfProject(dispatch, navigate, commonReducer, eventId)
    };

    const selectedProjectName = commonReducer.events?.filter(event => event.id == mediaFilesReducer.imported_project_id)[0]?.name


  return (
   
           <Box mt={3} className='flex col' sx={{minWidth:'60px'}}>
                <Box sx={{overflowY:'scroll', height:popup == true ? '30vh':'55vh'}}>

               
               {mediaFilesReducer.my_projects_loading === true ? <Typography style={{fontSize:'30px'}} textAlign={'center'} mt={'8%'}>{'Loading...'}</Typography> : mediaFilesReducer?.my_files_projects?.length === 0 ?  <Typography sx={{fontSize: 30, color: '#878887', fontWeight: 300, textAlign: 'center', mt:10}} >No media file to show. </Typography> : mediaFilesReducer?.my_files_projects?.map((project, index)=> ( 
                <>

                {/* {expanded === project?.id && <CustomScrollToBottom /> } */}
                <Accordion expanded={expanded === project?.id} style={{backgroundColor:'transparent'}} disableGutters={true} onChange={handleAccordianChange(project?.id)}>
                  
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography  sx={{fontSize: {xs:'14px',sm:'15px'}, fontFamily: 'Rubik' , color:'#08231B', textTransform:'capitalize'}}>{project.name}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p:0, pl:'4px', pr:'0px'}} >
                <table className="user-files-responsive-table" >
        <thead>
          <tr style={{display:'flex', width:'100%'}}>
            <th style={{display:'flex', justifyContent:'start', alignItems:'center'}}>
                      <Checkbox 
                        size='medium'
                           sx={{fontSize: {xs:'18px',sm:'19px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'18px',sm:'19px'},},  zIndex:0}} 
                           onChange={(e)=>{e.stopPropagation();e.preventDefault();handleSelectAllChange(e)}} 
                           checked={selectAll}    
                           checkedIcon={<CheckBoxOutlinedIcon/>}          
                       />
            </th>
            <th>
              <Typography sx={{fontSize: {xs:'13px',sm:'14px'}, fontFamily: 'Rubik', fontWeight: 800, color:'#08231B'}}>File Name</Typography>
            </th>
            <th></th> 
          </tr>
        </thead>
        <tbody>
        {files?.length  == 0 && mediaFilesReducer?.my_files_loading === true ? (<Typography textAlign={'center'} mt={'5%'}>{'Loading...'}</Typography>) : files?.map((file, index) => (
            <tr key={index} style={{width:'100%'}}>
              <td data-label="Checkbox" style={{display:'flex', justifyContent:'center', alignItems:'start'}}>
                              <Checkbox 
                                
                                size='medium'
                                sx={{fontSize: {xs:'18px',sm:'19px'}, '& .MuiSvgIcon-root':{
                                    fontSize:{xs:'18px',sm:'19px'}, },  zIndex:0, }} 
                                onChange={(e)=>{e.stopPropagation();e.preventDefault();handleFileCheckboxChange(index)}} 
                                checked={file?.checked}  
                                checkedIcon={<CheckBoxOutlinedIcon/>}           
                                />
              </td>
              <CustomTooltip title={<Box sx={{display:'flex', flexDirection:'column'}}>
                  <Typography sx={{fontSize: {xs:'10px',sm:'11px'}, fontWeight:400, fontFamily: 'Rubik',  textAlign:'left', display:'inline-block'}}><span style={{fontWeight:600}}>Filename </span>{`${file.name}  `}</Typography>
                  <Typography sx={{fontSize: {xs:'10px',sm:'11px'}, fontWeight:400, fontFamily: 'Rubik',  textAlign:'left', display:'inline-block'}}><span style={{fontWeight:600}}>Uploaded by </span>{`${file.uploaded_by}`}</Typography>
                  <Typography sx={{fontSize: {xs:'10px',sm:'11px'}, fontWeight:400, fontFamily: 'Rubik',  textAlign:'left', display:'inline-block'}}><span style={{fontWeight:600}}>Uploaded time </span>{timeStampToNumberedDateString(file.upload_time)}</Typography>
                </Box>}>
              <td data-label="Name" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start'}}>
               
                 <div className="filename" style={{display:'flex', justifyContent:'start', textAlign:'left'}}>
                 <span>
                  <Typography sx={{fontSize: {xs:'12px',sm:'13px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik', fontWeight:600, textAlign:'left', display:'inline-block'}}>{`${file.name}  `}<span><Typography sx={{fontSize: {xs:'10px !important',sm:'11px !important',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik', display:'inline-block'}}>({sizeConverter(parseInt(file?.size))})</Typography></span></Typography>
                  </span>
                 </div>

                <div className="filedetails" >
                  <span><Typography sx={{fontSize: {xs:'11px',sm:'12px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik', display:'inline-block'}}>{`${file.uploaded_by} `}</Typography></span> | <span><Typography sx={{fontSize: {xs:'11px',sm:'12px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik', display:'inline-block'}}>{timeStampToNumberedDateString(file.upload_time)}</Typography></span>
                </div>
              </td>
                 </CustomTooltip>
                
              <td data-label="Options" className="options" style={{display:'flex', justifyContent:'center', alignItems:'end'}}>
              <>           
                            <Checkbox
                            classes={{
                            root: classes.checkboxRoot,
                            }}
                            sx={{fontSize: {xs:'15px',sm:'16px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'15px',sm:'16px'}},  zIndex:0}} 
                            checked={file?.favourite}  
                            icon={<StarOutlineIcon sx={{fontSize: {xs:'15px',sm:'16px'},  '& .MuiSvgIcon-root':{
                            fontSize:{xs:'15px',sm:'16px'},
                            }}}/>} 
                            checkedIcon={<StarIcon sx={{fontSize: {xs:'15px',sm:'16px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'15px',sm:'16px'},
                            } }}/>} /></>
              </td>
            </tr>
          ))}
        </tbody>
                 </table>
                {/* <Box sx={{ display:'flex', alignItems:'center'}} mb={{xs:2,xl:4}}>
                    <Box flex={0.3}  className='flex a-center'  style={{  minWidth:'90px', justifyContent:'flex-start'}}>
                        <Checkbox 
                        size='medium'
                           sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'},},  zIndex:0}} 
                           onChange={(e)=>handleSelectAllChange(e)} 
                           checked={selectAll}    
                           checkedIcon={<CheckBoxOutlinedIcon/>}          
                       />
                    </Box>
                    <Box flex={4} style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24,  minWidth:'220px'}}><Typography  sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik' , color:'#08231B'}}>File Name</Typography></Box>

                    <Box flex={0.7} style={{  minWidth:'90px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'},  fontFamily: 'Rubik' , color:'#08231B'}}>File Type</Typography></Box>

                    <Box flex={0.7} style={{  minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik' , color:'#08231B' }}>Size</Typography></Box>

                    <Box flex={0.7} style={{  minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}>Upload Date</Typography></Box>


                    <Box flex={0.7}  style={{  minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}>Uploaded By</Typography></Box>

                    <Box flex={1} style={{  minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}>Project Name</Typography></Box>

                    <Box flex={0.5}   ><Typography  sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}></Typography></Box>
                </Box> */}

                     {/* {files?.length  == 0 && mediaFilesReducer?.my_files_loading === true ? (<Typography textAlign={'center'} mt={'5%'}>{'Loading...'}</Typography>) : files?.map((file, index) => (
                        <Box className={classes.tableRowData}  key={file?.size + index} sx={{display:'flex',  alignItems:'center', height: popup == true ?{md:'40px',lg:'45px', xl:'60px'}:{lg:'55px',xl:'65px'}}}>
                            <Box flex={0.3}  className='flex'  style={{  minWidth:'90px', justifyContent:'flex-start'}}>
                                <Checkbox 
                                
                                size='medium'
                                sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'}, '& .MuiSvgIcon-root':{
                                    fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'}, },  zIndex:0, }} 
                                onChange={()=>handleFileCheckboxChange(index)} 
                                checked={file?.checked}  
                                checkedIcon={<CheckBoxOutlinedIcon/>}           
                                />
                            </Box>
                
                    <CustomTooltip title = {file?.name} >
                            <Box flex={4} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, wordWrap:'break-word', minWidth:'220px'}}><Typography  sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, wordWrap:'break-word', fontFamily: 'Rubik', }}>{removeExtension(file?.name)}</Typography></Box>
                    </CustomTooltip> 

                            <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'90px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik' }}>{file?.content_type}</Typography></Box>

                            <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{sizeConverter(parseInt(file?.size))}</Typography></Box>

                            <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(file?.upload_time)}</Typography></Box>


                            <Box flex={0.7} className='flex j-center' style={{ minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{file?.uploaded_by}</Typography></Box>

                            <Box flex={1}  className='flex j-center'  style={{ minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:600, fontFamily: 'Rubik'}}>{file?.event_name ? file?.event_name : selectedProjectName}</Typography></Box>

                            <Box flex={0.5}  sx={{ display:'flex', justifyContent:'end'}}>
                            <Box sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, display:'flex', alignItems:'center',justifyContent:'end' }} gap={2}>
                
                            <>           
                            <Checkbox
                            classes={{
                            root: classes.checkboxRoot,
                            }}
                            sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'}},  zIndex:0}} 
                            checked={file?.favourite}  
                            icon={<StarOutlineIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'},  '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'},
                            }}}/>} 
                            checkedIcon={<StarIcon sx={{fontSize: {xs:'12px',md:'16px',lg:'20px',xl:'25px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'12px',md:'16px',lg:'20px',xl:'25px'},
                            } }}/>} /></>
                        </Box>
                    </Box>
               </Box>
                     ))}  */}

               </AccordionDetails>
          </Accordion>
          </>
          ))}
            </Box>
            </Box>
  )
}

export default UserMediaMobileTable