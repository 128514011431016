import { useEffect } from "react";
import useEventRequest from "../../../hooks/useEventRequest";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../../redux/commonSlice";
import { setSnackbar } from "../../../redux/messageSlice";
import { getAllEvents } from "../actions";
import { getTeamEvents } from "../../teamsdashboard/actions";


export default (id, teamId)=> {
    const dispatch = useDispatch();
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [cancelEvent, cancelEventStatus] = useEventRequest(
        "cancel",
      );
      const deleteProject = () => {
        teamId ? cancelEvent({ id: id, cancel: true, team_id: teamId }) : cancelEvent({ id: id, cancel: true})  ;
  };

  const updateList = (list)=> {
    const tempEvents = commonReducer[list].map((event)=> {
        if(event.id == id){
            return {...event, cancel: true}
        } else {
            return event
        }
    })

    dispatch(updateState({[list]: tempEvents}))
  }
    useEffect(()=> {
        if(cancelEventStatus == 'success') {
            // updateList("events");
            // updateList("status_filtered_events");
            // updateList("type_filtered_events");
            // updateList("combined_filter");
            // resetList((last)=>{
            //     return last.map((e)=> {
            //         if(e.id == id) {
            //             return {...e, cancel: true}
            //         } else {
            //             return e
            //         }
            //     })
            // })
            dispatch(setSnackbar({flag:true, message: 'Project deleted successfully.', type:'success'}))
           teamId ? getTeamEvents({team_id: teamId}, dispatch) : getAllEvents(dispatch)
            dispatch(updateState({ event_delete_popup: false}))
        }else if(cancelEventStatus =='error'){
            dispatch(setSnackbar({flag:true, message: 'Some error occured', type:'error'}))
            dispatch(updateState({ event_delete_popup: false}))
        }
    },[cancelEventStatus])

    return [deleteProject, cancelEventStatus]
}