import React from "react";
import { Box, SvgIcon } from "@mui/material";
import useCustomTheme from "../../../theme/useCustomTheme";

const PageNotFoundImage = (props)=> {
    const [theme] = useCustomTheme()
    return <SvgIcon 
            {...props} 
            id="Group_156" data-name="Group 156" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396.345 337.603">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_172" data-name="Rectangle 172" width="396.345" height="337.603" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_226" data-name="Group 226" >
    <path id="Path_174" data-name="Path 174" d="M197.966,337.6q-83.064,0-166.127-.01C10.419,337.575.06,327.337.046,305.833q-.092-137.022,0-274.044C.064,10.5,10.7.021,31.974.017Q198.1-.015,364.227.02c20.8.005,32.061,11.18,32.074,32.029q.089,137.022,0,274.044c-.011,20.765-10.723,31.473-31.335,31.487q-83.5.058-167.005.015M374.3,97.569H22.058c-.235,2.56-.578,4.564-.579,6.568q-.043,100.59,0,201.18c.007,9.739,1.328,10.788,12.455,10.789q163.961.018,327.921,0c11.588,0,12.431-.849,12.434-12.586q.029-98.394.01-196.787ZM21.557,75.468H374.98c0-13.765.01-26.875,0-39.985-.013-13.591-.662-14.231-14.473-14.231q-136.163,0-272.327,0H56.7c-9.236,0-22.453-3.64-30.764,2.205-5.7,4.007-4.791,10.555-4.812,16.778-.038,11.746.43,23.486.43,35.232" transform="translate(0 0)" fill="#08231b"/>
    <path id="Path_175" data-name="Path 175" d="M392.82,95.316H37.3v-47.3a8.061,8.061,0,0,1,8.037-8.038H384.783a8.062,8.062,0,0,1,8.037,8.038Z" transform="translate(-18.518 -19.847)" fill={theme.palette.primary.main}/>
    <path id="Path_176" data-name="Path 176" d="M373.244,458.443c0,6.28.052,12.056-.01,17.831-.2,18.225-11.644,30.807-27.783,30.589-15.846-.214-27.051-12.707-27.2-30.375-.049-5.794-.008-11.589-.008-18.486-17.621,0-34.5,0-51.383,0-12.98,0-17.624-2.828-17.443-10.579.177-7.579,4.9-10.308,17.939-10.311q54.454-.01,108.907.011a52.2,52.2,0,0,1,8.752.357c5.635.968,9.854,4.736,8.351,10.2-1.075,3.9-5.243,7.609-8.957,9.911-2.564,1.589-6.71.627-11.164.858m-33.87.563c0,7.42-.508,14.386.3,21.2.254,2.155,3.967,5.326,6.186,5.4,1.976.067,5.593-3.333,5.837-5.5.765-6.806.282-13.751.282-21.1Z" transform="translate(-123.816 -216.993)" fill="#08231b"/>
    <path id="Path_177" data-name="Path 177" d="M274.25,304.8c-4.97,5.935-8,10.364-11.831,13.92-4.7,4.36-10.333,4.561-14.9-.212-4.55-4.753-4.133-10.237.4-14.878,3.466-3.547,7.5-6.539,14.288-12.352-5.839-4.19-10.521-6.821-14.269-10.423-4.609-4.429-5.058-10.08-.53-14.882,4.887-5.183,10.525-4.676,15.484.125,3.553,3.439,6.632,7.367,12.324,13.787,4.165-5.637,6.888-10.22,10.485-13.966,4.5-4.686,10.091-4.966,14.827-.349s4.464,10.2.18,14.933c-3.5,3.867-7.821,6.995-13.825,12.237,5.582,4.475,9.992,7.4,13.629,11.081,4.561,4.622,4.73,10.2.1,14.847-4.663,4.684-10.278,4.337-14.91-.089-3.762-3.594-6.733-8.015-11.454-13.778" transform="translate(-121.246 -130.19)" fill="#08231b"/>
    <path id="Path_178" data-name="Path 178" d="M465.884,292.985c5.812,5.1,9.8,8.13,13.21,11.7,4.451,4.66,4.895,10.2.35,14.873s-10.242,4.421-14.945.2c-3.879-3.481-6.932-7.882-12.009-13.811-4.291,5.356-7.1,9.457-10.515,12.982-4.742,4.9-10.569,5.6-15.546.82-5.033-4.835-4.412-10.612.461-15.465,3.51-3.5,7.507-6.5,13.23-11.39-4.726-3.584-8.271-5.757-11.181-8.583-6.636-6.447-7.48-12.685-2.842-17.465,4.74-4.884,11.064-4.168,17.486,2.27,2.878,2.885,5.36,6.165,9.842,11.387,3.606-4.79,6.021-8.466,8.907-11.726,4.868-5.5,10.661-7.452,16.839-2.2,5.144,4.372,4.263,11.084-2.174,17.426-2.9,2.857-6.341,5.163-11.113,8.978" transform="translate(-209.958 -130.811)" fill="#08231b"/>
    <path id="Path_179" data-name="Path 179" d="M427.905,480.595h12.6c0,7.347.483,14.293-.282,21.1-.244,2.167-3.861,5.567-5.837,5.5-2.219-.075-5.932-3.246-6.186-5.4-.8-6.811-.3-13.778-.3-21.2" transform="translate(-212.347 -238.583)" fill={theme.palette.secondary.main}/>
    <path id="Path_180" data-name="Path 180" d="M99.428,85.849A12.439,12.439,0,1,1,86.99,73.41,12.439,12.439,0,0,1,99.428,85.849" transform="translate(-37.01 -36.443)" fill="#08231b"/>
    <path id="Path_181" data-name="Path 181" d="M159.454,85.849A12.439,12.439,0,1,1,147.016,73.41a12.439,12.439,0,0,1,12.439,12.439" transform="translate(-66.808 -36.443)" fill="#08231b"/>
    <path id="Path_182" data-name="Path 182" d="M219.481,85.849A12.439,12.439,0,1,1,207.043,73.41a12.439,12.439,0,0,1,12.439,12.439" transform="translate(-96.608 -36.443)" fill="#08231b"/>
  </g>

  </SvgIcon>
    
  
}
export default PageNotFoundImage