import { SvgIcon } from "@mui/material";
import React from "react";
import { customTheme as theme } from "../../../theme/customTheme";

const DownIcon = (props)=> {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="127" height="127" viewBox="20 0 100 100">
  <defs>
    <filter id="Rectangle_29" x="0" y="0" width="127" height="127" filterUnits="userSpaceOnUse">
      <feOffset dy="5" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="7.5" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_48" data-name="Rectangle 48" width="32.025" height="19.382" fill={props.color}/>
    </clipPath>
  </defs>
  <g id="Group_347" data-name="Group 347" transform="translate(-1754.5 -939.5)">
    <g id="Add_Project" data-name="Add Project" transform="translate(1558 613)">
      <g transform="matrix(1, 0, 0, 1, 196.5, 326.5)" filter="url(#Rectangle_29)">
        <path id="Rectangle_29-2" data-name="Rectangle 29" d="M0,0H72A10,10,0,0,1,82,10V72A10,10,0,0,1,72,82H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(22.5 17.5)" fill="#08231b"/>
      </g>
    </g>
    <g id="Group_333" data-name="Group 333" transform="translate(1801.987 988.309)">
      <g id="Group_81" data-name="Group 81" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path id="Path_42" data-name="Path 42" d="M3.716,0a10.271,10.271,0,0,1,2.13,1.342q4.692,4.537,9.237,9.224c.794.817,1.253.79,2.032-.011,3.095-3.179,6.257-6.292,9.4-9.427A3.164,3.164,0,0,1,29.895.193a2.706,2.706,0,0,1,2.13,2.452,4.441,4.441,0,0,1-.888,2.888c-4.156,4.343-8.427,8.575-12.7,12.8a3.06,3.06,0,0,1-4.577.033Q7.382,12,.993,5.549A3.045,3.045,0,0,1,.34,1.894C.886.72,1.874.1,3.716,0" transform="translate(0 0)" fill={props.color}/>
      </g>
    </g>
  </g>


      </SvgIcon>
    )
}

export default DownIcon