import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    user_id: "",
    user_email: "",
    user_name: "",
    is_admin: false,
    company_name:"",
    brand_logo: null,
    brand_logo_download_url: null,
    is_company_name_available: false,
    show_revision_tutorial: false,
    copilot_access: false,
    token: "",
    is_logged: false,
    add_event_open: false,
    events: [],
    status_filtered_events: [],
    type_filtered_events: [],
    combined_filter: [],
    selected_event: {},
    event_details: {
        id: "",
        name: "",
        collab_code: "",
        collaborators: [],
        event_type: "",
        owner_name: "",
        start_date: "",
        end_date: "",
        editor_start_date: "",
        status_type: null,
        status_type_order: null,
        consent_accepted: false,
        final_video_download_url: null,
        final_video_id: null,
        final_video_url: null,
        revision: {
            id: null,
            submitted: null
        },
        revision_left: 1, 
      },
      production_event_id: null,
      consent_id: '',
      consent_category: "",
      consent_detail:"",
      consent_accepted: null,
      open_agreement_popup: false,
      event_delete_popup: false,
      delete_decline_popup: false,
      event_delete_name: "",
      guest_event_detail: {},
      guest_collab_code: "",
      guest_events: [],
      play_tutorial: false,
      open_media_preview: false,
      under_revision: false,
      new_video: false,
      is_collaborator: false,
      // open_previous_project_media_popup:false,
      // imported_project_id: null,
      // import_files_confirmation_popup: false,
      is_media_loading: false,
      project_media_files: [],
      account_delete_popup:false,
      is_loading: false,
      selected_project_members: [],
      my_files_projects: [],
      my_files_project_media_files:[],
      change_theme_popup: false,
      theme_loading: false,
      signin_loading:false,
      is_redirected_from_butterfly:false,
      logout_all: false,
      
}
export const commonSlice = createSlice({
    name: 'common',
    initialState: initialValue,
    reducers: {
        updateState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateState, resetState } = commonSlice.actions
export default commonSlice.reducer