

//// VIDEO TABLE WITH ACCORDIAN//////////////////////////////////////////////////////

import { makeStyles } from "@mui/styles";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, CircularProgress, IconButton, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import CustomVideoLinearProgress from "./CustomVideoLinearProgress"; 
import "@fontsource/rubik";
import CustomTooltip from "./CustomTooltip";
import { bytesToMegabytes, downloadS3File, fileFormat, getFileType, groupArrayByValue, removeExtension, showSnackBar, sizeConverter, timeStampToNumberedDateString } from '../utils/commonServices'
import { deleteMedia, downloadMedia, favoriteMedia, markViewed } from '../services/api'
import StarIcon from '@mui/icons-material/Star';
import { useMutation, useQuery } from "react-query";
import useUploadedList from "../hooks/useUploadedList"; 
import { apiConstant } from "../services/apiConstants"; 
import useSort from "../pages/events/hooks/useSort";
import useMediaDownload from "../pages/events/hooks/useMediaDownload";
import PopupVideoPlayer from "./PopupVideoPlayer";
import VideoPlayer from "../pages/completedvideo/components/VideoPlayer";
import { updateState } from "../redux/commonSlice";
import MediaPreview from "./MediaPreview";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { uploadedfiles } from "../constants/staticData";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { LoadingButton } from "@mui/lab";
import { set } from "date-fns";
import usePermissions from "../hooks/usePermissions";
import { PERMISSION } from "../constants/constants";


const useStyles = makeStyles((theme) => ({
  container: {
      width:'100%',
      borderCollapse: 'collapse',
     // paddingRight:'4%'
     scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
               display : 'none'
             }
  },
  tableHead:{
      marginBottom:'10px',
      height:'28.5px',
      fontFamily: "Rubik",
     // position: 'sticky',
      //top: '0px',
      //zIndex: 15,
     // background: 'white',
  },
  tableRowHead:{
      textAlign:'left',
      verticalAlign:'top',
      color:'#08231B',
      fontSize:'15px',
      fontFamily: "Rubik",
      fontWeight:500,
      letterSpacing: '0.75px',
      zIndex: 15
  },
  tableRowData:{
      borderBottom:'2px solid #EBEBEB',
      // height:'60px',
      backgroundColor:'transparent',
      fontFamily: "Rubik",
      textAlign:'left',
      width:'100%',
      fontSize:'15px',
      // font: 'normal normal 600 15px/18px ',
      fontWeight:600,
      color:'#08231B',
      letterSpacing: '0.75px',
      zIndex: 15,
      '&:last-child':{
        borderBottom:'none'
      }
  },
//   'tableRowData&:last-child' :{
//     borderBottom:'none',
 
// },
  tableCell:{
      textAlign:'right'
  },
  icons:{
      height:'18px',
      width:'18px'
  }
}));




const CustomVideoTable = (props) => {

  const {progressFlag=true, files, isUploading, progress, stopUpload, uploadStatus, uploadedVideosArray=[],sortBy, importFiles, setUploadedVideosArray, importFilesStatus, refetch} = props;
  const classes = useStyles();
  const [enableDownload, setEnableDownload] = useState(false)
  const [payload, setPayload] = useState({id:''})
  const commonReducer = useSelector((state) => state.root.commonReducer);
  // const [tempUploadedVideoArray, setTempUploadedVideoArray] = useState(uploadedVideosArray)
  const [videoSrc, setVideoSrc] = useState("");
  // const [favorite, setFavorite] = useState({id:'', favorite: null})
  const [uploadedVideos, refetchList, uploadedListLoading] = useUploadedList(commonReducer.is_logged);
  const dispatch = useDispatch();
  const [downloadMedia] = useMediaDownload();
  const [reversedArray, setReversedArray] = useState(uploadedVideosArray)
  const [sortedFiles] = useSort( reversedArray, sortBy)
  const [fileType, setFileType] = useState('')
  const [importedFiles, setImportedFiles] = useState([])
  const [fileId, setFileId] = useState(null)
  const [clickType, setClickType] = useState("")
  const [isMainAccordianExpanded, setIsMainAccordianExpanded] = useState(true)
  const [hasPermission] = usePermissions()


//  console.log({tempUploadedVideoArray, uploadedVideosArray})

const {
  data: downloadData,
  error: downloadError,
  isLoading
} = useQuery(
  [`download${payload}`, payload],
  () => downloadMedia(payload),
  { enabled: enableDownload, retry: false }
);

//POST API Request  favorite video
const { mutate: addFavoriteMediaMutate, isLoading: addFavoriteMediaLoading } =
  useMutation(favoriteMedia, {
    onSuccess: (response, variables, context) =>
      favoriteMediaSuccessHandler(response, variables, context),
    onError: (error, variables, context) =>
      favoriteMediaErrorHandler(error, variables, context),
  });

const  favoriteMediaSuccessHandler = (response, variables, context) => {
  const responseObject = response?.data;
  const responseData = responseObject?.data
  if (responseObject?.http_code == 200) {
  //   dispatch(updateState({ production_event_id: responseData?.id }));
  //   showSnackbar(responseObject);
  //   setCreateEventStatus("success");
  // setUploadedVideosArray((prev)=>prev.map(file=>{
  //   if(file?.id == fileId){
  //     return {...file, favourite: !file?.favourite}
  //   }else{
  //     return file
  //   }
  // }))
  refetchList()

  }

};

const favoriteMediaErrorHandler = (error, variables, context) => {
  // setSubmitFlag(false);
  // setCreateEventStatus("error");
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);

};

//PUT API Request  to mark viewed video
const { mutate: markViewedMutate, isLoading: markViewedLoading } =
  useMutation(markViewed, {
    onSuccess: (response, variables, context) =>
      markViewedSuccessHandler(response, variables, context),
    onError: (error, variables, context) =>
      markViewedErrorHandler(error, variables, context),
  });

const  markViewedSuccessHandler = (response, variables, context) => {
  const responseObject = response?.data;
  const responseData = responseObject?.data
  if (responseObject?.http_code == 200) {
  //   dispatch(updateState({ production_event_id: responseData?.id }));
  //   showSnackbar(responseObject);
  //   setCreateEventStatus("success");
  // setUploadedVideosArray((prev)=>prev.map(file=>{
  //   if(file?.id == fileId){
  //     return {...file, favourite: !file?.favourite}
  //   }else{
  //     return file
  //   }
  // }))
  refetchList()

  }

};


//console.log("uploadstatus", uploadListLoading)



const markViewedErrorHandler = (error, variables, context) => {
  // setSubmitFlag(false);
  // setCreateEventStatus("error");
  const errorObject = error?.response?.data;

};


  //DELETE API Request   video
  const { mutate: deleteMediaMutate, isLoading: deleteMediaLoading } =
  useMutation(deleteMedia, {
    onSuccess: (response, variables, context) =>
      deleteMediaSuccessHandler(response, variables, context),
    onError: (error, variables, context) =>
      deleteMediaErrorHandler(error, variables, context),
  });

const  deleteMediaSuccessHandler = (response, variables, context) => {
  const responseObject = response?.data;
  const responseData = responseObject?.data
  if (responseObject?.http_code == 200) {
  //   dispatch(updateState({ production_event_id: responseData?.id }));
  //   showSnackbar(responseObject);
  //   setCreateEventStatus("success");
  refetchList()
  }

};

const deleteMediaErrorHandler = (error, variables, context) => {
  // setSubmitFlag(false);
  // setCreateEventStatus("error");
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
};


  const favoriteHandler =(file, e)=>{
      // console.log(e.target.checked)
      // console.log(file.favourite)
      setClickType("fvt")
      setFileId(file?.id)
      addFavoriteMediaMutate({id:file?.id ,event_id: commonReducer.production_event_id, favourite: !file?.favourite })   
  }

  const handleDelete =  (id) => {
    setClickType("dlt")
       setFileId(id)
       deleteMediaMutate({id})
      // refetchList()
    };

  const removeLinkedFileHandler =async (id)=>{
    setClickType("dlt")
    setFileId(id)
    await importFiles({destination_event_id: commonReducer.production_event_id, media_files: JSON.stringify([{id: id, checked: false}])})
    
    // refetchList();
  }

    const videoDownloadHandler =  (file)=>{
      if(file?.video_url != null && file?.video_url != "") {
        downloadS3File(file.download_link)
      }
     
     // console.log('Video download')// download video 
      // const url = 'https://s3.us-central-1.wasabisys.com/files-storage-staging/final_video/2/wasabi_sample.mp4';
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'video.mp4');
      // link.target = '_blank';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);  
  }
  const viewMediaHandler = (file)=> {
    if(file?.video_url != null && file?.video_url != "" && bytesToMegabytes(parseInt(file?.size)) <= 500) {
      markViewedMutate({id: file?.id})
       //console.log(bytesToMegabytes(parseInt(file?.size)))
      setFileType(getFileType(file?.name))
      setVideoSrc(file.video_url)
      dispatch(updateState({open_media_preview: true}))
    }

  }
  

  useEffect(() => {
   
    let tempArr = [...uploadedVideosArray].reverse().map(element => {
      return element;
    });
    //console.log({tempArr})
    setReversedArray(tempArr)
    // setTempUploadedVideoArray(uploadedVideosArray)
    // console.log(tempArray)

  }, [uploadedVideosArray])

  useEffect(()=> {
    const tempArray = groupArrayByValue(sortedFiles?.filter(video => video.event_name != commonReducer.event_details?.name), 'event_name')
    setImportedFiles(tempArray)
   // console.log({tempArray})

  }, [reversedArray])

  // useEffect(()=> {
  //   if(importFilesStatus === 'success') {
  //     console.log("sdsd")
  //     refetch()
  //   }
  // }, [importFilesStatus])

 
  

  const iconButtonActions = (
      <Box >
          <IconButton >
           <DeleteIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>
          </IconButton>
         <IconButton >
              <StarOutlineIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},'& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           } }}/>
         </IconButton>
          <IconButton disabled >
                 <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},'& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           } }}/>
         </IconButton>
 </Box>
    )

return (
  <Box pr={0.5}>
    <Box sx={{ display:'flex'}} className={classes.tableHead}>
      <Box flex={6} style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24,  minWidth:'220px'}}><Typography  sx={{fontSize: {xs:'9px',md:'10px',lg:'12px',xl:'17px'}, fontFamily: 'Rubik' ,fontWeight:600,color:'#4f635d'}}>File Name</Typography></Box>

      <Box flex={0.7} style={{  minWidth:'90px', }}><Typography textAlign='center' sx={{fontSize: {xs:'9px',md:'10px',lg:'12px',xl:'17px'},  fontFamily: 'Rubik' ,fontWeight:600,color:'#4f635d'}}>File Type</Typography></Box>

      <Box flex={0.7} style={{  minWidth:'60px', }}><Typography textAlign='center' sx={{fontSize: {xs:'9px',md:'10px',lg:'12px',xl:'17px'}, fontFamily: 'Rubik',fontWeight:600 ,color:'#4f635d' }}>Size</Typography></Box> 

      <Box flex={0.7} style={{  minWidth:'70px', }}><Typography textAlign='center' sx={{fontSize: {xs:'9px',md:'10px',lg:'12px',xl:'17px'}, fontFamily: 'Rubik',fontWeight:600,color:'#4f635d'}}>Upload Date</Typography></Box>

      <Box flex={0.7}  style={{  minWidth:'60px',}}><Typography textAlign='center' sx={{fontSize: {xs:'9px',md:'10px',lg:'12px',xl:'17px'}, fontFamily: 'Rubik', fontWeight:600,color:'#4f635d'}}>Uploaded By</Typography></Box>

      <Box flex={1}  className={classes.tableCell} ><Typography  sx={{fontSize: {xs:'9px',md:'10px',lg:'12px',xl:'17px'}, fontFamily: 'Rubik', fontWeight:600,color:'#4f635d'}}>{files?.length > 0 && "Status"}</Typography></Box>
    </Box>

    <Box className={classes.container} 
      component = 'div'
      sx={{
          overflowY: 'scroll',
          height: {xs:'35vh',sm:'35vh',md: '37vh',lg:'39vh', xl: '48vh'}, 
          minHeight:'300px'
      }}
    >
      {
        uploadedListLoading == true ? 
          <Typography px={15} sx={{color:'#878887', fontSize:{xs:'20px',sm:'24px',md:'28px',lg:'33.33px',xl:'50px'}, fontWeight: 300, fontFamily: "Rubik", textAlign: 'center', marginTop:'4%', marginBottom:'1%'}} variant="h3">Loading...</Typography>
          : <> 
         {hasPermission(PERMISSION.PROJECT.MEDIA.VIEW.SELF) && <Accordion defaultExpanded={true} style={{backgroundColor:'transparent'}} disableGutters={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
                <Typography sx={{fontSize: {xs:'10px',md:'12px',lg:'13px',xl:'20px'}, fontFamily: 'Rubik' , color:'#08231B'}}>{commonReducer.event_details?.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{width:'100%', textAlign:'center', display:'grid', placeContent:'center', }} >
                   {(sortedFiles?.filter(video => video.event_name == commonReducer.event_details?.name)?.length == 0 && files?.length == 0 ) && <Typography px={15} sx={{color:'#878887', fontSize:{xs:'20px',sm:'24px',md:'28px',lg:'33.33px',xl:'50px'}, fontWeight: 300, fontFamily: "Rubik", marginTop:'2%', marginBottom:'2%'}} variant="h3">{commonReducer.event_details.status_type_order > 2 ? "No media file to show" : "Start uploading files to your Project."}</Typography>}
                </Box>

                {files?.length > 0 && files?.map((file, index) => (
                  <Box className={classes.tableRowData} key={file.size} sx={{display:'flex', alignItems:'center', height:{xs:'40px',md:'45px',xl:'63px'}}}>

                   <CustomTooltip title = {file?.name} >
                       <Box flex={6} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, minWidth:'220px'}} ><Typography sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:400, fontFamily: 'Rubik'}}>{removeExtension(file?.name)}</Typography></Box>
                   </CustomTooltip>

                   <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'90px'}} ><Typography textAlign='center'  sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:400,  fontFamily: 'Rubik'}}>{file?.type}</Typography></Box>

                    <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'60px'}}  ><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:400, fontFamily: 'Rubik'}}>{sizeConverter(parseInt(file?.size))}</Typography></Box>

                    <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'70px'}} ><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:400, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(Math.floor(Date.now()/1000))}</Typography></Box>

                    <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'60px'}} ><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:400, fontFamily: 'Rubik', wordBreak:'break-word'}}>{commonReducer.is_logged ? commonReducer.user_name : "Guest Cam"}</Typography></Box>

                    <Box flex={1}  sx={{display:'flex', justifyContent:'end', backgroundColor:'transparent', minWidth:'100px', }} ><Box textAlign='end' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik',width:'100%',display:'flex', justifyContent:'end', }}>{progressFlag? <CustomVideoLinearProgress
                       progress={progress[index]}
                       status={uploadStatus[index]}
                       stopUpload={() => stopUpload(index)}
                       fontSize = {12}
                       bar = {5}
          /> : iconButtonActions}</Box>
                    </Box>
                  </Box>
              ))}

              
              {sortedFiles?.length > 0 && sortedFiles?.filter(video => video.event_name == commonReducer.event_details?.name)?.map((file, index) => (
                <Box className={classes.tableRowData} key={file?.size + index} sx={{display:'flex',  alignItems:'center', height:{xs:'40px',md:'45px',xl:'63px'}}}>
               <Box sx={{width: '8px', height: '8px', borderRadius: 50, backgroundColor: file.viewed || bytesToMegabytes(parseInt(file?.size)) > 500 ? '#808080' : '#00FF00', mr: 1}}></Box>
                <Box>
                    <CustomTooltip title = {(file?.video_url == null || file?.video_url == "" || bytesToMegabytes(parseInt(file?.size)) > 500) ? "Preview not available" : file?.name} >
                       <Box flex={6} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, wordWrap:'break-word', minWidth:'220px'}}><Typography onClick={()=>hasPermission(PERMISSION.PROJECT.MEDIA.PLAYBACK.SELF) && viewMediaHandler(file)}  sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight: file.viewed || bytesToMegabytes(parseInt(file?.size)) > 500 ? 500 : 900 , wordWrap:'break-word', fontFamily: 'Rubik', cursor: (file?.video_url != null && file?.video_url != "") && 'pointer'}}>{removeExtension(file?.name)}</Typography>
                  
                       </Box>
                    </CustomTooltip> 
                    </Box>

                    <Box flex={0.7} style={{  minWidth:'90px'}}> <Typography textAlign='center' sx={{fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:file.viewed || bytesToMegabytes(parseInt(file?.size)) > 500? 500 : 900,  fontFamily: 'Rubik' }}>{fileFormat(file?.name)}</Typography></Box>

                    <Box flex={0.7} style={{  minWidth:'60px'}}> <Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:file.viewed || bytesToMegabytes(parseInt(file?.size)) > 500 ? 500 : 900, fontFamily: 'Rubik'}}>{sizeConverter(parseInt(file?.size))}</Typography></Box> 
              

                    <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:file.viewed || bytesToMegabytes(parseInt(file?.size)) > 500? 500 : 900, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(file?.upload_time)}</Typography></Box>

                    <Box flex={0.7} className='flex j-center' style={{ minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:file.viewed || bytesToMegabytes(parseInt(file?.size)) > 500 ? 500 : 900, fontFamily: 'Rubik', wordBreak:'break-word'}}>{file?.uploaded_by}</Typography></Box>


                    <Box flex={1}  sx={{ display:'flex', justifyContent:'end'}} className={classes.tableCell}><Box  sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, display:'flex', alignItems:'center',justifyContent:'end' }} gap={0.5}>
                  { commonReducer.event_details?.status_type_order < 3 &&
                       <> {((commonReducer.is_media_loading == true && clickType == 'dlt') || deleteMediaLoading == true) && fileId == file.id ?(
                              <CircularProgress    
                                sx={{
                                  height:'4px', width:'4px',
                                  margin:{xs:0.75,md:0.87,lg:1.1, xl:1.4},
                                  color:'#666'
                                  }}
                                size={14}
                                loading={(commonReducer.is_media_loading == true && clickType == 'dlt') || deleteMediaLoading == true}
                                disabled 
                              >    
                              </CircularProgress>
                             ):(
                           
                        hasPermission(PERMISSION.PROJECT.MEDIA.DELETE.SELF) && <IconButton  
                            disabled = {commonReducer.is_media_loading == true || deleteMediaLoading == true || addFavoriteMediaLoading == true }
                            onClick={()=>handleDelete(file.id)} sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}>
                            <DeleteIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>
                         </IconButton> 
                             )
                       }
                  
                             
                             {((commonReducer.is_media_loading == true && clickType == 'fvt')|| addFavoriteMediaLoading == true) && fileId == file.id ?(
                              <CircularProgress    
                                sx={{
                                  height:'4px', width:'4px',
                                  margin:{xs:0.75,md:0.87,lg:1.1, xl:1.4},
                                  color:'#666'
                                  }}
                                size={14}
                                loading={(commonReducer.is_media_loading == true && clickType == 'fvt') || addFavoriteMediaLoading == true}
                                disabled
                              >    
                              </CircularProgress>
                             ):(
                           
                          hasPermission(PERMISSION.PROJECT.MEDIA.FAVORITE.SELF) && <Checkbox 
                           sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'}},  zIndex:0}} 
                          //  onClick={()=>favoriteHandler(file)} 
                          onChange={(e)=>favoriteHandler(file, e)}
                           checked={file?.favourite}  
                           disabled = {commonReducer.is_media_loading == true || deleteMediaLoading == true || addFavoriteMediaLoading == true }
                           icon={<StarOutlineIcon sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},  '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>} 
                           checkedIcon={<StarIcon sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           } }}/>} 

                           />
                             )}
                           
                           </>
                               }
                          <CustomTooltip title= {(file?.video_url == null || file?.video_url == "") ? "Will be available for download after some time" : "Download"} >
                            {hasPermission(PERMISSION.PROJECT.MEDIA.DOWNLOAD.SELF) && <IconButton 
                              disabled={file?.video_url == null || file?.video_url == "" || commonReducer.is_media_loading == true || deleteMediaLoading == true || addFavoriteMediaLoading == true }  
                              onClick={()=>videoDownloadHandler(file)} 
                              sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, 
                                  '& .MuiSvgIcon-root':{fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}} 
                            >
                              <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},  '& .MuiSvgIcon-root':{fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}}/>
                            </IconButton>}
                          </CustomTooltip>
                    </Box></Box>
               </Box>
               ))}

                </AccordionDetails>
               </Accordion>}

               { hasPermission(PERMISSION.PROJECT.MEDIA.VIEW.IMPORTED) && importedFiles.map((project, index)=> ( 
                <Accordion style={{backgroundColor:'transparent'}} disableGutters={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography  sx={{fontSize:  {xs:'10px',md:'12px',lg:'13px',xl:'20px'}, fontFamily: 'Rubik' , color:'#08231B', textTransform:'capitalize'}}>{project[0].event_name}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                {project.map(file =>(
              <Box className={classes.tableRowData} key={file?.size} sx={{display:'flex',  alignItems:'center', height:{xs:'40px',md:'45px',xl:'63px'}}}>
                <Box>
                    <CustomTooltip title = {(file?.video_url == null || file?.video_url == "" || bytesToMegabytes(parseInt(file?.size)) > 500) ? "Preview not available" : file?.name} >
                       <Box flex={6} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, wordWrap:'break-word', minWidth:'220px'}}><Typography onClick={()=>hasPermission(PERMISSION.PROJECT.MEDIA.PLAYBACK.IMPORTED) &&  viewMediaHandler(file)}  sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:500, wordWrap:'break-word', fontFamily: 'Rubik', cursor: (file?.video_url != null && file?.video_url != "") && 'pointer'}}>{removeExtension(file?.name)}</Typography>
                  
                       </Box>
                    </CustomTooltip> 
                    </Box>

                    <Box flex={0.7} style={{  minWidth:'90px'}}> <Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:500, fontFamily: 'Rubik' }}>{fileFormat(file?.name)}</Typography></Box>

                    <Box flex={0.7} style={{  minWidth:'60px'}}> <Typography textAlign='center' sx={{fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:500, fontFamily: 'Rubik'}}>{sizeConverter(parseInt(file?.size))}</Typography></Box> 
              

                    <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:500, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(file?.upload_time)}</Typography></Box>

                    <Box flex={0.7} className='flex j-center' style={{ minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight:500, fontFamily: 'Rubik'}}>{file?.uploaded_by}</Typography></Box>

              

                    <Box flex={1}  sx={{ display:'flex', justifyContent:'end'}} className={classes.tableCell}><Box  sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, display:'flex', alignItems:'center',justifyContent:'end' }} gap={0.5}>
                  { commonReducer.event_details?.status_type_order < 3 &&
                       <>  
                       {((commonReducer.is_media_loading == true && clickType == 'dlt') || importFilesStatus == 'loading') && fileId == file.id ?(
                              <CircularProgress    
                                sx={{
                                  height:'4px', width:'4px',
                                  margin:{xs:0.75,md:0.87,lg:1.1, xl:1.4},
                                  color:'#666'
                                  }}
                                size={14}
                                loading={(commonReducer.is_media_loading == true && clickType == 'dlt') || importFilesStatus == 'loading'}
                                disabled
                              >    
                              </CircularProgress>
                             ):(
                           
                            <CustomTooltip title='Remove this file'>
                              {hasPermission(PERMISSION.PROJECT.MEDIA.DELETE.IMPORTED) && <IconButton  
                                disabled = {commonReducer.is_media_loading == true || deleteMediaLoading == true || addFavoriteMediaLoading == true }
                              onClick={()=>removeLinkedFileHandler(file.id)} sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}>
                                <LinkOffIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                                  fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}}/>
                              </IconButton> }
                         </CustomTooltip>
                             )
                       }
                         
                       {((commonReducer.is_media_loading == true && clickType == 'fvt')|| addFavoriteMediaLoading == true) && fileId == file.id ?(
                              <CircularProgress    
                                sx={{
                                  height:'4px', width:'4px',
                                  margin:{xs:0.75,md:0.87,lg:1.1, xl:1.4},
                                  color:'#666'
                                  }}
                                size={14}
                                loading={(commonReducer.is_media_loading == true && clickType == 'fvt') || addFavoriteMediaLoading == true}
                                disabled
                              >    
                              </CircularProgress>
                             ):(
                           
                          hasPermission(PERMISSION.PROJECT.MEDIA.FAVORITE.IMPORTED) &&  <Checkbox 
                           sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'}},  zIndex:0}} 
                          //  onClick={()=>favoriteHandler(file)} 
                          onChange={(e)=>favoriteHandler(file, e)}
                           checked={file?.favourite}  
                           disabled = {commonReducer.is_media_loading == true || deleteMediaLoading == true || addFavoriteMediaLoading == true }
                           icon={<StarOutlineIcon sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},  '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>} 
                           checkedIcon={<StarIcon sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           } }}/>} 

                           />
                             )}
                           </>
                               }
                               
                          <CustomTooltip title= {(file?.video_url == null || file?.video_url == "") ? "Will be available for download after some time" : "Download"} >
                           {hasPermission(PERMISSION.PROJECT.MEDIA.DOWNLOAD.IMPORTED) &&  <IconButton 
                              disabled={file?.video_url == null || file?.video_url == "" || commonReducer.is_media_loading == true || deleteMediaLoading == true || addFavoriteMediaLoading == true }  
                              onClick={()=>videoDownloadHandler(file)} 
                              sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, 
                                  '& .MuiSvgIcon-root':{fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}} 
                            >
                              <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},  '& .MuiSvgIcon-root':{fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}}/>
                            </IconButton>}
                          </CustomTooltip>
                    </Box>
                    </Box>
               </Box>
                ))}
               </AccordionDetails>
          </Accordion>
          ))}

          </>
      }


      {
        commonReducer.open_media_preview == true && <MediaPreview url={videoSrc} fileType={fileType}/>
      }
  </Box >
  </Box>
)
}

export default CustomVideoTable
















