import React from 'react'
import { Box, Button, Link, Typography } from "@mui/material"
import CustomButton from "../../../components/CustomButton"
// import { customTheme as theme } from "../../../theme/customTheme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import CustomCard from '../../events/components/CustomCard'
import { updateReviewState } from "../../../redux/reviewSlice"
import useAcceptFinalVideo from '../hooks/useAcceptFinalVideo'
import celebrationImage from "../../../assets/celebration.svg";
import { cleanFileName, downloadS3File } from '../../../utils/commonServices'
import { useNavigate } from 'react-router-dom/dist'
import usePermissions from '../../../hooks/usePermissions'
import { PERMISSION } from '../../../constants/constants'
import useCustomTheme from '../../../theme/useCustomTheme'
import { Celebration } from '@mui/icons-material'
import CelebrationImage from '../../events/components/assets/CelebrationImage'
import CustomTooltip from '../../../components/CustomTooltip'
import QRCode from 'qrcode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const FinalVideoCompletedCard = (props) => {
    const {eventPage} = props; 
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const reviewEventReducer = useSelector((state)=> state.reviewEventReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [acceptFinalVideoMutate ,finalVideoUrl , finalVideoLoading] = useAcceptFinalVideo()
    const [hasPermission] = usePermissions()
    const [theme ] = useCustomTheme()
    const [imageUrl, setImageUrl] = useState('')

    const [copyButtonText, setCopyButtonText] = useState("Share Video");

    const generateQRCode = async ()=>{
      try {
        const response = await QRCode.toDataURL(`${window.location.host}/watchvideo/${commonReducer?.event_details?.final_video_slug}`)
        setImageUrl(response)
      } catch (error) {
       
      }
    }
  
  
    useEffect(() => {
      generateQRCode() 
    }, [commonReducer.production_event_id,  ])
   
  
    const finalVideoDownloadHandler =  ()=>{
        downloadS3File(commonReducer.event_details?.final_video_download_url)
    }

    const copyClickHandler = () => {
      setCopyButtonText("Video Link Copied!")
      navigator.clipboard.writeText(`${window.location.host}/watchvideo/${commonReducer?.event_details?.final_video_slug}`)
      setTimeout(() => {
        setCopyButtonText("Share Video")
      }, 1500)
    }


    const handleImageDownload =()=>{
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = `${cleanFileName(commonReducer?.event_details?.name)}`;
      link.click();
    }
  



    return (
        <CustomCard  style={{height:'100%', }} >
        <Box sx={{display:'flex', height:'100%', width:'100%', justifyContent:'space-between', gap:4}}>
    
            <Box  gap={{xs:0,md:1}} sx={{display:'flex', flexDirection:'column', paddingLeft:{xs:'10px',sm:'20px',md:'30px',lg:'34px',xl:'44px'}, paddingTop:{xs:'12px',sm:'16px',md:'20px',lg:'27px', xl:'29px'}, paddingBottom:{xs:'12px',sm:'16px',md:'18px',lg:'25px', xl:'20px'},height:'100%' , justifyContent:'center', alignItems:'start', width:'60%'}}>
    
                <Typography mt={0.6} sx={{fontSize:{xs:'12px',sm:'13px',md:'13px',lg:'14px',xl:'20px'}, fontWeight:600, letterSpacing:0, fontFamily:'Rubik'}}>Congratulations! Your Lucivideo is complete.</Typography>
                  
                 <Typography sx={{fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, display:'inline', textDecoration:'none', width:'100%', fontFamily:'Rubik', letterSpacing:'-0.05px'}} mb={1}>Here is your final video. Enjoy!</Typography>

                 <Box className='flex'  gap={2}>

                 
                 {/* <CustomTooltip title='Only project owner is authorized' sx={{display:commonReducer.user_name == commonReducer.event_details?.owner_name && 'none' }}> */}
        
          {// hasPermission(PERMISSION.PROJECT.GUEST_INVITE) && 
            <CustomButton 
                  // btnText='Download your Lucivideo'
                  btnText={copyButtonText}
                 disabled={ !hasPermission(PERMISSION.PROJECT.REVISION.FINAL_VIDEO.DOWNLOAD)}
                  onClick={copyClickHandler}
                  endIcon={<ContentCopyIcon sx={{fontSize: '12px !important'}} />}
                  component='button'
                  color='secondary'
                  type='button'
                  variant='contained'
                  style={{
                  borderRadius: '5px',
                  border: "none",          
                  textTransform:'none',
                  }}
                  sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'135px',lg:'150px',xl:'230px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
                />
          }
          {/* <CustomButton 
            btnText={copyButtonText}
            type='button'
            onClick={copyClickHandler}
            color='secondary'
            variant="contained" 
            disabled={ !hasPermission(PERMISSION.PROJECT.GUEST_INVITE)}
              style={{
              borderRadius: '5px',
              border: "none",
              textTransform:'none',
              // backgroundColor:theme.palette.secondary.main
              }}
              sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'},  height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontWeight:600, width:{xs:'60px',sm:'70px',md:'80px',lg:'90px',xl:'123px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'} }}
            />} */}
          
            {/* </CustomTooltip> */}

                { eventPage ? 
                //hasPermission(PERMISSION.PROJECT.REVISION.FINAL_VIDEO.VIEW) && 
                <CustomButton 
                  btnText='Watch Now'
                  disabled = {!hasPermission(PERMISSION.PROJECT.REVISION.FINAL_VIDEO.VIEW)}
                  onClick={()=>navigate('/completedevent', {replace: true})} 
                  component='button'
                  type='button'
                  color='secondary'
                  variant='contained'
                  style={{
                  borderRadius: '5px',
                  border: "none",          
                  textTransform:'none',
                  }}
                  sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
                />
                : //hasPermission(PERMISSION.PROJECT.REVISION.FINAL_VIDEO.DOWNLOAD) && 
                <CustomButton 
                  btnText='Download'
                  disabled = {!hasPermission(PERMISSION.PROJECT.REVISION.FINAL_VIDEO.DOWNLOAD)}
                  onClick={finalVideoDownloadHandler} 
                  component='button'
                  color='secondary'
                  type='button'
                  variant='contained'
                  style={{
                  borderRadius: '5px',
                  border: "none",          
                  textTransform:'none',
                  }}
                  sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'230px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
                />}
            </Box>
          </Box>
    
            <Box  sx={{ display:'flex', alignItems:'center',  height:'100%' ,width:'40%', justifyContent:{xs:'flex-end !important',lg:'space-evenly !important'} }}  gap={{xs:0, lg:2}}>    
            {hasPermission(PERMISSION.PROJECT.REVISION.FINAL_VIDEO.DOWNLOAD) && (
              <CustomTooltip title = "Download QR" >
                 <Box className='flex j-center a-center' sx={{backgroundColor:'white',  boxShadow:'0px 5px 15px #00000029', borderRadius:'10px',height:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'137px'}, width:{xs:'70px',sm:'70px',md:'80px',lg:'90px',xl:'137px'},}} >
                  <Box onClick={handleImageDownload} sx={{height:{xs:'60px',sm:'60px',md:'70px',lg:'80px',xl:'127px'}, width:{xs:'60px',sm:'60px',md:'70px',lg:'80px',xl:'127px'}, cursor:'pointer',}}>
                    {imageUrl ? (  <img src={imageUrl} alt='Guest upload url' height='100%' width='100%' />  ): null}
                  </Box>   
                </Box>     
              </CustomTooltip>
        )}
              <Box sx={{width:'50%'}}>
                  <CelebrationImage sx={{height:'100%', width: '100%', display:{xs:'none', lg:'flex'}}} />
              </Box>
                
            </Box>
            </Box>
        </CustomCard>
      )
}

export default FinalVideoCompletedCard