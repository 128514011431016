import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import useCustomTheme from '../../../theme/useCustomTheme';

const CustomTextbox = (props) => {

    const { required, errorFlag, errorMsg, maxLength , rows, max, minValue, maxValue, lineHeight} = props;
    const [theme ] = useCustomTheme()
  return (
    <div style={{position:'relative'}}>
           <TextField 
              required
            fullWidth
           // txtStyle = {{height: '100%'}}
            multiline
            variant='outlined'
            value={props.value}
            placeholder={props.placeholder}
            rows={rows}
            rowsMax={10}
            size="small"
            style={{backgroundColor:'white', }}
           sx={{
            color:'#08231B',
                border:'1px solid #08231B',
                borderRadius:'5px',
                backgroundColor:'white',
                fontFamily: 'Rubik',
                marginBottom:0,
            '& .MuiInputBase-input': {
                        padding:0,
                        paddingX: '4px',
                        fontSize:  {xs:'10px',md:'11px',lg:'12px',xl:'16px'},
                        fontWeight: 600,
                        // color: theme.palette.inputs.main
                        lineHeight: !!lineHeight && lineHeight,
                        color:'#08231B',
                        "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            fontWeight: 600,
                    },
              },
           }}
        
            type="text"
           
            inputProps={{
            // fullWidth: true,
            maxLength: max && max,
            min: minValue && minValue,
            max: maxValue && maxValue,
          }}
            shrink

            {...props}
        
            /> 

      {required && errorFlag && (
          <Typography
            variant="body1"
            className="errorDom"
            component="span"
            sx={{
              fontFamily: 'Rubik',
              color: "rgb(211, 47, 47)",
              fontSize: {xs:'7px',lg:'9px',xl:'14px'},
              position:'absolute',
              right: 0,
              bottom: -8,
            //    paddingTop: 20,
            }}
          >
            <Box textAlign='right'>{errorMsg}</Box>
          </Typography>
        )}
    </div>
  )
}

export default CustomTextbox