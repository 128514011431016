import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    all_project_members:[
        // {id:1, name: 'Abc', role:'Owner'},
        // {id:2, name: 'Abc 1', role:'Contributor'},
        // {id:10, name: 'Abc 9', role:'Filmer'},
        // {id:6, name: 'Abc 5', role:'Contributor'},
        // {id:4, name: 'Abc 3', role:'Contributor'},
        // {id:3, name: 'Abc 2', role:'Contributor'},
        // {id:9, name: 'Abc 8', role:'Filmer'},
        // {id:11, name: 'Abc 10', role:'Filmer'},
        // {id:5, name: 'Abc 4', role:'Contributor'},
        // {id:7, name: 'Abc 6', role:'Contributor'},
        // {id:8, name: 'Abc 7', role:'Filmer'},
    ],
    invite_project_members_popup: false,
    view_project_members_popup: false,
    assign_owner_popup:false,
    remove_user_confirmation_popup:false,
    remove_owner_popup:false,
    selected_member: null,
    invite_contributor_flag:false,
    invite_filmer_flag:false,
    change_project_admin_flag: false,
    loading_state: 'noloading',
    prev_project_admin: null,
    is_loading: false,
    view_notes_to_editor_popup:false,
    upload_documents_to_editor_popup: false,
    uploaded_event_notes: [],
    delete_note_loading: false,
    signin_action_loader: true,
    upload_song_popup: false,
    song_desc_popup:false,
    uploaded_songs:[],
    delete_song_loading:false
}


export const projectSlice = createSlice({
    name: 'project',
    initialState: initialValue,
    reducers: {
        updateProjectState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetProjectState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateProjectState, resetProjectState } = projectSlice.actions
export default projectSlice.reducer