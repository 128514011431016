import React, { useState } from 'react'
import {FaLessThan, FaGreaterThan} from 'react-icons/fa'
import './calendar.css'
// import { customTheme as theme } from '../../../theme/customTheme'
import useCustomTheme from '../../../theme/useCustomTheme'

const Calendar = (props) => {

  const { finalDate, setFinalDate, setFinalValue, finalTime, hideLast, hideFuture } = props
  const daysArr = ["S", "M", "T", "W", "T", "F", "S"]
  const [theme ] = useCustomTheme()
  const [month, setMonth] = useState(finalDate.getMonth() + 1);
  const [year, setYear] = useState(finalDate.getFullYear());
  const [primaryColor, setPrimaryColor] = useState(`${theme.palette.primary.main}99`);
  const [primaryColorLight, setPrimaryColorLight] = useState(`${theme.palette.primary.main}70`);

  function getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  function getWeekdayOfFirstDay(month, year) {
    return new Date(year, month-1, 1).getDay();
  }

  function handlePrevMonthClick() {
    let newMonth = month - 1;
    let newYear = year;
    if (newMonth < 1) {
      newMonth = 12;
      newYear -= 1;
    }
    setMonth(newMonth);
    setYear(newYear);
  }

  function handleNextMonthClick() {
    let newMonth = month + 1;
    let newYear = year;
    if (newMonth > 12) {
      newMonth = 1;
      newYear += 1;
    }
    setMonth(newMonth);
    setYear(newYear);
  }
  
  // const handleDateChange = (event) => {
  //   let newDate = new Date(year, month-1, event.target.innerText);
  //   setFinalDate(newDate)
  // }
  const handleDateChange = (event) => {
    let newDate = new Date(year, month-1, event.target.innerText);
    setFinalDate(newDate)

    let tempValue = new Date(Date.parse(`${newDate.toDateString()}, ${finalTime}`))
    setFinalValue(tempValue.toLocaleString('en-US', {weekday: 'short', day: "2-digit", month: "2-digit", year: "numeric", hour: 'numeric', hour12: true, minute:'numeric'}))
  }

  const daysInMonth = getDaysInMonth(month, year);
  const firstDayWeekday = getWeekdayOfFirstDay(month, year);
  const rows = [];
  let cells = [];
  let day = 1;
  for (let i = 0; i < 6; i++) {
    for (let j = 0; j < 7; j++) {
      if (i === 0 && j < firstDayWeekday) {
        let date = getDaysInMonth(month-1, year) - firstDayWeekday + j + 1
        cells.push(<div key={`pre_${j}`} className={`calendar-column prev-month ${new Date().toDateString() == new Date(year, month-2, date ).toDateString ? "current-date" : ""}`}>{date}</div>);
      } else if (day > daysInMonth) {
          cells.push(<div key={`current_${j + 7*i}`} className={`calendar-column next-month ${new Date().toDateString() == new Date(year, month, day - daysInMonth ).toDateString() ? "current-date" : ""}`}>{day - daysInMonth}</div>);
          day++;
      } else {
        cells.push(<div style={{'--primary-color': primaryColor, '--primary-light': primaryColorLight}} key={`next_${j + 7*i}`} className={ `calendar-column current-month ${finalDate.toDateString() == new Date(year, month-1, day).toDateString() ? "selected-date" : ""} ${new Date().toDateString() == new Date(year, month-1, day).toDateString() ? "current-date" : ""}`} onClick={(event) => handleDateChange(event)} >{day}</div>);
        day++;
      }
    }
    rows.push(<div className="calendar-rows" key={`row_${i}`}>{cells}</div>);
    cells = [];
    if (day > daysInMonth) {
      break;
    }
  }

  return (
    <div className="calendar">
      <div className='calendar_header'>
        <div className='left_icon inline'>
          <button className="header-icon" onClick={() => handlePrevMonthClick()} disabled={new Date().getMonth() == month-1 && new Date().getFullYear() === year && hideLast}>
              <FaLessThan/> 
          </button>
        </div>
        <div className='header_info inline'>
        {new Date(year, month-1).toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <div className='right_icon inline'>
          <button className="header-icon" onClick={() => handleNextMonthClick()} disabled={new Date().getMonth() == month-1 && new Date().getFullYear() === year && hideFuture}>
              <FaGreaterThan/>
          </button>
        </div>
      </div>
      <div className='day-date'>
        <div className="days">
          {daysArr.map((day, i) =>{
            return <div key={`list_${i}`} className='weekdays'>{day}</div>
          })}
        </div>
        <div className='dates'>
          {rows}
        </div>
      </div>
    </div>
  )
}

export default Calendar