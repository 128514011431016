import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '28px'
    // "& .MuiButton-root": {
    //     borderWidth: 2,
    //     fontSize: 20,
    //     fontWeight: 600,
    //     borderRadius: 50,
    //     textOverflow: "ellipsis",
    //     whiteSpace: "nowrap",
    //     overflow: "hidden",
    //   //  textAlign: "left",
    //     display: "block"
    //  }
    //textTransform: 'uppercase', //with prop btnStyle
  },
}));

const CustomButton = (props) => {
  const { btnText, btnStyle } = props;
  const classes = useStyles();
  return (
    <Button
      className={classNames(classes.root, props.newClassName)}
      fullWidth
      style={btnStyle}
      {...props}
    >
      {btnText}
    </Button>
  );
};

export default CustomButton;
