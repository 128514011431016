import { timeStampToNumberedDateString } from "../../../utils/commonServices";


export default (filesArray, sortBy) => {
    
  
    let sortedFiles

   
    if (sortBy == 'file_name'){
      const sorted = [...filesArray].sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
   );
   sortedFiles = sorted
 
    }

    if (sortBy == 'original_project_name'){
      const sorted = [...filesArray].sort((a, b) =>
      a.event_name.toLowerCase() > b.event_name.toLowerCase() ? 1 : -1,
   );
   sortedFiles = sorted
 
    }

    if (sortBy == 'date_added'){
      const sorted = [...filesArray].sort((a, b) => new Date(timeStampToNumberedDateString(a.upload_time)) -  new Date(timeStampToNumberedDateString(b.upload_time)) );
   sortedFiles = sorted
   
    }

    if (sortBy == 'file_size'){
      const sorted = [...filesArray].sort((a, b) => a.size -  b.size );
      sortedFiles = sorted
   
    }
    
if(sortBy == 'favourites'){
    const sortedData = [...filesArray].sort((a, b) => {
      // Items that are favored come before non-favored items
      if (a.favourite && !b.favourite) {
        return -1; // a is favored over b
      } else if (!a.favourite && b.favourite) {
        return 1; // b is favored over a
      } else {
        return 0; // keep the order as it is
      }
    });
    sortedFiles = sortedData
   
  }

  // if(sortBy == 'file_type'){

  //   const sortedData = [...filesArray].sort((a, b) => {
  //     if (a.content_type < b.content_type) {
  //       return -1;
  //     }
  //     if (a.content_type > b.content_type) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  //   sortedFiles = sortedData
  // }


  if (sortBy === 'file_type') {
    const sortedData = [...filesArray].sort((a, b) => {
      const extA = a?.name?.split('.').pop().toLowerCase();
      const extB = b?.name?.split('.').pop().toLowerCase();

      if (extA < extB) {
        return -1;
      }
      if (extA > extB) {
        return 1;
      }
      return 0;
    });

    sortedFiles = sortedData;
  }

  if(sortBy == 'uploaded_by'){

    const sortedData = [...filesArray].sort((a, b) => {
      if (a.uploaded_by < b.uploaded_by) {
        return -1;
      }
      if (a.uploaded_by > b.uploaded_by) {
        return 1;
      }
      return 0;
    });
    sortedFiles = sortedData
  }



    if(sortBy == "" || sortBy == null){
      sortedFiles = [...filesArray]
    }

  
    return [sortedFiles];
  };