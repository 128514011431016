export const genres = [
    {id:1, value: "Birthday"},
    {id:2, value: "Sporting Event"},
    {id:3, value: "Engagement Party"},
    {id:4, value: "Wedding"},
    {id:5, value: "Graduation"},
    {id:6, value: "Other"},

]

export const tones = [
    {id:1, value: "EXCITING"},
    {id:2, value: "RELAXING"},
    {id:3, value: "JOYFUL"},
    {id:4, value: "SOMBER"},
    {id:5, value: "ADVENTUROUS"},
    {id:6, value: "HOLIDAYS"},
    {id:7, value: "FESTIVE"},
    {id:8, value: "COUNTRY"},
    {id:9, value: "DISCO"},
    {id:10, value: "FAST"},
    {id:11, value: "HIP-HOP"},
    {id:12, value: "DRAMATIC"},
    {id:13, value: "ROCK & ROLL"},
    {id:14, value: "GLAM"},
    {id:15, value: "CHILL"},
]

export const userRanges = [
    {id:1, value: "1 - 2"},
    {id:2, value: "3 - 5"},
    {id:3, value: "6 - 8"},
  //  {id:4, value: "8+"},
]

export const uploads = []

export const sortByOptions = [
    { value: 'date_added', label: 'Date Added' },
    { value: 'file_name', label: 'File Name' },
    { value: 'favourites', label: 'Favourites' },
    {value: 'file_size', label:'File Size'},
    {value: 'file_type', label:'File Type'},
    {value: 'uploaded_by', label:'Uploaded By'},
  ];
  export const eventSortOptions = [
    { value: 'date_added', label: 'Date Added' },
    // { value: 'start_dates', label: 'Start Dates' },
    { value: 'end_dates', label: 'Editing Dates' },
    {value: 'a_z', label:'A-Z'}
  ];

  export const mediaFilesSortOptions = [
    { value: 'file_name', label: 'File Name' },
    { value: 'date_added', label: 'Date Added' },
    {value: 'file_type', label:'File Type'},
    { value: 'favourites', label: 'Favourites' },
    {value: 'original_project_name', label:'Original Project Name'}
  ];

  export const mediaFilesPopupSortOptions = [
    { value: 'file_name', label: 'File Name' },
    { value: 'date_added', label: 'Date Added' },
    {value: 'file_type', label:'File Type'},
    { value: 'favourites', label: 'Favourites' },
    {value: 'file_size', label:'File Size'}
  ];

  export const event_details = {
    id: 1,
    name: "Final Test",
    collab_code: "RIJZ",
    collaborators: [],
    event_type: null,
    owner_name: "Azeem",
    start_date: 1671470159,
    end_date: 1671492501,
    editor_start_date: null,
    status_type: "Post Production",
    status_type_order: 3,
    consent_accepted: false,
    final_video_download_url: null,
    final_video_id: null,
    final_video_url: null,
    revision: {
        id: null,
        submitted: null
    },
    revision_left: 1
  }

  export const exampleFiles = [
    { id: 1, name: 'file1.txt', uploadedBy: 'John', fileSize: '10 KB', fileType: 'Text', uploadDate: '2023-05-14', projectName: 'Product Launching', favourite: false, checked: true },
    { id: 2, name: 'file2.docx', uploadedBy: 'Alice', fileSize: '25 KB', fileType: 'Document', uploadDate: '2023-05-15', projectName: 'Marketing Campaign', favourite: true, checked: false },
    { id: 3, name: 'file3.jpg', uploadedBy: 'Bob', fileSize: '500 KB', fileType: 'Image', uploadDate: '2023-05-13', projectName: 'Website Redesign', favourite: false, checked: true },
    { id: 4, name: 'file4.xlsx', uploadedBy: 'Emma', fileSize: '50 KB', fileType: 'Spreadsheet', uploadDate: '2023-05-12', projectName: 'Sales Analysis', favourite: true, checked: false },
    { id: 5, name: 'file5.pptx', uploadedBy: 'Sarah', fileSize: '100 KB', fileType: 'Presentation', uploadDate: '2023-05-11', projectName: 'Quarterly Meeting', favourite: true, checked: true },
    { id: 6, name: 'file6.pdf', uploadedBy: 'Michael', fileSize: '2 MB', fileType: 'PDF', uploadDate: '2023-05-10', projectName: 'Research Report', favourite: false, checked: false },
    { id: 7, name: 'file7.csv', uploadedBy: 'David', fileSize: '1 KB', fileType: 'CSV', uploadDate: '2023-05-09', projectName: 'Data Analysis', favourite: true, checked: false },
    { id: 8, name: 'file8.mp4', uploadedBy: 'Sophia', fileSize: '15 MB', fileType: 'Video', uploadDate: '2023-05-08', projectName: 'Training Session', favourite: true, checked: true },

  ];


  export const uploadedfiles = [
    
     
       {
        id:12,
        projectName: 'Project 1',
        mediaFiles: [
    
          {
            fileName: 'file3.txt',
            uploadDate: '2023-05-14',
            size: '10 KB',
            fileType: 'Text',
            uploadedBy: 'John',
            favourite: true
          },
          {
            fileName: 'file4.png',
            uploadDate: '2023-05-15',
            size: '100 KB',
            fileType: 'Image',
            uploadedBy: 'Alice',
            favourite: false
          },
        ]
      }
    ,

    {
      id:63,
      projectName: 'Project 2',
      mediaFiles: [
        {
          fileName: 'file1.docx',
          uploadDate: '2023-05-16',
          size: '50 KB',
          fileType: 'Document',
          uploadedBy: 'Bob',
          favourite: true
        },
        {
          fileName: 'file2.pdf',
          uploadDate: '2023-05-17',
          size: '500 KB',
          fileType: 'PDF',
          uploadedBy: 'Emma',
          favourite: true
        },
        {
          fileName: 'file3.png',
          uploadDate: '2023-05-15',
          size: '100 KB',
          fileType: 'Image',
          uploadedBy: 'Alice',
          favourite: false
        },
        {
          fileName: 'file4.txt',
          uploadDate: '2023-05-14',
          size: '10 KB',
          fileType: 'Text',
          uploadedBy: 'John',
          favourite: true
        },
        {
          fileName: 'file5.png',
          uploadDate: '2023-05-15',
          size: '100 KB',
          fileType: 'Image',
          uploadedBy: 'Alice',
          favourite: false
        },
      ]
    
  },
  ];

  export const colors = ['red', 'green', 'black', 'blue', 'grey', 'orange'];

  export const BRANDBOX_OPTIONS = [
    {id:1, value: 'Colour Palettes'},
    {id:2, value:'Fonts'},
    {id:3, value:'Image'},
    {id:4 , value: 'Logo'},
    {id:5, value:'Videos'},
    {id:6, value:'Others'}
  ]