import React from 'react'
import { Avatar, Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Input, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import { makeStyles } from "@mui/styles";
// import { customTheme as theme } from "../../../theme/customTheme";
import { updateState } from '../../../redux/commonSlice';
import { matchIsValidColor, MuiColorInput } from 'mui-color-input'
import { CustomLogoUploader } from './CustomLogoUploader';
import luciLogo from '../../../assets/luciLogo2.png'
import { createTheme, getTheme, removeTheme, updateTheme } from '../actions';
import { generateLighterContrastColor } from '../../../utils/commonServices';
import { setSnackbar } from '../../../redux/messageSlice';
import { LoadingButton } from '@mui/lab';
import useCustomTheme from '../../../theme/useCustomTheme';



const useStyles = makeStyles((theme) => ({

  expanded: {
    width:'60%'
  }
}));



const SelectThemePopup = (props) => {
    const {  options} = props
   
    const dispatch = useDispatch()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const themeReducer = useSelector((state)=>state.root.themeReducer);
    const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
    const [theme ] = useCustomTheme()

    const [primaryColor, setPrimaryColor] = useState(theme.palette.primary.main.toUpperCase())
    const [secondaryColor, setSecondaryColor] = useState(theme.palette.secondary.main.toUpperCase())
    const [brandLogo, setBrandLogo] = useState(null)
    const [image, setImage] = useState(luciLogo);
  
    // const [defaultImage, setDefaultImage] = useState(window.localStorage.getItem('brand_logo_url') !== null ? window.localStorage.getItem('brand_logo_url') : luciLogo)
    const [defaultImage, setDefaultImage] = useState(  themeReducer?.brand_logo !== null ?   themeReducer?.brand_logo : luciLogo)
    const [resetThemeChecked, setResetThemeChecked] = useState(false)
  
    const [error, setError] = useState({
      flag: false,
      message: ''
    })
    const [isLogo, setIsLogo] = useState({
      path:'',
      flag: false
    })

    useEffect(()=>{
      getTheme(dispatch)
    },[])

    useEffect(()=>{
     setPrimaryColor(theme.palette.primary.main.toUpperCase())
     setSecondaryColor(theme.palette.secondary.main.toUpperCase())
     setDefaultImage(themeReducer?.brand_logo !== null ?   themeReducer?.brand_logo : luciLogo)
    },[themeReducer])

//  console.log("get theme", themeReducer.get_call_pending)

    const originalTheme = {
      primaryColor:  '#00D744',
      secondaryColor: "#00CeFF",
      logo: luciLogo
    }

    // useEffect(()=>{
    //   if(resetThemeChecked){
    //       setPrimaryColor(originalTheme.primaryColor)
    //       setSecondaryColor(originalTheme.secondaryColor)
    //       setDefaultImage(luciLogo)
    //   }
    // },[resetThemeChecked])
   

    const handleClose = () => {
         dispatch(updateState({change_theme_popup : false}))      
    };

    const setThemeClickHandler =()=>{
      let payload = {
        primary_colour : primaryColor ,
        secondary_colour : secondaryColor ,
        logo: brandLogo
      }
      if(resetThemeChecked){
        removeTheme(payload, dispatch, handleClose)
        // console.log('reset theme api-------------')
      }else{
        if((themeReducer?.primary === null) && (themeReducer?.secondary === null) && (  themeReducer?.brand_logo === null)){
          //create theme api
          brandLogo !== null ? createTheme(payload,dispatch, handleClose) :  dispatch(setSnackbar({type:'error', flag:true, message: 'Please select a logo'}))
          
          // console.log('create theme api------')
        }else{
          //update theme api
          brandLogo !== null ?  updateTheme(payload, dispatch, handleClose) :  updateTheme({primary_colour : primaryColor , secondary_colour : secondaryColor }, dispatch, handleClose)
          // updateTheme(payload, dispatch)
          // console.log('update theme api------')
        }
      }


      // console.log(image, 'image----cropped')
      // console.log(primaryColor, 'primarycolor')
      // console.log(secondaryColor,  'secondary color')
      // localStorage.setItem("primary", primaryColor);
      // localStorage.setItem("secondary", secondaryColor);
      // localStorage.setItem('brand_logo_url', brandLogo)
      // window.location.reload()
      // handleClose()
    }

    const onLogoSubmit = (base64, binary) => {
// setImage(binary)
      // console.log(binary, 'binary in select theme on logo submit')
      setBrandLogo(binary)
      // setBrandLogo(base64)
      // console.log(base64, 'base64 onlogo submit')
  };

  const onCheckboxChange =(e)=>{
    setIsLogo(prev => ({...prev, flag: false}))
    setResetThemeChecked(e.target.checked)
    if(e.target.checked === false ){
      setPrimaryColor(themeReducer?.primary)
      setSecondaryColor(themeReducer?.secondary)
      setDefaultImage(themeReducer?.brand_logo)
    }else{
      setPrimaryColor(originalTheme.primaryColor)
      setSecondaryColor(originalTheme.secondaryColor)
      setDefaultImage(luciLogo)
    }
  }

  const setPrimaryColorHandler = (newValue)=>{
      setPrimaryColor(newValue)
  }

  const setSecondaryColorHandler = (newValue)=>{
    setSecondaryColor(newValue)
  }


    return (
        <CustomAlert 
          open={commonReducer.change_theme_popup} 
          handleClose={handleClose} 
          height={{xs:'420px', md:'75%',lg:'70%',xl:'600px'}}
          width={{md:'65%',lg:'65%', xl:'1400px'}}
          >       
            <Box sx={{display:'flex',  height:'100%', width:'100%'}} px={4} pt={3} pb={2} >
           

             <Box  pl={2} sx={{  height:'100%', width:'80%',}}>
                <Box className='flex a-center j-center' sx={{ width:'300px', height:'200px',}}>
                  <CustomLogoUploader 
                   imageWrapperHeight="110px" 
                   imageWrapperWidth="110px" 
                   onSubmitImage={onLogoSubmit} 
                   isLogo={isLogo} 
                   setIsLogo={setIsLogo} 
                   image={image}
                   setImage={setImage}
                   defaultImage={defaultImage}
                  />
                </Box>
             </Box>
             <Box pr={2} sx={{  height:'100%', width:'100%', display:'flex', flexDirection:'column',justifyContent:'space-between'}} gap={1}>
                <Box className='flex col' gap={2} mt={5}>
                <Box className='flex row a-center' sx={{justifyContent:'flex-start'}} gap={1}>
                    <Typography sx={{minWidth:'170px'}}>Primary Color: </Typography>
                    <MuiColorInput   inputProps={{ maxLength: 7}}  isAlphaHidden={true}   value={primaryColor} onChange={setPrimaryColorHandler} format='hex' sx={{width:'100%'}} />
                </Box>
                <Box className='flex row a-center ' sx={{justifyContent:'flex-start'}} gap={1}>
                    <Typography sx={{minWidth:'170px'}}>Secondary Color: </Typography>

                    <MuiColorInput  inputProps={{ maxLength: 7}} isAlphaHidden={true} sx={{width:'100%'}} format="hex"  value={secondaryColor} onChange={setSecondaryColorHandler} />

                </Box>
                  <FormGroup>
                      <FormControlLabel control={<Checkbox disabled={themeReducer?.primary ===  null && themeReducer?.secondary == null} inputProps={{ 'aria-label': 'controlled' }}  size='small' checked={resetThemeChecked} onChange={onCheckboxChange}/>} label="Reset Theme" />
                  </FormGroup>
                </Box>
                <Box  alignSelf={'end'} >
                   

                    { commonReducer?.theme_loading ?  <LoadingButton
                   // size="small"
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color:  theme.palette.primary.main,
                        },
                        height: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B',
                        mb:'10px'
                    }}
                    loading={commonReducer?.theme_loading }
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> :
                 <IconButton sx={{borderRadius: '2px', padding: 0}} disabled = {themeReducer.get_call_pending} onClick= {setThemeClickHandler}  > <CheckIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)}   sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer', opacity: themeReducer.get_call_pending ? 0.7 : 1 }}/></IconButton>
               }
                </Box>
             </Box>
            </Box>
       
        </CustomAlert>
      )
}

export default SelectThemePopup