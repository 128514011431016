import { Box, TextField, Typography, getContrastRatio } from "@mui/material";
import CustomAlert from "../../events/components/CustomAlert"
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import { generateLighterContrastColor} from "../../../utils/commonServices";
import useCustomTheme from "../../../theme/useCustomTheme";
import { updateProjectState } from "../../../redux/projectSlice";
import CustomButton from "../../../components/CustomButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { PERMISSION } from "../../../constants/constants";
import usePermissions from "../../../hooks/usePermissions";
import {  useEffect, useState } from "react";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { getEventDetails, updateEventSongText } from "../actions";
import { LoadingButton } from "@mui/lab";
import { getTeamEvents } from "../../teamsdashboard/actions";
import { getAllEvents } from "../../dashboard/actions";




const ViewSongDescriptionPopup = ({event}) => {
    const dispatch = useDispatch()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const projectReducer = useSelector((state) => state.projectReducer);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const [theme] = useCustomTheme()
    // const inputRef = useRef(null);
    const [hasPermission] = usePermissions()
    const [editMode, setEditMode] = useState(false)
    const [songType, setSongType] = useState(commonReducer?.event_details?.song_type)
  
 


    const handleClose = () => {
      dispatch(updateProjectState({song_desc_popup : false}))      
    };

    const handleFocus = (event) => {
      const length = event.target.value?.trim()?.length;
      event.target.setSelectionRange(length, length);
    };


    const convertToEditMode = ()=>{
      setEditMode(true)
    }

    const saveSongDescription =()=>{
     
      const payload = {
        id: commonReducer?.production_event_id,
        song_type: songType
      }
      console.log({payload})
      updateEventSongText(payload, dispatch, convertToViewMode)
    //   updateNotesToEditor(payload, dispatch, callback)
    }

    const convertToViewMode = ()=>{
      setEditMode(false)
    }

    const callback =()=>{
      !!teamsReducer.selected_team ?  getTeamEvents({  team_id: teamsReducer?.selected_team?.id}, dispatch) :    getAllEvents(dispatch);
      convertToViewMode()
    }


    useEffect(() => {
      const handleKeyDown = (e) => {
        e.stopPropagation(); 
        if (e.key === ' ' || e.key === 'f' || e.key ===  'arrowright' || e.key === 'k'  || e.key === 'm'  || e.key === 'arrowleft') {
          // e.preventDefault(); 
        }
      };
  
      if (projectReducer.song_desc_popup) {
        document.addEventListener('keydown', handleKeyDown, true);
      }
  
      return () => {
        document.removeEventListener('keydown', handleKeyDown, true);
      };
    }, [projectReducer.song_desc_popup]);


    return (
        <CustomAlert 
          open={projectReducer.song_desc_popup} 
          handleClose={handleClose} 
          height={{xs:'550px',md:'520px',lg:'530px' ,xl:'740px'}}
          // height={{xs:'550px',md:'610px',lg:'605px' ,xl:'700px'}}
          width={{xs:'600px',md:'700px',lg:'740px',xl:'1130px'}}
          >       
            <Box sx={{display:'flex', flexDirection:'column', height:'100%'}} px={4} pt={{xs:5,sm:3, md:4, lg:5, xl:6}} pb={2}>
          
                 
                  <Box className='flex col' gap={{xs:0.5, lg:1}}  sx={{ height:'100%'}}>
                    <Box className='flex j-between a-center' mb={2} style={{width:{xs:'90%', md:'80%'}}}>
                      <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'#000', fontSize:{xs:'14px',sm:'14px',md:'16px',lg:'20px',xl:'30px'}}}>Music Notes</Typography>
                      <Box className='flex a-center j-end' gap={2}>
                      {(editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) &&
                        <CustomButton 
                        btnText={'Cancel'}
                        onClick={convertToViewMode}
                        type='button'    
                        color='secondary'
                        variant='outlined'
                        disabled={ commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null}
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                        }}
                        sx={{fontSize:{xs:'9px',sm:'10px',lg:'12px',xl:'18px'},  fontWeight:600, height:{xs:'22px',sm:'24px',lg:'28px',xl:"40px"}, width: '100%', maxWidth: {xs:'120px', xl:'180px'} , fontFamily:'Rubik'}}
                      />
                      }
                      {( hasPermission(PERMISSION.PROJECT.INFO.EDIT) &&   ((commonReducer?.event_details?.status_type_order < 3 || ( commonReducer.event_details?.revision?.submitted == false && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id)) ) ) &&  <LoadingButton
                        onClick={(editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) ? saveSongDescription : convertToEditMode}
                        type='button'    
                        color='secondary'
                        variant='contained'
                        loading={projectReducer?.is_loading}
                        loadingPosition="end"
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                        }}
                       // disabled={editMode && songType?.length  === 0}
                        endIcon={(editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) ? <SaveOutlinedIcon style={{fontSize:'15px'}} /> : <EditOutlinedIcon style={{fontSize:'15px'}} />}
                        sx={{fontSize:{xs:'9px',sm:'10px',lg:'12px',xl:'18px'},  fontWeight:600, height:{xs:'22px',sm:'24px',lg:'28px',xl:"40px"}, width: '100%', maxWidth: {xs:'120px', xl:'180px'} , fontFamily:'Rubik'}}
                      >{(editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) ? 'Save' : 'Edit'}</LoadingButton>
                      }

                      </Box>
                      
                    </Box>
        

                       
                        <Box className='flex' flex={1} sx={{ overflowY:'auto', }} >
                        {(editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) ?   <TextField
                            sx={{mt:0.5}}
                            id="outlined-multiline-static"
                            autoFocus
                            fullWidth
                            multiline
                            // minRows={2}
                            rows={9}
                            // maxRows={5}
                            inputProps={{
                              maxLength: 500
                            }}
                            placeholder="Enter track name, mood, genre..."
                            onFocus={handleFocus}
                            defaultValue={commonReducer?.event_details?.song_type}
                        onChange={(event) => setSongType(  songType != ""
                          ? event.target.value
                          : event.target.value.trim())}
                        value={songType}
                            
                             /> : (
                                <Typography style={{ whiteSpace: "pre-line", wordBreak:'break-all' }}   sx={{mt:0.5}}>{commonReducer?.event_details?.song_type}</Typography>
                             )}
                           
                         
                          
                           
                  
                        </Box>
                        <Box  className='flex j-end a-end'  mt={1}>
                           <CheckIcon onClick={!(editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) ? handleClose: null}  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)}  sx={{fontSize:{xs:'28px',sm:'30px',md:'42px', lg:'56px',xl:'75px'}, cursor: !(editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) && 'pointer', opacity: (editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) && 0.6 }}/>
                        </Box>
                  </Box>
  
             
          </Box>
       
        </CustomAlert>
      )
}

export default ViewSongDescriptionPopup
