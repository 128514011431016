import CustomCard from "./CustomCard"
import filmingTutorialImage from "../../../assets/filmingTutorialCard.png"
import { Box } from "@mui/material"

const FilmingTutorialCard = (props) => {
  const {clickable} = props;
  return (
    <CustomCard {...props} sx={{ height:'100%'}}>
    <Box className="rubik" textAlign='center' m={0} p={0} sx={{height:'100%', cursor: clickable && 'pointer' }}>
       <img src={filmingTutorialImage} height='100%' style={{objectFit:'cover', borderRadius:'5px'}} width='100%'/>
    </Box>
</CustomCard>
  )
}

export default FilmingTutorialCard
