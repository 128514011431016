import { Backdrop, Box, Slide, Typography, getContrastRatio } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import CustomInput from './CustomInput';
import { resetCreationState, updateCreationState } from '../../../redux/eventCreationSlice';
import { afterValidate, bytesToMegabytes, generateLighterContrastColor, getFileType, setInput } from '../../../utils/commonServices';
import { useEffect } from 'react';
import useEventList from '../../dashboard/hooks/useEventList';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { updateState } from '../../../redux/commonSlice';
import { useNavigate } from 'react-router-dom';
import UpIcon from '../icons/UpIcon';
import DownIcon from '../icons/DownIcon';
// import { customTheme as theme } from '../../../theme/customTheme';
import useCustomTheme from '../../../theme/useCustomTheme';

const NotesToEditorPage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex, selectedProjectType, setSelectedProjectType, totalScreens, requestStatus, submitRequest, closeHandler} = props
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const dispatch = useDispatch()
    // const [refetchEvents] = useEventList();
    const [fileFormatError, setFileFormatError] = useState(false)
    const [notesErrorMessage, setNotesErrorMessage] = useState('')
    const navigate = useNavigate()
    const [theme ] = useCustomTheme()

    const finishedHandler = ()=> {
      afterValidate(requestCallback);
    }

    useEffect(()=> {
      const date = new Date()
      // refetchEvents();
      setPageData((last) => {
        return { ...last, time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone , start_date: Math.floor(Date.now()/1000)};
      });
    },[])

    useEffect(()=> {
        if( requestStatus === 'success' ) {
            dispatch(updateState({selected_event: commonReducer.events.find((e)=> e.id == commonReducer.production_event_id) }))
            dispatch(resetCreationState());
            navigate('/productionevent', {replace: true})
        }
    },[requestStatus])

    const requestCallback= async ()=>{
      dispatch(updateCreationState({open_backdrop_project_creation: false, create_project_backdrop_loader: true}))
      if(createEventReducer.edit == true && createEventReducer.event_id != null) {
        await submitRequest({id: createEventReducer.event_id, param: pageData})
      } else{
        await submitRequest({...pageData, team_id: teamsReducer?.selected_team ? teamsReducer?.selected_team?.id : ''});
      }
    }

    const uploadNotesHandler = (event, newValue) => {
      if(!event.target.files[0]?.type?.includes('application') || (getFileType(event.target.files[0].name) === 'unknown')){
        setFileFormatError(true)
        setNotesErrorMessage('Please upload file in valid format. (pdf, doc, docx, rtf)')
      }else if(bytesToMegabytes(event.target.files[0].size) >= 100){
        // console.log(bytesToMegabytes(event.target.files[0].size), 'file size------------------')
        setFileFormatError(true)
        setNotesErrorMessage('File size should be less than 100 Mb')
      }else{
        setFileFormatError(false)
        setPageData((last) => {
          return { ...last, note: event.target.files[0]?.type?.includes('application') ? event.target.files[0] : null };
        });
      }
  }

    useEffect(() => {
      const keyDownHandler = (event) => {
        // console.log("key Parent", event.key)
        if (event.key === "Enter") {
          event.preventDefault();
  
          finishedHandler();
        }
      };
        document.addEventListener("keydown", keyDownHandler)
        
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [pageData]);

  return (
    <>
    <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(23px) !important', display:'flex', flexDirection:'column', background: '#08231B99 0% 0% no-repeat padding-box', }}
    open={createEventReducer.open_backdrop_project_creation}
    >
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
          <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3, fontFamily:'Rubik'}} onClick={closeHandler}>Close X</Typography>
          <Box flexGrow={1} className='flex col ' sx={{ paddingLeft:'14vw', marginTop:{xs:'20%',sm:'14%',md:'8%', lg:'7%'}}}>
              <Box mb={6}>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>Notes to your Lucihub Editing Team.</Typography>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>What should your editor know about your story, key people, locations, or shots to look out for? Your Lucihub Brand Manager will reach out to review your project with any questions.</Typography>
              </Box>
              <Box className='flex' sx={{width:'80%'}}>
              <CustomInput
                // id= {errorObjectArray[0]?.id}
                // onFocus={() =>{ setNameValidationFlag(false); setTimeout(()=>{setNametooltip(true)},[1500]) }}
                // onBlur={() => setNameValidationFlag(true)}
                type='sendInput'
                required
                medium
                multiline={true}
                // errorFlag={
                //   nameValidationFlag &&
                //   getErrorMsg("project_name", pageData?.name) != ""
                // }
                // errorMsg={getErrorMsg("project_name", pageData?.name)}
                minRows={2}
                            // rows={2}
                maxRows={8}
                fullWidth
                max={500}
                placeholder="Please type your detailed notes here..."
                onChange={(event) => setInput(event.target.value , "description", pageData, setPageData)}
                value={pageData?.description}
                onButtonClick={ finishedHandler}
               />
              </Box>
              <Box mt={1} sx={{marginBottom:{xs:'10px',sm:'15px',md:'15px',lg:'20px',xl:'57px'}}}>
              <Typography textAlign={'left'} variant='body1' sx={{fontSize:{xs:'13px',lg:'15px',xl:'20px'}, fontWeight:600, fontFamily:'Rubik', color:'white'}} >Upload materials using the <Typography textAlign={'left'} component='span' variant='body1' sx={{fontSize:{xs:'13px',lg:'15px',xl:'20px'}, fontWeight:900, fontFamily:'Rubik', color: 'white'}} >'My Documents'</Typography> button within the project</Typography>
              </Box>          

              {/* <Box sx={{marginBottom:{xs:'10px',sm:'15px',md:'15px',lg:'20px',xl:'57px'}}}>
                              
                                <input
                                   accept=".pdf, .doc, .docx"
                                    id="contained-button-file"
                                    onClick={event => event.target.value = ''}
                                    style={{display:'none'}}
                                    type="file"
                                    onChange={(event, newValue) => uploadNotesHandler(event, newValue)}
                                />
                            <Box sx={{display:'flex', gap:1, alignItems:'center'}}>
                            <label htmlFor="contained-button-file">
                                <Typography textAlign={'left'} variant='body1' sx={{fontSize:{xs:'13px',lg:'15px',xl:'20px'}, fontWeight:600, fontFamily:'Rubik', cursor:'pointer', textDecoration:'underline', color:'navy'}} >Upload Notes</Typography>
                            </label>
                            <Typography textAlign={'left'} variant='body1' sx={{fontSize:fileFormatError ? '10px' :'11px', fontWeight:600, fontFamily:'Rubik', ml:1, color: fileFormatError && 'red'}} >{fileFormatError ? notesErrorMessage : (!pageData?.note ? "(Maximum 100 MB, PDF, DOC, DOCX, or RTF only)" : pageData?.note?.name )}</Typography>

                           {pageData?.note?.name && !fileFormatError && <CloseRoundedIcon 
                                onClick={()=>setPageData((last) => {
                                          return { ...last, note: null }
                                        })} 
                                style={{color:'red', fontSize:'12px', cursor:'pointer'}} 
                            />}

                            </Box>
                        </Box> */}
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:5, mr:5}}>
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer', opacity: pageIndex === 1 && 0.5}} onClick={pageIndex !== 1 ? prevPageHandler : null}>/\</Box> */}
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer',  }} onClick={ finishedHandler}>\/</Box> */}
            <UpIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', opacity: pageIndex === 1 && 0.5, fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <DownIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} sx={{ cursor:'pointer', fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={ finishedHandler}/>
          </Box>
        </Box>
    </Backdrop>
    </Slide>
    </>
  )
}

export default NotesToEditorPage